import {yupResolver} from '@hookform/resolvers/yup';
import {Button, Typography, Link, Stack, Box} from '@mui/material';
import {Auth} from 'aws-amplify';
import pick from 'just-pick';
import {useState} from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';
import * as yup from 'yup';
import {BoxForAuth} from '../components/BoxForAuth';
import {LabelTitle} from '../components/LabelTitle';
import {TextFieldForAuth} from '../components/TextFieldForAuth';
import {useSignIn} from '../providers/AuthProvider';
import {ErrnoException} from '../utils/errnoException';
import {onPromise} from '../utils/promise';
import {AUTH_SCHEMA} from '../validators/schema';

interface FormInput {
  authCode: string;
}

const schema: yup.SchemaOf<FormInput> = yup
  .object()
  .shape(pick(AUTH_SCHEMA, ['authCode']));

export const SignUpVerificationScreen = () => {
  const {t} = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [codeMismatchException, setCodeMismatchException] = useState(false);
  const state = location.state as {
    email: string;
    password: string;
  };
  const {
    register,
    handleSubmit,
    formState: {errors, isValid},
  } = useForm<FormInput>({
    mode: 'all',
    resolver: yupResolver(schema),
  });
  const signIn = useSignIn();

  return (
    <BoxForAuth>
      <Stack spacing={3}>
        <Typography textAlign="center" fontWeight="bold" mt="16px">
          {t('sentAuthCode')}
        </Typography>
        <Typography textAlign="center" fontSize="14px">
          {t('pleaseInputAuthCode')}
        </Typography>
        <Stack sx={{width: '100%'}} spacing={1}>
          <LabelTitle isRequired>{t('authCode')}</LabelTitle>
          <TextFieldForAuth
            fullWidth
            size="small"
            variant="outlined"
            {...register('authCode')}
            error={'authCode' in errors || codeMismatchException}
            helperText={errors.authCode?.message}
          />
          <Box sx={{display: 'flex', justifyContent: 'end'}}>
            <Link
              component="button"
              underline="hover"
              onClick={() => {
                void Auth.resendSignUp(state.email);
              }}
              sx={{
                fontSize: '12px',
                fontWeight: '500',
                textAlign: 'right',
              }}>
              {t('resendAuthCode')}
            </Link>
          </Box>
        </Stack>
        <Button
          variant="contained"
          disabled={!isValid}
          onClick={onPromise(
            handleSubmit(data => {
              Auth.confirmSignUp(state.email, data.authCode)
                .then(async () => {
                  await signIn(state.email, state.password);
                  navigate('/');
                })
                .catch((e: ErrnoException) => {
                  if (e.code === 'CodeMismatchException') {
                    setCodeMismatchException(true);
                  } else {
                    navigate('/error');
                  }
                });
            }),
          )}>
          {t('next')}
        </Button>
      </Stack>
    </BoxForAuth>
  );
};
