import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import {ReactElement} from 'react';
import {useIsMobile} from './hooks/useIsMobile';

export const ThemeProvider = ({children}: {children: ReactElement}) => {
  const isMobile = useIsMobile();
  const theme = createTheme({
    typography: {
      fontSize: 14,
    },
    components: {
      MuiContainer: {
        styleOverrides: {
          root: {
            display: 'flex',
            justifyContent: 'center',
            padding: isMobile ? '24px' : '40px',
            minHeight: isMobile ? 'calc(100vh - 46px)' : 'calc(100vh - 64px)',
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            padding: isMobile
              ? '0 10px 0 24px !important'
              : '0 16px 0 36px !important',
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            borderRadius: '8px',
            padding: isMobile ? '12px' : '32px',
            '&:not(:last-child)': {
              borderBottom: 0,
            },
            '&:before': {
              display: 'none',
            },
            boxShadow: 'none',
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            padding: 0,
            minHeight: 0,
            '&.Mui-expanded': {
              minHeight: 0,
            },
            backgroundColor: '#FFFFFF',
            flexDirection: 'row-reverse',
            '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
              transform: 'rotate(90deg)',
            },
          },
          content: {
            margin: '0 0 0 12px !important',
            '&.Mui-expanded': {
              margin: '0 0 0 12px',
            },
          },
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            padding: isMobile ? '12px 0 0 20px' : '24px 0 0 28px',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          containedSizeMedium: {
            fontSize: '14px !important',
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            fontSize: isMobile ? '12px' : '14px',
            svg: {
              width: isMobile ? '20px' : '24px',
              height: isMobile ? '20px' : '24px',
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          scrollButtons: {
            width: isMobile ? '32px' : '40px',
          },
        },
      },
      MuiBadge: {
        styleOverrides: {
          badge: {
            backgroundColor: '#D32F2F',
            color: '#FFFFFF',
          },
        },
      },
    },
  });
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};
