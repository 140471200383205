import {yupResolver} from '@hookform/resolvers/yup';
import {DeleteOutline} from '@mui/icons-material';
import {
  Box,
  Grid,
  Stack,
  Typography,
  TextField,
  Button,
  Link,
  Select,
  MenuItem,
  IconButton,
  TextareaAutosize,
  InputAdornment,
} from '@mui/material';
import {useCallback} from 'react';
import {Controller, useForm, useFieldArray} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import * as yup from 'yup';
import {
  CreateDomesticParcelMutationVariables,
  CreateItemMutationVariables,
  Delivered,
  ItemCategory,
  RegistrationStatus,
} from '../API';
import {FormLabel} from '../components/FormLabel';
import {useOAuthUser} from '../providers/AuthProvider';
import {useCreateItemMutation} from '../queries/item';
import {useCreateDomesticParcelMutation} from '../queries/parcel';
import {useUser} from '../queries/user';
import {ITEM_CATEGORY_INFO} from '../utils/constants';
import {onPromise} from '../utils/promise';
import {ITEM_SCHEMA} from '../validators/schema';

interface ItemInputs {
  name: string;
  price: number;
  category: ItemCategory;
  quantity: number;
  url: string;
  note?: string;
}

const DefaultItemInputs: ItemInputs = {
  name: '',
  price: 0,
  quantity: 0,
  category: ItemCategory.Other,
  url: '',
} as const;

const schema: yup.SchemaOf<{items: ItemInputs[]}> = yup
  .object()
  .shape({items: yup.array().of(yup.object().shape(ITEM_SCHEMA))});

const getRegistrationStatus = (category: ItemCategory): RegistrationStatus =>
  ITEM_CATEGORY_INFO[category].isValidationRequired
    ? RegistrationStatus.Requesting
    : RegistrationStatus.Accepted;

export const RegisterItem = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const oAuthUser = useOAuthUser();
  const {data: user} = useUser(oAuthUser?.username ?? '');
  const {
    control,
    register,
    handleSubmit,
    formState: {errors, isValid},
  } = useForm<{items: ItemInputs[]}>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {items: [DefaultItemInputs]},
  });
  const {fields, append, remove} = useFieldArray({
    control,
    name: 'items',
    rules: {minLength: 1, required: true},
  });
  const {mutateAsync: createItemMutationAsync} = useCreateItemMutation();
  const {mutate: createDomesticParcelMutation} =
    useCreateDomesticParcelMutation();

  const onClickRegister = useCallback(
    (data: {items: ItemInputs[]}) => {
      try {
        const isAcceptableParcel = data.items.every(
          i =>
            getRegistrationStatus(i.category) === RegistrationStatus.Accepted,
        );
        const parcelVariables: CreateDomesticParcelMutationVariables = {
          input: {
            accepted: isAcceptableParcel,
            delivered: false,
            deliveredEnum: Delivered.No,
            userDomesticParcelId: user?.id,
          },
        };
        createDomesticParcelMutation(parcelVariables, {
          onSuccess: async result => {
            const itemVariables: CreateItemMutationVariables[] = data.items.map(
              item => {
                return {
                  input: {
                    ...item,
                    registrationStatus: getRegistrationStatus(item.category),
                    userItemsId: user?.id,
                    domesticParcelItemsId: result?.id,
                  },
                };
              },
            );

            // TODO: [SLIN-5] implement bulk mutation by custom resolver
            await Promise.all(
              itemVariables.map(async v => await createItemMutationAsync(v)),
            ).catch(() => {
              navigate('/error');
            });
            navigate('/');
          },
        });
      } catch (_e) {
        navigate('/error');
      }
    },
    [createDomesticParcelMutation, createItemMutationAsync, user?.id, navigate],
  );

  return (
    <Stack>
      <Typography
        fontWeight="bold"
        sx={{
          fontSize: {xs: '18px', md: '24px'},
          mb: {xs: '16px', md: '24px'},
        }}>
        {t('newParcelRegistration')}
      </Typography>
      <Box
        sx={{
          bgcolor: '#FFFFFF',
          width: {xs: '100%', md: '900px'},
          borderRadius: '8px',
          boxSizing: 'border-box',
          p: {xs: '12px', md: '32px'},
        }}>
        {fields.map((field, index) => (
          <Stack key={field.id} sx={{mb: '32px'}}>
            <Stack flexDirection="row" alignItems="center" sx={{mb: '16px'}}>
              <Typography fontWeight="bold" fontSize="16px">
                {t('point', {x: index + 1})}
              </Typography>
              {index > 0 ? (
                <IconButton
                  aria-label="delete"
                  size="small"
                  sx={{ml: '8px', background: '#E9EAEE'}}
                  onClick={() => {
                    remove(index);
                  }}>
                  <DeleteOutline />
                </IconButton>
              ) : null}
            </Stack>
            <Grid container spacing="32px">
              <Grid item xs={12} md={6}>
                <Stack spacing="32px">
                  <Stack spacing="8px">
                    <FormLabel isRequired label={t('itemName')} />
                    <TextField
                      fullWidth
                      size="small"
                      placeholder="example"
                      {...register(`items.${index}.name` as const)}
                      error={!!errors?.items?.[index]?.name}
                    />
                  </Stack>
                  <Stack spacing="8px">
                    <FormLabel isRequired label={t('itemPrice')} />
                    <TextField
                      fullWidth
                      size="small"
                      placeholder="example"
                      {...register(`items.${index}.price` as const)}
                      error={!!errors?.items?.[index]?.price}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {t('yen')}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Stack>
                  <Stack spacing="8px">
                    <FormLabel isRequired label={t('itemQuantity')} />
                    <TextField
                      fullWidth
                      size="small"
                      placeholder="example"
                      {...register(`items.${index}.quantity` as const)}
                      error={!!errors?.items?.[index]?.quantity}
                    />
                  </Stack>
                </Stack>
              </Grid>

              <Grid item xs={12} md={6}>
                <Stack spacing="32px">
                  <Stack spacing="8px">
                    <FormLabel isRequired label={t('itemType')} />
                    <Controller
                      name={`items.${index}.category`}
                      control={control}
                      render={() => (
                        <Select
                          fullWidth
                          required
                          size="small"
                          defaultValue={ItemCategory.Other}
                          {...register(`items.${index}.category` as const)}
                          error={!!errors?.items?.[index]?.category}>
                          {Object.values(ItemCategory).map(v => (
                            <MenuItem key={v} value={v}>
                              {ITEM_CATEGORY_INFO[v].getLabel()}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </Stack>
                  <Stack spacing="8px">
                    <FormLabel isRequired label={t('itemURL')} />
                    <TextField
                      fullWidth
                      size="small"
                      placeholder="example"
                      {...register(`items.${index}.url` as const)}
                      error={!!errors?.items?.[index]?.url}
                    />
                  </Stack>
                  <Stack spacing="8px">
                    <FormLabel label={t('note')} />
                    <TextField
                      fullWidth
                      size="small"
                      placeholder="example"
                      {...register(`items.${index}.note` as const)}
                      error={!!errors?.items?.[index]?.note}
                      InputProps={{
                        inputComponent: TextareaAutosize,
                      }}
                    />
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        ))}
        <Link
          component="button"
          underline="hover"
          sx={{
            fontSize: '14px',
            textAlign: 'left',
          }}
          onClick={() => {
            append(DefaultItemInputs);
          }}>
          {t('addItem')}
        </Link>
        <Stack
          direction="row"
          justifyContent="flex-end"
          spacing="24px"
          sx={{mt: '32px'}}>
          <Button
            variant="outlined"
            color="inherit"
            onClick={() => {
              navigate('/');
            }}>
            {t('cancel')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={!isValid}
            onClick={onPromise(handleSubmit(onClickRegister))}>
            {t('register')}
          </Button>
        </Stack>
      </Box>
    </Stack>
  );
};
