import {yupResolver} from '@hookform/resolvers/yup';
import {DeleteOutlined} from '@mui/icons-material';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import {
  Box,
  Grid,
  Stack,
  Typography,
  TextField,
  Select,
  MenuItem,
  Button,
  IconButton,
} from '@mui/material';
import {Auth, Storage} from 'aws-amplify';
import Image from 'mui-image';
import {useCallback, useState} from 'react';
import {useForm, Controller} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useQueryClient} from 'react-query';
import {useNavigate} from 'react-router-dom';
import * as yup from 'yup';
import {CreateUserMutationVariables, Gender, RegistrationStatus} from '../API';
import awsExports from '../aws-exports';
import {FormLabel} from '../components/FormLabel';
import {useOAuthUser} from '../providers/AuthProvider';
import {useCreateUserMutation} from '../queries/user';
import {onPromise} from '../utils/promise';
import {REGION_INFO} from '../utils/region';
import {USER_SCHEMA} from '../validators/schema';

interface ProfileInputs {
  name: string;
  companyName?: string;
  email: string;
  birthday: Date;
  gender: Gender;
  idPictureUrl: string;
  job: string;
  i18nAreaCodeMobile?: string;
  mobilePhoneNumber?: string;
  i18nAreaCodeFixed?: string;
  fixedPhoneNumber?: string;
  detailedAddress: string;
  city: string;
  provinceOrState: string;
  countryOrRegion: string;
  postalCode: string;
}

const schema: yup.SchemaOf<ProfileInputs> = yup.object().shape(USER_SCHEMA);

export const ProfileSettings = (): JSX.Element => {
  const {t} = useTranslation();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: {errors, isValid},
  } = useForm<ProfileInputs>({mode: 'onBlur', resolver: yupResolver(schema)});
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const user = useOAuthUser();
  const {mutate: createUserMutation} = useCreateUserMutation();
  const [idPictureFile, setIdPictureFile] = useState<File>();

  const onClickUpload = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files?.[0]) {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = e => {
          if (e?.target?.result && typeof e.target.result === 'string') {
            setIdPictureFile(file);
            setValue('idPictureUrl', e.target.result, {shouldValidate: true});
          }
        };
        reader.readAsDataURL(file);
        e.target.value = ''; // NOTE: clear value to upload same file again
      }
    },
    [setValue],
  );

  const onClickRegister = useCallback(
    async (data: ProfileInputs) => {
      const onError = (_e: unknown) => {
        navigate('/error');
      };
      const onSuccess = async () => {
        await queryClient.invalidateQueries();
        navigate('/');
      };

      try {
        const username = user?.username;
        const {birthday, idPictureUrl, ...inputData} = data;
        if (!username || !idPictureUrl || !idPictureFile) {
          throw new Error('invalid_params');
        }
        const userCredential = await Auth.currentUserCredentials();
        const identityId = userCredential.identityId;
        const idPictureName = `idPicture/${username}.jpg`;
        const variables: CreateUserMutationVariables = {
          input: {
            id: username,
            identityId,
            registrationStatus: RegistrationStatus.Requesting,
            idPicture: {
              bucket: awsExports.aws_user_files_s3_bucket,
              region: awsExports.aws_user_files_s3_bucket_region,
              key: idPictureName,
            },
            birthday: birthday.getTime(),
            ...inputData,
          },
        };
        await Storage.put(idPictureName, idPictureFile, {
          level: 'private',
          contentType: idPictureFile.type,
        });
        createUserMutation(variables, {
          onSuccess,
          onError,
        });
      } catch (e) {
        onError(e);
      }
    },
    [createUserMutation, idPictureFile, navigate, queryClient, user?.username],
  );

  return (
    <Stack>
      <Box
        sx={{
          bgcolor: '#FFFFFF',
          width: {xs: '100%', md: '900px'},
          borderRadius: '8px',
          boxSizing: 'border-box',
          p: {xs: '12px', md: '32px'},
        }}>
        <Stack>
          <Typography
            fontWeight="bold"
            sx={{
              fontSize: {xs: '18px', md: '24px'},
              mb: {xs: '16px', md: '24px'},
            }}>
            {t('profileSettings')}
          </Typography>
        </Stack>
        <Grid container spacing="32px">
          <Grid item xs={12} md={6}>
            <Stack spacing="32px">
              <Stack spacing="8px">
                <FormLabel isRequired label={t('name')} />
                <TextField
                  fullWidth
                  size="small"
                  placeholder="example"
                  {...register('name')}
                  error={'name' in errors}
                  helperText={errors.name?.message}
                />
              </Stack>
              <Stack spacing="8px">
                <FormLabel label={t('companyName')} />
                <TextField
                  fullWidth
                  size="small"
                  placeholder="example"
                  {...register('companyName')}
                  error={'companyName' in errors}
                  helperText={errors.companyName?.message}
                />
              </Stack>
              <Stack spacing="8px">
                <FormLabel isRequired label={t('email')} />
                <TextField
                  fullWidth
                  size="small"
                  placeholder="example@mail.com"
                  {...register('email')}
                  error={'email' in errors}
                  helperText={errors.email?.message}
                />
              </Stack>
              <Stack spacing="8px">
                <FormLabel isRequired label={t('birthday')} />
                <TextField
                  fullWidth
                  type="date"
                  size="small"
                  placeholder="DD MM YYYY"
                  defaultValue="2017-05-24"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register('birthday')}
                  error={'birthday' in errors}
                  helperText={errors.birthday?.message}
                />
              </Stack>
              <Stack spacing="8px">
                <FormLabel isRequired label={t('gender')} />
                <Controller
                  name="gender"
                  control={control}
                  render={() => (
                    <Select
                      fullWidth
                      required
                      size="small"
                      defaultValue={Gender.Other}
                      {...register('gender')}
                      error={'gender' in errors}>
                      <MenuItem value={Gender.Male}>{t('male')}</MenuItem>
                      <MenuItem value={Gender.Female}>{t('female')}</MenuItem>
                      <MenuItem value={Gender.Other}>{t('other')}</MenuItem>
                    </Select>
                  )}
                />
              </Stack>
              <Stack spacing="8px">
                <FormLabel isRequired label={t('idPhoto')} />
                <Button
                  component="label"
                  variant="outlined"
                  color="inherit"
                  sx={{width: '180px'}}
                  startIcon={<SettingsOutlinedIcon />}>
                  {t('clickToUpload')}
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={onClickUpload}
                  />
                </Button>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{
                    height: '66px',
                    px: '8px',
                    py: '8px',
                    border: 1,
                    borderRadius: '2px',
                    borderColor: '#D9D9D9',
                  }}>
                  {getValues('idPictureUrl') && (
                    <Image
                      src={getValues('idPictureUrl') ?? ''}
                      width="48px"
                      height="48px"
                    />
                  )}
                  <Typography sx={{ml: '8px'}}>
                    {idPictureFile?.name ?? ''}
                  </Typography>
                  {idPictureFile && (
                    <IconButton
                      sx={{ml: 'auto', mr: '8px'}}
                      onClick={() => {
                        setIdPictureFile(undefined);
                        setValue('idPictureUrl', '', {shouldValidate: true});
                      }}>
                      <DeleteOutlined />
                    </IconButton>
                  )}
                </Stack>
              </Stack>
              <Stack spacing="8px">
                <FormLabel isRequired label={t('job')} />
                <TextField
                  fullWidth
                  size="small"
                  placeholder="example"
                  {...register('job')}
                  error={'job' in errors}
                  helperText={errors.job?.message}
                />
              </Stack>
            </Stack>
          </Grid>

          <Grid item xs={12} md={6}>
            <Stack spacing="32px">
              <Stack spacing="8px">
                <FormLabel label={t('mobilePhoneNo')} />
                <Grid container columnGap="8px" rowGap="4px">
                  <Controller
                    name="i18nAreaCodeMobile"
                    control={control}
                    render={() => (
                      <Select
                        size="small"
                        sx={{width: '150px', maxHeight: '40px'}}
                        defaultValue={REGION_INFO.China.countryCode}
                        {...register('i18nAreaCodeMobile')}
                        error={'i18nAreaCodeMobile' in errors}>
                        <MenuItem value={REGION_INFO.China.countryCode}>
                          {REGION_INFO.China.getLocalizedCountryCode()}
                        </MenuItem>
                        <MenuItem value={REGION_INFO.Japan.countryCode}>
                          {REGION_INFO.Japan.getLocalizedCountryCode()}
                        </MenuItem>
                        <MenuItem value={REGION_INFO.HongKong.countryCode}>
                          {REGION_INFO.HongKong.getLocalizedCountryCode()}
                        </MenuItem>
                      </Select>
                    )}
                  />
                  <TextField
                    size="small"
                    sx={{width: '242px', maxHeight: '40px'}}
                    placeholder="example"
                    {...register('mobilePhoneNumber')}
                    error={'mobilePhoneNumber' in errors}
                    helperText={errors.mobilePhoneNumber?.message}
                  />
                </Grid>
              </Stack>
              <Stack spacing="8px">
                <FormLabel label={t('fixedPhoneNo')} />
                <Grid container columnGap="8px" rowGap="4px">
                  <Grid item>
                    <Controller
                      name="i18nAreaCodeFixed"
                      control={control}
                      render={() => (
                        <Select
                          size="small"
                          sx={{width: '150px', maxHeight: '40px'}}
                          defaultValue={REGION_INFO.China.countryCode}
                          {...register('i18nAreaCodeFixed')}
                          error={'i18nAreaCodeFixed' in errors}>
                          <MenuItem value={REGION_INFO.China.countryCode}>
                            {REGION_INFO.China.getLocalizedCountryCode()}
                          </MenuItem>
                          <MenuItem value={REGION_INFO.Japan.countryCode}>
                            {REGION_INFO.Japan.getLocalizedCountryCode()}
                          </MenuItem>
                          <MenuItem value={REGION_INFO.HongKong.countryCode}>
                            {REGION_INFO.HongKong.getLocalizedCountryCode()}
                          </MenuItem>
                        </Select>
                      )}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      size="small"
                      sx={{width: '242px', maxHeight: '40px'}}
                      placeholder="example"
                      {...register('fixedPhoneNumber')}
                      error={'fixedPhoneNumber' in errors}
                      helperText={errors.fixedPhoneNumber?.message}
                    />
                  </Grid>
                </Grid>
              </Stack>
              <Stack spacing="8px">
                <FormLabel isRequired label={t('detailedAddress')} />
                <TextField
                  fullWidth
                  size="small"
                  placeholder="example"
                  {...register('detailedAddress')}
                  error={'detailedAddress' in errors}
                  helperText={errors.detailedAddress?.message}
                />
              </Stack>
              <Stack spacing="8px">
                <FormLabel isRequired label={t('city')} />
                <TextField
                  fullWidth
                  size="small"
                  placeholder="example"
                  {...register('city')}
                  error={'city' in errors}
                  helperText={errors.city?.message}
                />
              </Stack>
              <Stack spacing="8px">
                <FormLabel isRequired label={t('provinceState')} />
                <TextField
                  fullWidth
                  size="small"
                  placeholder="example"
                  {...register('provinceOrState')}
                  error={'provinceOrState' in errors}
                  helperText={errors.provinceOrState?.message}
                />
              </Stack>
            </Stack>
            <Grid container spacing="12px" sx={{mt: '32px'}}>
              <Grid item xs={6}>
                <Stack spacing="8px">
                  <FormLabel isRequired label={t('countryRegion')} />
                  <Controller
                    name="countryOrRegion"
                    control={control}
                    render={() => (
                      <Select
                        fullWidth
                        required
                        size="small"
                        defaultValue={REGION_INFO.China.regionName}
                        {...register('countryOrRegion')}
                        error={'countryOrRegion' in errors}>
                        <MenuItem value={REGION_INFO.China.regionName}>
                          {REGION_INFO.China.getLocalizedRegionName()}
                        </MenuItem>
                        <MenuItem value={REGION_INFO.Japan.regionName}>
                          {REGION_INFO.Japan.getLocalizedRegionName()}
                        </MenuItem>
                        <MenuItem value={REGION_INFO.HongKong.regionName}>
                          {REGION_INFO.HongKong.getLocalizedRegionName()}
                        </MenuItem>
                      </Select>
                    )}
                  />
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack spacing="8px">
                  <FormLabel isRequired label={t('postalCode')} />
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="example"
                    {...register('postalCode')}
                    error={'postalCode' in errors}
                    helperText={errors.postalCode?.message}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Stack
          direction="row"
          justifyContent="flex-end"
          spacing="24px"
          sx={{mt: '56px'}}>
          <Button
            variant="contained"
            color="primary"
            disabled={!isValid}
            onClick={onPromise(handleSubmit(onClickRegister))}>
            {t('save')}
          </Button>
        </Stack>
      </Box>
    </Stack>
  );
};
