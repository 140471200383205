import {styled, TextField} from '@mui/material';

export const TextFieldForAuth = styled(TextField)`
  &.MuiOutlinedInput-root {
    & fieldset {
      border-color: '#D9D9D9';
    }
    &:hover fieldset {
      border-color: '#D9D9D9';
    }
    &.Mui-focused fieldset {
      border-color: '#0050B3';
    }
    &.Mui-error fieldset {
      border-color: '#FF4D4F';
    }
  }
`;
