import {Stack, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {Button} from '../components/Button';

export const NotFoundErrorScreen = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  return (
    <Stack alignItems="center" mt="100px">
      <Stack spacing={2} alignItems="center">
        <Typography
          color="#DADCE3"
          sx={{fontSize: '160px', fontWeight: 'bold'}}>
          {t('404')}
        </Typography>
        <Typography sx={{fontSize: '20px', fontWeight: 'bold'}}>
          {t('pageNotFound')}
        </Typography>
        <Typography>{t('serviceUnavailable')}</Typography>
      </Stack>
      <Button
        variant="contained"
        sx={{mt: '46px'}}
        onClick={() => navigate('/')}>
        {t('home')}
      </Button>
    </Stack>
  );
};
