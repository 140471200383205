import {GraphQLResult} from '@aws-amplify/api-graphql';
import {API, graphqlOperation} from 'aws-amplify';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {
  ListDomesticParcelsQueryVariables,
  ListDomesticParcelsQuery,
  ListInternationalParcelsQueryVariables,
  ListInternationalParcelsQuery,
  CreateInternationalParcelMutationVariables,
  CreateInternationalParcelMutation,
  CreateDomesticParcelMutationVariables,
  CreateDomesticParcelMutation,
  UpdateDomesticParcelMutationVariables,
  UpdateDomesticParcelMutation,
  DeleteDomesticParcelMutation,
  DeleteDomesticParcelMutationVariables,
  GetDomesticParcelQueryVariables,
  GetDomesticParcelQuery,
  UpdateInternationalParcelMutationVariables,
  UpdateInternationalParcelMutation,
  GetInternationalParcelQueryVariables,
  GetInternationalParcelQuery,
} from '../API';
import {
  createDomesticParcel,
  createInternationalParcel,
  deleteDomesticParcel,
  updateDomesticParcel,
  updateInternationalParcel,
} from '../graphql/mutations';
import {
  getDomesticParcel,
  getInternationalParcel,
  listDomesticParcels,
  listInternationalParcels,
} from '../graphql/queries';

/**
 * 国内小包一覧 (登録済み小包一覧)
 */
export const useNotAcceptedDomesticParcels = () => {
  return useQuery(['domesticParcels', 'notAccepted'], async () => {
    const variable: ListDomesticParcelsQueryVariables = {
      filter: {
        and: [
          {
            accepted: {
              eq: false,
            },
          },
          {
            slipNumber: {
              attributeExists: false,
            },
          },
          {
            delivered: {
              eq: false,
            },
          },
        ],
      },
    };
    const result = (await API.graphql(
      graphqlOperation(listDomesticParcels, variable),
    )) as GraphQLResult<ListDomesticParcelsQuery>;
    return result.data?.listDomesticParcels;
  });
};

/**
 * 国内小包一覧 (配達番号記入待ち)
 */
export const useAcceptedDomesticParcels = () => {
  return useQuery(['domesticParcels', 'accepted'], async () => {
    const variable: ListDomesticParcelsQueryVariables = {
      filter: {
        and: [
          {
            accepted: {
              eq: true,
            },
          },
          {
            slipNumber: {
              attributeExists: false,
            },
          },
          {
            delivered: {
              eq: false,
            },
          },
        ],
      },
    };
    const result = (await API.graphql(
      graphqlOperation(listDomesticParcels, variable),
    )) as GraphQLResult<ListDomesticParcelsQuery>;
    return result.data?.listDomesticParcels;
  });
};

/**
 * 国内小包一覧 (入庫待ち)
 */
export const useDomesticParcelsInTransit = () => {
  return useQuery(['domesticParcels', 'inTransit'], async () => {
    const variable: ListDomesticParcelsQueryVariables = {
      filter: {
        and: [
          {
            accepted: {
              eq: true,
            },
          },
          {
            slipNumber: {
              attributeExists: true,
            },
          },
          {
            delivered: {
              eq: false,
            },
          },
        ],
      },
    };
    const result = (await API.graphql(
      graphqlOperation(listDomesticParcels, variable),
    )) as GraphQLResult<ListDomesticParcelsQuery>;
    return result.data?.listDomesticParcels;
  });
};

/**
 * 国内小包一覧 (入庫済み/国際発送待ち)
 */
export const useDeliveredDomesticParcels = () => {
  return useQuery(['domesticParcels', 'delivered'], async () => {
    const variable: ListDomesticParcelsQueryVariables = {
      filter: {
        and: [
          {
            accepted: {
              eq: true,
            },
          },
          {
            slipNumber: {
              attributeExists: true,
            },
          },
          {
            delivered: {
              eq: true,
            },
          },
        ],
      },
    };
    const result = (await API.graphql(
      graphqlOperation(listDomesticParcels, variable),
    )) as GraphQLResult<ListDomesticParcelsQuery>;
    return result.data?.listDomesticParcels;
  });
};

/**
 * 国際小包一覧 (国際発送依頼済み)
 */
export const useNotDeliveredInternationalParcels = () => {
  return useQuery(['internationalParcels', 'notDelivered'], async () => {
    const filter = {
      and: [
        {
          slipNumber: {
            attributeExists: false,
          },
        },
        {
          delivered: {
            eq: false,
          },
        },
      ],
    };
    const variable: ListInternationalParcelsQueryVariables = {
      filter,
    };
    const result = (await API.graphql(
      graphqlOperation(listInternationalParcels, variable),
    )) as GraphQLResult<ListInternationalParcelsQuery>;
    return result.data?.listInternationalParcels;
  });
};

/**
 * 国際小包一覧 (国際発送済み)
 */
export const useInternationalParcelsInTransit = () => {
  return useQuery(['internationalParcels', 'inTransit'], async () => {
    const filter = {
      and: [
        {
          slipNumber: {
            attributeExists: true,
          },
        },
        {
          delivered: {
            eq: false,
          },
        },
      ],
    };
    const variable: ListInternationalParcelsQueryVariables = {
      filter,
    };
    const result = (await API.graphql(
      graphqlOperation(listInternationalParcels, variable),
    )) as GraphQLResult<ListInternationalParcelsQuery>;
    return result.data?.listInternationalParcels;
  });
};

/**
 * 国際小包一覧 (受取完了)
 */
export const useDeliveredInternationalParcels = () => {
  return useQuery(['internationalParcels', 'delivered'], async () => {
    const filter = {
      and: [
        {
          slipNumber: {
            attributeExists: true,
          },
        },
        {
          delivered: {
            eq: true,
          },
        },
      ],
    };
    const variable: ListInternationalParcelsQueryVariables = {
      filter,
    };
    const result = (await API.graphql(
      graphqlOperation(listInternationalParcels, variable),
    )) as GraphQLResult<ListInternationalParcelsQuery>;
    return result.data?.listInternationalParcels;
  });
};

export const useDomesticParcels = () => {
  return useQuery(['domesticParcels'], async () => {
    const result = (await API.graphql(
      graphqlOperation(listDomesticParcels),
    )) as GraphQLResult<ListDomesticParcelsQuery>;
    return result.data?.listDomesticParcels;
  });
};

export const useDomesticParcel = (parcelId: string) => {
  return useQuery(['domesticParcel', parcelId], async () => {
    const variables: GetDomesticParcelQueryVariables = {id: parcelId};
    const result = (await API.graphql(
      graphqlOperation(getDomesticParcel, variables),
    )) as GraphQLResult<GetDomesticParcelQuery>;
    return result.data?.getDomesticParcel;
  });
};

export const useInternationalParcels = () => {
  return useQuery(['internationalParcels'], async () => {
    const result = (await API.graphql(
      graphqlOperation(listInternationalParcels),
    )) as GraphQLResult<ListInternationalParcelsQuery>;
    return result.data?.listInternationalParcels;
  });
};

export const useInternationalParcel = (parcelId: string) => {
  return useQuery(['InternationalParcel', parcelId], async () => {
    const variables: GetInternationalParcelQueryVariables = {id: parcelId};
    const result = (await API.graphql(
      graphqlOperation(getInternationalParcel, variables),
    )) as GraphQLResult<GetInternationalParcelQuery>;
    return result.data?.getInternationalParcel;
  });
};

export const useCreateDomesticParcelMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (variables: CreateDomesticParcelMutationVariables) => {
      const result = (await API.graphql(
        graphqlOperation(createDomesticParcel, variables),
      )) as GraphQLResult<CreateDomesticParcelMutation>;
      return result.data?.createDomesticParcel;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries();
      },
    },
  );
};

export const useUpdateDomesticParcelMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (variables: UpdateDomesticParcelMutationVariables) => {
      const result = (await API.graphql(
        graphqlOperation(updateDomesticParcel, variables),
      )) as GraphQLResult<UpdateDomesticParcelMutation>;
      return result.data?.updateDomesticParcel;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries();
      },
    },
  );
};

export const useDeleteDomesticParcelMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (variables: DeleteDomesticParcelMutationVariables) => {
      const result = (await API.graphql(
        graphqlOperation(deleteDomesticParcel, variables),
      )) as GraphQLResult<DeleteDomesticParcelMutation>;
      return result.data?.deleteDomesticParcel;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries();
      },
    },
  );
};

export const useCreateInternationalParcelMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (variables: CreateInternationalParcelMutationVariables) => {
      const result = (await API.graphql(
        graphqlOperation(createInternationalParcel, variables),
      )) as GraphQLResult<CreateInternationalParcelMutation>;
      return result.data?.createInternationalParcel;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries();
      },
    },
  );
};

export const useUpdateInternationalParcelMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (variables: UpdateInternationalParcelMutationVariables) => {
      const result = (await API.graphql(
        graphqlOperation(updateInternationalParcel, variables),
      )) as GraphQLResult<UpdateInternationalParcelMutation>;
      return result.data?.updateInternationalParcel;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries();
      },
    },
  );
};
