import {yupResolver} from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  Link,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {t} from 'i18next';
import pick from 'just-pick';
import {useEffect, useMemo, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {Navigate, useLocation, useNavigate} from 'react-router-dom';
import * as yup from 'yup';
import {InternationalDeliveryCompany, ShippingInfo} from '../API';
import {FormLabel} from '../components/FormLabel';
import {ShippingInfoTile} from '../components/ShippingInformation';
import {useIsMobile} from '../hooks/useIsMobile';
import {useOAuthUser} from '../providers/AuthProvider';
import {useDeliveredDomesticParcels} from '../queries/parcel';
import {useUser} from '../queries/user';
import {getJapanPostCharge, getSfExpressCharge} from '../utils/charge';
import {JAPAN_POST_CHARGES, SF_EXPRESS_CHARGES} from '../utils/constants';
import {REGION_INFO} from '../utils/region';
import {isArray, notEmpty} from '../utils/type';
import {
  SELECT_DELIVERY_COMPANY_SCHEMA,
  USER_SCHEMA,
} from '../validators/schema';

const shippingInfoSchema: yup.SchemaOf<Omit<ShippingInfo, '__typename'>> = yup
  .object()
  .shape(
    pick(USER_SCHEMA, [
      'name',
      'i18nAreaCodeMobile',
      'mobilePhoneNumber',
      'i18nAreaCodeFixed',
      'fixedPhoneNumber',
      'detailedAddress',
      'city',
      'provinceOrState',
      'countryOrRegion',
      'postalCode',
    ]),
  );

// TODO: fix warning(components cannot be given refs. Attempts to access this ref will fail. Did you mean to use React.forwardRef()?)
const UpdateShippingInfo = ({
  closeModal,
  onSubmitShippingInfo,
}: {
  closeModal: () => void;
  onSubmitShippingInfo: (updatedInfo: Omit<ShippingInfo, '__typename'>) => void;
}) => {
  const {
    register,
    control,
    handleSubmit,
    formState: {errors, isValid},
  } = useForm<Omit<ShippingInfo, '__typename'>>({
    mode: 'onBlur',
    resolver: yupResolver(shippingInfoSchema),
  });
  const isMobile = useIsMobile();

  return (
    <Stack
      alignItems="center"
      sx={{
        my: '5%',
        height: '90%',
      }}>
      <Box
        sx={{
          bgcolor: '#FFFFFF',
          height: '1061px',
          width: {xs: '302px', md: '468px'},
          overflow: 'scroll',
          overflowX: 'hidden',
        }}>
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            height: '54px',
          }}>
          <Typography
            sx={{
              py: '16px',
              px: ' 24px',
              fontSize: '16px',
            }}>
            {t('registerShippingAddress')}
          </Typography>
          <CloseIcon
            onClick={closeModal}
            sx={{
              py: '16px',
              px: ' 24px',
            }}
          />
        </Stack>
        <Divider
          sx={{
            border: '1px solid rgba(0, 0, 0, 0.12)',
          }}
        />
        <Stack spacing={isMobile ? '28px' : '32px'} sx={{p: '24px'}}>
          <Stack spacing="8px">
            <FormLabel isRequired label={t('name')} />
            <TextField
              fullWidth
              size="small"
              placeholder="example"
              {...register('name')}
              error={'name' in errors}
              helperText={errors.name?.message}
            />
          </Stack>
          <Stack spacing="8px">
            <FormLabel label={t('mobilePhoneNo')} />
            <Grid container columnGap="8px" rowGap="8px">
              <Grid item>
                <Controller
                  name="i18nAreaCodeMobile"
                  control={control}
                  render={() => (
                    <Select
                      size="small"
                      sx={{width: '150px'}}
                      defaultValue={REGION_INFO.China.countryCode}
                      {...register('i18nAreaCodeMobile')}
                      error={'i18nAreaCodeMobile' in errors}>
                      <MenuItem value={REGION_INFO.China.countryCode}>
                        {REGION_INFO.China.getLocalizedCountryCode()}
                      </MenuItem>
                      <MenuItem value={REGION_INFO.Japan.countryCode}>
                        {REGION_INFO.Japan.getLocalizedCountryCode()}
                      </MenuItem>
                      <MenuItem value={REGION_INFO.HongKong.countryCode}>
                        {REGION_INFO.HongKong.getLocalizedCountryCode()}
                      </MenuItem>
                    </Select>
                  )}
                />
              </Grid>
              <Grid item>
                <TextField
                  size="small"
                  sx={{width: '242px'}}
                  placeholder={t('fixedPhoneNo')}
                  {...register('mobilePhoneNumber')}
                  error={'mobilePhoneNumber' in errors}
                  helperText={errors.mobilePhoneNumber?.message}
                />
              </Grid>
            </Grid>
          </Stack>
          <Stack spacing="8px" sx={{width: '100%'}}>
            <FormLabel label={t('fixedPhoneNo')} />
            <Grid container columnGap="8px" rowGap="8px">
              <Grid item>
                <Controller
                  name="i18nAreaCodeFixed"
                  control={control}
                  render={() => (
                    <Select
                      size="small"
                      sx={{width: '150px'}}
                      defaultValue={REGION_INFO.China.countryCode}
                      {...register('i18nAreaCodeFixed')}
                      error={'i18nAreaCodeFixed' in errors}>
                      <MenuItem value={REGION_INFO.China.countryCode}>
                        {REGION_INFO.China.getLocalizedCountryCode()}
                      </MenuItem>
                      <MenuItem value={REGION_INFO.Japan.countryCode}>
                        {REGION_INFO.Japan.getLocalizedCountryCode()}
                      </MenuItem>
                      <MenuItem value={REGION_INFO.HongKong.countryCode}>
                        {REGION_INFO.HongKong.getLocalizedCountryCode()}
                      </MenuItem>
                    </Select>
                  )}
                />
              </Grid>
              <Grid item>
                <TextField
                  size="small"
                  sx={{width: '242px'}}
                  placeholder={t('mobilePhoneNo')}
                  {...register('fixedPhoneNumber')}
                  error={'fixedPhoneNumber' in errors}
                  helperText={errors.fixedPhoneNumber?.message}
                />
              </Grid>
            </Grid>
          </Stack>
          <Stack spacing="8px">
            <FormLabel isRequired label={t('detailedAddress')} />
            <TextField
              fullWidth
              size="small"
              placeholder="example"
              {...register('detailedAddress')}
              error={'detailedAddress' in errors}
              helperText={errors.detailedAddress?.message}
            />
          </Stack>
          <Stack spacing="8px">
            <FormLabel isRequired label={t('city')} />
            <TextField
              fullWidth
              size="small"
              placeholder="example"
              {...register('city')}
              error={'city' in errors}
              helperText={errors.city?.message}
            />
          </Stack>
          <Stack spacing="8px">
            <FormLabel isRequired label={t('provinceState')} />
            <TextField
              fullWidth
              size="small"
              placeholder="example"
              {...register('provinceOrState')}
              error={'provinceOrState' in errors}
              helperText={errors.provinceOrState?.message}
            />
          </Stack>
          <Stack spacing="8px">
            <FormLabel isRequired label={t('countryRegion')} />
            <Controller
              name="countryOrRegion"
              control={control}
              render={() => (
                <Select
                  fullWidth
                  required
                  size="small"
                  defaultValue={REGION_INFO.China.regionName}
                  {...register('countryOrRegion')}
                  error={'countryOrRegion' in errors}>
                  <MenuItem value={REGION_INFO.China.regionName}>
                    {REGION_INFO.China.getLocalizedRegionName()}
                  </MenuItem>
                  <MenuItem value={REGION_INFO.Japan.regionName}>
                    {REGION_INFO.Japan.getLocalizedRegionName()}
                  </MenuItem>
                  <MenuItem value={REGION_INFO.HongKong.regionName}>
                    {REGION_INFO.HongKong.getLocalizedRegionName()}
                  </MenuItem>
                </Select>
              )}
            />
          </Stack>
          <Stack spacing="8px">
            <FormLabel isRequired label={t('postalCode')} />
            <TextField
              fullWidth
              size="small"
              placeholder="example"
              {...register('postalCode')}
              error={'postalCode' in errors}
              helperText={errors.postalCode?.message}
            />
          </Stack>
        </Stack>
        <Divider
          sx={{
            border: '1px solid rgba(0, 0, 0, 0.12)',
          }}
        />
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          sx={{px: '16px', py: '10px'}}>
          <Button
            variant="outlined"
            color="inherit"
            onClick={closeModal}
            sx={{height: '32px', mr: '8px'}}>
            {t('cancel')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={!isValid}
            onClick={() => {
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              handleSubmit(onSubmitShippingInfo)();
              closeModal();
            }}
            sx={{height: '32px'}}>
            {t('save')}
          </Button>
        </Stack>
      </Box>
    </Stack>
  );
};

interface SelectDeliveryCompanyInput {
  deliveryCompany: InternationalDeliveryCompany;
  declaredPrice: number;
}
const schema: yup.SchemaOf<SelectDeliveryCompanyInput> = yup
  .object()
  .shape(SELECT_DELIVERY_COMPANY_SCHEMA);

export const SelectDeliveryCompany = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const user = useOAuthUser();
  const {data: userData, isFetched: isUserDataFetched} = useUser(
    user?.username ?? '',
  );
  const location = useLocation();
  const itemIds = (location.state as {itemIds: string[]})?.itemIds;
  const {
    register,
    control,
    getValues,
    formState: {errors, isValid},
  } = useForm<SelectDeliveryCompanyInput>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });
  const {data: parcelsData, isFetched: isParcelsFetched} =
    useDeliveredDomesticParcels();
  const [shippingInfo, setShippingInfo] =
    useState<Omit<ShippingInfo, '__typename'>>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (isUserDataFetched && userData) {
      setShippingInfo(
        pick(userData, [
          'name',
          'i18nAreaCodeMobile',
          'mobilePhoneNumber',
          'i18nAreaCodeFixed',
          'fixedPhoneNumber',
          'detailedAddress',
          'city',
          'provinceOrState',
          'countryOrRegion',
          'postalCode',
        ]),
      );
    }
  }, [isUserDataFetched, userData]);

  const items = useMemo(() => {
    if (!isParcelsFetched || !parcelsData || !isArray(itemIds)) {
      return [];
    }
    const allItems = parcelsData.items
      .map(p => p?.items?.items)
      .flat()
      .filter(notEmpty);
    return allItems.filter(i => itemIds.includes(i.id));
  }, [isParcelsFetched, itemIds, parcelsData]);
  const totalWeightKg = useMemo(
    () =>
      items.reduce(
        (total, item) =>
          typeof item.weightKg === 'number' ? total + item.weightKg : total,
        0,
      ),
    [items],
  );
  const sfCharge = getSfExpressCharge(totalWeightKg);
  const japanPostCharge = getJapanPostCharge(totalWeightKg);

  if (!itemIds?.length) {
    return <Navigate replace to="/error" />;
  }

  return (
    <>
      <Modal open={isModalOpen}>
        <UpdateShippingInfo
          closeModal={() => {
            setIsModalOpen(false);
          }}
          onSubmitShippingInfo={info => {
            setShippingInfo(info);
          }}
        />
      </Modal>
      <Box sx={{width: '100%'}}>
        <Typography
          fontWeight="bold"
          sx={{
            fontSize: {xs: '18px', md: '24px'},
            mb: {xs: '16px', md: '24px'},
          }}>
          {t('internationalShippingRequest')}
        </Typography>
        <Typography
          sx={{
            fontSize: {xs: '12px', md: '14px'},
            mb: {xs: '16px', md: '24px'},
          }}>
          {t('pleaseInputShippingCompanyAndDeclaredPrice')}
        </Typography>
        <Controller
          name="deliveryCompany"
          control={control}
          render={({field, fieldState}) => (
            <FormControl error={fieldState.invalid}>
              <FormLabel
                id="delivery-provider"
                label={t('deliveryCompany')}
                sx={{mb: '8px'}}
              />
              <RadioGroup
                row
                name="radio-buttons-delivery-provider"
                sx={{
                  width: {xs: '272px', md: '560px'},
                  mb: '16px',
                  rowGap: '8px',
                  columnGap: '20px',
                }}>
                <FormControlLabel
                  {...field}
                  value={InternationalDeliveryCompany.SfExpress}
                  disabled={!sfCharge}
                  control={
                    <Radio
                      size="small"
                      sx={{alignSelf: 'flex-start', mt: '12px'}}
                    />
                  }
                  label={[
                    t('sfExpress'),
                    <br key="sfExpress" />,
                    `${
                      typeof sfCharge === 'number'
                        ? `${sfCharge.toLocaleString()}${t('yen')}`
                        : '利用不可'
                    }`,
                  ]}
                  sx={{
                    border: `${
                      getValues('deliveryCompany') ===
                      InternationalDeliveryCompany.SfExpress
                        ? '1px solid #1976D2'
                        : '1px solid #D9D9D9'
                    }`,
                    borderRadius: '8px',
                    background: '#ffffff',
                    height: {xs: '69px', md: '82px'},
                    width: {xs: '272px', md: '180px'},
                    ml: '0px',
                  }}
                />
                <FormControlLabel
                  {...field}
                  value={InternationalDeliveryCompany.Ems}
                  disabled={!japanPostCharge}
                  control={
                    <Radio
                      size="small"
                      sx={{alignSelf: 'flex-start', mt: '12px'}}
                    />
                  }
                  label={[
                    t('ems'),
                    <br key="ems" />,
                    `${
                      typeof japanPostCharge === 'number'
                        ? `${japanPostCharge.toLocaleString()}${t('yen')}`
                        : '利用不可'
                    }`,
                  ]}
                  sx={{
                    border: `${
                      getValues('deliveryCompany') ===
                      InternationalDeliveryCompany.Ems
                        ? '1px solid #1976D2'
                        : '1px solid #D9D9D9'
                    }`,
                    borderRadius: '8px',
                    background: '#ffffff',
                    height: {xs: '69px', md: '82px'},
                    width: {xs: '272px', md: '180px'},
                    ml: '0px',
                  }}
                />
              </RadioGroup>
            </FormControl>
          )}
        />

        {typeof sfCharge !== 'number' && (
          <Typography
            fontSize="14px"
            color="red"
            sx={{
              mb: typeof japanPostCharge !== 'number' ? '8px' : '16px',
              width: {xs: '272px', md: '100%'},
            }}>
            {t('totalWeightExceeds', {
              x: SF_EXPRESS_CHARGES[SF_EXPRESS_CHARGES.length - 1].grams / 1000,
              y: t('sfExpress'),
            })}
          </Typography>
        )}
        {typeof japanPostCharge !== 'number' && (
          <Typography
            fontSize="14px"
            color="red"
            sx={{mb: '16px', width: {xs: '272px', md: '100%'}}}>
            {t('totalWeightExceeds', {
              x: JAPAN_POST_CHARGES[JAPAN_POST_CHARGES.length - 1].grams / 1000,
              y: t('ems'),
            })}
          </Typography>
        )}
        <Stack spacing="8px" sx={{mb: '16px'}}>
          <FormLabel isRequired label={t('declaredPrice')} />
          <TextField
            size="small"
            placeholder="0"
            sx={{width: {xs: '272px', md: '452px'}}}
            {...register('declaredPrice')}
            error={'declaredPrice' in errors}
            helperText={errors.declaredPrice && t('pleaseInput')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">{t('yen')}</InputAdornment>
              ),
            }}
          />
        </Stack>
        <Stack spacing="8px" sx={{mb: '16px'}}>
          <Typography fontSize="14px">{t('shippingAddress')}</Typography>
          <Typography
            fontSize="14px"
            color="red"
            sx={{
              width: {xs: '272px', md: '100%'},
            }}>
            {t('pleaseConfirmShippingAddress')}
          </Typography>
          {shippingInfo && <ShippingInfoTile {...shippingInfo} />}
          <Link
            component="button"
            underline="hover"
            sx={{
              mt: '10px',
              px: '8px',
              width: '117px',
              height: '36px',
              fontSize: '14px',
              textAlign: 'left',
            }}
            onClick={() => {
              setIsModalOpen(true);
            }}>
            {t('sendToDifferentAddress')}
          </Link>
        </Stack>
        <Stack flexDirection="row" sx={{mt: '30px'}}>
          <Button
            variant="outlined"
            onClick={() => {
              navigate(-1);
            }}
            sx={{
              width: {xs: '128px', md: '120px'},
              height: '36px',
              mr: '16px',
              color: '#1976D2',
            }}>
            {t('back')}
          </Button>
          <Button
            disabled={!isValid || !shippingInfo}
            variant="contained"
            color="primary"
            onClick={() => {
              const deliveryCompany = getValues('deliveryCompany');
              const charge = {
                [InternationalDeliveryCompany.Ems]: japanPostCharge,
                [InternationalDeliveryCompany.SfExpress]: sfCharge,
              }[deliveryCompany];
              if (shippingInfo && charge) {
                navigate('/confirmInternationalShippingRequest', {
                  state: {
                    itemIds,
                    charge,
                    deliveryCompany,
                    declaredPrice: getValues('declaredPrice'),
                    totalWeightKg,
                    shippingInfo,
                  },
                });
              }
            }}
            sx={{width: {xs: '128px', md: '120px'}, height: '36px'}}>
            {t('check')}
          </Button>
        </Stack>
      </Box>
    </>
  );
};
