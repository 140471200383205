import {SvgIconProps, SvgIcon} from '@mui/material';

export const LocalShippingOutlinedIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="26"
      height="22"
      viewBox="0 0 26 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#1976d2"
      {...props}>
      <path
        d="M6.33334 20.3333C7.8061 20.3333 9.00001 19.1394 9.00001 17.6667C9.00001 16.1939 7.8061 15 6.33334 15C4.86058 15 3.66667 16.1939 3.66667 17.6667C3.66667 19.1394 4.86058 20.3333 6.33334 20.3333Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M19.6667 20.3333C21.1394 20.3333 22.3333 19.1394 22.3333 17.6667C22.3333 16.1939 21.1394 15 19.6667 15C18.1939 15 17 16.1939 17 17.6667C17 19.1394 18.1939 20.3333 19.6667 20.3333Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M3.66667 17.6667H1V3.00008C1 2.64646 1.14048 2.30732 1.39052 2.05727C1.64057 1.80722 1.97971 1.66675 2.33333 1.66675H14.3333V17.6667M9 17.6667H17M22.3333 17.6667H25V9.66675H14.3333M14.3333 3.00008H21L25 9.66675"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </SvgIcon>
  );
};
