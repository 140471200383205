import {SvgIconProps, SvgIcon} from '@mui/material';

export const ListIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="22"
      height="26"
      viewBox="0 0 22 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#1976d2"
      {...props}>
      <path
        d="M17.6667 1H4.33332C2.86056 1 1.66666 2.19391 1.66666 3.66667V22.3333C1.66666 23.8061 2.86056 25 4.33332 25H17.6667C19.1394 25 20.3333 23.8061 20.3333 22.3333V3.66667C20.3333 2.19391 19.1394 1 17.6667 1Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M7 6.33325H15"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M7 11.6667H15"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M7 17H12.3333"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </SvgIcon>
  );
};
