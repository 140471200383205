/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  Stack,
  Grid,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  MenuItem,
  Select,
  DialogTitle,
} from '@mui/material';
import {t} from 'i18next';
import {FC, useState, useMemo, useCallback} from 'react';
import {Edit, useGetOne, useGetRecordId} from 'react-admin';
import {useNavigate} from 'react-router-dom';
import {Item, RegistrationStatus, UpdateItemInput} from '../API';
import {ItemChip} from '../components/ItemChip';
import {ItemInfo} from '../components/ItemInfo';
import {useIsMobile} from '../hooks/useIsMobile';
import {useUpdateItemMutation} from '../queries/item';
import {useUpdateDomesticParcelMutation} from '../queries/parcel';
import {convertDateToDateTimeString} from '../utils/date';
import {onPromise} from '../utils/promise';
import {notEmpty} from '../utils/type';

const RegisterButton: FC<{
  id: string;
  registrationStatus: RegistrationStatus;
}> = ({id, registrationStatus}) => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const {mutateAsync: updateItemMutationAsync} = useUpdateItemMutation();
  const {mutate: updateDomesticParcelMutation} =
    useUpdateDomesticParcelMutation();
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState<RegistrationStatus>(registrationStatus);

  const disabled = useMemo(() => {
    return status === registrationStatus;
  }, [registrationStatus, status]);

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const onSave = useCallback(async () => {
    try {
      const input: UpdateItemInput = {
        id,
        registrationStatus: status,
      };
      await updateItemMutationAsync(
        {input},
        {
          onSuccess: data => {
            if (data?.domesticParcel?.items?.items) {
              const isAcceptableParcel = data.domesticParcel.items.items
                .filter(notEmpty)
                .every(
                  (item: Item) =>
                    item.registrationStatus === RegistrationStatus.Accepted,
                );
              updateDomesticParcelMutation({
                input: {
                  id: data.domesticParcel.id,
                  accepted: isAcceptableParcel,
                },
              });
            }
            onClose();
          },
        },
      );
    } catch (_e) {
      navigate('/error');
    }
    onClose();
  }, [
    id,
    navigate,
    onClose,
    status,
    updateDomesticParcelMutation,
    updateItemMutationAsync,
  ]);

  return (
    <>
      <Button
        id="configurableButton"
        variant="contained"
        size={isMobile ? 'small' : 'medium'}
        onClick={() => setOpen(true)}>
        ステータス更新
      </Button>
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          style: {
            width: isMobile ? '100%' : '500px',
          },
        }}>
        <DialogTitle
          sx={{fontSize: isMobile ? '14px' : '16px', fontWeight: 'bold'}}>
          ステータスを選択してください
        </DialogTitle>
        <DialogContent sx={{display: 'grid', gap: '8px'}}>
          <Select
            fullWidth
            required
            size="small"
            defaultValue={registrationStatus}
            onChange={e => {
              if (e.target.value !== undefined) {
                setStatus(e.target.value as RegistrationStatus);
              }
            }}>
            {Object.values(RegistrationStatus).map(v => (
              <MenuItem key={v} value={v}>
                {v}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined">
            キャンセル
          </Button>
          <Button
            onClick={onPromise(onSave)}
            variant="contained"
            disabled={disabled}>
            完了
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const ItemEdit = () => {
  const recordId = useGetRecordId() as string;
  const {data: item} = useGetOne<Item>('items', {
    id: recordId,
  });
  const isMobile = useIsMobile();
  return (
    <Edit>
      {item ? (
        <Stack key={item.id} sx={{mb: isMobile ? '16px' : '24px', p: '24px'}}>
          <Grid container spacing={1} alignItems="center" mb="24px">
            <Grid item xs={isMobile ? 11 : undefined}>
              <Stack>
                <Typography
                  sx={{
                    fontSize: isMobile ? '14px' : '16px',
                    fontWeight: 'bold',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}>
                  {item.name}
                </Typography>
                <Typography
                  sx={{fontSize: isMobile ? '10px' : '12px', opacity: '0.6'}}>
                  {`${t('registrationDate')}：${convertDateToDateTimeString(
                    new Date(item.createdAt),
                  )}`}
                </Typography>
              </Stack>
              <ItemChip registrationStatus={item.registrationStatus} />
            </Grid>
            {isMobile ? (
              <>
                <Grid item xs={1} sx={{margin: '0 0 0 auto'}} />
                <Grid item xs>
                  <Grid item>
                    <RegisterButton
                      id={item.id}
                      registrationStatus={item.registrationStatus}
                    />
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid item sx={{margin: '0 0 0 auto'}}>
                <Grid container spacing={3} sx={{alignItems: 'center'}}>
                  <Grid item>
                    <RegisterButton
                      id={item.id}
                      registrationStatus={item.registrationStatus}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
          {item ? <ItemInfo item={item} /> : null}
        </Stack>
      ) : null}
    </Edit>
  );
};
