import {setLocale} from 'yup';

setLocale({
  string: {
    max({value, max}: {value: string; max: number}) {
      return `${value.length - max}文字オーバーしています`;
    },
    email: '正しいメールアドレスを入力してください',
  },
  mixed: {
    required: '入力してください',
  },
});
