/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {Box, Grid, Stack, styled, Typography} from '@mui/material';
import {Storage} from 'aws-amplify';
import {useMemo, useState, useEffect} from 'react';
import {Edit, useGetOne, useGetRecordId} from 'react-admin';
import {useTranslation} from 'react-i18next';
import {User} from '../API';
import {FormLabel} from '../components/FormLabel';
import {useIsMobile} from '../hooks/useIsMobile';

export const UserEdit = () => {
  const recordId = useGetRecordId() as string;
  const {data} = useGetOne<User>('users', {
    id: recordId,
  });
  const {t} = useTranslation();
  const isMobile = useIsMobile();
  const birth = useMemo(() => {
    if (!data?.birthday) return '';
    const date = new Date(data?.birthday);
    return `${date.getFullYear()} / ${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')} / ${date.getDate().toString().padStart(2, '0')}`;
  }, [data?.birthday]);
  const [imageUrl, setImageUrl] = useState('');
  useEffect(() => {
    const fetchThumbnail = async () => {
      setImageUrl(
        data?.idPicture
          ? await Storage.get(data.idPicture.key, {
              level: 'private',
              identityId: data.identityId,
            })
          : '',
      );
    };
    void fetchThumbnail();
  }, [data?.identityId, data?.idPicture]);
  return (
    <Edit>
      <Stack
        flexDirection="column"
        sx={{width: '100%', maxWidth: '900px', p: '24px'}}>
        <Typography
          fontWeight="bold"
          sx={{
            fontSize: {xs: '18px', md: '24px'},
            mb: {xs: '16px', md: '24px'},
          }}>
          {t('profile')}
        </Typography>
        <Box
          sx={{
            bgcolor: '#FFFFFF',
            borderRadius: '8px',
            boxSizing: 'border-box',
            p: {xs: '12px', md: '32px'},
          }}>
          <Grid container spacing="32px">
            <Grid item xs={12} md={6}>
              <Stack spacing={isMobile ? '20px' : '24px'}>
                <Stack spacing="8px">
                  <StyledFormLabel label={t('name')} />
                  <StyledTypography>{data?.name}</StyledTypography>
                </Stack>
                <Stack spacing="8px">
                  <StyledFormLabel label={t('companyName')} />
                  <StyledTypography>{data?.companyName}</StyledTypography>
                </Stack>
                <Stack spacing="8px">
                  <StyledFormLabel label={t('email')} />
                  <StyledTypography>{data?.email}</StyledTypography>
                </Stack>
                <Stack spacing="8px">
                  <StyledFormLabel label={t('birthday')} />
                  <StyledTypography>{birth}</StyledTypography>
                </Stack>
                <Stack spacing="8px">
                  <StyledFormLabel label={t('gender')} />
                  <StyledTypography>{data?.gender}</StyledTypography>
                </Stack>
                <Stack spacing="8px">
                  <StyledFormLabel label={t('idPhoto')} />
                  <StyledA href={imageUrl} target="_blank" rel="noreferrer">
                    <Box
                      component="img"
                      sx={{
                        height: 'auto',
                        width: '100%',
                      }}
                      src={imageUrl}
                      alt="photo image"
                    />
                  </StyledA>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack spacing="32px">
                <Stack spacing="8px">
                  <StyledFormLabel label={t('mobilePhoneNo')} />
                  <StyledTypography>
                    {data?.i18nAreaCodeMobile} {data?.mobilePhoneNumber}
                  </StyledTypography>
                </Stack>
                <Stack spacing="8px">
                  <StyledFormLabel label={t('fixedPhoneNo')} />
                  <StyledTypography>
                    {data?.i18nAreaCodeFixed} {data?.fixedPhoneNumber}
                  </StyledTypography>
                </Stack>
                <Stack spacing="8px">
                  <StyledFormLabel label={t('address')} />
                  <StyledTypography>{data?.postalCode}</StyledTypography>
                  <StyledTypography>
                    {data?.countryOrRegion}
                    {data?.provinceOrState}
                    {data?.city}
                    {data?.detailedAddress}
                  </StyledTypography>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </Edit>
  );
};

const StyledFormLabel = styled(FormLabel)`
  line-height: 1.3;
  color: #595959;
`;
const StyledTypography = styled(Typography)`
  font-weight: bold;
  font-size: 14px;
  line-height: 1.3;
`;
const StyledA = styled('a')({
  cursor: 'zoom-in',
});
