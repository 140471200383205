import {SvgIconProps, SvgIcon} from '@mui/material';

export const AddParcelIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M20 1.33325H4.00001C2.52725 1.33325 1.33334 2.52716 1.33334 3.99992V19.9999C1.33334 21.4727 2.52725 22.6666 4.00001 22.6666H20C21.4728 22.6666 22.6667 21.4727 22.6667 19.9999V3.99992C22.6667 2.52716 21.4728 1.33325 20 1.33325Z"
        stroke="#1976D2"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M8 12H16"
        stroke="#1976D2"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M12 8V16"
        stroke="#1976D2"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </SvgIcon>
  );
};
