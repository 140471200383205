import {InternationalDeliveryCompany} from '../API';
import i18n from '../i18n/i18n';

export type DomesticDeliveryCompany =
  | 'youpack'
  | 'yamato'
  | 'hikyaku'
  | 'kangaroo'
  | 'fukuyama'
  | 'others';

export const getLocalizedDomesticDeliveryCompany = (
  deliveryCompany: string,
) => {
  switch (deliveryCompany) {
    case 'youpack':
      return i18n.t('youPack');
    case 'yamato':
      return i18n.t('yamato');
    case 'hikyaku':
      return i18n.t('hikyaku');
    case 'kangaroo':
      return i18n.t('kangaroo');
    case 'fukuyama':
      return i18n.t('fukuyama');
    case 'others':
      return i18n.t('other');
  }
  return i18n.t('other');
};

export const getLocalizedInternationalDeliveryCompany = (
  deliveryCompany: InternationalDeliveryCompany | null,
) => {
  switch (deliveryCompany) {
    case InternationalDeliveryCompany.Ems:
      return i18n.t('ems');
    case InternationalDeliveryCompany.SfExpress:
      return i18n.t('sfExpress');
  }
  return '';
};
