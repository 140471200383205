import {yupResolver} from '@hookform/resolvers/yup';
import {Typography, Button, Stack} from '@mui/material';
import {Auth} from 'aws-amplify';
import pick from 'just-pick';
import {useCallback, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import * as yup from 'yup';
import {BoxForAuth} from '../components/BoxForAuth';
import {TextFieldForAuth} from '../components/TextFieldForAuth';
import {useIsMobile} from '../hooks/useIsMobile';
import {onPromise} from '../utils/promise';
import {AUTH_SCHEMA} from '../validators/schema';

interface FormInput {
  email: string;
}

const schema: yup.SchemaOf<FormInput> = yup
  .object()
  .shape(pick(AUTH_SCHEMA, ['email']));

export const ForgotPasswordScreen = (): JSX.Element => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: {isValid, errors},
  } = useForm<FormInput>({
    mode: 'all',
    criteriaMode: 'all',
    shouldFocusError: false,
    resolver: yupResolver(schema),
  });
  const [error, setError] = useState(false);
  const isMobile = useIsMobile();

  const onSubmit = useCallback(
    (data: FormInput) => {
      const email = data.email;
      Auth.forgotPassword(email)
        .then(_data => {
          navigate('/resetPassword', {state: {email}});
        })
        .catch(() => {
          setError(true);
        });
    },
    [navigate],
  );

  return (
    <BoxForAuth>
      <Stack spacing={3}>
        <Typography textAlign="center" fontWeight="bold" mt="16px">
          {t('forgotPassword')}
        </Typography>
        {error ? (
          <Typography
            color="warning.main"
            fontSize="14px"
            mb="8px"
            textAlign="center">
            {t('pleaseConfirmEmail')}
          </Typography>
        ) : null}
        <TextFieldForAuth
          fullWidth
          variant="outlined"
          type="email"
          label="email"
          placeholder="example@mail.com"
          size={isMobile ? 'small' : 'medium'}
          {...register('email')}
          error={'email' in errors}
        />
        <Button
          variant="contained"
          disabled={!isValid}
          onClick={onPromise(handleSubmit(onSubmit))}>
          {t('next')}
        </Button>
      </Stack>
    </BoxForAuth>
  );
};
