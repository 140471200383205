import {Box, Button, Stack, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useIsMobile} from '../hooks/useIsMobile';

export const CompleteInternationalShippingRequest = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  return (
    <Box sx={{width: '100%'}}>
      <Stack spacing={isMobile ? '16px' : '24px'} sx={{mb: '12px'}}>
        <Typography
          fontWeight="bold"
          sx={{
            fontSize: {xs: '18px', md: '24px'},
          }}>
          {t('internationalShippingRequest')}
        </Typography>
        <Typography
          sx={{
            fontSize: '14px',
          }}>
          {t('requestedInternationalShipping')}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            navigate('/', {replace: true});
          }}
          sx={{width: {xs: '272px', md: '120px'}, height: '36px'}}>
          {t('home')}
        </Button>
      </Stack>
    </Box>
  );
};
