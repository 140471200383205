import {Stack, Typography, Link} from '@mui/material';
import {FC} from 'react';
import i18n from '../i18n/i18n';

interface Props {
  color?: 'black' | 'white';
}

export const LanguageButton: FC<Props> = ({color = 'black'}) => {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Link
        component="button"
        underline="hover"
        sx={{
          fontSize: '14px',
          color,
          opacity: i18n.language === 'ja' ? 1 : 0.8,
          fontWeight: i18n.language === 'ja' ? 'bold' : 'unset',
        }}
        onClick={() => {
          void i18n.changeLanguage('ja');
        }}>
        日本語
      </Link>
      <Typography sx={{opacity: 0.7}}>/</Typography>
      <Link
        component="button"
        underline="hover"
        sx={{
          fontSize: '14px',
          color,
          opacity: i18n.language === 'zh' ? 1 : 0.9,
          fontWeight: i18n.language === 'zh' ? 'bold' : 'unset',
        }}
        onClick={() => {
          void i18n.changeLanguage('zh');
        }}>
        中文
      </Link>
    </Stack>
  );
};
