import {Divider, Typography} from '@mui/material';
import {Stack} from '@mui/system';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useIsMobile} from '../hooks/useIsMobile';
import {useDomesticParcelsInTransit} from '../queries/parcel';
import {convertDateToDateTimeString} from '../utils/date';
import {notEmpty} from '../utils/type';
import {Accordion} from './Accordion';
import {ItemInfo} from './ItemInfo';

export const DomesticParcelsInTransit = () => {
  const {t} = useTranslation();
  const {data} = useDomesticParcelsInTransit();
  const parcels = useMemo(
    () => data?.items.filter(notEmpty) ?? [],
    [data?.items],
  );
  const isMobile = useIsMobile();

  return (
    <>
      {parcels.map((parcel, index) =>
        parcel ? (
          <Stack key={parcel.id} sx={{mb: isMobile ? '16px' : '24px'}}>
            <Accordion
              key={index}
              title={`${t('domesticSlipNumber')}:${parcel.slipNumber ?? ''}`}
              subTitle={`${t(
                'registrationDate',
              )}：${convertDateToDateTimeString(new Date(parcel.createdAt))}`}>
              {parcel.items?.items.map((item, index) =>
                item ? (
                  <div key={item.id}>
                    {parcel.items?.items.length &&
                    parcel.items?.items.length > 1 ? (
                      <Typography
                        fontWeight="bold"
                        sx={{fontSize: isMobile ? 14 : 16}}>
                        {t('point', {x: index + 1})}
                      </Typography>
                    ) : null}
                    <ItemInfo item={item} />
                    {parcel.items?.items.length &&
                      parcel.items?.items.length > index + 1 && (
                        <Divider
                          sx={{
                            my: '24px',
                            border: '1px dashed rgba(0, 0, 0, 0.12)',
                          }}
                        />
                      )}
                  </div>
                ) : null,
              )}
            </Accordion>
          </Stack>
        ) : null,
      )}
    </>
  );
};
