import {yupResolver} from '@hookform/resolvers/yup';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveIcon from '@mui/icons-material/Remove';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  Stack,
  Typography,
  InputAdornment,
  IconButton,
  Checkbox,
  Link,
  Button,
} from '@mui/material';
import {Auth} from 'aws-amplify';
import pick from 'just-pick';
import {useState} from 'react';
import {useForm, SubmitHandler} from 'react-hook-form';
import {useTranslation, Trans} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import * as yup from 'yup';
import {BoxForAuth} from '../components/BoxForAuth';
import {LabelTitle} from '../components/LabelTitle';
import {TextFieldForAuth} from '../components/TextFieldForAuth';
import {useIsMobile} from '../hooks/useIsMobile';
import {ErrnoException} from '../utils/errnoException';
import {onPromise} from '../utils/promise';
import {AUTH_SCHEMA} from '../validators/schema';

interface FormInput {
  email: string;
  password: string;
  policy: boolean;
}

const schema: yup.SchemaOf<FormInput> = yup
  .object()
  .shape(pick(AUTH_SCHEMA, ['email', 'password', 'policy']));

export const SignUpScreen = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: {errors, isValid, dirtyFields},
  } = useForm<FormInput>({
    mode: 'all',
    defaultValues: {
      email: '',
      password: '',
      policy: false,
    },
    criteriaMode: 'all',
    shouldFocusError: false,
    resolver: yupResolver(schema),
  });
  const [showPassword, setShowPassword] = useState(false);
  const [existsUser, setExistsUser] = useState(false);
  const isMobile = useIsMobile();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit: SubmitHandler<FormInput> = data => {
    Auth.signUp({
      username: data.email,
      password: data.password,
    })
      .then(() => {
        navigate('/signUpVerification', {
          state: {
            email: data.email,
            password: data.password,
          },
        });
      })
      .catch((e: ErrnoException) => {
        if (e.code === 'UsernameExistsException') {
          setExistsUser(true);
        }
      });
  };

  return (
    <BoxForAuth>
      <Stack spacing={3}>
        <Typography textAlign="center" fontWeight="bold" mt="16px">
          {t('signUp')}
        </Typography>
        <Stack sx={{width: '100%'}} spacing={1}>
          <LabelTitle isRequired>{t('email')}</LabelTitle>
          <TextFieldForAuth
            placeholder={t('email')}
            fullWidth
            error={'email' in errors}
            helperText={errors.email?.message}
            size={isMobile ? 'small' : 'medium'}
            {...register('email', {required: true})}
          />
        </Stack>
        <Stack sx={{width: '100%'}} spacing={1}>
          <LabelTitle isRequired>{t('password')}</LabelTitle>
          <TextFieldForAuth
            fullWidth
            aria-label="password"
            placeholder={t('password')}
            type={showPassword ? 'text' : 'password'}
            size={isMobile ? 'small' : 'medium'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}>
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            {...register('password', {required: true})}
            error={'password' in errors}
          />
          <div>
            <Stack direction="row" spacing={0.5}>
              {!!errors.password?.types?.required ||
              !!errors.password?.types?.min ||
              !dirtyFields.password ? (
                <RemoveIcon fontSize="inherit" />
              ) : (
                <CheckCircleIcon color="success" fontSize="inherit" />
              )}
              <Typography sx={{fontSize: '12px'}}>
                {t('halfAngleEnglishNumeralsAbove8Words')}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={0.5}>
              {!!errors.password?.types?.required ||
              !!errors.password?.types?.matches ||
              !dirtyFields.password ? (
                <RemoveIcon fontSize="inherit" />
              ) : (
                <CheckCircleIcon color="success" fontSize="inherit" />
              )}
              <Typography sx={{fontSize: '12px'}}>
                {t('alphanumericCombinations')}
              </Typography>
            </Stack>
          </div>
        </Stack>
        <Stack direction="row" justifyContent="center" alignItems="center">
          <Checkbox {...register('policy')} />
          <Trans i18nKey="agree" values={{x: t('terms'), y: t('privacy')}}>
            <Link
              key="terms"
              href="https://cozmic.notion.site/5b4f2f23c3564a739143f4fde7e31207"
              underline="hover"
            />
            <Link
              key="privacy"
              href="https://cozmic.notion.site/681846b297984636a1c409b997747307"
              underline="hover"
            />
          </Trans>
        </Stack>
        {existsUser ? (
          <Typography color="warning.main" fontSize="14px">
            {t('emailAlreadyInUse')}
          </Typography>
        ) : null}
        <Button
          variant="contained"
          disabled={!isValid}
          onClick={onPromise(handleSubmit(onSubmit))}>
          {t('register')}
        </Button>
      </Stack>
    </BoxForAuth>
  );
};
