/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      identityId
      name
      companyName
      email
      birthday
      gender
      idPicture {
        bucket
        region
        key
      }
      job
      i18nAreaCodeMobile
      mobilePhoneNumber
      i18nAreaCodeFixed
      fixedPhoneNumber
      detailedAddress
      city
      provinceOrState
      countryOrRegion
      postalCode
      registrationStatus
      items {
        items {
          id
          name
          price
          category
          quantity
          url
          note
          registrationStatus
          weightKg
          user {
            id
            identityId
            name
            companyName
            email
            birthday
            gender
            job
            i18nAreaCodeMobile
            mobilePhoneNumber
            i18nAreaCodeFixed
            fixedPhoneNumber
            detailedAddress
            city
            provinceOrState
            countryOrRegion
            postalCode
            registrationStatus
            updatedAt
            createdAt
            owner
          }
          domesticParcel {
            id
            slipNumber
            accepted
            delivered
            deliveredEnum
            deliveryCompany
            updatedAt
            createdAt
            userDomesticParcelId
            owner
          }
          internationalParcel {
            id
            slipNumber
            shippingFee
            weightKg
            cost
            delivered
            deliveredEnum
            deliveryCompany
            updatedAt
            createdAt
            userInternationalParcelId
            owner
          }
          updatedAt
          createdAt
          userItemsId
          domesticParcelItemsId
          internationalParcelItemsId
          owner
        }
        nextToken
      }
      domesticParcel {
        items {
          id
          slipNumber
          accepted
          delivered
          deliveredEnum
          deliveryCompany
          items {
            nextToken
          }
          user {
            id
            identityId
            name
            companyName
            email
            birthday
            gender
            job
            i18nAreaCodeMobile
            mobilePhoneNumber
            i18nAreaCodeFixed
            fixedPhoneNumber
            detailedAddress
            city
            provinceOrState
            countryOrRegion
            postalCode
            registrationStatus
            updatedAt
            createdAt
            owner
          }
          updatedAt
          createdAt
          userDomesticParcelId
          owner
        }
        nextToken
      }
      internationalParcel {
        items {
          id
          slipNumber
          shippingFee
          weightKg
          cost
          delivered
          deliveredEnum
          shippingInfo {
            name
            i18nAreaCodeMobile
            mobilePhoneNumber
            i18nAreaCodeFixed
            fixedPhoneNumber
            detailedAddress
            city
            provinceOrState
            countryOrRegion
            postalCode
          }
          deliveryCompany
          items {
            nextToken
          }
          user {
            id
            identityId
            name
            companyName
            email
            birthday
            gender
            job
            i18nAreaCodeMobile
            mobilePhoneNumber
            i18nAreaCodeFixed
            fixedPhoneNumber
            detailedAddress
            city
            provinceOrState
            countryOrRegion
            postalCode
            registrationStatus
            updatedAt
            createdAt
            owner
          }
          updatedAt
          createdAt
          userInternationalParcelId
          owner
        }
        nextToken
      }
      updatedAt
      createdAt
      owner
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      identityId
      name
      companyName
      email
      birthday
      gender
      idPicture {
        bucket
        region
        key
      }
      job
      i18nAreaCodeMobile
      mobilePhoneNumber
      i18nAreaCodeFixed
      fixedPhoneNumber
      detailedAddress
      city
      provinceOrState
      countryOrRegion
      postalCode
      registrationStatus
      items {
        items {
          id
          name
          price
          category
          quantity
          url
          note
          registrationStatus
          weightKg
          user {
            id
            identityId
            name
            companyName
            email
            birthday
            gender
            job
            i18nAreaCodeMobile
            mobilePhoneNumber
            i18nAreaCodeFixed
            fixedPhoneNumber
            detailedAddress
            city
            provinceOrState
            countryOrRegion
            postalCode
            registrationStatus
            updatedAt
            createdAt
            owner
          }
          domesticParcel {
            id
            slipNumber
            accepted
            delivered
            deliveredEnum
            deliveryCompany
            updatedAt
            createdAt
            userDomesticParcelId
            owner
          }
          internationalParcel {
            id
            slipNumber
            shippingFee
            weightKg
            cost
            delivered
            deliveredEnum
            deliveryCompany
            updatedAt
            createdAt
            userInternationalParcelId
            owner
          }
          updatedAt
          createdAt
          userItemsId
          domesticParcelItemsId
          internationalParcelItemsId
          owner
        }
        nextToken
      }
      domesticParcel {
        items {
          id
          slipNumber
          accepted
          delivered
          deliveredEnum
          deliveryCompany
          items {
            nextToken
          }
          user {
            id
            identityId
            name
            companyName
            email
            birthday
            gender
            job
            i18nAreaCodeMobile
            mobilePhoneNumber
            i18nAreaCodeFixed
            fixedPhoneNumber
            detailedAddress
            city
            provinceOrState
            countryOrRegion
            postalCode
            registrationStatus
            updatedAt
            createdAt
            owner
          }
          updatedAt
          createdAt
          userDomesticParcelId
          owner
        }
        nextToken
      }
      internationalParcel {
        items {
          id
          slipNumber
          shippingFee
          weightKg
          cost
          delivered
          deliveredEnum
          shippingInfo {
            name
            i18nAreaCodeMobile
            mobilePhoneNumber
            i18nAreaCodeFixed
            fixedPhoneNumber
            detailedAddress
            city
            provinceOrState
            countryOrRegion
            postalCode
          }
          deliveryCompany
          items {
            nextToken
          }
          user {
            id
            identityId
            name
            companyName
            email
            birthday
            gender
            job
            i18nAreaCodeMobile
            mobilePhoneNumber
            i18nAreaCodeFixed
            fixedPhoneNumber
            detailedAddress
            city
            provinceOrState
            countryOrRegion
            postalCode
            registrationStatus
            updatedAt
            createdAt
            owner
          }
          updatedAt
          createdAt
          userInternationalParcelId
          owner
        }
        nextToken
      }
      updatedAt
      createdAt
      owner
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      identityId
      name
      companyName
      email
      birthday
      gender
      idPicture {
        bucket
        region
        key
      }
      job
      i18nAreaCodeMobile
      mobilePhoneNumber
      i18nAreaCodeFixed
      fixedPhoneNumber
      detailedAddress
      city
      provinceOrState
      countryOrRegion
      postalCode
      registrationStatus
      items {
        items {
          id
          name
          price
          category
          quantity
          url
          note
          registrationStatus
          weightKg
          user {
            id
            identityId
            name
            companyName
            email
            birthday
            gender
            job
            i18nAreaCodeMobile
            mobilePhoneNumber
            i18nAreaCodeFixed
            fixedPhoneNumber
            detailedAddress
            city
            provinceOrState
            countryOrRegion
            postalCode
            registrationStatus
            updatedAt
            createdAt
            owner
          }
          domesticParcel {
            id
            slipNumber
            accepted
            delivered
            deliveredEnum
            deliveryCompany
            updatedAt
            createdAt
            userDomesticParcelId
            owner
          }
          internationalParcel {
            id
            slipNumber
            shippingFee
            weightKg
            cost
            delivered
            deliveredEnum
            deliveryCompany
            updatedAt
            createdAt
            userInternationalParcelId
            owner
          }
          updatedAt
          createdAt
          userItemsId
          domesticParcelItemsId
          internationalParcelItemsId
          owner
        }
        nextToken
      }
      domesticParcel {
        items {
          id
          slipNumber
          accepted
          delivered
          deliveredEnum
          deliveryCompany
          items {
            nextToken
          }
          user {
            id
            identityId
            name
            companyName
            email
            birthday
            gender
            job
            i18nAreaCodeMobile
            mobilePhoneNumber
            i18nAreaCodeFixed
            fixedPhoneNumber
            detailedAddress
            city
            provinceOrState
            countryOrRegion
            postalCode
            registrationStatus
            updatedAt
            createdAt
            owner
          }
          updatedAt
          createdAt
          userDomesticParcelId
          owner
        }
        nextToken
      }
      internationalParcel {
        items {
          id
          slipNumber
          shippingFee
          weightKg
          cost
          delivered
          deliveredEnum
          shippingInfo {
            name
            i18nAreaCodeMobile
            mobilePhoneNumber
            i18nAreaCodeFixed
            fixedPhoneNumber
            detailedAddress
            city
            provinceOrState
            countryOrRegion
            postalCode
          }
          deliveryCompany
          items {
            nextToken
          }
          user {
            id
            identityId
            name
            companyName
            email
            birthday
            gender
            job
            i18nAreaCodeMobile
            mobilePhoneNumber
            i18nAreaCodeFixed
            fixedPhoneNumber
            detailedAddress
            city
            provinceOrState
            countryOrRegion
            postalCode
            registrationStatus
            updatedAt
            createdAt
            owner
          }
          updatedAt
          createdAt
          userInternationalParcelId
          owner
        }
        nextToken
      }
      updatedAt
      createdAt
      owner
    }
  }
`;
export const createItem = /* GraphQL */ `
  mutation CreateItem(
    $input: CreateItemInput!
    $condition: ModelItemConditionInput
  ) {
    createItem(input: $input, condition: $condition) {
      id
      name
      price
      category
      quantity
      url
      note
      registrationStatus
      weightKg
      user {
        id
        identityId
        name
        companyName
        email
        birthday
        gender
        idPicture {
          bucket
          region
          key
        }
        job
        i18nAreaCodeMobile
        mobilePhoneNumber
        i18nAreaCodeFixed
        fixedPhoneNumber
        detailedAddress
        city
        provinceOrState
        countryOrRegion
        postalCode
        registrationStatus
        items {
          items {
            id
            name
            price
            category
            quantity
            url
            note
            registrationStatus
            weightKg
            updatedAt
            createdAt
            userItemsId
            domesticParcelItemsId
            internationalParcelItemsId
            owner
          }
          nextToken
        }
        domesticParcel {
          items {
            id
            slipNumber
            accepted
            delivered
            deliveredEnum
            deliveryCompany
            updatedAt
            createdAt
            userDomesticParcelId
            owner
          }
          nextToken
        }
        internationalParcel {
          items {
            id
            slipNumber
            shippingFee
            weightKg
            cost
            delivered
            deliveredEnum
            deliveryCompany
            updatedAt
            createdAt
            userInternationalParcelId
            owner
          }
          nextToken
        }
        updatedAt
        createdAt
        owner
      }
      domesticParcel {
        id
        slipNumber
        accepted
        delivered
        deliveredEnum
        deliveryCompany
        items {
          items {
            id
            name
            price
            category
            quantity
            url
            note
            registrationStatus
            weightKg
            updatedAt
            createdAt
            userItemsId
            domesticParcelItemsId
            internationalParcelItemsId
            owner
          }
          nextToken
        }
        user {
          id
          identityId
          name
          companyName
          email
          birthday
          gender
          idPicture {
            bucket
            region
            key
          }
          job
          i18nAreaCodeMobile
          mobilePhoneNumber
          i18nAreaCodeFixed
          fixedPhoneNumber
          detailedAddress
          city
          provinceOrState
          countryOrRegion
          postalCode
          registrationStatus
          items {
            nextToken
          }
          domesticParcel {
            nextToken
          }
          internationalParcel {
            nextToken
          }
          updatedAt
          createdAt
          owner
        }
        updatedAt
        createdAt
        userDomesticParcelId
        owner
      }
      internationalParcel {
        id
        slipNumber
        shippingFee
        weightKg
        cost
        delivered
        deliveredEnum
        shippingInfo {
          name
          i18nAreaCodeMobile
          mobilePhoneNumber
          i18nAreaCodeFixed
          fixedPhoneNumber
          detailedAddress
          city
          provinceOrState
          countryOrRegion
          postalCode
        }
        deliveryCompany
        items {
          items {
            id
            name
            price
            category
            quantity
            url
            note
            registrationStatus
            weightKg
            updatedAt
            createdAt
            userItemsId
            domesticParcelItemsId
            internationalParcelItemsId
            owner
          }
          nextToken
        }
        user {
          id
          identityId
          name
          companyName
          email
          birthday
          gender
          idPicture {
            bucket
            region
            key
          }
          job
          i18nAreaCodeMobile
          mobilePhoneNumber
          i18nAreaCodeFixed
          fixedPhoneNumber
          detailedAddress
          city
          provinceOrState
          countryOrRegion
          postalCode
          registrationStatus
          items {
            nextToken
          }
          domesticParcel {
            nextToken
          }
          internationalParcel {
            nextToken
          }
          updatedAt
          createdAt
          owner
        }
        updatedAt
        createdAt
        userInternationalParcelId
        owner
      }
      updatedAt
      createdAt
      userItemsId
      domesticParcelItemsId
      internationalParcelItemsId
      owner
    }
  }
`;
export const updateItem = /* GraphQL */ `
  mutation UpdateItem(
    $input: UpdateItemInput!
    $condition: ModelItemConditionInput
  ) {
    updateItem(input: $input, condition: $condition) {
      id
      name
      price
      category
      quantity
      url
      note
      registrationStatus
      weightKg
      user {
        id
        identityId
        name
        companyName
        email
        birthday
        gender
        idPicture {
          bucket
          region
          key
        }
        job
        i18nAreaCodeMobile
        mobilePhoneNumber
        i18nAreaCodeFixed
        fixedPhoneNumber
        detailedAddress
        city
        provinceOrState
        countryOrRegion
        postalCode
        registrationStatus
        items {
          items {
            id
            name
            price
            category
            quantity
            url
            note
            registrationStatus
            weightKg
            updatedAt
            createdAt
            userItemsId
            domesticParcelItemsId
            internationalParcelItemsId
            owner
          }
          nextToken
        }
        domesticParcel {
          items {
            id
            slipNumber
            accepted
            delivered
            deliveredEnum
            deliveryCompany
            updatedAt
            createdAt
            userDomesticParcelId
            owner
          }
          nextToken
        }
        internationalParcel {
          items {
            id
            slipNumber
            shippingFee
            weightKg
            cost
            delivered
            deliveredEnum
            deliveryCompany
            updatedAt
            createdAt
            userInternationalParcelId
            owner
          }
          nextToken
        }
        updatedAt
        createdAt
        owner
      }
      domesticParcel {
        id
        slipNumber
        accepted
        delivered
        deliveredEnum
        deliveryCompany
        items {
          items {
            id
            name
            price
            category
            quantity
            url
            note
            registrationStatus
            weightKg
            updatedAt
            createdAt
            userItemsId
            domesticParcelItemsId
            internationalParcelItemsId
            owner
          }
          nextToken
        }
        user {
          id
          identityId
          name
          companyName
          email
          birthday
          gender
          idPicture {
            bucket
            region
            key
          }
          job
          i18nAreaCodeMobile
          mobilePhoneNumber
          i18nAreaCodeFixed
          fixedPhoneNumber
          detailedAddress
          city
          provinceOrState
          countryOrRegion
          postalCode
          registrationStatus
          items {
            nextToken
          }
          domesticParcel {
            nextToken
          }
          internationalParcel {
            nextToken
          }
          updatedAt
          createdAt
          owner
        }
        updatedAt
        createdAt
        userDomesticParcelId
        owner
      }
      internationalParcel {
        id
        slipNumber
        shippingFee
        weightKg
        cost
        delivered
        deliveredEnum
        shippingInfo {
          name
          i18nAreaCodeMobile
          mobilePhoneNumber
          i18nAreaCodeFixed
          fixedPhoneNumber
          detailedAddress
          city
          provinceOrState
          countryOrRegion
          postalCode
        }
        deliveryCompany
        items {
          items {
            id
            name
            price
            category
            quantity
            url
            note
            registrationStatus
            weightKg
            updatedAt
            createdAt
            userItemsId
            domesticParcelItemsId
            internationalParcelItemsId
            owner
          }
          nextToken
        }
        user {
          id
          identityId
          name
          companyName
          email
          birthday
          gender
          idPicture {
            bucket
            region
            key
          }
          job
          i18nAreaCodeMobile
          mobilePhoneNumber
          i18nAreaCodeFixed
          fixedPhoneNumber
          detailedAddress
          city
          provinceOrState
          countryOrRegion
          postalCode
          registrationStatus
          items {
            nextToken
          }
          domesticParcel {
            nextToken
          }
          internationalParcel {
            nextToken
          }
          updatedAt
          createdAt
          owner
        }
        updatedAt
        createdAt
        userInternationalParcelId
        owner
      }
      updatedAt
      createdAt
      userItemsId
      domesticParcelItemsId
      internationalParcelItemsId
      owner
    }
  }
`;
export const deleteItem = /* GraphQL */ `
  mutation DeleteItem(
    $input: DeleteItemInput!
    $condition: ModelItemConditionInput
  ) {
    deleteItem(input: $input, condition: $condition) {
      id
      name
      price
      category
      quantity
      url
      note
      registrationStatus
      weightKg
      user {
        id
        identityId
        name
        companyName
        email
        birthday
        gender
        idPicture {
          bucket
          region
          key
        }
        job
        i18nAreaCodeMobile
        mobilePhoneNumber
        i18nAreaCodeFixed
        fixedPhoneNumber
        detailedAddress
        city
        provinceOrState
        countryOrRegion
        postalCode
        registrationStatus
        items {
          items {
            id
            name
            price
            category
            quantity
            url
            note
            registrationStatus
            weightKg
            updatedAt
            createdAt
            userItemsId
            domesticParcelItemsId
            internationalParcelItemsId
            owner
          }
          nextToken
        }
        domesticParcel {
          items {
            id
            slipNumber
            accepted
            delivered
            deliveredEnum
            deliveryCompany
            updatedAt
            createdAt
            userDomesticParcelId
            owner
          }
          nextToken
        }
        internationalParcel {
          items {
            id
            slipNumber
            shippingFee
            weightKg
            cost
            delivered
            deliveredEnum
            deliveryCompany
            updatedAt
            createdAt
            userInternationalParcelId
            owner
          }
          nextToken
        }
        updatedAt
        createdAt
        owner
      }
      domesticParcel {
        id
        slipNumber
        accepted
        delivered
        deliveredEnum
        deliveryCompany
        items {
          items {
            id
            name
            price
            category
            quantity
            url
            note
            registrationStatus
            weightKg
            updatedAt
            createdAt
            userItemsId
            domesticParcelItemsId
            internationalParcelItemsId
            owner
          }
          nextToken
        }
        user {
          id
          identityId
          name
          companyName
          email
          birthday
          gender
          idPicture {
            bucket
            region
            key
          }
          job
          i18nAreaCodeMobile
          mobilePhoneNumber
          i18nAreaCodeFixed
          fixedPhoneNumber
          detailedAddress
          city
          provinceOrState
          countryOrRegion
          postalCode
          registrationStatus
          items {
            nextToken
          }
          domesticParcel {
            nextToken
          }
          internationalParcel {
            nextToken
          }
          updatedAt
          createdAt
          owner
        }
        updatedAt
        createdAt
        userDomesticParcelId
        owner
      }
      internationalParcel {
        id
        slipNumber
        shippingFee
        weightKg
        cost
        delivered
        deliveredEnum
        shippingInfo {
          name
          i18nAreaCodeMobile
          mobilePhoneNumber
          i18nAreaCodeFixed
          fixedPhoneNumber
          detailedAddress
          city
          provinceOrState
          countryOrRegion
          postalCode
        }
        deliveryCompany
        items {
          items {
            id
            name
            price
            category
            quantity
            url
            note
            registrationStatus
            weightKg
            updatedAt
            createdAt
            userItemsId
            domesticParcelItemsId
            internationalParcelItemsId
            owner
          }
          nextToken
        }
        user {
          id
          identityId
          name
          companyName
          email
          birthday
          gender
          idPicture {
            bucket
            region
            key
          }
          job
          i18nAreaCodeMobile
          mobilePhoneNumber
          i18nAreaCodeFixed
          fixedPhoneNumber
          detailedAddress
          city
          provinceOrState
          countryOrRegion
          postalCode
          registrationStatus
          items {
            nextToken
          }
          domesticParcel {
            nextToken
          }
          internationalParcel {
            nextToken
          }
          updatedAt
          createdAt
          owner
        }
        updatedAt
        createdAt
        userInternationalParcelId
        owner
      }
      updatedAt
      createdAt
      userItemsId
      domesticParcelItemsId
      internationalParcelItemsId
      owner
    }
  }
`;
export const createDomesticParcel = /* GraphQL */ `
  mutation CreateDomesticParcel(
    $input: CreateDomesticParcelInput!
    $condition: ModelDomesticParcelConditionInput
  ) {
    createDomesticParcel(input: $input, condition: $condition) {
      id
      slipNumber
      accepted
      delivered
      deliveredEnum
      deliveryCompany
      items {
        items {
          id
          name
          price
          category
          quantity
          url
          note
          registrationStatus
          weightKg
          user {
            id
            identityId
            name
            companyName
            email
            birthday
            gender
            job
            i18nAreaCodeMobile
            mobilePhoneNumber
            i18nAreaCodeFixed
            fixedPhoneNumber
            detailedAddress
            city
            provinceOrState
            countryOrRegion
            postalCode
            registrationStatus
            updatedAt
            createdAt
            owner
          }
          domesticParcel {
            id
            slipNumber
            accepted
            delivered
            deliveredEnum
            deliveryCompany
            updatedAt
            createdAt
            userDomesticParcelId
            owner
          }
          internationalParcel {
            id
            slipNumber
            shippingFee
            weightKg
            cost
            delivered
            deliveredEnum
            deliveryCompany
            updatedAt
            createdAt
            userInternationalParcelId
            owner
          }
          updatedAt
          createdAt
          userItemsId
          domesticParcelItemsId
          internationalParcelItemsId
          owner
        }
        nextToken
      }
      user {
        id
        identityId
        name
        companyName
        email
        birthday
        gender
        idPicture {
          bucket
          region
          key
        }
        job
        i18nAreaCodeMobile
        mobilePhoneNumber
        i18nAreaCodeFixed
        fixedPhoneNumber
        detailedAddress
        city
        provinceOrState
        countryOrRegion
        postalCode
        registrationStatus
        items {
          items {
            id
            name
            price
            category
            quantity
            url
            note
            registrationStatus
            weightKg
            updatedAt
            createdAt
            userItemsId
            domesticParcelItemsId
            internationalParcelItemsId
            owner
          }
          nextToken
        }
        domesticParcel {
          items {
            id
            slipNumber
            accepted
            delivered
            deliveredEnum
            deliveryCompany
            updatedAt
            createdAt
            userDomesticParcelId
            owner
          }
          nextToken
        }
        internationalParcel {
          items {
            id
            slipNumber
            shippingFee
            weightKg
            cost
            delivered
            deliveredEnum
            deliveryCompany
            updatedAt
            createdAt
            userInternationalParcelId
            owner
          }
          nextToken
        }
        updatedAt
        createdAt
        owner
      }
      updatedAt
      createdAt
      userDomesticParcelId
      owner
    }
  }
`;
export const updateDomesticParcel = /* GraphQL */ `
  mutation UpdateDomesticParcel(
    $input: UpdateDomesticParcelInput!
    $condition: ModelDomesticParcelConditionInput
  ) {
    updateDomesticParcel(input: $input, condition: $condition) {
      id
      slipNumber
      accepted
      delivered
      deliveredEnum
      deliveryCompany
      items {
        items {
          id
          name
          price
          category
          quantity
          url
          note
          registrationStatus
          weightKg
          user {
            id
            identityId
            name
            companyName
            email
            birthday
            gender
            job
            i18nAreaCodeMobile
            mobilePhoneNumber
            i18nAreaCodeFixed
            fixedPhoneNumber
            detailedAddress
            city
            provinceOrState
            countryOrRegion
            postalCode
            registrationStatus
            updatedAt
            createdAt
            owner
          }
          domesticParcel {
            id
            slipNumber
            accepted
            delivered
            deliveredEnum
            deliveryCompany
            updatedAt
            createdAt
            userDomesticParcelId
            owner
          }
          internationalParcel {
            id
            slipNumber
            shippingFee
            weightKg
            cost
            delivered
            deliveredEnum
            deliveryCompany
            updatedAt
            createdAt
            userInternationalParcelId
            owner
          }
          updatedAt
          createdAt
          userItemsId
          domesticParcelItemsId
          internationalParcelItemsId
          owner
        }
        nextToken
      }
      user {
        id
        identityId
        name
        companyName
        email
        birthday
        gender
        idPicture {
          bucket
          region
          key
        }
        job
        i18nAreaCodeMobile
        mobilePhoneNumber
        i18nAreaCodeFixed
        fixedPhoneNumber
        detailedAddress
        city
        provinceOrState
        countryOrRegion
        postalCode
        registrationStatus
        items {
          items {
            id
            name
            price
            category
            quantity
            url
            note
            registrationStatus
            weightKg
            updatedAt
            createdAt
            userItemsId
            domesticParcelItemsId
            internationalParcelItemsId
            owner
          }
          nextToken
        }
        domesticParcel {
          items {
            id
            slipNumber
            accepted
            delivered
            deliveredEnum
            deliveryCompany
            updatedAt
            createdAt
            userDomesticParcelId
            owner
          }
          nextToken
        }
        internationalParcel {
          items {
            id
            slipNumber
            shippingFee
            weightKg
            cost
            delivered
            deliveredEnum
            deliveryCompany
            updatedAt
            createdAt
            userInternationalParcelId
            owner
          }
          nextToken
        }
        updatedAt
        createdAt
        owner
      }
      updatedAt
      createdAt
      userDomesticParcelId
      owner
    }
  }
`;
export const deleteDomesticParcel = /* GraphQL */ `
  mutation DeleteDomesticParcel(
    $input: DeleteDomesticParcelInput!
    $condition: ModelDomesticParcelConditionInput
  ) {
    deleteDomesticParcel(input: $input, condition: $condition) {
      id
      slipNumber
      accepted
      delivered
      deliveredEnum
      deliveryCompany
      items {
        items {
          id
          name
          price
          category
          quantity
          url
          note
          registrationStatus
          weightKg
          user {
            id
            identityId
            name
            companyName
            email
            birthday
            gender
            job
            i18nAreaCodeMobile
            mobilePhoneNumber
            i18nAreaCodeFixed
            fixedPhoneNumber
            detailedAddress
            city
            provinceOrState
            countryOrRegion
            postalCode
            registrationStatus
            updatedAt
            createdAt
            owner
          }
          domesticParcel {
            id
            slipNumber
            accepted
            delivered
            deliveredEnum
            deliveryCompany
            updatedAt
            createdAt
            userDomesticParcelId
            owner
          }
          internationalParcel {
            id
            slipNumber
            shippingFee
            weightKg
            cost
            delivered
            deliveredEnum
            deliveryCompany
            updatedAt
            createdAt
            userInternationalParcelId
            owner
          }
          updatedAt
          createdAt
          userItemsId
          domesticParcelItemsId
          internationalParcelItemsId
          owner
        }
        nextToken
      }
      user {
        id
        identityId
        name
        companyName
        email
        birthday
        gender
        idPicture {
          bucket
          region
          key
        }
        job
        i18nAreaCodeMobile
        mobilePhoneNumber
        i18nAreaCodeFixed
        fixedPhoneNumber
        detailedAddress
        city
        provinceOrState
        countryOrRegion
        postalCode
        registrationStatus
        items {
          items {
            id
            name
            price
            category
            quantity
            url
            note
            registrationStatus
            weightKg
            updatedAt
            createdAt
            userItemsId
            domesticParcelItemsId
            internationalParcelItemsId
            owner
          }
          nextToken
        }
        domesticParcel {
          items {
            id
            slipNumber
            accepted
            delivered
            deliveredEnum
            deliveryCompany
            updatedAt
            createdAt
            userDomesticParcelId
            owner
          }
          nextToken
        }
        internationalParcel {
          items {
            id
            slipNumber
            shippingFee
            weightKg
            cost
            delivered
            deliveredEnum
            deliveryCompany
            updatedAt
            createdAt
            userInternationalParcelId
            owner
          }
          nextToken
        }
        updatedAt
        createdAt
        owner
      }
      updatedAt
      createdAt
      userDomesticParcelId
      owner
    }
  }
`;
export const createInternationalParcel = /* GraphQL */ `
  mutation CreateInternationalParcel(
    $input: CreateInternationalParcelInput!
    $condition: ModelInternationalParcelConditionInput
  ) {
    createInternationalParcel(input: $input, condition: $condition) {
      id
      slipNumber
      shippingFee
      weightKg
      cost
      delivered
      deliveredEnum
      shippingInfo {
        name
        i18nAreaCodeMobile
        mobilePhoneNumber
        i18nAreaCodeFixed
        fixedPhoneNumber
        detailedAddress
        city
        provinceOrState
        countryOrRegion
        postalCode
      }
      deliveryCompany
      items {
        items {
          id
          name
          price
          category
          quantity
          url
          note
          registrationStatus
          weightKg
          user {
            id
            identityId
            name
            companyName
            email
            birthday
            gender
            job
            i18nAreaCodeMobile
            mobilePhoneNumber
            i18nAreaCodeFixed
            fixedPhoneNumber
            detailedAddress
            city
            provinceOrState
            countryOrRegion
            postalCode
            registrationStatus
            updatedAt
            createdAt
            owner
          }
          domesticParcel {
            id
            slipNumber
            accepted
            delivered
            deliveredEnum
            deliveryCompany
            updatedAt
            createdAt
            userDomesticParcelId
            owner
          }
          internationalParcel {
            id
            slipNumber
            shippingFee
            weightKg
            cost
            delivered
            deliveredEnum
            deliveryCompany
            updatedAt
            createdAt
            userInternationalParcelId
            owner
          }
          updatedAt
          createdAt
          userItemsId
          domesticParcelItemsId
          internationalParcelItemsId
          owner
        }
        nextToken
      }
      user {
        id
        identityId
        name
        companyName
        email
        birthday
        gender
        idPicture {
          bucket
          region
          key
        }
        job
        i18nAreaCodeMobile
        mobilePhoneNumber
        i18nAreaCodeFixed
        fixedPhoneNumber
        detailedAddress
        city
        provinceOrState
        countryOrRegion
        postalCode
        registrationStatus
        items {
          items {
            id
            name
            price
            category
            quantity
            url
            note
            registrationStatus
            weightKg
            updatedAt
            createdAt
            userItemsId
            domesticParcelItemsId
            internationalParcelItemsId
            owner
          }
          nextToken
        }
        domesticParcel {
          items {
            id
            slipNumber
            accepted
            delivered
            deliveredEnum
            deliveryCompany
            updatedAt
            createdAt
            userDomesticParcelId
            owner
          }
          nextToken
        }
        internationalParcel {
          items {
            id
            slipNumber
            shippingFee
            weightKg
            cost
            delivered
            deliveredEnum
            deliveryCompany
            updatedAt
            createdAt
            userInternationalParcelId
            owner
          }
          nextToken
        }
        updatedAt
        createdAt
        owner
      }
      updatedAt
      createdAt
      userInternationalParcelId
      owner
    }
  }
`;
export const updateInternationalParcel = /* GraphQL */ `
  mutation UpdateInternationalParcel(
    $input: UpdateInternationalParcelInput!
    $condition: ModelInternationalParcelConditionInput
  ) {
    updateInternationalParcel(input: $input, condition: $condition) {
      id
      slipNumber
      shippingFee
      weightKg
      cost
      delivered
      deliveredEnum
      shippingInfo {
        name
        i18nAreaCodeMobile
        mobilePhoneNumber
        i18nAreaCodeFixed
        fixedPhoneNumber
        detailedAddress
        city
        provinceOrState
        countryOrRegion
        postalCode
      }
      deliveryCompany
      items {
        items {
          id
          name
          price
          category
          quantity
          url
          note
          registrationStatus
          weightKg
          user {
            id
            identityId
            name
            companyName
            email
            birthday
            gender
            job
            i18nAreaCodeMobile
            mobilePhoneNumber
            i18nAreaCodeFixed
            fixedPhoneNumber
            detailedAddress
            city
            provinceOrState
            countryOrRegion
            postalCode
            registrationStatus
            updatedAt
            createdAt
            owner
          }
          domesticParcel {
            id
            slipNumber
            accepted
            delivered
            deliveredEnum
            deliveryCompany
            updatedAt
            createdAt
            userDomesticParcelId
            owner
          }
          internationalParcel {
            id
            slipNumber
            shippingFee
            weightKg
            cost
            delivered
            deliveredEnum
            deliveryCompany
            updatedAt
            createdAt
            userInternationalParcelId
            owner
          }
          updatedAt
          createdAt
          userItemsId
          domesticParcelItemsId
          internationalParcelItemsId
          owner
        }
        nextToken
      }
      user {
        id
        identityId
        name
        companyName
        email
        birthday
        gender
        idPicture {
          bucket
          region
          key
        }
        job
        i18nAreaCodeMobile
        mobilePhoneNumber
        i18nAreaCodeFixed
        fixedPhoneNumber
        detailedAddress
        city
        provinceOrState
        countryOrRegion
        postalCode
        registrationStatus
        items {
          items {
            id
            name
            price
            category
            quantity
            url
            note
            registrationStatus
            weightKg
            updatedAt
            createdAt
            userItemsId
            domesticParcelItemsId
            internationalParcelItemsId
            owner
          }
          nextToken
        }
        domesticParcel {
          items {
            id
            slipNumber
            accepted
            delivered
            deliveredEnum
            deliveryCompany
            updatedAt
            createdAt
            userDomesticParcelId
            owner
          }
          nextToken
        }
        internationalParcel {
          items {
            id
            slipNumber
            shippingFee
            weightKg
            cost
            delivered
            deliveredEnum
            deliveryCompany
            updatedAt
            createdAt
            userInternationalParcelId
            owner
          }
          nextToken
        }
        updatedAt
        createdAt
        owner
      }
      updatedAt
      createdAt
      userInternationalParcelId
      owner
    }
  }
`;
export const deleteInternationalParcel = /* GraphQL */ `
  mutation DeleteInternationalParcel(
    $input: DeleteInternationalParcelInput!
    $condition: ModelInternationalParcelConditionInput
  ) {
    deleteInternationalParcel(input: $input, condition: $condition) {
      id
      slipNumber
      shippingFee
      weightKg
      cost
      delivered
      deliveredEnum
      shippingInfo {
        name
        i18nAreaCodeMobile
        mobilePhoneNumber
        i18nAreaCodeFixed
        fixedPhoneNumber
        detailedAddress
        city
        provinceOrState
        countryOrRegion
        postalCode
      }
      deliveryCompany
      items {
        items {
          id
          name
          price
          category
          quantity
          url
          note
          registrationStatus
          weightKg
          user {
            id
            identityId
            name
            companyName
            email
            birthday
            gender
            job
            i18nAreaCodeMobile
            mobilePhoneNumber
            i18nAreaCodeFixed
            fixedPhoneNumber
            detailedAddress
            city
            provinceOrState
            countryOrRegion
            postalCode
            registrationStatus
            updatedAt
            createdAt
            owner
          }
          domesticParcel {
            id
            slipNumber
            accepted
            delivered
            deliveredEnum
            deliveryCompany
            updatedAt
            createdAt
            userDomesticParcelId
            owner
          }
          internationalParcel {
            id
            slipNumber
            shippingFee
            weightKg
            cost
            delivered
            deliveredEnum
            deliveryCompany
            updatedAt
            createdAt
            userInternationalParcelId
            owner
          }
          updatedAt
          createdAt
          userItemsId
          domesticParcelItemsId
          internationalParcelItemsId
          owner
        }
        nextToken
      }
      user {
        id
        identityId
        name
        companyName
        email
        birthday
        gender
        idPicture {
          bucket
          region
          key
        }
        job
        i18nAreaCodeMobile
        mobilePhoneNumber
        i18nAreaCodeFixed
        fixedPhoneNumber
        detailedAddress
        city
        provinceOrState
        countryOrRegion
        postalCode
        registrationStatus
        items {
          items {
            id
            name
            price
            category
            quantity
            url
            note
            registrationStatus
            weightKg
            updatedAt
            createdAt
            userItemsId
            domesticParcelItemsId
            internationalParcelItemsId
            owner
          }
          nextToken
        }
        domesticParcel {
          items {
            id
            slipNumber
            accepted
            delivered
            deliveredEnum
            deliveryCompany
            updatedAt
            createdAt
            userDomesticParcelId
            owner
          }
          nextToken
        }
        internationalParcel {
          items {
            id
            slipNumber
            shippingFee
            weightKg
            cost
            delivered
            deliveredEnum
            deliveryCompany
            updatedAt
            createdAt
            userInternationalParcelId
            owner
          }
          nextToken
        }
        updatedAt
        createdAt
        owner
      }
      updatedAt
      createdAt
      userInternationalParcelId
      owner
    }
  }
`;
