import {Button, Stack, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {BoxForAuth} from '../components/BoxForAuth';

export const CompleteResetPasswordScreen = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  return (
    <BoxForAuth>
      <Stack spacing={3}>
        <Typography textAlign="center" fontWeight="bold" mt="16px">
          {t('passwordResetCompleted')}
        </Typography>
        <Button
          variant="contained"
          onClick={() => {
            return navigate('/signIn');
          }}>
          {t('signIn')}
        </Button>
      </Stack>
    </BoxForAuth>
  );
};
