import {yupResolver} from '@hookform/resolvers/yup';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import {
  Stack,
  IconButton,
  InputAdornment,
  Typography,
  Button,
  Link,
  Box,
} from '@mui/material';
import pick from 'just-pick';
import {useState} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import * as yup from 'yup';
import {BoxForAuth} from '../components/BoxForAuth';
import {TextFieldForAuth} from '../components/TextFieldForAuth';
import {useIsMobile} from '../hooks/useIsMobile';
import {useSignIn} from '../providers/AuthProvider';
import {onPromise} from '../utils/promise';
import {AUTH_SCHEMA} from '../validators/schema';

interface FormInput {
  email: string;
  password: string;
}

const schema: yup.SchemaOf<FormInput> = yup
  .object()
  .shape(pick(AUTH_SCHEMA, ['email', 'password']));

export const SignInScreen = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: {isValid, errors},
  } = useForm<FormInput>({
    mode: 'all',
    defaultValues: {
      email: '',
      password: '',
    },
    criteriaMode: 'all',
    shouldFocusError: false,
    resolver: yupResolver(schema),
  });
  const [showPassword, setShowPassword] = useState(false);
  const [authError, setAuthError] = useState(false);
  const signIn = useSignIn();
  const isMobile = useIsMobile();

  const onSubmit: SubmitHandler<FormInput> = data => {
    signIn(data.email, data.password)
      .then(() => {
        navigate('/', {replace: true});
      })
      .catch(() => {
        setAuthError(true);
      });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <BoxForAuth>
      {authError ? (
        <Typography color="warning.main" fontSize="14px">
          {t('incorrectEmailOrPassword')}
        </Typography>
      ) : null}
      <form
        style={{width: '100%'}}
        onSubmit={onPromise(handleSubmit(onSubmit))}>
        <Stack mt={3} spacing={3}>
          <TextFieldForAuth
            fullWidth
            label={t('email')}
            variant="outlined"
            size={isMobile ? 'small' : 'medium'}
            type="email"
            {...register('email')}
            error={'email' in errors}
            helperText={errors.email?.message}
          />
          <Stack spacing={1}>
            <TextFieldForAuth
              fullWidth
              label={t('password')}
              variant="outlined"
              size={isMobile ? 'small' : 'medium'}
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              {...register('password')}
              error={'password' in errors}
              helperText={errors.password?.message}
            />
            <Box sx={{display: 'flex', justifyContent: 'end'}}>
              <Link
                underline="hover"
                onClick={() => navigate('/forgotPassword')}
                sx={{
                  fontSize: '12px',
                  fontWeight: '500',
                  width: '100%',
                  textAlign: 'right',
                  cursor: 'pointer',
                }}>
                {t('didYouForgetPassword')}
              </Link>
            </Box>
          </Stack>
          <Button type="submit" variant="contained" disabled={!isValid}>
            {t('signIn')}
          </Button>
        </Stack>
      </form>
      <Link
        component="button"
        underline="hover"
        sx={{
          fontSize: '14px',
          fontWeight: '500',
          display: 'flex',
          margin: '24px auto 0',
        }}
        onClick={() => {
          return navigate('/signUp');
        }}>
        {t('firstTimeUsers')}
      </Link>
    </BoxForAuth>
  );
};
