/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      identityId
      name
      companyName
      email
      birthday
      gender
      idPicture {
        bucket
        region
        key
      }
      job
      i18nAreaCodeMobile
      mobilePhoneNumber
      i18nAreaCodeFixed
      fixedPhoneNumber
      detailedAddress
      city
      provinceOrState
      countryOrRegion
      postalCode
      registrationStatus
      items {
        items {
          id
          name
          price
          category
          quantity
          url
          note
          registrationStatus
          weightKg
          user {
            id
            identityId
            name
            companyName
            email
            birthday
            gender
            job
            i18nAreaCodeMobile
            mobilePhoneNumber
            i18nAreaCodeFixed
            fixedPhoneNumber
            detailedAddress
            city
            provinceOrState
            countryOrRegion
            postalCode
            registrationStatus
            updatedAt
            createdAt
            owner
          }
          domesticParcel {
            id
            slipNumber
            accepted
            delivered
            deliveredEnum
            deliveryCompany
            updatedAt
            createdAt
            userDomesticParcelId
            owner
          }
          internationalParcel {
            id
            slipNumber
            shippingFee
            weightKg
            cost
            delivered
            deliveredEnum
            deliveryCompany
            updatedAt
            createdAt
            userInternationalParcelId
            owner
          }
          updatedAt
          createdAt
          userItemsId
          domesticParcelItemsId
          internationalParcelItemsId
          owner
        }
        nextToken
      }
      domesticParcel {
        items {
          id
          slipNumber
          accepted
          delivered
          deliveredEnum
          deliveryCompany
          items {
            nextToken
          }
          user {
            id
            identityId
            name
            companyName
            email
            birthday
            gender
            job
            i18nAreaCodeMobile
            mobilePhoneNumber
            i18nAreaCodeFixed
            fixedPhoneNumber
            detailedAddress
            city
            provinceOrState
            countryOrRegion
            postalCode
            registrationStatus
            updatedAt
            createdAt
            owner
          }
          updatedAt
          createdAt
          userDomesticParcelId
          owner
        }
        nextToken
      }
      internationalParcel {
        items {
          id
          slipNumber
          shippingFee
          weightKg
          cost
          delivered
          deliveredEnum
          shippingInfo {
            name
            i18nAreaCodeMobile
            mobilePhoneNumber
            i18nAreaCodeFixed
            fixedPhoneNumber
            detailedAddress
            city
            provinceOrState
            countryOrRegion
            postalCode
          }
          deliveryCompany
          items {
            nextToken
          }
          user {
            id
            identityId
            name
            companyName
            email
            birthday
            gender
            job
            i18nAreaCodeMobile
            mobilePhoneNumber
            i18nAreaCodeFixed
            fixedPhoneNumber
            detailedAddress
            city
            provinceOrState
            countryOrRegion
            postalCode
            registrationStatus
            updatedAt
            createdAt
            owner
          }
          updatedAt
          createdAt
          userInternationalParcelId
          owner
        }
        nextToken
      }
      updatedAt
      createdAt
      owner
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        identityId
        name
        companyName
        email
        birthday
        gender
        idPicture {
          bucket
          region
          key
        }
        job
        i18nAreaCodeMobile
        mobilePhoneNumber
        i18nAreaCodeFixed
        fixedPhoneNumber
        detailedAddress
        city
        provinceOrState
        countryOrRegion
        postalCode
        registrationStatus
        items {
          items {
            id
            name
            price
            category
            quantity
            url
            note
            registrationStatus
            weightKg
            updatedAt
            createdAt
            userItemsId
            domesticParcelItemsId
            internationalParcelItemsId
            owner
          }
          nextToken
        }
        domesticParcel {
          items {
            id
            slipNumber
            accepted
            delivered
            deliveredEnum
            deliveryCompany
            updatedAt
            createdAt
            userDomesticParcelId
            owner
          }
          nextToken
        }
        internationalParcel {
          items {
            id
            slipNumber
            shippingFee
            weightKg
            cost
            delivered
            deliveredEnum
            deliveryCompany
            updatedAt
            createdAt
            userInternationalParcelId
            owner
          }
          nextToken
        }
        updatedAt
        createdAt
        owner
      }
      nextToken
    }
  }
`;
export const userByRegistrationStatus = /* GraphQL */ `
  query UserByRegistrationStatus(
    $registrationStatus: RegistrationStatus!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByRegistrationStatus(
      registrationStatus: $registrationStatus
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        identityId
        name
        companyName
        email
        birthday
        gender
        idPicture {
          bucket
          region
          key
        }
        job
        i18nAreaCodeMobile
        mobilePhoneNumber
        i18nAreaCodeFixed
        fixedPhoneNumber
        detailedAddress
        city
        provinceOrState
        countryOrRegion
        postalCode
        registrationStatus
        items {
          items {
            id
            name
            price
            category
            quantity
            url
            note
            registrationStatus
            weightKg
            updatedAt
            createdAt
            userItemsId
            domesticParcelItemsId
            internationalParcelItemsId
            owner
          }
          nextToken
        }
        domesticParcel {
          items {
            id
            slipNumber
            accepted
            delivered
            deliveredEnum
            deliveryCompany
            updatedAt
            createdAt
            userDomesticParcelId
            owner
          }
          nextToken
        }
        internationalParcel {
          items {
            id
            slipNumber
            shippingFee
            weightKg
            cost
            delivered
            deliveredEnum
            deliveryCompany
            updatedAt
            createdAt
            userInternationalParcelId
            owner
          }
          nextToken
        }
        updatedAt
        createdAt
        owner
      }
      nextToken
    }
  }
`;
export const getItem = /* GraphQL */ `
  query GetItem($id: ID!) {
    getItem(id: $id) {
      id
      name
      price
      category
      quantity
      url
      note
      registrationStatus
      weightKg
      user {
        id
        identityId
        name
        companyName
        email
        birthday
        gender
        idPicture {
          bucket
          region
          key
        }
        job
        i18nAreaCodeMobile
        mobilePhoneNumber
        i18nAreaCodeFixed
        fixedPhoneNumber
        detailedAddress
        city
        provinceOrState
        countryOrRegion
        postalCode
        registrationStatus
        items {
          items {
            id
            name
            price
            category
            quantity
            url
            note
            registrationStatus
            weightKg
            updatedAt
            createdAt
            userItemsId
            domesticParcelItemsId
            internationalParcelItemsId
            owner
          }
          nextToken
        }
        domesticParcel {
          items {
            id
            slipNumber
            accepted
            delivered
            deliveredEnum
            deliveryCompany
            updatedAt
            createdAt
            userDomesticParcelId
            owner
          }
          nextToken
        }
        internationalParcel {
          items {
            id
            slipNumber
            shippingFee
            weightKg
            cost
            delivered
            deliveredEnum
            deliveryCompany
            updatedAt
            createdAt
            userInternationalParcelId
            owner
          }
          nextToken
        }
        updatedAt
        createdAt
        owner
      }
      domesticParcel {
        id
        slipNumber
        accepted
        delivered
        deliveredEnum
        deliveryCompany
        items {
          items {
            id
            name
            price
            category
            quantity
            url
            note
            registrationStatus
            weightKg
            updatedAt
            createdAt
            userItemsId
            domesticParcelItemsId
            internationalParcelItemsId
            owner
          }
          nextToken
        }
        user {
          id
          identityId
          name
          companyName
          email
          birthday
          gender
          idPicture {
            bucket
            region
            key
          }
          job
          i18nAreaCodeMobile
          mobilePhoneNumber
          i18nAreaCodeFixed
          fixedPhoneNumber
          detailedAddress
          city
          provinceOrState
          countryOrRegion
          postalCode
          registrationStatus
          items {
            nextToken
          }
          domesticParcel {
            nextToken
          }
          internationalParcel {
            nextToken
          }
          updatedAt
          createdAt
          owner
        }
        updatedAt
        createdAt
        userDomesticParcelId
        owner
      }
      internationalParcel {
        id
        slipNumber
        shippingFee
        weightKg
        cost
        delivered
        deliveredEnum
        shippingInfo {
          name
          i18nAreaCodeMobile
          mobilePhoneNumber
          i18nAreaCodeFixed
          fixedPhoneNumber
          detailedAddress
          city
          provinceOrState
          countryOrRegion
          postalCode
        }
        deliveryCompany
        items {
          items {
            id
            name
            price
            category
            quantity
            url
            note
            registrationStatus
            weightKg
            updatedAt
            createdAt
            userItemsId
            domesticParcelItemsId
            internationalParcelItemsId
            owner
          }
          nextToken
        }
        user {
          id
          identityId
          name
          companyName
          email
          birthday
          gender
          idPicture {
            bucket
            region
            key
          }
          job
          i18nAreaCodeMobile
          mobilePhoneNumber
          i18nAreaCodeFixed
          fixedPhoneNumber
          detailedAddress
          city
          provinceOrState
          countryOrRegion
          postalCode
          registrationStatus
          items {
            nextToken
          }
          domesticParcel {
            nextToken
          }
          internationalParcel {
            nextToken
          }
          updatedAt
          createdAt
          owner
        }
        updatedAt
        createdAt
        userInternationalParcelId
        owner
      }
      updatedAt
      createdAt
      userItemsId
      domesticParcelItemsId
      internationalParcelItemsId
      owner
    }
  }
`;
export const listItems = /* GraphQL */ `
  query ListItems(
    $filter: ModelItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        price
        category
        quantity
        url
        note
        registrationStatus
        weightKg
        user {
          id
          identityId
          name
          companyName
          email
          birthday
          gender
          idPicture {
            bucket
            region
            key
          }
          job
          i18nAreaCodeMobile
          mobilePhoneNumber
          i18nAreaCodeFixed
          fixedPhoneNumber
          detailedAddress
          city
          provinceOrState
          countryOrRegion
          postalCode
          registrationStatus
          items {
            nextToken
          }
          domesticParcel {
            nextToken
          }
          internationalParcel {
            nextToken
          }
          updatedAt
          createdAt
          owner
        }
        domesticParcel {
          id
          slipNumber
          accepted
          delivered
          deliveredEnum
          deliveryCompany
          items {
            nextToken
          }
          user {
            id
            identityId
            name
            companyName
            email
            birthday
            gender
            job
            i18nAreaCodeMobile
            mobilePhoneNumber
            i18nAreaCodeFixed
            fixedPhoneNumber
            detailedAddress
            city
            provinceOrState
            countryOrRegion
            postalCode
            registrationStatus
            updatedAt
            createdAt
            owner
          }
          updatedAt
          createdAt
          userDomesticParcelId
          owner
        }
        internationalParcel {
          id
          slipNumber
          shippingFee
          weightKg
          cost
          delivered
          deliveredEnum
          shippingInfo {
            name
            i18nAreaCodeMobile
            mobilePhoneNumber
            i18nAreaCodeFixed
            fixedPhoneNumber
            detailedAddress
            city
            provinceOrState
            countryOrRegion
            postalCode
          }
          deliveryCompany
          items {
            nextToken
          }
          user {
            id
            identityId
            name
            companyName
            email
            birthday
            gender
            job
            i18nAreaCodeMobile
            mobilePhoneNumber
            i18nAreaCodeFixed
            fixedPhoneNumber
            detailedAddress
            city
            provinceOrState
            countryOrRegion
            postalCode
            registrationStatus
            updatedAt
            createdAt
            owner
          }
          updatedAt
          createdAt
          userInternationalParcelId
          owner
        }
        updatedAt
        createdAt
        userItemsId
        domesticParcelItemsId
        internationalParcelItemsId
        owner
      }
      nextToken
    }
  }
`;
export const itemByRegistrationStatus = /* GraphQL */ `
  query ItemByRegistrationStatus(
    $registrationStatus: RegistrationStatus!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemByRegistrationStatus(
      registrationStatus: $registrationStatus
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        price
        category
        quantity
        url
        note
        registrationStatus
        weightKg
        user {
          id
          identityId
          name
          companyName
          email
          birthday
          gender
          idPicture {
            bucket
            region
            key
          }
          job
          i18nAreaCodeMobile
          mobilePhoneNumber
          i18nAreaCodeFixed
          fixedPhoneNumber
          detailedAddress
          city
          provinceOrState
          countryOrRegion
          postalCode
          registrationStatus
          items {
            nextToken
          }
          domesticParcel {
            nextToken
          }
          internationalParcel {
            nextToken
          }
          updatedAt
          createdAt
          owner
        }
        domesticParcel {
          id
          slipNumber
          accepted
          delivered
          deliveredEnum
          deliveryCompany
          items {
            nextToken
          }
          user {
            id
            identityId
            name
            companyName
            email
            birthday
            gender
            job
            i18nAreaCodeMobile
            mobilePhoneNumber
            i18nAreaCodeFixed
            fixedPhoneNumber
            detailedAddress
            city
            provinceOrState
            countryOrRegion
            postalCode
            registrationStatus
            updatedAt
            createdAt
            owner
          }
          updatedAt
          createdAt
          userDomesticParcelId
          owner
        }
        internationalParcel {
          id
          slipNumber
          shippingFee
          weightKg
          cost
          delivered
          deliveredEnum
          shippingInfo {
            name
            i18nAreaCodeMobile
            mobilePhoneNumber
            i18nAreaCodeFixed
            fixedPhoneNumber
            detailedAddress
            city
            provinceOrState
            countryOrRegion
            postalCode
          }
          deliveryCompany
          items {
            nextToken
          }
          user {
            id
            identityId
            name
            companyName
            email
            birthday
            gender
            job
            i18nAreaCodeMobile
            mobilePhoneNumber
            i18nAreaCodeFixed
            fixedPhoneNumber
            detailedAddress
            city
            provinceOrState
            countryOrRegion
            postalCode
            registrationStatus
            updatedAt
            createdAt
            owner
          }
          updatedAt
          createdAt
          userInternationalParcelId
          owner
        }
        updatedAt
        createdAt
        userItemsId
        domesticParcelItemsId
        internationalParcelItemsId
        owner
      }
      nextToken
    }
  }
`;
export const getDomesticParcel = /* GraphQL */ `
  query GetDomesticParcel($id: ID!) {
    getDomesticParcel(id: $id) {
      id
      slipNumber
      accepted
      delivered
      deliveredEnum
      deliveryCompany
      items {
        items {
          id
          name
          price
          category
          quantity
          url
          note
          registrationStatus
          weightKg
          user {
            id
            identityId
            name
            companyName
            email
            birthday
            gender
            job
            i18nAreaCodeMobile
            mobilePhoneNumber
            i18nAreaCodeFixed
            fixedPhoneNumber
            detailedAddress
            city
            provinceOrState
            countryOrRegion
            postalCode
            registrationStatus
            updatedAt
            createdAt
            owner
          }
          domesticParcel {
            id
            slipNumber
            accepted
            delivered
            deliveredEnum
            deliveryCompany
            updatedAt
            createdAt
            userDomesticParcelId
            owner
          }
          internationalParcel {
            id
            slipNumber
            shippingFee
            weightKg
            cost
            delivered
            deliveredEnum
            deliveryCompany
            updatedAt
            createdAt
            userInternationalParcelId
            owner
          }
          updatedAt
          createdAt
          userItemsId
          domesticParcelItemsId
          internationalParcelItemsId
          owner
        }
        nextToken
      }
      user {
        id
        identityId
        name
        companyName
        email
        birthday
        gender
        idPicture {
          bucket
          region
          key
        }
        job
        i18nAreaCodeMobile
        mobilePhoneNumber
        i18nAreaCodeFixed
        fixedPhoneNumber
        detailedAddress
        city
        provinceOrState
        countryOrRegion
        postalCode
        registrationStatus
        items {
          items {
            id
            name
            price
            category
            quantity
            url
            note
            registrationStatus
            weightKg
            updatedAt
            createdAt
            userItemsId
            domesticParcelItemsId
            internationalParcelItemsId
            owner
          }
          nextToken
        }
        domesticParcel {
          items {
            id
            slipNumber
            accepted
            delivered
            deliveredEnum
            deliveryCompany
            updatedAt
            createdAt
            userDomesticParcelId
            owner
          }
          nextToken
        }
        internationalParcel {
          items {
            id
            slipNumber
            shippingFee
            weightKg
            cost
            delivered
            deliveredEnum
            deliveryCompany
            updatedAt
            createdAt
            userInternationalParcelId
            owner
          }
          nextToken
        }
        updatedAt
        createdAt
        owner
      }
      updatedAt
      createdAt
      userDomesticParcelId
      owner
    }
  }
`;
export const listDomesticParcels = /* GraphQL */ `
  query ListDomesticParcels(
    $filter: ModelDomesticParcelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDomesticParcels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        slipNumber
        accepted
        delivered
        deliveredEnum
        deliveryCompany
        items {
          items {
            id
            name
            price
            category
            quantity
            url
            note
            registrationStatus
            weightKg
            updatedAt
            createdAt
            userItemsId
            domesticParcelItemsId
            internationalParcelItemsId
            owner
          }
          nextToken
        }
        user {
          id
          identityId
          name
          companyName
          email
          birthday
          gender
          idPicture {
            bucket
            region
            key
          }
          job
          i18nAreaCodeMobile
          mobilePhoneNumber
          i18nAreaCodeFixed
          fixedPhoneNumber
          detailedAddress
          city
          provinceOrState
          countryOrRegion
          postalCode
          registrationStatus
          items {
            nextToken
          }
          domesticParcel {
            nextToken
          }
          internationalParcel {
            nextToken
          }
          updatedAt
          createdAt
          owner
        }
        updatedAt
        createdAt
        userDomesticParcelId
        owner
      }
      nextToken
    }
  }
`;
export const domesticParcelByDeliveredEnum = /* GraphQL */ `
  query DomesticParcelByDeliveredEnum(
    $deliveredEnum: Delivered!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDomesticParcelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    domesticParcelByDeliveredEnum(
      deliveredEnum: $deliveredEnum
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        slipNumber
        accepted
        delivered
        deliveredEnum
        deliveryCompany
        items {
          items {
            id
            name
            price
            category
            quantity
            url
            note
            registrationStatus
            weightKg
            updatedAt
            createdAt
            userItemsId
            domesticParcelItemsId
            internationalParcelItemsId
            owner
          }
          nextToken
        }
        user {
          id
          identityId
          name
          companyName
          email
          birthday
          gender
          idPicture {
            bucket
            region
            key
          }
          job
          i18nAreaCodeMobile
          mobilePhoneNumber
          i18nAreaCodeFixed
          fixedPhoneNumber
          detailedAddress
          city
          provinceOrState
          countryOrRegion
          postalCode
          registrationStatus
          items {
            nextToken
          }
          domesticParcel {
            nextToken
          }
          internationalParcel {
            nextToken
          }
          updatedAt
          createdAt
          owner
        }
        updatedAt
        createdAt
        userDomesticParcelId
        owner
      }
      nextToken
    }
  }
`;
export const getInternationalParcel = /* GraphQL */ `
  query GetInternationalParcel($id: ID!) {
    getInternationalParcel(id: $id) {
      id
      slipNumber
      shippingFee
      weightKg
      cost
      delivered
      deliveredEnum
      shippingInfo {
        name
        i18nAreaCodeMobile
        mobilePhoneNumber
        i18nAreaCodeFixed
        fixedPhoneNumber
        detailedAddress
        city
        provinceOrState
        countryOrRegion
        postalCode
      }
      deliveryCompany
      items {
        items {
          id
          name
          price
          category
          quantity
          url
          note
          registrationStatus
          weightKg
          user {
            id
            identityId
            name
            companyName
            email
            birthday
            gender
            job
            i18nAreaCodeMobile
            mobilePhoneNumber
            i18nAreaCodeFixed
            fixedPhoneNumber
            detailedAddress
            city
            provinceOrState
            countryOrRegion
            postalCode
            registrationStatus
            updatedAt
            createdAt
            owner
          }
          domesticParcel {
            id
            slipNumber
            accepted
            delivered
            deliveredEnum
            deliveryCompany
            updatedAt
            createdAt
            userDomesticParcelId
            owner
          }
          internationalParcel {
            id
            slipNumber
            shippingFee
            weightKg
            cost
            delivered
            deliveredEnum
            deliveryCompany
            updatedAt
            createdAt
            userInternationalParcelId
            owner
          }
          updatedAt
          createdAt
          userItemsId
          domesticParcelItemsId
          internationalParcelItemsId
          owner
        }
        nextToken
      }
      user {
        id
        identityId
        name
        companyName
        email
        birthday
        gender
        idPicture {
          bucket
          region
          key
        }
        job
        i18nAreaCodeMobile
        mobilePhoneNumber
        i18nAreaCodeFixed
        fixedPhoneNumber
        detailedAddress
        city
        provinceOrState
        countryOrRegion
        postalCode
        registrationStatus
        items {
          items {
            id
            name
            price
            category
            quantity
            url
            note
            registrationStatus
            weightKg
            updatedAt
            createdAt
            userItemsId
            domesticParcelItemsId
            internationalParcelItemsId
            owner
          }
          nextToken
        }
        domesticParcel {
          items {
            id
            slipNumber
            accepted
            delivered
            deliveredEnum
            deliveryCompany
            updatedAt
            createdAt
            userDomesticParcelId
            owner
          }
          nextToken
        }
        internationalParcel {
          items {
            id
            slipNumber
            shippingFee
            weightKg
            cost
            delivered
            deliveredEnum
            deliveryCompany
            updatedAt
            createdAt
            userInternationalParcelId
            owner
          }
          nextToken
        }
        updatedAt
        createdAt
        owner
      }
      updatedAt
      createdAt
      userInternationalParcelId
      owner
    }
  }
`;
export const listInternationalParcels = /* GraphQL */ `
  query ListInternationalParcels(
    $filter: ModelInternationalParcelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInternationalParcels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        slipNumber
        shippingFee
        weightKg
        cost
        delivered
        deliveredEnum
        shippingInfo {
          name
          i18nAreaCodeMobile
          mobilePhoneNumber
          i18nAreaCodeFixed
          fixedPhoneNumber
          detailedAddress
          city
          provinceOrState
          countryOrRegion
          postalCode
        }
        deliveryCompany
        items {
          items {
            id
            name
            price
            category
            quantity
            url
            note
            registrationStatus
            weightKg
            updatedAt
            createdAt
            userItemsId
            domesticParcelItemsId
            internationalParcelItemsId
            owner
          }
          nextToken
        }
        user {
          id
          identityId
          name
          companyName
          email
          birthday
          gender
          idPicture {
            bucket
            region
            key
          }
          job
          i18nAreaCodeMobile
          mobilePhoneNumber
          i18nAreaCodeFixed
          fixedPhoneNumber
          detailedAddress
          city
          provinceOrState
          countryOrRegion
          postalCode
          registrationStatus
          items {
            nextToken
          }
          domesticParcel {
            nextToken
          }
          internationalParcel {
            nextToken
          }
          updatedAt
          createdAt
          owner
        }
        updatedAt
        createdAt
        userInternationalParcelId
        owner
      }
      nextToken
    }
  }
`;
export const internationalParcelByDeliveredEnum = /* GraphQL */ `
  query InternationalParcelByDeliveredEnum(
    $deliveredEnum: Delivered!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInternationalParcelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    internationalParcelByDeliveredEnum(
      deliveredEnum: $deliveredEnum
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        slipNumber
        shippingFee
        weightKg
        cost
        delivered
        deliveredEnum
        shippingInfo {
          name
          i18nAreaCodeMobile
          mobilePhoneNumber
          i18nAreaCodeFixed
          fixedPhoneNumber
          detailedAddress
          city
          provinceOrState
          countryOrRegion
          postalCode
        }
        deliveryCompany
        items {
          items {
            id
            name
            price
            category
            quantity
            url
            note
            registrationStatus
            weightKg
            updatedAt
            createdAt
            userItemsId
            domesticParcelItemsId
            internationalParcelItemsId
            owner
          }
          nextToken
        }
        user {
          id
          identityId
          name
          companyName
          email
          birthday
          gender
          idPicture {
            bucket
            region
            key
          }
          job
          i18nAreaCodeMobile
          mobilePhoneNumber
          i18nAreaCodeFixed
          fixedPhoneNumber
          detailedAddress
          city
          provinceOrState
          countryOrRegion
          postalCode
          registrationStatus
          items {
            nextToken
          }
          domesticParcel {
            nextToken
          }
          internationalParcel {
            nextToken
          }
          updatedAt
          createdAt
          owner
        }
        updatedAt
        createdAt
        userInternationalParcelId
        owner
      }
      nextToken
    }
  }
`;
