import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {ItemInfo} from '../components/ItemInfo';
import {useIsMobile} from '../hooks/useIsMobile';
import {useDeliveredDomesticParcels} from '../queries/parcel';
import {convertDateToDateTimeString} from '../utils/date';
import {notEmpty} from '../utils/type';

export const InternationalShippingRequest = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const isMobile = useIsMobile();
  const FOOTER_HEIGHT = isMobile ? '62px' : '68px';
  const {data} = useDeliveredDomesticParcels();
  const [isChecked, setIsChecked] = useState<{
    [parcelId: string]: Array<{itemId: string; checked: boolean}>;
  }>({});
  const parcels = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.items
      .filter(notEmpty)
      .map(parcel => ({
        ...parcel,
        items: {
          ...parcel.items,
          items:
            parcel.items?.items
              .filter(notEmpty)
              .filter(i => !i.internationalParcelItemsId) ?? [], // NOTE: 国際発送依頼済み or 国際発送済みの商品は対象外とする
        },
      }))
      .filter(parcel => parcel.items.items.length > 0);
  }, [data]);

  const handleChangeParcelCheckbox = useCallback(
    (parcelId: string, itemIds?: string[]) =>
      (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!itemIds) {
          return;
        }

        setIsChecked(prevState => {
          return {
            ...prevState,
            [parcelId]: itemIds.map(itemId => ({
              itemId,
              checked: event.target.checked,
            })),
          };
        });
      },
    [],
  );

  const handleChangeItemCheckbox = useCallback(
    (parcelId: string, itemId?: string) =>
      (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!itemId) {
          return;
        }

        setIsChecked(prevState => {
          const parcelState = prevState[parcelId]
            ? [...prevState[parcelId]]
            : [];
          const index = parcelState.findIndex(item => item.itemId === itemId);
          if (index === -1) {
            parcelState.push({itemId, checked: event.target.checked});
          } else {
            parcelState[index] = {itemId, checked: event.target.checked};
          }
          return {...prevState, [parcelId]: parcelState};
        });
      },
    [],
  );

  return (
    <Box sx={{width: '100%'}}>
      <Typography
        fontWeight="bold"
        sx={{
          fontSize: {xs: '18px', md: '24px'},
          mb: {xs: '16px', md: '24px'},
        }}>
        {t('internationalShippingRequest')}
      </Typography>
      <Typography
        sx={{
          fontSize: {xs: '12px', md: '14px'},
          mb: {xs: '16px', md: '24px'},
        }}>
        {t('pleaseSelectItem')}
      </Typography>
      {parcels.map(parcel => (
        <Stack
          key={parcel.id}
          sx={{
            p: isMobile ? '12px' : '32px',
            mb: isMobile ? '16px' : '24px',
            borderRadius: '8px',
            boxSizing: 'border-box',
            background: '#FFFFFF',
          }}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={isMobile ? 11 : undefined}>
              <Stack
                flexDirection="row"
                sx={{
                  mb: '24px',
                }}>
                <Checkbox
                  id={`${parcel.id}`}
                  sx={{width: '20px', height: '20px', m: '2.5px'}}
                  checked={
                    isChecked[parcel.id]
                      ? isChecked[parcel.id].filter(item => item.checked)
                          .length === parcel.items?.items.length
                      : false
                  }
                  onChange={handleChangeParcelCheckbox(
                    parcel.id,
                    parcel.items?.items.filter(notEmpty).map(item => item.id),
                  )}
                />
                <Stack flexDirection="column" sx={{ml: '4px'}}>
                  <Typography
                    sx={{
                      fontSize: isMobile ? '14px' : '16px',
                      fontWeight: 'bold',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}>
                    {`${t('domesticSlipNumber')}:${parcel.slipNumber ?? ''}`}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: isMobile ? '10px' : '12px',
                      opacity: '0.6',
                    }}>
                    {`${t('registrationDate')}：${convertDateToDateTimeString(
                      new Date(parcel.createdAt),
                    )}`}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
          {parcel.items?.items.filter(notEmpty).map((item, index) => (
            <div key={item.id}>
              {parcel.items?.items.length && parcel.items?.items.length > 1 ? (
                <Stack flexDirection="row" sx={{px: '32px'}}>
                  <Checkbox
                    id={`${parcel.id}-${item.id}`}
                    sx={{width: '15px', height: '15px', m: '2.5px'}}
                    checked={
                      isChecked[parcel.id]?.find(i => i.itemId === item.id)
                        ?.checked ?? false
                    }
                    onChange={handleChangeItemCheckbox(parcel.id, item.id)}
                  />
                  <Stack spacing={1} sx={{width: '100%', ml: '8px'}}>
                    <Typography
                      fontWeight="bold"
                      sx={{fontSize: isMobile ? '14px' : '16px'}}>
                      {t('point', {x: index + 1})}
                    </Typography>
                    <ItemInfo item={item} />
                  </Stack>
                </Stack>
              ) : (
                <Stack sx={{px: '32px'}}>
                  <ItemInfo item={item} />
                </Stack>
              )}
              {parcel.items && parcel.items.items.length > index + 1 && (
                <Divider
                  sx={{
                    my: '24px',
                    border: '1px dashed rgba(0, 0, 0, 0.12)',
                  }}
                />
              )}
            </div>
          ))}
        </Stack>
      ))}

      <Box sx={{height: isMobile ? '62px' : '68px'}} />
      <Stack
        justifyContent="center"
        alignItems="center"
        flexDirection="row"
        sx={{
          width: '100vw',
          height: FOOTER_HEIGHT,
          background: '#FFFFFF',
          position: 'fixed',
          bottom: 0,
          left: 0,
        }}>
        <Button
          variant="outlined"
          color="inherit"
          onClick={() => {
            navigate(-1);
          }}
          sx={{width: '120px', mx: '12px'}}>
          {t('cancel')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={
            !Object.values(isChecked).some(parcelState =>
              parcelState.find(item => item.checked),
            )
          }
          onClick={() => {
            navigate('/selectDeliveryCompany', {
              state: {
                itemIds: Object.values(isChecked)
                  .map(v => v.filter(i => i.checked).map(i => i.itemId))
                  .flat(),
              },
            });
          }}
          sx={{width: '120px', mx: '12px'}}>
          {t('next')}
        </Button>
      </Stack>
    </Box>
  );
};
