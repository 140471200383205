import {yupResolver} from '@hookform/resolvers/yup';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveIcon from '@mui/icons-material/Remove';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  Button,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material';
import {Auth} from 'aws-amplify';
import pick from 'just-pick';
import {useState} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';
import * as yup from 'yup';
import {BoxForAuth} from '../components/BoxForAuth';
import {LabelTitle} from '../components/LabelTitle';
import {TextFieldForAuth} from '../components/TextFieldForAuth';
import {useIsMobile} from '../hooks/useIsMobile';
import {onPromise} from '../utils/promise';
import {AUTH_SCHEMA} from '../validators/schema';

interface FormInput {
  authCode: string;
  password: string;
}

const schema: yup.SchemaOf<FormInput> = yup
  .object()
  .shape(pick(AUTH_SCHEMA, ['authCode', 'password']));

export const ResetPasswordScreen = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useIsMobile();
  const {
    register,
    handleSubmit,
    formState: {isValid, errors, dirtyFields},
  } = useForm<FormInput>({
    mode: 'onChange',
    criteriaMode: 'all',
    shouldFocusError: false,
    resolver: yupResolver(schema),
  });
  const [showPassword, setShowPassword] = useState(false);
  const [authError, setAuthError] = useState(false);

  const onSubmit: SubmitHandler<FormInput> = data => {
    const state = location.state as {email: string};
    Auth.forgotPasswordSubmit(state.email, data.authCode, data.password)
      .then(_data => {
        navigate('/completeResetPassword');
      })
      .catch(() => {
        setAuthError(true);
      });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <BoxForAuth>
      <Stack spacing={3}>
        <Typography
          textAlign="center"
          sx={{
            fontSize: '20px',
            fontWeight: '700',
            mt: '16px',
          }}>
          {t('changePassword')}
        </Typography>
        <Stack sx={{width: '100%'}} spacing={1}>
          <LabelTitle isRequired>{t('authCode')}</LabelTitle>
          <TextFieldForAuth
            fullWidth
            size={isMobile ? 'small' : 'medium'}
            variant="outlined"
            InputLabelProps={{shrink: false}}
            {...register('authCode', {required: true})}
            error={'authCode' in errors}
            helperText={errors.authCode?.message}
          />
        </Stack>
        <Stack sx={{mb: '8px', width: '100%'}} spacing={1}>
          <LabelTitle isRequired>{t('newPassword')}</LabelTitle>
          <TextFieldForAuth
            fullWidth
            aria-label="password"
            size={isMobile ? 'small' : 'medium'}
            variant="outlined"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}>
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            {...register('password', {required: true})}
            error={'password' in errors}
          />
          <div>
            <Stack direction="row" spacing={0.5}>
              {!!errors.password?.types?.required ||
              !!errors.password?.types?.min ||
              !dirtyFields.password ? (
                <RemoveIcon fontSize="inherit" />
              ) : (
                <CheckCircleIcon color="success" fontSize="inherit" />
              )}
              <Typography sx={{fontSize: '12px'}}>
                {t('halfAngleEnglishNumeralsAbove8Words')}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={0.5}>
              {!!errors.password?.types?.required ||
              !!errors.password?.types?.matches ||
              !dirtyFields.password ? (
                <RemoveIcon fontSize="inherit" />
              ) : (
                <CheckCircleIcon color="success" fontSize="inherit" />
              )}
              <Typography sx={{fontSize: '12px'}}>
                {t('alphanumericCombinations')}
              </Typography>
            </Stack>
          </div>
        </Stack>
        {authError ? (
          <Typography
            color="warning.main"
            fontSize="16px"
            mb="25px"
            textAlign="center">
            {t('pleaseConfirmInformation')}
          </Typography>
        ) : null}
        <Button
          variant="contained"
          disabled={!isValid}
          onClick={onPromise(handleSubmit(onSubmit))}>
          {t('change')}
        </Button>
      </Stack>
    </BoxForAuth>
  );
};
