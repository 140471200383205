import {Box, BoxProps, Typography} from '@mui/material';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useIsMobile} from '../hooks/useIsMobile';
import {LanguageButton} from './LanguageButton';

export const BoxForAuth: FC<BoxProps> = ({children, ...props}) => {
  const isMobile = useIsMobile();
  const {t} = useTranslation();

  return (
    <Box
      sx={{
        height: '100vh',
        padding: '0 24px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <Box
        sx={{
          bgcolor: '#FFFFFF',
          width: isMobile ? '100%' : '480px',
          borderRadius: '8px',
          padding: isMobile ? '24px' : '32px',
          boxSizing: 'border-box',
          marginBottom: '20px',
        }}
        {...props}>
        <Typography
          fontWeight="bold"
          color="#0050B3"
          fontSize={isMobile ? '28px' : '36px'}
          textAlign="center">
          {t('molCart')}
        </Typography>
        {children}
      </Box>
      <LanguageButton />
    </Box>
  );
};
