import {
  AppBar,
  Toolbar,
  Button,
  Avatar,
  Grid,
  Box,
  MenuItem,
  Typography,
  Menu,
  Divider,
  Stack,
} from '@mui/material';
import {Auth} from 'aws-amplify';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useIsMobile} from '../hooks/useIsMobile';
import {AccountIcon} from '../images/AccountIcon';
import {useOAuthUser} from '../providers/AuthProvider';
import {useUser} from '../queries/user';
import {LanguageButton} from './LanguageButton';

export const Header = () => {
  const {t} = useTranslation();
  const isMobile = useIsMobile();
  const user = useOAuthUser();
  const {data} = useUser(user?.username ?? '');

  const [anchorElAccountMenu, setAnchorElAccountMenu] =
    useState<null | HTMLElement>(null);
  const handleOpenAccountMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElAccountMenu(event.currentTarget);
  };
  const handleCloseAccountMenu = () => setAnchorElAccountMenu(null);
  const navigate = useNavigate();

  return (
    <AppBar
      sx={{
        background: '#0050B3',
        position: 'relative',
        boxShadow: 'unset',
      }}>
      <Toolbar>
        <Typography
          onClick={() => {
            navigate('/');
          }}
          sx={{
            fontSize: isMobile ? '20px' : '38px',
            fontWeight: 'bold',
            cursor: 'pointer',
          }}>
          {t('molCart')}
        </Typography>
        <Box sx={{margin: '0 0 0 auto', display: 'flex', gap: '12px'}}>
          <Grid container wrap="nowrap">
            <LanguageButton color="white" />
            <Button
              onClick={handleOpenAccountMenu}
              sx={{fontSize: '16px', color: '#FFFFFF'}}>
              <Avatar
                sx={{
                  width: '36px',
                  height: '36px',
                  backgroundColor: 'transparent',
                }}>
                <AccountIcon fontSize="large" />
              </Avatar>
              {isMobile ? null : data?.name}
            </Button>
            <Menu
              open={Boolean(anchorElAccountMenu)}
              onClose={handleCloseAccountMenu}
              anchorEl={anchorElAccountMenu}
              PaperProps={{style: {width: 'fit-content'}}}>
              <MenuItem sx={{pointerEvents: 'none'}}>
                <Stack>
                  <Typography variant="body2">{data?.name}</Typography>
                  <Typography sx={{fontSize: '0.8rem', opacity: '60%'}}>
                    {data?.email}
                  </Typography>
                </Stack>
              </MenuItem>
              <Divider />
              <MenuItem
                disabled={!data?.name}
                onClick={() => {
                  navigate('/profile');
                  handleCloseAccountMenu();
                }}>
                <Typography variant="body2">{t('profile')}</Typography>
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={() => {
                  window.location.href =
                    'https://cozmic.notion.site/6872408deb8a42a194dca1d63c721876';
                  handleCloseAccountMenu();
                }}>
                <Typography variant="body2">{t('law')}</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  window.location.href =
                    'https://cozmic.notion.site/5b4f2f23c3564a739143f4fde7e31207';
                  handleCloseAccountMenu();
                }}>
                <Typography variant="body2">{t('terms')}</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  window.location.href =
                    'https://cozmic.notion.site/681846b297984636a1c409b997747307';
                  handleCloseAccountMenu();
                }}>
                <Typography variant="body2">{t('privacy')}</Typography>
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={() => {
                  Auth.signOut()
                    .then(() => {
                      navigate('/');
                    })
                    .catch(() => {
                      navigate('/error');
                    });
                }}>
                <Typography variant="body2">{t('signOut')}</Typography>
              </MenuItem>
            </Menu>
          </Grid>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
