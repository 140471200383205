import {Auth, Hub} from 'aws-amplify';
import constate from 'constate';
import {useCallback, useEffect, useState} from 'react';

interface OAuthUser {
  username: string;
  signInUserSession: {
    accessToken: {
      payload: {
        'cognito:groups': string[];
      };
    };
  };
}

export const [AuthProvider, useOAuthUserState, useSignIn] = constate(
  () => {
    const [oAuthUser, setOAuthUser] = useState<{
      state: 'loading' | 'ready';
      user: OAuthUser | null;
    }>({
      state: 'loading',
      user: null,
    });
    const updateUser = useCallback(async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: true,
      })
        // NOTE: [SLIN-3] currentAuthenticatedUser returns unsafe type => https://github.com/aws-amplify/amplify-js/issues/6053
        .then((response: OAuthUser) => {
          setOAuthUser({state: 'ready', user: response});
        })
        .catch(() => {
          setOAuthUser({state: 'ready', user: null});
        });
    }, []);

    useEffect(() => {
      const unsubscribe = Hub.listen('auth', ({payload: {event}}) => {
        switch (event) {
          case 'signIn':
          case 'cognitoHostedUI':
            void updateUser();
            break;
          case 'signOut':
            setOAuthUser({state: 'ready', user: null});
            break;
          case 'signIn_failure':
          case 'cognitoHostedUI_failure':
            break;
        }
      });
      void updateUser();
      return unsubscribe;
    }, [updateUser]);
    const signIn = useCallback(
      async (username: string, password: string) => {
        await Auth.signIn({
          username,
          password,
        });
        await updateUser();
      },
      [updateUser],
    );

    return {oAuthUser, signIn};
  },
  value => value.oAuthUser,
  value => value.signIn,
);

export const useOAuthUser = (): OAuthUser | null => {
  const userState = useOAuthUserState();
  return userState.user;
};
