/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Typography,
  Grid,
  TextField,
  Box,
  InputAdornment,
} from '@mui/material';
import {FC, useCallback, useState} from 'react';
import {Edit, useGetOne, useGetRecordId} from 'react-admin';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {InternationalParcel, UpdateInternationalParcelInput} from '../API';
import {ItemInfo} from '../components/ItemInfo';
import {ShippingInfoTile} from '../components/ShippingInformation';
import {useIsMobile} from '../hooks/useIsMobile';
import {
  useInternationalParcel,
  useUpdateInternationalParcelMutation,
} from '../queries/parcel';
import {convertDateToDateTimeString} from '../utils/date';
import {getLocalizedInternationalDeliveryCompany} from '../utils/deliveryCompany';
import {onPromise} from '../utils/promise';

const RegisterButton: FC<{id: string}> = ({id}) => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<{
    slipNumber: UpdateInternationalParcelInput['slipNumber'];
    shippingFee: UpdateInternationalParcelInput['shippingFee'];
    weightKg: UpdateInternationalParcelInput['weightKg'];
  }>({
    mode: 'onBlur',
  });
  const {mutate} = useUpdateInternationalParcelMutation();
  const [open, setOpen] = useState(false);
  const onClose = useCallback(() => {
    setOpen(false);
  }, []);
  const {data} = useInternationalParcel(id);
  const onSave = useCallback(
    (data: {
      slipNumber: UpdateInternationalParcelInput['slipNumber'];
      shippingFee: UpdateInternationalParcelInput['shippingFee'];
      weightKg: UpdateInternationalParcelInput['weightKg'];
    }) => {
      try {
        const input = {
          id,
          slipNumber: data.slipNumber,
          shippingFee: data.shippingFee,
          weightKg: data.weightKg,
        };
        mutate(
          {input},
          {
            onSuccess: () => {
              onClose();
            },
          },
        );
      } catch (_e) {
        navigate('/error');
      }
    },
    [id, mutate, navigate, onClose],
  );

  return (
    <>
      <Button
        id="configurableButton"
        variant="contained"
        size={isMobile ? 'small' : 'medium'}
        onClick={() => setOpen(true)}
        disabled={!!(data?.shippingFee && data?.weightKg && data?.slipNumber)}>
        発送手続き
      </Button>
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          style: {
            width: isMobile ? '100%' : '500px',
          },
        }}>
        <DialogTitle
          sx={{fontSize: isMobile ? '14px' : '16px', fontWeight: 'bold'}}>
          発送手続き
        </DialogTitle>
        <DialogContent sx={{display: 'grid', gap: '8px'}}>
          <TextField
            {...register('slipNumber', {required: true})}
            size="small"
            placeholder="伝票番号"
            error={'slipNumber' in errors}
            fullWidth
          />
          <TextField
            {...register('shippingFee', {required: true})}
            size="small"
            placeholder="送料(円)"
            error={'shippingFee' in errors}
            fullWidth
          />
          <TextField
            {...register('weightKg', {required: true})}
            size="small"
            placeholder="0.00"
            error={'weightKg' in errors}
            fullWidth
            InputProps={{
              endAdornment: <InputAdornment position="end">Kg</InputAdornment>,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose()} variant="outlined">
            キャンセル
          </Button>
          <Button onClick={onPromise(handleSubmit(onSave))} variant="contained">
            完了
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const InternationalParcelEdit = () => {
  const {t} = useTranslation();
  const isMobile = useIsMobile();
  const recordId = useGetRecordId() as string;
  const {data: parcel} = useGetOne<InternationalParcel>(
    'internationalParcels',
    {
      id: recordId,
    },
  );
  return (
    <Edit>
      {parcel ? (
        <Stack key={parcel.id} sx={{mb: isMobile ? '16px' : '24px', p: '24px'}}>
          <Grid container spacing={1} alignItems="center" mb="24px">
            <Grid item xs={isMobile ? 11 : undefined}>
              <Stack>
                <Typography
                  sx={{
                    fontSize: isMobile ? '14px' : '16px',
                    fontWeight: 'bold',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}>
                  {`${t('internationalSlipNumber')}:${parcel.slipNumber ?? ''}`}
                </Typography>
                <Typography
                  sx={{fontSize: isMobile ? '10px' : '12px', opacity: '0.6'}}>
                  {`${t('registrationDate')}：${convertDateToDateTimeString(
                    new Date(parcel.createdAt),
                  )}`}
                </Typography>
              </Stack>
            </Grid>
            {isMobile ? (
              <>
                <Grid item xs={1} sx={{margin: '0 0 0 auto'}} />
                <Grid item xs>
                  <Grid item>
                    <RegisterButton id={parcel.id} />
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid item sx={{margin: '0 0 0 auto'}}>
                <Grid container spacing={3} sx={{alignItems: 'center'}}>
                  <Grid item>
                    <RegisterButton id={parcel.id} />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Box mb="12px" p="8px" borderRadius="4px" bgcolor="#E7F2FE">
            <ShippingInfoTile {...parcel.shippingInfo} />
          </Box>
          <Box mb="12px" p="8px" borderRadius="4px" bgcolor="#E7F2FE">
            <Typography
              sx={{
                fontSize: isMobile ? '12px !important' : '14px !important',
              }}>
              {`総申告価格：${parcel.cost ?? ''}円`}
            </Typography>
            <Typography
              sx={{
                fontSize: isMobile ? '12px !important' : '14px !important',
              }}>
              {`国際配送会社：${
                parcel.deliveryCompany
                  ? getLocalizedInternationalDeliveryCompany(
                      parcel.deliveryCompany,
                    )
                  : ''
              }`}
            </Typography>
            <Typography
              sx={{
                fontSize: isMobile ? '12px !important' : '14px !important',
              }}>
              {`最終確定送料：${parcel.shippingFee ?? ''}円`}
            </Typography>
            <Typography
              sx={{
                fontSize: isMobile ? '12px !important' : '14px !important',
              }}>
              {`最終確定重量：${parcel.weightKg ?? ''}Kg`}
            </Typography>
          </Box>
          {parcel.items?.items.map((item, index) =>
            item ? (
              <div key={item.id}>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  sx={{mb: '8px'}}>
                  <Typography
                    fontWeight="bold"
                    sx={{fontSize: isMobile ? 14 : 16}}>
                    {t('point', {x: index + 1})}
                  </Typography>
                </Stack>
                {parcel.slipNumber ? (
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    sx={{mb: '12px'}}>
                    <Typography
                      fontWeight="bold"
                      sx={{fontSize: isMobile ? 14 : 16}}>
                      国際商品ID: {parcel.slipNumber}-{index + 1}
                    </Typography>
                  </Stack>
                ) : null}
                <ItemInfo item={item} />
                {parcel.items?.items.length &&
                  parcel.items?.items.length > index + 1 && (
                    <Divider
                      sx={{
                        my: '24px',
                        border: '1px dashed rgba(0, 0, 0, 0.12)',
                      }}
                    />
                  )}
              </div>
            ) : null,
          )}
        </Stack>
      ) : null}
    </Edit>
  );
};
