import {SvgIconProps, SvgIcon} from '@mui/material';

export const ReceiptIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="22"
      height="26"
      viewBox="0 0 22 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#1976d2"
      {...props}>
      <path
        d="M1.66666 25V3.66667C1.66666 2.95942 1.94761 2.28115 2.4477 1.78105C2.9478 1.28095 3.62608 1 4.33332 1H17.6667C18.3739 1 19.0522 1.28095 19.5523 1.78105C20.0524 2.28115 20.3333 2.95942 20.3333 3.66667V25L16.3333 22.3333L13.6667 25L11 22.3333L8.33332 25L5.66666 22.3333L1.66666 25Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M13.6667 7.66675H10.3333C9.80291 7.66675 9.2942 7.87746 8.91913 8.25253C8.54406 8.62761 8.33334 9.13632 8.33334 9.66675C8.33334 10.1972 8.54406 10.7059 8.91913 11.081C9.2942 11.456 9.80291 11.6667 10.3333 11.6667H11.6667C12.1971 11.6667 12.7058 11.8775 13.0809 12.2525C13.456 12.6276 13.6667 13.1363 13.6667 13.6667C13.6667 14.1972 13.456 14.7059 13.0809 15.081C12.7058 15.456 12.1971 15.6667 11.6667 15.6667H8.33334M11 15.6667V17.6667M11 5.66675V7.66675"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </SvgIcon>
  );
};
