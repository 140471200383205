import {SvgIconProps, SvgIcon} from '@mui/material';

export const CheckListIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="22"
      height="26"
      viewBox="0 0 22 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#1976d2"
      {...props}>
      <path
        d="M6.99999 3.66675H4.33332C3.62608 3.66675 2.9478 3.9477 2.4477 4.4478C1.94761 4.94789 1.66666 5.62617 1.66666 6.33341V22.3334C1.66666 23.0407 1.94761 23.7189 2.4477 24.219C2.9478 24.7191 3.62608 25.0001 4.33332 25.0001H17.6667C18.3739 25.0001 19.0522 24.7191 19.5523 24.219C20.0524 23.7189 20.3333 23.0407 20.3333 22.3334V6.33341C20.3333 5.62617 20.0524 4.94789 19.5523 4.4478C19.0522 3.9477 18.3739 3.66675 17.6667 3.66675H15"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M12.3333 1H9.66667C8.19391 1 7 2.19391 7 3.66667C7 5.13943 8.19391 6.33333 9.66667 6.33333H12.3333C13.8061 6.33333 15 5.13943 15 3.66667C15 2.19391 13.8061 1 12.3333 1Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M7 15.6667L9.66667 18.3333L15 13"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </SvgIcon>
  );
};
