/* eslint-disable @typescript-eslint/restrict-plus-operands */

export const convertDateToDateString = (date: Date) => {
  const yyyy = date.getFullYear();
  const mm = ('00' + (date.getMonth() + 1)).slice(-2);
  const dd = ('00' + date.getDate()).slice(-2);
  return `${yyyy}/${mm}/${dd}`;
};

export const convertDateToTimeString = (date: Date) => {
  const hh = ('00' + date.getHours()).slice(-2);
  const mm = ('00' + date.getMinutes()).slice(-2);
  return `${hh}:${mm}`;
};

export const convertDateToDateTimeString = (date: Date) => {
  return `${convertDateToDateString(date)} ${convertDateToTimeString(date)}`;
};
