import {SvgIconProps, SvgIcon} from '@mui/material';

export const HelpIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M11.7 20.8H14.3V18.2H11.7V20.8ZM13 0C11.2928 0 9.60235 0.336255 8.02511 0.989566C6.44788 1.64288 5.01477 2.60045 3.80761 3.80761C1.36964 6.24558 0 9.55219 0 13C0 16.4478 1.36964 19.7544 3.80761 22.1924C5.01477 23.3995 6.44788 24.3571 8.02511 25.0104C9.60235 25.6637 11.2928 26 13 26C16.4478 26 19.7544 24.6304 22.1924 22.1924C24.6304 19.7544 26 16.4478 26 13C26 11.2928 25.6637 9.60235 25.0104 8.02511C24.3571 6.44788 23.3995 5.01477 22.1924 3.80761C20.9852 2.60045 19.5521 1.64288 17.9749 0.989566C16.3977 0.336255 14.7072 0 13 0ZM13 23.4C7.267 23.4 2.6 18.733 2.6 13C2.6 7.267 7.267 2.6 13 2.6C18.733 2.6 23.4 7.267 23.4 13C23.4 18.733 18.733 23.4 13 23.4ZM13 5.2C11.6209 5.2 10.2982 5.74786 9.32304 6.72304C8.34786 7.69823 7.8 9.02087 7.8 10.4H10.4C10.4 9.71044 10.6739 9.04912 11.1615 8.56152C11.6491 8.07393 12.3104 7.8 13 7.8C13.6896 7.8 14.3509 8.07393 14.8385 8.56152C15.3261 9.04912 15.6 9.71044 15.6 10.4C15.6 13 11.7 12.675 11.7 16.9H14.3C14.3 13.975 18.2 13.65 18.2 10.4C18.2 9.02087 17.6521 7.69823 16.677 6.72304C15.7018 5.74786 14.3791 5.2 13 5.2Z"
        fill="#1976D2"
      />
    </SvgIcon>
  );
};
