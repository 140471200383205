import {SvgIconProps, SvgIcon} from '@mui/material';

export const WarehouseOutlinedIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="26"
      height="25"
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#1976d2"
      {...props}>
      <path
        d="M1 23.9999V6.66659L13 1.33325L25 6.66659V23.9999"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M14.3333 13.3333H19.6667V23.9999H6.33333V15.9999H14.3333"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M14.3333 24.0001V12.0001C14.3333 11.6465 14.1929 11.3073 13.9428 11.0573C13.6928 10.8072 13.3536 10.6667 13 10.6667H10.3333C9.97971 10.6667 9.64057 10.8072 9.39052 11.0573C9.14048 11.3073 9 11.6465 9 12.0001V16.0001"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </SvgIcon>
  );
};
