import {SvgIconProps, SvgIcon} from '@mui/material';

export const AirportOutlinedIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="30"
      height="26"
      viewBox="0 0 30 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#1976d2"
      {...props}>
      <path
        d="M20.3333 10.3333H25.6667C26.3739 10.3333 27.0522 10.6143 27.5523 11.1144C28.0524 11.6145 28.3333 12.2928 28.3333 13C28.3333 13.7072 28.0524 14.3855 27.5523 14.8856C27.0522 15.3857 26.3739 15.6667 25.6667 15.6667H20.3333L15 25H11L13.6667 15.6667H8.33334L5.66667 18.3333H1.66667L4.33334 13L1.66667 7.66667H5.66667L8.33334 10.3333H13.6667L11 1H15L20.3333 10.3333Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </SvgIcon>
  );
};
