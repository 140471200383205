import {Button, Divider, Stack, Typography} from '@mui/material';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {Accordion} from '../components/Accordion';
import {ItemInfo} from '../components/ItemInfo';
import {useIsMobile} from '../hooks/useIsMobile';
import {useDeliveredDomesticParcels} from '../queries/parcel';
import {convertDateToDateTimeString} from '../utils/date';
import {notEmpty} from '../utils/type';

export const DeliveredDomesticParcels = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const {data} = useDeliveredDomesticParcels();
  const parcels = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.items
      .filter(notEmpty)
      .map(parcel => ({
        ...parcel,
        items: {
          ...parcel.items,
          items:
            parcel.items?.items
              .filter(notEmpty)
              .filter(i => !i.internationalParcelItemsId) ?? [], // NOTE: 国際発送依頼済み or 国際発送済みの商品は対象外とする
        },
      }))
      .filter(parcel => parcel.items.items.length > 0);
  }, [data]);
  return (
    <>
      <Button
        id="internationalShippingRequest"
        variant="contained"
        size={isMobile ? 'small' : 'medium'}
        sx={{mb: '24px'}}
        disabled={!parcels.length}
        onClick={() => {
          navigate('/internationalShippingRequest');
        }}>
        {t('internationalShippingRequest')}
      </Button>
      <>
        {parcels.map((parcel, index) => (
          <Stack key={parcel.id} sx={{mb: isMobile ? '16px' : '24px'}}>
            <Accordion
              key={index}
              title={`${t('domesticSlipNumber')}:${parcel.slipNumber ?? ''}`}
              subTitle={`${t(
                'registrationDate',
              )}：${convertDateToDateTimeString(new Date(parcel.createdAt))}`}>
              {parcel.items?.items.filter(notEmpty).map((item, index) => (
                <div key={item.id}>
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    sx={{mb: '12px'}}>
                    <Typography
                      fontWeight="bold"
                      sx={{fontSize: isMobile ? 14 : 16}}>
                      {t('point', {x: index + 1})}
                    </Typography>
                  </Stack>
                  <ItemInfo item={item} />
                  {parcel.items && parcel.items.items.length > index + 1 && (
                    <Divider
                      sx={{
                        my: '24px',
                        border: '1px dashed rgba(0, 0, 0, 0.12)',
                      }}
                    />
                  )}
                </div>
              ))}
            </Accordion>
          </Stack>
        ))}
      </>
    </>
  );
};
