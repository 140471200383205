import {Box, Stack, Typography, Button, Divider} from '@mui/material';
import {isValid} from 'date-fns';
import {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {ItemInfo} from '../components/ItemInfo';
import {useIsMobile} from '../hooks/useIsMobile';
import {DeleteIcon} from '../images/DeleteIcon';
import {useDeleteItemMutation} from '../queries/item';
import {
  useDeleteDomesticParcelMutation,
  useDomesticParcel,
} from '../queries/parcel';

export const EditParcel = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id') ?? '';
  const state = searchParams.get('state') ?? '';
  const {data} = useDomesticParcel(id);
  const {mutate: deleteParcel} = useDeleteDomesticParcelMutation();
  const {mutate: deleteItem} = useDeleteItemMutation();
  const [items, setItems] = useState(() => {
    return data?.items?.items ?? [];
  });
  const [deletedItems, setDeletedItems] = useState<string[]>([]);
  useEffect(() => {
    if (!data?.items?.items) return;
    setItems(data.items.items);
  }, [data]);
  const onDeleteItem = useCallback((id: string) => {
    setDeletedItems(prev => {
      prev.push(id);
      return prev;
    });
    setItems(prev => {
      return prev.filter(p => p && p.id !== id);
    });
  }, []);
  const onSave = useCallback(() => {
    if (items.length > 0) {
      deletedItems.forEach(id => {
        const input = {
          id,
        };
        deleteItem(
          {input},
          {
            onSuccess: () => navigate(`/progressTracker#${state}`),
            onError: () => navigate('/error'),
          },
        );
      });
    } else {
      const input = {
        id,
      };
      deleteParcel(
        {input},
        {onSuccess: () => navigate(-1), onError: () => navigate('/error')},
      );
    }
  }, [
    deleteParcel,
    deleteItem,
    deletedItems,
    id,
    items.length,
    navigate,
    state,
  ]);

  return (
    <Stack sx={{width: '100%', maxWidth: '960px'}}>
      <Typography
        fontWeight="bold"
        sx={{
          fontSize: {xs: '18px', md: '24px'},
          mb: {xs: '16px', md: '24px'},
        }}>
        {t('edit')}
      </Typography>
      <Box
        sx={{
          bgcolor: '#FFFFFF',
          borderRadius: '8px',
          boxSizing: 'border-box',
          p: {xs: '12px', md: '32px'},
        }}>
        {items.length > 0 ? (
          items.map((item, index) =>
            item ? (
              <div key={item.id}>
                {items.length && items.length > 1 ? (
                  <Typography
                    fontWeight="bold"
                    sx={{fontSize: isMobile ? 14 : 16}}>
                    {t('point', {x: index + 1})}
                  </Typography>
                ) : null}
                <ItemInfo item={item} />
                <Stack
                  direction="row"
                  justifyContent="end"
                  sx={{mt: isMobile ? '12px' : '24px'}}>
                  <Button
                    variant="outlined"
                    size="small"
                    startIcon={<DeleteIcon sx={{fill: 'none'}} />}
                    onClick={() => onDeleteItem(item.id)}>
                    {t('delete')}
                  </Button>
                </Stack>
                {items.length && items.length > index + 1 && (
                  <Divider
                    sx={{
                      my: '24px',
                      border: '1px dashed rgba(0, 0, 0, 0.12)',
                    }}
                  />
                )}
              </div>
            ) : null,
          )
        ) : (
          <Typography sx={{fontSize: isMobile ? '14px' : '16px'}}>
            {t('noItem')}
          </Typography>
        )}
      </Box>
      <Stack
        direction="row"
        justifyContent="flex-end"
        spacing={isMobile ? '16px' : '24px'}
        sx={{
          mt: isMobile ? '16px' : '24px',
        }}>
        <Button
          variant="outlined"
          color="inherit"
          sx={{width: isMobile ? '100%' : 'unset'}}
          onClick={() => {
            navigate(`/progressTracker#${state}`);
          }}>
          {t('cancel')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          sx={{width: isMobile ? '100%' : 'unset'}}
          disabled={!isValid}
          onClick={onSave}>
          {t('save')}
        </Button>
      </Stack>
    </Stack>
  );
};
