import {Box} from '@mui/material';
import {FC, ReactNode, SyntheticEvent, useCallback, useState} from 'react';
import {useNavigate} from 'react-router';
import {AcceptedDomesticParcels} from '../components/AcceptedDomesticParcels';
import {DomesticParcelsInTransit} from '../components/DomesticParcelsInTransit';
import {Tabs} from '../components/Tabs';
import {useIsMobile} from '../hooks/useIsMobile';
import {DeliveredDomesticParcels} from './DeliveredDomesticParcels';
import {DeliveredInternationalParcels} from './DeliveredInternationalParcels';
import {InternationalParcelsInTransit} from './InternationalParcelsInTransit';
import {NotAcceptedDomesticParcels} from './NotAcceptedDomesticParcels';
import {NotDeliveredInternationalParcels} from './NotDeliveredInternationalParcels';

const PROGRESSES = [
  'notAcceptedDomesticParcels',
  'acceptedDomesticParcels',
  'domesticParcelsInTransit',
  'deliveredDomesticParcels',
  'notDeliveredInternationalParcels',
  'internationalParcelsInTransit',
  'deliveredInternationalParcels',
] as const;
type Progress = typeof PROGRESSES[number];
interface TabPanelProps {
  children?: ReactNode;
  value: Progress;
  currentValue: Progress;
}

const isProgressType = (value: string): value is Progress =>
  PROGRESSES.some(p => p === value);

const TabPanel: FC<TabPanelProps> = ({children, value, currentValue}) => {
  const isMobile = useIsMobile();
  return (
    <div role="tabpanel" hidden={value !== currentValue}>
      {value === currentValue && (
        <Box sx={{pt: isMobile ? 2 : 3}}>{children}</Box>
      )}
    </div>
  );
};

export const ProgressTracker = (): JSX.Element => {
  const navigate = useNavigate();
  const [value, setValue] = useState<Progress>(() => {
    const hash = location.hash.slice(1);
    return isProgressType(hash) ? hash : 'notAcceptedDomesticParcels';
  });
  const handleChange = useCallback(
    (_event: SyntheticEvent, newValue: Progress) => {
      setValue(newValue);
      navigate(`#${newValue}`);
    },
    [navigate],
  );

  return (
    <Box sx={{width: '100%'}}>
      <Tabs value={value} onChange={handleChange} />
      <TabPanel currentValue={value} value="notAcceptedDomesticParcels">
        <NotAcceptedDomesticParcels />
      </TabPanel>
      <TabPanel currentValue={value} value="acceptedDomesticParcels">
        <AcceptedDomesticParcels />
      </TabPanel>
      <TabPanel currentValue={value} value="domesticParcelsInTransit">
        <DomesticParcelsInTransit />
      </TabPanel>
      <TabPanel currentValue={value} value="deliveredDomesticParcels">
        <DeliveredDomesticParcels />
      </TabPanel>
      <TabPanel currentValue={value} value="notDeliveredInternationalParcels">
        <NotDeliveredInternationalParcels />
      </TabPanel>
      <TabPanel currentValue={value} value="internationalParcelsInTransit">
        <InternationalParcelsInTransit />
      </TabPanel>
      <TabPanel currentValue={value} value="deliveredInternationalParcels">
        <DeliveredInternationalParcels />
      </TabPanel>
    </Box>
  );
};
