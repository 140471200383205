import {Tabs as MuiTabs, TabsProps, Tab, tabsClasses, Box} from '@mui/material';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useIsMobile} from '../hooks/useIsMobile';
import {AirportOutlinedIcon} from '../images/AirportOutlinedIcon';
import {CheckListIcon} from '../images/CheckListIcon';
import {ListIcon} from '../images/ListIcon';
import {LocalShippingOutlinedIcon} from '../images/LocalShippingOutlinedIcon';
import {ParcelOutlinedIcon} from '../images/ParcelOutlinedIcon';
import {ReceiptIcon} from '../images/ReceiptIcon';
import {WarehouseOutlinedIcon} from '../images/WarehouseOutlinedIcon';

export const Tabs: FC<TabsProps> = ({...props}) => {
  const {t} = useTranslation();
  const isMobile = useIsMobile();
  return (
    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
      <MuiTabs
        variant="scrollable"
        scrollButtons={isMobile ? 'auto' : false}
        allowScrollButtonsMobile
        sx={{
          [`& .${tabsClasses.scrollButtons}`]: {
            '&.Mui-disabled': {opacity: 0.3},
          },
        }}
        {...props}>
        <Tab
          icon={<ListIcon sx={{stroke: 'currentColor'}} />}
          iconPosition="start"
          label={<>{`${t('notAcceptedDomesticParcels')}`}</>}
          value="notAcceptedDomesticParcels"
        />
        <Tab
          icon={<CheckListIcon sx={{stroke: 'currentColor'}} />}
          iconPosition="start"
          label={<>{`${t('acceptedDomesticParcels')}`}</>}
          value="acceptedDomesticParcels"
        />
        <Tab
          icon={<LocalShippingOutlinedIcon sx={{stroke: 'currentColor'}} />}
          iconPosition="start"
          label={<>{`${t('domesticParcelsInTransit')}`}</>}
          value="domesticParcelsInTransit"
        />
        <Tab
          icon={<WarehouseOutlinedIcon sx={{stroke: 'currentColor'}} />}
          iconPosition="start"
          label={<>{`${t('deliveredDomesticParcels')}`}</>}
          value="deliveredDomesticParcels"
        />
        <Tab
          icon={<ParcelOutlinedIcon sx={{stroke: 'currentColor'}} />}
          iconPosition="start"
          label={<>{`${t('notDeliveredInternationalParcels')}`}</>}
          value="notDeliveredInternationalParcels"
        />
        <Tab
          icon={<AirportOutlinedIcon sx={{stroke: 'currentColor'}} />}
          iconPosition="start"
          label={<>{`${t('internationalParcelsInTransit')}`}</>}
          value="internationalParcelsInTransit"
        />
        <Tab
          icon={<ReceiptIcon sx={{stroke: 'currentColor'}} />}
          iconPosition="start"
          label={<>{`${t('deliveredInternationalParcels')}`}</>}
          value="deliveredInternationalParcels"
        />
      </MuiTabs>
    </Box>
  );
};
