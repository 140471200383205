import {Divider, Stack, Typography} from '@mui/material';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {Accordion} from '../components/Accordion';
import {ItemChip} from '../components/ItemChip';
import {ItemInfo} from '../components/ItemInfo';
import {useIsMobile} from '../hooks/useIsMobile';
import {useOAuthUser} from '../providers/AuthProvider';
import {useNotAcceptedDomesticParcels} from '../queries/parcel';
import {useUser} from '../queries/user';
import {convertDateToDateTimeString} from '../utils/date';
import {notEmpty} from '../utils/type';

const MolCartAddress = () => {
  const {t} = useTranslation();
  const user = useOAuthUser();
  const {data: userData} = useUser(user?.username ?? '');

  return (
    <Stack
      direction="column"
      justifyContent="center"
      sx={{
        mb: '24px',
        background: '#f6ffed',
        border: 1,
        borderRadius: '2px',
        borderColor: '#b7eb8f',
      }}>
      <Typography
        fontSize="14px"
        lineHeight="22px"
        sx={{
          py: '9px',
          px: '52px',
        }}>
        {t('molCartAddress')}
        <br />
        〒 273-0017
        <br />
        千葉県船橋市西浦 3-2-2 メガワールド船橋センター内
        <br />
        MC {userData?.name} 宛
        <br />
        TEL 047-408-5995
      </Typography>
    </Stack>
  );
};

export const NotAcceptedDomesticParcels = () => {
  const {t} = useTranslation();
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const {data} = useNotAcceptedDomesticParcels();
  const parcels = useMemo(
    () => data?.items.filter(notEmpty) ?? [],
    [data?.items],
  );
  return (
    <>
      <MolCartAddress />
      <>
        {parcels.map((parcel, index) => (
          <Stack key={parcel.id} sx={{mb: isMobile ? '16px' : '24px'}}>
            <Accordion
              key={index}
              title={`${t('registrationDate')}：${convertDateToDateTimeString(
                new Date(parcel.createdAt),
              )}`}
              onEdit={() =>
                navigate(
                  `/editParcel?state=notAcceptedDomesticParcels&id=${parcel.id}`,
                )
              }>
              {parcel.items?.items.filter(notEmpty).map((item, index) => (
                <div key={item.id}>
                  {parcel.items && parcel.items.items.length > 1 ? (
                    <Stack
                      direction="row"
                      spacing={1}
                      alignItems="center"
                      sx={{mb: '12px'}}>
                      <Typography
                        fontWeight="bold"
                        sx={{fontSize: isMobile ? 14 : 16}}>
                        {t('point', {x: index + 1})}
                      </Typography>
                      <ItemChip registrationStatus={item.registrationStatus} />
                    </Stack>
                  ) : (
                    <ItemChip
                      registrationStatus={item.registrationStatus}
                      sx={{mb: '12px'}}
                    />
                  )}
                  <ItemInfo item={item} />
                  {parcel.items && parcel.items.items.length > index + 1 && (
                    <Divider
                      sx={{
                        my: '24px',
                        border: '1px dashed rgba(0, 0, 0, 0.12)',
                      }}
                    />
                  )}
                </div>
              ))}
            </Accordion>
          </Stack>
        ))}
      </>
    </>
  );
};
