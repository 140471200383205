import {Button, Chip, Divider, Typography} from '@mui/material';
import {Stack} from '@mui/system';
import {FC, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useIsMobile} from '../hooks/useIsMobile';
import {useAcceptedDomesticParcels} from '../queries/parcel';
import {convertDateToDateTimeString} from '../utils/date';
import {Accordion} from './Accordion';
import {ItemInfo} from './ItemInfo';
import {RegisterSlipNumberDialog} from './RegisterSlipNumberDialog';

const RegisterButton: FC<{id: string}> = ({id}) => {
  const {t} = useTranslation();
  const isMobile = useIsMobile();
  const [open, setOpen] = useState(false);
  const onClose = useCallback(() => {
    setOpen(false);
  }, []);
  return (
    <>
      <Button
        id="configurableButton"
        variant="contained"
        size={isMobile ? 'small' : 'medium'}
        onClick={() => setOpen(true)}>
        {t('registerSlipNumber')}
      </Button>
      <RegisterSlipNumberDialog open={open} onClose={onClose} id={id} />
    </>
  );
};

export const AcceptedDomesticParcels = () => {
  const {t} = useTranslation();
  const {data} = useAcceptedDomesticParcels();
  const parcels = data?.items;
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  return (
    <>
      {parcels
        ? parcels.map((parcel, index) =>
            parcel ? (
              <Stack key={parcel.id} sx={{mb: isMobile ? '16px' : '24px'}}>
                <Accordion
                  key={index}
                  title={`${t(
                    'registrationDate',
                  )}：${convertDateToDateTimeString(
                    new Date(parcel.createdAt),
                  )}`}
                  onEdit={() =>
                    navigate(
                      `/editParcel?state=acceptedDomesticParcels&id=${parcel.id}`,
                    )
                  }
                  configurableButton={<RegisterButton id={parcel.id} />}>
                  {parcel.items?.items.map((item, index) =>
                    item ? (
                      <div key={item.id}>
                        {parcel.items?.items.length &&
                        parcel.items?.items.length > 1 ? (
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                            sx={{mb: '12px'}}>
                            <Typography
                              fontWeight="bold"
                              sx={{fontSize: isMobile ? 14 : 16}}>
                              {t('point', {x: index + 1})}
                            </Typography>
                            <Chip
                              label={t('reviewed')}
                              color="success"
                              size="small"
                            />
                          </Stack>
                        ) : (
                          <Chip
                            label={t('reviewed')}
                            color="success"
                            size="small"
                            sx={{mb: '12px'}}
                          />
                        )}
                        <ItemInfo item={item} />
                        {parcel.items?.items.length &&
                          parcel.items?.items.length > index + 1 && (
                            <Divider
                              sx={{
                                my: '24px',
                                border: '1px dashed rgba(0, 0, 0, 0.12)',
                              }}
                            />
                          )}
                      </div>
                    ) : null,
                  )}
                </Accordion>
              </Stack>
            ) : null,
          )
        : null}
    </>
  );
};
