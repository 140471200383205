/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateUserInput = {
  id?: string | null,
  identityId: string,
  name: string,
  companyName?: string | null,
  email: string,
  birthday: number,
  gender: Gender,
  idPicture: S3ObjectInput,
  job: string,
  i18nAreaCodeMobile?: string | null,
  mobilePhoneNumber?: string | null,
  i18nAreaCodeFixed?: string | null,
  fixedPhoneNumber?: string | null,
  detailedAddress: string,
  city: string,
  provinceOrState: string,
  countryOrRegion: string,
  postalCode: string,
  registrationStatus: RegistrationStatus,
  updatedAt?: string | null,
};

export enum Gender {
  Male = "Male",
  Female = "Female",
  Other = "Other",
}


export type S3ObjectInput = {
  bucket: string,
  region: string,
  key: string,
};

export enum RegistrationStatus {
  Requesting = "Requesting",
  Accepted = "Accepted",
  Denied = "Denied",
}


export type ModelUserConditionInput = {
  identityId?: ModelStringInput | null,
  name?: ModelStringInput | null,
  companyName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  birthday?: ModelIntInput | null,
  gender?: ModelGenderInput | null,
  job?: ModelStringInput | null,
  i18nAreaCodeMobile?: ModelStringInput | null,
  mobilePhoneNumber?: ModelStringInput | null,
  i18nAreaCodeFixed?: ModelStringInput | null,
  fixedPhoneNumber?: ModelStringInput | null,
  detailedAddress?: ModelStringInput | null,
  city?: ModelStringInput | null,
  provinceOrState?: ModelStringInput | null,
  countryOrRegion?: ModelStringInput | null,
  postalCode?: ModelStringInput | null,
  registrationStatus?: ModelRegistrationStatusInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelGenderInput = {
  eq?: Gender | null,
  ne?: Gender | null,
};

export type ModelRegistrationStatusInput = {
  eq?: RegistrationStatus | null,
  ne?: RegistrationStatus | null,
};

export type User = {
  __typename: "User",
  id: string,
  identityId: string,
  name: string,
  companyName?: string | null,
  email: string,
  birthday: number,
  gender: Gender,
  idPicture: S3Object,
  job: string,
  i18nAreaCodeMobile?: string | null,
  mobilePhoneNumber?: string | null,
  i18nAreaCodeFixed?: string | null,
  fixedPhoneNumber?: string | null,
  detailedAddress: string,
  city: string,
  provinceOrState: string,
  countryOrRegion: string,
  postalCode: string,
  registrationStatus: RegistrationStatus,
  items?: ModelItemConnection | null,
  domesticParcel?: ModelDomesticParcelConnection | null,
  internationalParcel?: ModelInternationalParcelConnection | null,
  updatedAt: string,
  createdAt: string,
  owner?: string | null,
};

export type S3Object = {
  __typename: "S3Object",
  bucket: string,
  region: string,
  key: string,
};

export type ModelItemConnection = {
  __typename: "ModelItemConnection",
  items:  Array<Item | null >,
  nextToken?: string | null,
};

export type Item = {
  __typename: "Item",
  id: string,
  name: string,
  price: number,
  category: ItemCategory,
  quantity: number,
  url: string,
  note?: string | null,
  registrationStatus: RegistrationStatus,
  weightKg?: number | null,
  user?: User | null,
  domesticParcel?: DomesticParcel | null,
  internationalParcel?: InternationalParcel | null,
  updatedAt: string,
  createdAt: string,
  userItemsId?: string | null,
  domesticParcelItemsId?: string | null,
  internationalParcelItemsId?: string | null,
  owner?: string | null,
};

export enum ItemCategory {
  Clothing = "Clothing",
  Makeup = "Makeup",
  Baby = "Baby",
  LeatherGoods = "LeatherGoods",
  Toys = "Toys",
  Books = "Books",
  Games = "Games",
  Stationery = "Stationery",
  Sports = "Sports",
  Other = "Other",
}


export type DomesticParcel = {
  __typename: "DomesticParcel",
  id: string,
  slipNumber?: string | null,
  accepted: boolean,
  delivered: boolean,
  deliveredEnum?: Delivered | null,
  deliveryCompany?: string | null,
  items?: ModelItemConnection | null,
  user?: User | null,
  updatedAt: string,
  createdAt: string,
  userDomesticParcelId?: string | null,
  owner?: string | null,
};

export enum Delivered {
  Yes = "Yes",
  No = "No",
}


export type InternationalParcel = {
  __typename: "InternationalParcel",
  id: string,
  slipNumber?: string | null,
  shippingFee?: number | null,
  weightKg?: number | null,
  cost?: number | null,
  delivered: boolean,
  deliveredEnum?: Delivered | null,
  shippingInfo?: ShippingInfo | null,
  deliveryCompany?: InternationalDeliveryCompany | null,
  items?: ModelItemConnection | null,
  user?: User | null,
  updatedAt: string,
  createdAt: string,
  userInternationalParcelId?: string | null,
  owner?: string | null,
};

export type ShippingInfo = {
  __typename: "ShippingInfo",
  name?: string | null,
  i18nAreaCodeMobile?: string | null,
  mobilePhoneNumber?: string | null,
  i18nAreaCodeFixed?: string | null,
  fixedPhoneNumber?: string | null,
  detailedAddress?: string | null,
  city?: string | null,
  provinceOrState?: string | null,
  countryOrRegion?: string | null,
  postalCode?: string | null,
};

export enum InternationalDeliveryCompany {
  Ems = "Ems",
  SfExpress = "SfExpress",
}


export type ModelDomesticParcelConnection = {
  __typename: "ModelDomesticParcelConnection",
  items:  Array<DomesticParcel | null >,
  nextToken?: string | null,
};

export type ModelInternationalParcelConnection = {
  __typename: "ModelInternationalParcelConnection",
  items:  Array<InternationalParcel | null >,
  nextToken?: string | null,
};

export type UpdateUserInput = {
  id: string,
  identityId?: string | null,
  name?: string | null,
  companyName?: string | null,
  email?: string | null,
  birthday?: number | null,
  gender?: Gender | null,
  idPicture?: S3ObjectInput | null,
  job?: string | null,
  i18nAreaCodeMobile?: string | null,
  mobilePhoneNumber?: string | null,
  i18nAreaCodeFixed?: string | null,
  fixedPhoneNumber?: string | null,
  detailedAddress?: string | null,
  city?: string | null,
  provinceOrState?: string | null,
  countryOrRegion?: string | null,
  postalCode?: string | null,
  registrationStatus?: RegistrationStatus | null,
  updatedAt?: string | null,
};

export type DeleteUserInput = {
  id: string,
};

export type CreateItemInput = {
  id?: string | null,
  name: string,
  price: number,
  category: ItemCategory,
  quantity: number,
  url: string,
  note?: string | null,
  registrationStatus: RegistrationStatus,
  weightKg?: number | null,
  updatedAt?: string | null,
  userItemsId?: string | null,
  domesticParcelItemsId?: string | null,
  internationalParcelItemsId?: string | null,
};

export type ModelItemConditionInput = {
  name?: ModelStringInput | null,
  price?: ModelIntInput | null,
  category?: ModelItemCategoryInput | null,
  quantity?: ModelIntInput | null,
  url?: ModelStringInput | null,
  note?: ModelStringInput | null,
  registrationStatus?: ModelRegistrationStatusInput | null,
  weightKg?: ModelFloatInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelItemConditionInput | null > | null,
  or?: Array< ModelItemConditionInput | null > | null,
  not?: ModelItemConditionInput | null,
  userItemsId?: ModelIDInput | null,
  domesticParcelItemsId?: ModelIDInput | null,
  internationalParcelItemsId?: ModelIDInput | null,
};

export type ModelItemCategoryInput = {
  eq?: ItemCategory | null,
  ne?: ItemCategory | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type UpdateItemInput = {
  id: string,
  name?: string | null,
  price?: number | null,
  category?: ItemCategory | null,
  quantity?: number | null,
  url?: string | null,
  note?: string | null,
  registrationStatus?: RegistrationStatus | null,
  weightKg?: number | null,
  updatedAt?: string | null,
  userItemsId?: string | null,
  domesticParcelItemsId?: string | null,
  internationalParcelItemsId?: string | null,
};

export type DeleteItemInput = {
  id: string,
};

export type CreateDomesticParcelInput = {
  id?: string | null,
  slipNumber?: string | null,
  accepted: boolean,
  delivered: boolean,
  deliveredEnum?: Delivered | null,
  deliveryCompany?: string | null,
  updatedAt?: string | null,
  userDomesticParcelId?: string | null,
};

export type ModelDomesticParcelConditionInput = {
  slipNumber?: ModelStringInput | null,
  accepted?: ModelBooleanInput | null,
  delivered?: ModelBooleanInput | null,
  deliveredEnum?: ModelDeliveredInput | null,
  deliveryCompany?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelDomesticParcelConditionInput | null > | null,
  or?: Array< ModelDomesticParcelConditionInput | null > | null,
  not?: ModelDomesticParcelConditionInput | null,
  userDomesticParcelId?: ModelIDInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelDeliveredInput = {
  eq?: Delivered | null,
  ne?: Delivered | null,
};

export type UpdateDomesticParcelInput = {
  id: string,
  slipNumber?: string | null,
  accepted?: boolean | null,
  delivered?: boolean | null,
  deliveredEnum?: Delivered | null,
  deliveryCompany?: string | null,
  updatedAt?: string | null,
  userDomesticParcelId?: string | null,
};

export type DeleteDomesticParcelInput = {
  id: string,
};

export type CreateInternationalParcelInput = {
  id?: string | null,
  slipNumber?: string | null,
  shippingFee?: number | null,
  weightKg?: number | null,
  cost?: number | null,
  delivered: boolean,
  deliveredEnum?: Delivered | null,
  shippingInfo?: ShippingInfoInput | null,
  deliveryCompany?: InternationalDeliveryCompany | null,
  updatedAt?: string | null,
  userInternationalParcelId?: string | null,
};

export type ShippingInfoInput = {
  name?: string | null,
  i18nAreaCodeMobile?: string | null,
  mobilePhoneNumber?: string | null,
  i18nAreaCodeFixed?: string | null,
  fixedPhoneNumber?: string | null,
  detailedAddress?: string | null,
  city?: string | null,
  provinceOrState?: string | null,
  countryOrRegion?: string | null,
  postalCode?: string | null,
};

export type ModelInternationalParcelConditionInput = {
  slipNumber?: ModelStringInput | null,
  shippingFee?: ModelIntInput | null,
  weightKg?: ModelFloatInput | null,
  cost?: ModelIntInput | null,
  delivered?: ModelBooleanInput | null,
  deliveredEnum?: ModelDeliveredInput | null,
  deliveryCompany?: ModelInternationalDeliveryCompanyInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelInternationalParcelConditionInput | null > | null,
  or?: Array< ModelInternationalParcelConditionInput | null > | null,
  not?: ModelInternationalParcelConditionInput | null,
  userInternationalParcelId?: ModelIDInput | null,
};

export type ModelInternationalDeliveryCompanyInput = {
  eq?: InternationalDeliveryCompany | null,
  ne?: InternationalDeliveryCompany | null,
};

export type UpdateInternationalParcelInput = {
  id: string,
  slipNumber?: string | null,
  shippingFee?: number | null,
  weightKg?: number | null,
  cost?: number | null,
  delivered?: boolean | null,
  deliveredEnum?: Delivered | null,
  shippingInfo?: ShippingInfoInput | null,
  deliveryCompany?: InternationalDeliveryCompany | null,
  updatedAt?: string | null,
  userInternationalParcelId?: string | null,
};

export type DeleteInternationalParcelInput = {
  id: string,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  identityId?: ModelStringInput | null,
  name?: ModelStringInput | null,
  companyName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  birthday?: ModelIntInput | null,
  gender?: ModelGenderInput | null,
  job?: ModelStringInput | null,
  i18nAreaCodeMobile?: ModelStringInput | null,
  mobilePhoneNumber?: ModelStringInput | null,
  i18nAreaCodeFixed?: ModelStringInput | null,
  fixedPhoneNumber?: ModelStringInput | null,
  detailedAddress?: ModelStringInput | null,
  city?: ModelStringInput | null,
  provinceOrState?: ModelStringInput | null,
  countryOrRegion?: ModelStringInput | null,
  postalCode?: ModelStringInput | null,
  registrationStatus?: ModelRegistrationStatusInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelItemFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  price?: ModelIntInput | null,
  category?: ModelItemCategoryInput | null,
  quantity?: ModelIntInput | null,
  url?: ModelStringInput | null,
  note?: ModelStringInput | null,
  registrationStatus?: ModelRegistrationStatusInput | null,
  weightKg?: ModelFloatInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelItemFilterInput | null > | null,
  or?: Array< ModelItemFilterInput | null > | null,
  not?: ModelItemFilterInput | null,
  userItemsId?: ModelIDInput | null,
  domesticParcelItemsId?: ModelIDInput | null,
  internationalParcelItemsId?: ModelIDInput | null,
};

export type ModelDomesticParcelFilterInput = {
  id?: ModelIDInput | null,
  slipNumber?: ModelStringInput | null,
  accepted?: ModelBooleanInput | null,
  delivered?: ModelBooleanInput | null,
  deliveredEnum?: ModelDeliveredInput | null,
  deliveryCompany?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelDomesticParcelFilterInput | null > | null,
  or?: Array< ModelDomesticParcelFilterInput | null > | null,
  not?: ModelDomesticParcelFilterInput | null,
  userDomesticParcelId?: ModelIDInput | null,
};

export type ModelInternationalParcelFilterInput = {
  id?: ModelIDInput | null,
  slipNumber?: ModelStringInput | null,
  shippingFee?: ModelIntInput | null,
  weightKg?: ModelFloatInput | null,
  cost?: ModelIntInput | null,
  delivered?: ModelBooleanInput | null,
  deliveredEnum?: ModelDeliveredInput | null,
  deliveryCompany?: ModelInternationalDeliveryCompanyInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelInternationalParcelFilterInput | null > | null,
  or?: Array< ModelInternationalParcelFilterInput | null > | null,
  not?: ModelInternationalParcelFilterInput | null,
  userInternationalParcelId?: ModelIDInput | null,
};

export type ModelSubscriptionUserFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  identityId?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  companyName?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  birthday?: ModelSubscriptionIntInput | null,
  gender?: ModelSubscriptionStringInput | null,
  job?: ModelSubscriptionStringInput | null,
  i18nAreaCodeMobile?: ModelSubscriptionStringInput | null,
  mobilePhoneNumber?: ModelSubscriptionStringInput | null,
  i18nAreaCodeFixed?: ModelSubscriptionStringInput | null,
  fixedPhoneNumber?: ModelSubscriptionStringInput | null,
  detailedAddress?: ModelSubscriptionStringInput | null,
  city?: ModelSubscriptionStringInput | null,
  provinceOrState?: ModelSubscriptionStringInput | null,
  countryOrRegion?: ModelSubscriptionStringInput | null,
  postalCode?: ModelSubscriptionStringInput | null,
  registrationStatus?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionItemFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  price?: ModelSubscriptionIntInput | null,
  category?: ModelSubscriptionStringInput | null,
  quantity?: ModelSubscriptionIntInput | null,
  url?: ModelSubscriptionStringInput | null,
  note?: ModelSubscriptionStringInput | null,
  registrationStatus?: ModelSubscriptionStringInput | null,
  weightKg?: ModelSubscriptionFloatInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionItemFilterInput | null > | null,
  or?: Array< ModelSubscriptionItemFilterInput | null > | null,
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionDomesticParcelFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  slipNumber?: ModelSubscriptionStringInput | null,
  accepted?: ModelSubscriptionBooleanInput | null,
  delivered?: ModelSubscriptionBooleanInput | null,
  deliveredEnum?: ModelSubscriptionStringInput | null,
  deliveryCompany?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionDomesticParcelFilterInput | null > | null,
  or?: Array< ModelSubscriptionDomesticParcelFilterInput | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionInternationalParcelFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  slipNumber?: ModelSubscriptionStringInput | null,
  shippingFee?: ModelSubscriptionIntInput | null,
  weightKg?: ModelSubscriptionFloatInput | null,
  cost?: ModelSubscriptionIntInput | null,
  delivered?: ModelSubscriptionBooleanInput | null,
  deliveredEnum?: ModelSubscriptionStringInput | null,
  deliveryCompany?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionInternationalParcelFilterInput | null > | null,
  or?: Array< ModelSubscriptionInternationalParcelFilterInput | null > | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    identityId: string,
    name: string,
    companyName?: string | null,
    email: string,
    birthday: number,
    gender: Gender,
    idPicture:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    },
    job: string,
    i18nAreaCodeMobile?: string | null,
    mobilePhoneNumber?: string | null,
    i18nAreaCodeFixed?: string | null,
    fixedPhoneNumber?: string | null,
    detailedAddress: string,
    city: string,
    provinceOrState: string,
    countryOrRegion: string,
    postalCode: string,
    registrationStatus: RegistrationStatus,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        name: string,
        price: number,
        category: ItemCategory,
        quantity: number,
        url: string,
        note?: string | null,
        registrationStatus: RegistrationStatus,
        weightKg?: number | null,
        user?:  {
          __typename: "User",
          id: string,
          identityId: string,
          name: string,
          companyName?: string | null,
          email: string,
          birthday: number,
          gender: Gender,
          job: string,
          i18nAreaCodeMobile?: string | null,
          mobilePhoneNumber?: string | null,
          i18nAreaCodeFixed?: string | null,
          fixedPhoneNumber?: string | null,
          detailedAddress: string,
          city: string,
          provinceOrState: string,
          countryOrRegion: string,
          postalCode: string,
          registrationStatus: RegistrationStatus,
          updatedAt: string,
          createdAt: string,
          owner?: string | null,
        } | null,
        domesticParcel?:  {
          __typename: "DomesticParcel",
          id: string,
          slipNumber?: string | null,
          accepted: boolean,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: string | null,
          updatedAt: string,
          createdAt: string,
          userDomesticParcelId?: string | null,
          owner?: string | null,
        } | null,
        internationalParcel?:  {
          __typename: "InternationalParcel",
          id: string,
          slipNumber?: string | null,
          shippingFee?: number | null,
          weightKg?: number | null,
          cost?: number | null,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: InternationalDeliveryCompany | null,
          updatedAt: string,
          createdAt: string,
          userInternationalParcelId?: string | null,
          owner?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        userItemsId?: string | null,
        domesticParcelItemsId?: string | null,
        internationalParcelItemsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    domesticParcel?:  {
      __typename: "ModelDomesticParcelConnection",
      items:  Array< {
        __typename: "DomesticParcel",
        id: string,
        slipNumber?: string | null,
        accepted: boolean,
        delivered: boolean,
        deliveredEnum?: Delivered | null,
        deliveryCompany?: string | null,
        items?:  {
          __typename: "ModelItemConnection",
          nextToken?: string | null,
        } | null,
        user?:  {
          __typename: "User",
          id: string,
          identityId: string,
          name: string,
          companyName?: string | null,
          email: string,
          birthday: number,
          gender: Gender,
          job: string,
          i18nAreaCodeMobile?: string | null,
          mobilePhoneNumber?: string | null,
          i18nAreaCodeFixed?: string | null,
          fixedPhoneNumber?: string | null,
          detailedAddress: string,
          city: string,
          provinceOrState: string,
          countryOrRegion: string,
          postalCode: string,
          registrationStatus: RegistrationStatus,
          updatedAt: string,
          createdAt: string,
          owner?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        userDomesticParcelId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    internationalParcel?:  {
      __typename: "ModelInternationalParcelConnection",
      items:  Array< {
        __typename: "InternationalParcel",
        id: string,
        slipNumber?: string | null,
        shippingFee?: number | null,
        weightKg?: number | null,
        cost?: number | null,
        delivered: boolean,
        deliveredEnum?: Delivered | null,
        shippingInfo?:  {
          __typename: "ShippingInfo",
          name?: string | null,
          i18nAreaCodeMobile?: string | null,
          mobilePhoneNumber?: string | null,
          i18nAreaCodeFixed?: string | null,
          fixedPhoneNumber?: string | null,
          detailedAddress?: string | null,
          city?: string | null,
          provinceOrState?: string | null,
          countryOrRegion?: string | null,
          postalCode?: string | null,
        } | null,
        deliveryCompany?: InternationalDeliveryCompany | null,
        items?:  {
          __typename: "ModelItemConnection",
          nextToken?: string | null,
        } | null,
        user?:  {
          __typename: "User",
          id: string,
          identityId: string,
          name: string,
          companyName?: string | null,
          email: string,
          birthday: number,
          gender: Gender,
          job: string,
          i18nAreaCodeMobile?: string | null,
          mobilePhoneNumber?: string | null,
          i18nAreaCodeFixed?: string | null,
          fixedPhoneNumber?: string | null,
          detailedAddress: string,
          city: string,
          provinceOrState: string,
          countryOrRegion: string,
          postalCode: string,
          registrationStatus: RegistrationStatus,
          updatedAt: string,
          createdAt: string,
          owner?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        userInternationalParcelId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
    createdAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    identityId: string,
    name: string,
    companyName?: string | null,
    email: string,
    birthday: number,
    gender: Gender,
    idPicture:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    },
    job: string,
    i18nAreaCodeMobile?: string | null,
    mobilePhoneNumber?: string | null,
    i18nAreaCodeFixed?: string | null,
    fixedPhoneNumber?: string | null,
    detailedAddress: string,
    city: string,
    provinceOrState: string,
    countryOrRegion: string,
    postalCode: string,
    registrationStatus: RegistrationStatus,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        name: string,
        price: number,
        category: ItemCategory,
        quantity: number,
        url: string,
        note?: string | null,
        registrationStatus: RegistrationStatus,
        weightKg?: number | null,
        user?:  {
          __typename: "User",
          id: string,
          identityId: string,
          name: string,
          companyName?: string | null,
          email: string,
          birthday: number,
          gender: Gender,
          job: string,
          i18nAreaCodeMobile?: string | null,
          mobilePhoneNumber?: string | null,
          i18nAreaCodeFixed?: string | null,
          fixedPhoneNumber?: string | null,
          detailedAddress: string,
          city: string,
          provinceOrState: string,
          countryOrRegion: string,
          postalCode: string,
          registrationStatus: RegistrationStatus,
          updatedAt: string,
          createdAt: string,
          owner?: string | null,
        } | null,
        domesticParcel?:  {
          __typename: "DomesticParcel",
          id: string,
          slipNumber?: string | null,
          accepted: boolean,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: string | null,
          updatedAt: string,
          createdAt: string,
          userDomesticParcelId?: string | null,
          owner?: string | null,
        } | null,
        internationalParcel?:  {
          __typename: "InternationalParcel",
          id: string,
          slipNumber?: string | null,
          shippingFee?: number | null,
          weightKg?: number | null,
          cost?: number | null,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: InternationalDeliveryCompany | null,
          updatedAt: string,
          createdAt: string,
          userInternationalParcelId?: string | null,
          owner?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        userItemsId?: string | null,
        domesticParcelItemsId?: string | null,
        internationalParcelItemsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    domesticParcel?:  {
      __typename: "ModelDomesticParcelConnection",
      items:  Array< {
        __typename: "DomesticParcel",
        id: string,
        slipNumber?: string | null,
        accepted: boolean,
        delivered: boolean,
        deliveredEnum?: Delivered | null,
        deliveryCompany?: string | null,
        items?:  {
          __typename: "ModelItemConnection",
          nextToken?: string | null,
        } | null,
        user?:  {
          __typename: "User",
          id: string,
          identityId: string,
          name: string,
          companyName?: string | null,
          email: string,
          birthday: number,
          gender: Gender,
          job: string,
          i18nAreaCodeMobile?: string | null,
          mobilePhoneNumber?: string | null,
          i18nAreaCodeFixed?: string | null,
          fixedPhoneNumber?: string | null,
          detailedAddress: string,
          city: string,
          provinceOrState: string,
          countryOrRegion: string,
          postalCode: string,
          registrationStatus: RegistrationStatus,
          updatedAt: string,
          createdAt: string,
          owner?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        userDomesticParcelId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    internationalParcel?:  {
      __typename: "ModelInternationalParcelConnection",
      items:  Array< {
        __typename: "InternationalParcel",
        id: string,
        slipNumber?: string | null,
        shippingFee?: number | null,
        weightKg?: number | null,
        cost?: number | null,
        delivered: boolean,
        deliveredEnum?: Delivered | null,
        shippingInfo?:  {
          __typename: "ShippingInfo",
          name?: string | null,
          i18nAreaCodeMobile?: string | null,
          mobilePhoneNumber?: string | null,
          i18nAreaCodeFixed?: string | null,
          fixedPhoneNumber?: string | null,
          detailedAddress?: string | null,
          city?: string | null,
          provinceOrState?: string | null,
          countryOrRegion?: string | null,
          postalCode?: string | null,
        } | null,
        deliveryCompany?: InternationalDeliveryCompany | null,
        items?:  {
          __typename: "ModelItemConnection",
          nextToken?: string | null,
        } | null,
        user?:  {
          __typename: "User",
          id: string,
          identityId: string,
          name: string,
          companyName?: string | null,
          email: string,
          birthday: number,
          gender: Gender,
          job: string,
          i18nAreaCodeMobile?: string | null,
          mobilePhoneNumber?: string | null,
          i18nAreaCodeFixed?: string | null,
          fixedPhoneNumber?: string | null,
          detailedAddress: string,
          city: string,
          provinceOrState: string,
          countryOrRegion: string,
          postalCode: string,
          registrationStatus: RegistrationStatus,
          updatedAt: string,
          createdAt: string,
          owner?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        userInternationalParcelId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
    createdAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    identityId: string,
    name: string,
    companyName?: string | null,
    email: string,
    birthday: number,
    gender: Gender,
    idPicture:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    },
    job: string,
    i18nAreaCodeMobile?: string | null,
    mobilePhoneNumber?: string | null,
    i18nAreaCodeFixed?: string | null,
    fixedPhoneNumber?: string | null,
    detailedAddress: string,
    city: string,
    provinceOrState: string,
    countryOrRegion: string,
    postalCode: string,
    registrationStatus: RegistrationStatus,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        name: string,
        price: number,
        category: ItemCategory,
        quantity: number,
        url: string,
        note?: string | null,
        registrationStatus: RegistrationStatus,
        weightKg?: number | null,
        user?:  {
          __typename: "User",
          id: string,
          identityId: string,
          name: string,
          companyName?: string | null,
          email: string,
          birthday: number,
          gender: Gender,
          job: string,
          i18nAreaCodeMobile?: string | null,
          mobilePhoneNumber?: string | null,
          i18nAreaCodeFixed?: string | null,
          fixedPhoneNumber?: string | null,
          detailedAddress: string,
          city: string,
          provinceOrState: string,
          countryOrRegion: string,
          postalCode: string,
          registrationStatus: RegistrationStatus,
          updatedAt: string,
          createdAt: string,
          owner?: string | null,
        } | null,
        domesticParcel?:  {
          __typename: "DomesticParcel",
          id: string,
          slipNumber?: string | null,
          accepted: boolean,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: string | null,
          updatedAt: string,
          createdAt: string,
          userDomesticParcelId?: string | null,
          owner?: string | null,
        } | null,
        internationalParcel?:  {
          __typename: "InternationalParcel",
          id: string,
          slipNumber?: string | null,
          shippingFee?: number | null,
          weightKg?: number | null,
          cost?: number | null,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: InternationalDeliveryCompany | null,
          updatedAt: string,
          createdAt: string,
          userInternationalParcelId?: string | null,
          owner?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        userItemsId?: string | null,
        domesticParcelItemsId?: string | null,
        internationalParcelItemsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    domesticParcel?:  {
      __typename: "ModelDomesticParcelConnection",
      items:  Array< {
        __typename: "DomesticParcel",
        id: string,
        slipNumber?: string | null,
        accepted: boolean,
        delivered: boolean,
        deliveredEnum?: Delivered | null,
        deliveryCompany?: string | null,
        items?:  {
          __typename: "ModelItemConnection",
          nextToken?: string | null,
        } | null,
        user?:  {
          __typename: "User",
          id: string,
          identityId: string,
          name: string,
          companyName?: string | null,
          email: string,
          birthday: number,
          gender: Gender,
          job: string,
          i18nAreaCodeMobile?: string | null,
          mobilePhoneNumber?: string | null,
          i18nAreaCodeFixed?: string | null,
          fixedPhoneNumber?: string | null,
          detailedAddress: string,
          city: string,
          provinceOrState: string,
          countryOrRegion: string,
          postalCode: string,
          registrationStatus: RegistrationStatus,
          updatedAt: string,
          createdAt: string,
          owner?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        userDomesticParcelId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    internationalParcel?:  {
      __typename: "ModelInternationalParcelConnection",
      items:  Array< {
        __typename: "InternationalParcel",
        id: string,
        slipNumber?: string | null,
        shippingFee?: number | null,
        weightKg?: number | null,
        cost?: number | null,
        delivered: boolean,
        deliveredEnum?: Delivered | null,
        shippingInfo?:  {
          __typename: "ShippingInfo",
          name?: string | null,
          i18nAreaCodeMobile?: string | null,
          mobilePhoneNumber?: string | null,
          i18nAreaCodeFixed?: string | null,
          fixedPhoneNumber?: string | null,
          detailedAddress?: string | null,
          city?: string | null,
          provinceOrState?: string | null,
          countryOrRegion?: string | null,
          postalCode?: string | null,
        } | null,
        deliveryCompany?: InternationalDeliveryCompany | null,
        items?:  {
          __typename: "ModelItemConnection",
          nextToken?: string | null,
        } | null,
        user?:  {
          __typename: "User",
          id: string,
          identityId: string,
          name: string,
          companyName?: string | null,
          email: string,
          birthday: number,
          gender: Gender,
          job: string,
          i18nAreaCodeMobile?: string | null,
          mobilePhoneNumber?: string | null,
          i18nAreaCodeFixed?: string | null,
          fixedPhoneNumber?: string | null,
          detailedAddress: string,
          city: string,
          provinceOrState: string,
          countryOrRegion: string,
          postalCode: string,
          registrationStatus: RegistrationStatus,
          updatedAt: string,
          createdAt: string,
          owner?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        userInternationalParcelId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
    createdAt: string,
    owner?: string | null,
  } | null,
};

export type CreateItemMutationVariables = {
  input: CreateItemInput,
  condition?: ModelItemConditionInput | null,
};

export type CreateItemMutation = {
  createItem?:  {
    __typename: "Item",
    id: string,
    name: string,
    price: number,
    category: ItemCategory,
    quantity: number,
    url: string,
    note?: string | null,
    registrationStatus: RegistrationStatus,
    weightKg?: number | null,
    user?:  {
      __typename: "User",
      id: string,
      identityId: string,
      name: string,
      companyName?: string | null,
      email: string,
      birthday: number,
      gender: Gender,
      idPicture:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      job: string,
      i18nAreaCodeMobile?: string | null,
      mobilePhoneNumber?: string | null,
      i18nAreaCodeFixed?: string | null,
      fixedPhoneNumber?: string | null,
      detailedAddress: string,
      city: string,
      provinceOrState: string,
      countryOrRegion: string,
      postalCode: string,
      registrationStatus: RegistrationStatus,
      items?:  {
        __typename: "ModelItemConnection",
        items:  Array< {
          __typename: "Item",
          id: string,
          name: string,
          price: number,
          category: ItemCategory,
          quantity: number,
          url: string,
          note?: string | null,
          registrationStatus: RegistrationStatus,
          weightKg?: number | null,
          updatedAt: string,
          createdAt: string,
          userItemsId?: string | null,
          domesticParcelItemsId?: string | null,
          internationalParcelItemsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      domesticParcel?:  {
        __typename: "ModelDomesticParcelConnection",
        items:  Array< {
          __typename: "DomesticParcel",
          id: string,
          slipNumber?: string | null,
          accepted: boolean,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: string | null,
          updatedAt: string,
          createdAt: string,
          userDomesticParcelId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      internationalParcel?:  {
        __typename: "ModelInternationalParcelConnection",
        items:  Array< {
          __typename: "InternationalParcel",
          id: string,
          slipNumber?: string | null,
          shippingFee?: number | null,
          weightKg?: number | null,
          cost?: number | null,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: InternationalDeliveryCompany | null,
          updatedAt: string,
          createdAt: string,
          userInternationalParcelId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      createdAt: string,
      owner?: string | null,
    } | null,
    domesticParcel?:  {
      __typename: "DomesticParcel",
      id: string,
      slipNumber?: string | null,
      accepted: boolean,
      delivered: boolean,
      deliveredEnum?: Delivered | null,
      deliveryCompany?: string | null,
      items?:  {
        __typename: "ModelItemConnection",
        items:  Array< {
          __typename: "Item",
          id: string,
          name: string,
          price: number,
          category: ItemCategory,
          quantity: number,
          url: string,
          note?: string | null,
          registrationStatus: RegistrationStatus,
          weightKg?: number | null,
          updatedAt: string,
          createdAt: string,
          userItemsId?: string | null,
          domesticParcelItemsId?: string | null,
          internationalParcelItemsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        identityId: string,
        name: string,
        companyName?: string | null,
        email: string,
        birthday: number,
        gender: Gender,
        idPicture:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        job: string,
        i18nAreaCodeMobile?: string | null,
        mobilePhoneNumber?: string | null,
        i18nAreaCodeFixed?: string | null,
        fixedPhoneNumber?: string | null,
        detailedAddress: string,
        city: string,
        provinceOrState: string,
        countryOrRegion: string,
        postalCode: string,
        registrationStatus: RegistrationStatus,
        items?:  {
          __typename: "ModelItemConnection",
          nextToken?: string | null,
        } | null,
        domesticParcel?:  {
          __typename: "ModelDomesticParcelConnection",
          nextToken?: string | null,
        } | null,
        internationalParcel?:  {
          __typename: "ModelInternationalParcelConnection",
          nextToken?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        owner?: string | null,
      } | null,
      updatedAt: string,
      createdAt: string,
      userDomesticParcelId?: string | null,
      owner?: string | null,
    } | null,
    internationalParcel?:  {
      __typename: "InternationalParcel",
      id: string,
      slipNumber?: string | null,
      shippingFee?: number | null,
      weightKg?: number | null,
      cost?: number | null,
      delivered: boolean,
      deliveredEnum?: Delivered | null,
      shippingInfo?:  {
        __typename: "ShippingInfo",
        name?: string | null,
        i18nAreaCodeMobile?: string | null,
        mobilePhoneNumber?: string | null,
        i18nAreaCodeFixed?: string | null,
        fixedPhoneNumber?: string | null,
        detailedAddress?: string | null,
        city?: string | null,
        provinceOrState?: string | null,
        countryOrRegion?: string | null,
        postalCode?: string | null,
      } | null,
      deliveryCompany?: InternationalDeliveryCompany | null,
      items?:  {
        __typename: "ModelItemConnection",
        items:  Array< {
          __typename: "Item",
          id: string,
          name: string,
          price: number,
          category: ItemCategory,
          quantity: number,
          url: string,
          note?: string | null,
          registrationStatus: RegistrationStatus,
          weightKg?: number | null,
          updatedAt: string,
          createdAt: string,
          userItemsId?: string | null,
          domesticParcelItemsId?: string | null,
          internationalParcelItemsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        identityId: string,
        name: string,
        companyName?: string | null,
        email: string,
        birthday: number,
        gender: Gender,
        idPicture:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        job: string,
        i18nAreaCodeMobile?: string | null,
        mobilePhoneNumber?: string | null,
        i18nAreaCodeFixed?: string | null,
        fixedPhoneNumber?: string | null,
        detailedAddress: string,
        city: string,
        provinceOrState: string,
        countryOrRegion: string,
        postalCode: string,
        registrationStatus: RegistrationStatus,
        items?:  {
          __typename: "ModelItemConnection",
          nextToken?: string | null,
        } | null,
        domesticParcel?:  {
          __typename: "ModelDomesticParcelConnection",
          nextToken?: string | null,
        } | null,
        internationalParcel?:  {
          __typename: "ModelInternationalParcelConnection",
          nextToken?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        owner?: string | null,
      } | null,
      updatedAt: string,
      createdAt: string,
      userInternationalParcelId?: string | null,
      owner?: string | null,
    } | null,
    updatedAt: string,
    createdAt: string,
    userItemsId?: string | null,
    domesticParcelItemsId?: string | null,
    internationalParcelItemsId?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateItemMutationVariables = {
  input: UpdateItemInput,
  condition?: ModelItemConditionInput | null,
};

export type UpdateItemMutation = {
  updateItem?:  {
    __typename: "Item",
    id: string,
    name: string,
    price: number,
    category: ItemCategory,
    quantity: number,
    url: string,
    note?: string | null,
    registrationStatus: RegistrationStatus,
    weightKg?: number | null,
    user?:  {
      __typename: "User",
      id: string,
      identityId: string,
      name: string,
      companyName?: string | null,
      email: string,
      birthday: number,
      gender: Gender,
      idPicture:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      job: string,
      i18nAreaCodeMobile?: string | null,
      mobilePhoneNumber?: string | null,
      i18nAreaCodeFixed?: string | null,
      fixedPhoneNumber?: string | null,
      detailedAddress: string,
      city: string,
      provinceOrState: string,
      countryOrRegion: string,
      postalCode: string,
      registrationStatus: RegistrationStatus,
      items?:  {
        __typename: "ModelItemConnection",
        items:  Array< {
          __typename: "Item",
          id: string,
          name: string,
          price: number,
          category: ItemCategory,
          quantity: number,
          url: string,
          note?: string | null,
          registrationStatus: RegistrationStatus,
          weightKg?: number | null,
          updatedAt: string,
          createdAt: string,
          userItemsId?: string | null,
          domesticParcelItemsId?: string | null,
          internationalParcelItemsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      domesticParcel?:  {
        __typename: "ModelDomesticParcelConnection",
        items:  Array< {
          __typename: "DomesticParcel",
          id: string,
          slipNumber?: string | null,
          accepted: boolean,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: string | null,
          updatedAt: string,
          createdAt: string,
          userDomesticParcelId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      internationalParcel?:  {
        __typename: "ModelInternationalParcelConnection",
        items:  Array< {
          __typename: "InternationalParcel",
          id: string,
          slipNumber?: string | null,
          shippingFee?: number | null,
          weightKg?: number | null,
          cost?: number | null,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: InternationalDeliveryCompany | null,
          updatedAt: string,
          createdAt: string,
          userInternationalParcelId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      createdAt: string,
      owner?: string | null,
    } | null,
    domesticParcel?:  {
      __typename: "DomesticParcel",
      id: string,
      slipNumber?: string | null,
      accepted: boolean,
      delivered: boolean,
      deliveredEnum?: Delivered | null,
      deliveryCompany?: string | null,
      items?:  {
        __typename: "ModelItemConnection",
        items:  Array< {
          __typename: "Item",
          id: string,
          name: string,
          price: number,
          category: ItemCategory,
          quantity: number,
          url: string,
          note?: string | null,
          registrationStatus: RegistrationStatus,
          weightKg?: number | null,
          updatedAt: string,
          createdAt: string,
          userItemsId?: string | null,
          domesticParcelItemsId?: string | null,
          internationalParcelItemsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        identityId: string,
        name: string,
        companyName?: string | null,
        email: string,
        birthday: number,
        gender: Gender,
        idPicture:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        job: string,
        i18nAreaCodeMobile?: string | null,
        mobilePhoneNumber?: string | null,
        i18nAreaCodeFixed?: string | null,
        fixedPhoneNumber?: string | null,
        detailedAddress: string,
        city: string,
        provinceOrState: string,
        countryOrRegion: string,
        postalCode: string,
        registrationStatus: RegistrationStatus,
        items?:  {
          __typename: "ModelItemConnection",
          nextToken?: string | null,
        } | null,
        domesticParcel?:  {
          __typename: "ModelDomesticParcelConnection",
          nextToken?: string | null,
        } | null,
        internationalParcel?:  {
          __typename: "ModelInternationalParcelConnection",
          nextToken?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        owner?: string | null,
      } | null,
      updatedAt: string,
      createdAt: string,
      userDomesticParcelId?: string | null,
      owner?: string | null,
    } | null,
    internationalParcel?:  {
      __typename: "InternationalParcel",
      id: string,
      slipNumber?: string | null,
      shippingFee?: number | null,
      weightKg?: number | null,
      cost?: number | null,
      delivered: boolean,
      deliveredEnum?: Delivered | null,
      shippingInfo?:  {
        __typename: "ShippingInfo",
        name?: string | null,
        i18nAreaCodeMobile?: string | null,
        mobilePhoneNumber?: string | null,
        i18nAreaCodeFixed?: string | null,
        fixedPhoneNumber?: string | null,
        detailedAddress?: string | null,
        city?: string | null,
        provinceOrState?: string | null,
        countryOrRegion?: string | null,
        postalCode?: string | null,
      } | null,
      deliveryCompany?: InternationalDeliveryCompany | null,
      items?:  {
        __typename: "ModelItemConnection",
        items:  Array< {
          __typename: "Item",
          id: string,
          name: string,
          price: number,
          category: ItemCategory,
          quantity: number,
          url: string,
          note?: string | null,
          registrationStatus: RegistrationStatus,
          weightKg?: number | null,
          updatedAt: string,
          createdAt: string,
          userItemsId?: string | null,
          domesticParcelItemsId?: string | null,
          internationalParcelItemsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        identityId: string,
        name: string,
        companyName?: string | null,
        email: string,
        birthday: number,
        gender: Gender,
        idPicture:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        job: string,
        i18nAreaCodeMobile?: string | null,
        mobilePhoneNumber?: string | null,
        i18nAreaCodeFixed?: string | null,
        fixedPhoneNumber?: string | null,
        detailedAddress: string,
        city: string,
        provinceOrState: string,
        countryOrRegion: string,
        postalCode: string,
        registrationStatus: RegistrationStatus,
        items?:  {
          __typename: "ModelItemConnection",
          nextToken?: string | null,
        } | null,
        domesticParcel?:  {
          __typename: "ModelDomesticParcelConnection",
          nextToken?: string | null,
        } | null,
        internationalParcel?:  {
          __typename: "ModelInternationalParcelConnection",
          nextToken?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        owner?: string | null,
      } | null,
      updatedAt: string,
      createdAt: string,
      userInternationalParcelId?: string | null,
      owner?: string | null,
    } | null,
    updatedAt: string,
    createdAt: string,
    userItemsId?: string | null,
    domesticParcelItemsId?: string | null,
    internationalParcelItemsId?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteItemMutationVariables = {
  input: DeleteItemInput,
  condition?: ModelItemConditionInput | null,
};

export type DeleteItemMutation = {
  deleteItem?:  {
    __typename: "Item",
    id: string,
    name: string,
    price: number,
    category: ItemCategory,
    quantity: number,
    url: string,
    note?: string | null,
    registrationStatus: RegistrationStatus,
    weightKg?: number | null,
    user?:  {
      __typename: "User",
      id: string,
      identityId: string,
      name: string,
      companyName?: string | null,
      email: string,
      birthday: number,
      gender: Gender,
      idPicture:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      job: string,
      i18nAreaCodeMobile?: string | null,
      mobilePhoneNumber?: string | null,
      i18nAreaCodeFixed?: string | null,
      fixedPhoneNumber?: string | null,
      detailedAddress: string,
      city: string,
      provinceOrState: string,
      countryOrRegion: string,
      postalCode: string,
      registrationStatus: RegistrationStatus,
      items?:  {
        __typename: "ModelItemConnection",
        items:  Array< {
          __typename: "Item",
          id: string,
          name: string,
          price: number,
          category: ItemCategory,
          quantity: number,
          url: string,
          note?: string | null,
          registrationStatus: RegistrationStatus,
          weightKg?: number | null,
          updatedAt: string,
          createdAt: string,
          userItemsId?: string | null,
          domesticParcelItemsId?: string | null,
          internationalParcelItemsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      domesticParcel?:  {
        __typename: "ModelDomesticParcelConnection",
        items:  Array< {
          __typename: "DomesticParcel",
          id: string,
          slipNumber?: string | null,
          accepted: boolean,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: string | null,
          updatedAt: string,
          createdAt: string,
          userDomesticParcelId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      internationalParcel?:  {
        __typename: "ModelInternationalParcelConnection",
        items:  Array< {
          __typename: "InternationalParcel",
          id: string,
          slipNumber?: string | null,
          shippingFee?: number | null,
          weightKg?: number | null,
          cost?: number | null,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: InternationalDeliveryCompany | null,
          updatedAt: string,
          createdAt: string,
          userInternationalParcelId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      createdAt: string,
      owner?: string | null,
    } | null,
    domesticParcel?:  {
      __typename: "DomesticParcel",
      id: string,
      slipNumber?: string | null,
      accepted: boolean,
      delivered: boolean,
      deliveredEnum?: Delivered | null,
      deliveryCompany?: string | null,
      items?:  {
        __typename: "ModelItemConnection",
        items:  Array< {
          __typename: "Item",
          id: string,
          name: string,
          price: number,
          category: ItemCategory,
          quantity: number,
          url: string,
          note?: string | null,
          registrationStatus: RegistrationStatus,
          weightKg?: number | null,
          updatedAt: string,
          createdAt: string,
          userItemsId?: string | null,
          domesticParcelItemsId?: string | null,
          internationalParcelItemsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        identityId: string,
        name: string,
        companyName?: string | null,
        email: string,
        birthday: number,
        gender: Gender,
        idPicture:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        job: string,
        i18nAreaCodeMobile?: string | null,
        mobilePhoneNumber?: string | null,
        i18nAreaCodeFixed?: string | null,
        fixedPhoneNumber?: string | null,
        detailedAddress: string,
        city: string,
        provinceOrState: string,
        countryOrRegion: string,
        postalCode: string,
        registrationStatus: RegistrationStatus,
        items?:  {
          __typename: "ModelItemConnection",
          nextToken?: string | null,
        } | null,
        domesticParcel?:  {
          __typename: "ModelDomesticParcelConnection",
          nextToken?: string | null,
        } | null,
        internationalParcel?:  {
          __typename: "ModelInternationalParcelConnection",
          nextToken?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        owner?: string | null,
      } | null,
      updatedAt: string,
      createdAt: string,
      userDomesticParcelId?: string | null,
      owner?: string | null,
    } | null,
    internationalParcel?:  {
      __typename: "InternationalParcel",
      id: string,
      slipNumber?: string | null,
      shippingFee?: number | null,
      weightKg?: number | null,
      cost?: number | null,
      delivered: boolean,
      deliveredEnum?: Delivered | null,
      shippingInfo?:  {
        __typename: "ShippingInfo",
        name?: string | null,
        i18nAreaCodeMobile?: string | null,
        mobilePhoneNumber?: string | null,
        i18nAreaCodeFixed?: string | null,
        fixedPhoneNumber?: string | null,
        detailedAddress?: string | null,
        city?: string | null,
        provinceOrState?: string | null,
        countryOrRegion?: string | null,
        postalCode?: string | null,
      } | null,
      deliveryCompany?: InternationalDeliveryCompany | null,
      items?:  {
        __typename: "ModelItemConnection",
        items:  Array< {
          __typename: "Item",
          id: string,
          name: string,
          price: number,
          category: ItemCategory,
          quantity: number,
          url: string,
          note?: string | null,
          registrationStatus: RegistrationStatus,
          weightKg?: number | null,
          updatedAt: string,
          createdAt: string,
          userItemsId?: string | null,
          domesticParcelItemsId?: string | null,
          internationalParcelItemsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        identityId: string,
        name: string,
        companyName?: string | null,
        email: string,
        birthday: number,
        gender: Gender,
        idPicture:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        job: string,
        i18nAreaCodeMobile?: string | null,
        mobilePhoneNumber?: string | null,
        i18nAreaCodeFixed?: string | null,
        fixedPhoneNumber?: string | null,
        detailedAddress: string,
        city: string,
        provinceOrState: string,
        countryOrRegion: string,
        postalCode: string,
        registrationStatus: RegistrationStatus,
        items?:  {
          __typename: "ModelItemConnection",
          nextToken?: string | null,
        } | null,
        domesticParcel?:  {
          __typename: "ModelDomesticParcelConnection",
          nextToken?: string | null,
        } | null,
        internationalParcel?:  {
          __typename: "ModelInternationalParcelConnection",
          nextToken?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        owner?: string | null,
      } | null,
      updatedAt: string,
      createdAt: string,
      userInternationalParcelId?: string | null,
      owner?: string | null,
    } | null,
    updatedAt: string,
    createdAt: string,
    userItemsId?: string | null,
    domesticParcelItemsId?: string | null,
    internationalParcelItemsId?: string | null,
    owner?: string | null,
  } | null,
};

export type CreateDomesticParcelMutationVariables = {
  input: CreateDomesticParcelInput,
  condition?: ModelDomesticParcelConditionInput | null,
};

export type CreateDomesticParcelMutation = {
  createDomesticParcel?:  {
    __typename: "DomesticParcel",
    id: string,
    slipNumber?: string | null,
    accepted: boolean,
    delivered: boolean,
    deliveredEnum?: Delivered | null,
    deliveryCompany?: string | null,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        name: string,
        price: number,
        category: ItemCategory,
        quantity: number,
        url: string,
        note?: string | null,
        registrationStatus: RegistrationStatus,
        weightKg?: number | null,
        user?:  {
          __typename: "User",
          id: string,
          identityId: string,
          name: string,
          companyName?: string | null,
          email: string,
          birthday: number,
          gender: Gender,
          job: string,
          i18nAreaCodeMobile?: string | null,
          mobilePhoneNumber?: string | null,
          i18nAreaCodeFixed?: string | null,
          fixedPhoneNumber?: string | null,
          detailedAddress: string,
          city: string,
          provinceOrState: string,
          countryOrRegion: string,
          postalCode: string,
          registrationStatus: RegistrationStatus,
          updatedAt: string,
          createdAt: string,
          owner?: string | null,
        } | null,
        domesticParcel?:  {
          __typename: "DomesticParcel",
          id: string,
          slipNumber?: string | null,
          accepted: boolean,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: string | null,
          updatedAt: string,
          createdAt: string,
          userDomesticParcelId?: string | null,
          owner?: string | null,
        } | null,
        internationalParcel?:  {
          __typename: "InternationalParcel",
          id: string,
          slipNumber?: string | null,
          shippingFee?: number | null,
          weightKg?: number | null,
          cost?: number | null,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: InternationalDeliveryCompany | null,
          updatedAt: string,
          createdAt: string,
          userInternationalParcelId?: string | null,
          owner?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        userItemsId?: string | null,
        domesticParcelItemsId?: string | null,
        internationalParcelItemsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      identityId: string,
      name: string,
      companyName?: string | null,
      email: string,
      birthday: number,
      gender: Gender,
      idPicture:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      job: string,
      i18nAreaCodeMobile?: string | null,
      mobilePhoneNumber?: string | null,
      i18nAreaCodeFixed?: string | null,
      fixedPhoneNumber?: string | null,
      detailedAddress: string,
      city: string,
      provinceOrState: string,
      countryOrRegion: string,
      postalCode: string,
      registrationStatus: RegistrationStatus,
      items?:  {
        __typename: "ModelItemConnection",
        items:  Array< {
          __typename: "Item",
          id: string,
          name: string,
          price: number,
          category: ItemCategory,
          quantity: number,
          url: string,
          note?: string | null,
          registrationStatus: RegistrationStatus,
          weightKg?: number | null,
          updatedAt: string,
          createdAt: string,
          userItemsId?: string | null,
          domesticParcelItemsId?: string | null,
          internationalParcelItemsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      domesticParcel?:  {
        __typename: "ModelDomesticParcelConnection",
        items:  Array< {
          __typename: "DomesticParcel",
          id: string,
          slipNumber?: string | null,
          accepted: boolean,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: string | null,
          updatedAt: string,
          createdAt: string,
          userDomesticParcelId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      internationalParcel?:  {
        __typename: "ModelInternationalParcelConnection",
        items:  Array< {
          __typename: "InternationalParcel",
          id: string,
          slipNumber?: string | null,
          shippingFee?: number | null,
          weightKg?: number | null,
          cost?: number | null,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: InternationalDeliveryCompany | null,
          updatedAt: string,
          createdAt: string,
          userInternationalParcelId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      createdAt: string,
      owner?: string | null,
    } | null,
    updatedAt: string,
    createdAt: string,
    userDomesticParcelId?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateDomesticParcelMutationVariables = {
  input: UpdateDomesticParcelInput,
  condition?: ModelDomesticParcelConditionInput | null,
};

export type UpdateDomesticParcelMutation = {
  updateDomesticParcel?:  {
    __typename: "DomesticParcel",
    id: string,
    slipNumber?: string | null,
    accepted: boolean,
    delivered: boolean,
    deliveredEnum?: Delivered | null,
    deliveryCompany?: string | null,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        name: string,
        price: number,
        category: ItemCategory,
        quantity: number,
        url: string,
        note?: string | null,
        registrationStatus: RegistrationStatus,
        weightKg?: number | null,
        user?:  {
          __typename: "User",
          id: string,
          identityId: string,
          name: string,
          companyName?: string | null,
          email: string,
          birthday: number,
          gender: Gender,
          job: string,
          i18nAreaCodeMobile?: string | null,
          mobilePhoneNumber?: string | null,
          i18nAreaCodeFixed?: string | null,
          fixedPhoneNumber?: string | null,
          detailedAddress: string,
          city: string,
          provinceOrState: string,
          countryOrRegion: string,
          postalCode: string,
          registrationStatus: RegistrationStatus,
          updatedAt: string,
          createdAt: string,
          owner?: string | null,
        } | null,
        domesticParcel?:  {
          __typename: "DomesticParcel",
          id: string,
          slipNumber?: string | null,
          accepted: boolean,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: string | null,
          updatedAt: string,
          createdAt: string,
          userDomesticParcelId?: string | null,
          owner?: string | null,
        } | null,
        internationalParcel?:  {
          __typename: "InternationalParcel",
          id: string,
          slipNumber?: string | null,
          shippingFee?: number | null,
          weightKg?: number | null,
          cost?: number | null,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: InternationalDeliveryCompany | null,
          updatedAt: string,
          createdAt: string,
          userInternationalParcelId?: string | null,
          owner?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        userItemsId?: string | null,
        domesticParcelItemsId?: string | null,
        internationalParcelItemsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      identityId: string,
      name: string,
      companyName?: string | null,
      email: string,
      birthday: number,
      gender: Gender,
      idPicture:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      job: string,
      i18nAreaCodeMobile?: string | null,
      mobilePhoneNumber?: string | null,
      i18nAreaCodeFixed?: string | null,
      fixedPhoneNumber?: string | null,
      detailedAddress: string,
      city: string,
      provinceOrState: string,
      countryOrRegion: string,
      postalCode: string,
      registrationStatus: RegistrationStatus,
      items?:  {
        __typename: "ModelItemConnection",
        items:  Array< {
          __typename: "Item",
          id: string,
          name: string,
          price: number,
          category: ItemCategory,
          quantity: number,
          url: string,
          note?: string | null,
          registrationStatus: RegistrationStatus,
          weightKg?: number | null,
          updatedAt: string,
          createdAt: string,
          userItemsId?: string | null,
          domesticParcelItemsId?: string | null,
          internationalParcelItemsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      domesticParcel?:  {
        __typename: "ModelDomesticParcelConnection",
        items:  Array< {
          __typename: "DomesticParcel",
          id: string,
          slipNumber?: string | null,
          accepted: boolean,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: string | null,
          updatedAt: string,
          createdAt: string,
          userDomesticParcelId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      internationalParcel?:  {
        __typename: "ModelInternationalParcelConnection",
        items:  Array< {
          __typename: "InternationalParcel",
          id: string,
          slipNumber?: string | null,
          shippingFee?: number | null,
          weightKg?: number | null,
          cost?: number | null,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: InternationalDeliveryCompany | null,
          updatedAt: string,
          createdAt: string,
          userInternationalParcelId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      createdAt: string,
      owner?: string | null,
    } | null,
    updatedAt: string,
    createdAt: string,
    userDomesticParcelId?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteDomesticParcelMutationVariables = {
  input: DeleteDomesticParcelInput,
  condition?: ModelDomesticParcelConditionInput | null,
};

export type DeleteDomesticParcelMutation = {
  deleteDomesticParcel?:  {
    __typename: "DomesticParcel",
    id: string,
    slipNumber?: string | null,
    accepted: boolean,
    delivered: boolean,
    deliveredEnum?: Delivered | null,
    deliveryCompany?: string | null,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        name: string,
        price: number,
        category: ItemCategory,
        quantity: number,
        url: string,
        note?: string | null,
        registrationStatus: RegistrationStatus,
        weightKg?: number | null,
        user?:  {
          __typename: "User",
          id: string,
          identityId: string,
          name: string,
          companyName?: string | null,
          email: string,
          birthday: number,
          gender: Gender,
          job: string,
          i18nAreaCodeMobile?: string | null,
          mobilePhoneNumber?: string | null,
          i18nAreaCodeFixed?: string | null,
          fixedPhoneNumber?: string | null,
          detailedAddress: string,
          city: string,
          provinceOrState: string,
          countryOrRegion: string,
          postalCode: string,
          registrationStatus: RegistrationStatus,
          updatedAt: string,
          createdAt: string,
          owner?: string | null,
        } | null,
        domesticParcel?:  {
          __typename: "DomesticParcel",
          id: string,
          slipNumber?: string | null,
          accepted: boolean,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: string | null,
          updatedAt: string,
          createdAt: string,
          userDomesticParcelId?: string | null,
          owner?: string | null,
        } | null,
        internationalParcel?:  {
          __typename: "InternationalParcel",
          id: string,
          slipNumber?: string | null,
          shippingFee?: number | null,
          weightKg?: number | null,
          cost?: number | null,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: InternationalDeliveryCompany | null,
          updatedAt: string,
          createdAt: string,
          userInternationalParcelId?: string | null,
          owner?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        userItemsId?: string | null,
        domesticParcelItemsId?: string | null,
        internationalParcelItemsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      identityId: string,
      name: string,
      companyName?: string | null,
      email: string,
      birthday: number,
      gender: Gender,
      idPicture:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      job: string,
      i18nAreaCodeMobile?: string | null,
      mobilePhoneNumber?: string | null,
      i18nAreaCodeFixed?: string | null,
      fixedPhoneNumber?: string | null,
      detailedAddress: string,
      city: string,
      provinceOrState: string,
      countryOrRegion: string,
      postalCode: string,
      registrationStatus: RegistrationStatus,
      items?:  {
        __typename: "ModelItemConnection",
        items:  Array< {
          __typename: "Item",
          id: string,
          name: string,
          price: number,
          category: ItemCategory,
          quantity: number,
          url: string,
          note?: string | null,
          registrationStatus: RegistrationStatus,
          weightKg?: number | null,
          updatedAt: string,
          createdAt: string,
          userItemsId?: string | null,
          domesticParcelItemsId?: string | null,
          internationalParcelItemsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      domesticParcel?:  {
        __typename: "ModelDomesticParcelConnection",
        items:  Array< {
          __typename: "DomesticParcel",
          id: string,
          slipNumber?: string | null,
          accepted: boolean,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: string | null,
          updatedAt: string,
          createdAt: string,
          userDomesticParcelId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      internationalParcel?:  {
        __typename: "ModelInternationalParcelConnection",
        items:  Array< {
          __typename: "InternationalParcel",
          id: string,
          slipNumber?: string | null,
          shippingFee?: number | null,
          weightKg?: number | null,
          cost?: number | null,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: InternationalDeliveryCompany | null,
          updatedAt: string,
          createdAt: string,
          userInternationalParcelId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      createdAt: string,
      owner?: string | null,
    } | null,
    updatedAt: string,
    createdAt: string,
    userDomesticParcelId?: string | null,
    owner?: string | null,
  } | null,
};

export type CreateInternationalParcelMutationVariables = {
  input: CreateInternationalParcelInput,
  condition?: ModelInternationalParcelConditionInput | null,
};

export type CreateInternationalParcelMutation = {
  createInternationalParcel?:  {
    __typename: "InternationalParcel",
    id: string,
    slipNumber?: string | null,
    shippingFee?: number | null,
    weightKg?: number | null,
    cost?: number | null,
    delivered: boolean,
    deliveredEnum?: Delivered | null,
    shippingInfo?:  {
      __typename: "ShippingInfo",
      name?: string | null,
      i18nAreaCodeMobile?: string | null,
      mobilePhoneNumber?: string | null,
      i18nAreaCodeFixed?: string | null,
      fixedPhoneNumber?: string | null,
      detailedAddress?: string | null,
      city?: string | null,
      provinceOrState?: string | null,
      countryOrRegion?: string | null,
      postalCode?: string | null,
    } | null,
    deliveryCompany?: InternationalDeliveryCompany | null,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        name: string,
        price: number,
        category: ItemCategory,
        quantity: number,
        url: string,
        note?: string | null,
        registrationStatus: RegistrationStatus,
        weightKg?: number | null,
        user?:  {
          __typename: "User",
          id: string,
          identityId: string,
          name: string,
          companyName?: string | null,
          email: string,
          birthday: number,
          gender: Gender,
          job: string,
          i18nAreaCodeMobile?: string | null,
          mobilePhoneNumber?: string | null,
          i18nAreaCodeFixed?: string | null,
          fixedPhoneNumber?: string | null,
          detailedAddress: string,
          city: string,
          provinceOrState: string,
          countryOrRegion: string,
          postalCode: string,
          registrationStatus: RegistrationStatus,
          updatedAt: string,
          createdAt: string,
          owner?: string | null,
        } | null,
        domesticParcel?:  {
          __typename: "DomesticParcel",
          id: string,
          slipNumber?: string | null,
          accepted: boolean,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: string | null,
          updatedAt: string,
          createdAt: string,
          userDomesticParcelId?: string | null,
          owner?: string | null,
        } | null,
        internationalParcel?:  {
          __typename: "InternationalParcel",
          id: string,
          slipNumber?: string | null,
          shippingFee?: number | null,
          weightKg?: number | null,
          cost?: number | null,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: InternationalDeliveryCompany | null,
          updatedAt: string,
          createdAt: string,
          userInternationalParcelId?: string | null,
          owner?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        userItemsId?: string | null,
        domesticParcelItemsId?: string | null,
        internationalParcelItemsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      identityId: string,
      name: string,
      companyName?: string | null,
      email: string,
      birthday: number,
      gender: Gender,
      idPicture:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      job: string,
      i18nAreaCodeMobile?: string | null,
      mobilePhoneNumber?: string | null,
      i18nAreaCodeFixed?: string | null,
      fixedPhoneNumber?: string | null,
      detailedAddress: string,
      city: string,
      provinceOrState: string,
      countryOrRegion: string,
      postalCode: string,
      registrationStatus: RegistrationStatus,
      items?:  {
        __typename: "ModelItemConnection",
        items:  Array< {
          __typename: "Item",
          id: string,
          name: string,
          price: number,
          category: ItemCategory,
          quantity: number,
          url: string,
          note?: string | null,
          registrationStatus: RegistrationStatus,
          weightKg?: number | null,
          updatedAt: string,
          createdAt: string,
          userItemsId?: string | null,
          domesticParcelItemsId?: string | null,
          internationalParcelItemsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      domesticParcel?:  {
        __typename: "ModelDomesticParcelConnection",
        items:  Array< {
          __typename: "DomesticParcel",
          id: string,
          slipNumber?: string | null,
          accepted: boolean,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: string | null,
          updatedAt: string,
          createdAt: string,
          userDomesticParcelId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      internationalParcel?:  {
        __typename: "ModelInternationalParcelConnection",
        items:  Array< {
          __typename: "InternationalParcel",
          id: string,
          slipNumber?: string | null,
          shippingFee?: number | null,
          weightKg?: number | null,
          cost?: number | null,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: InternationalDeliveryCompany | null,
          updatedAt: string,
          createdAt: string,
          userInternationalParcelId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      createdAt: string,
      owner?: string | null,
    } | null,
    updatedAt: string,
    createdAt: string,
    userInternationalParcelId?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateInternationalParcelMutationVariables = {
  input: UpdateInternationalParcelInput,
  condition?: ModelInternationalParcelConditionInput | null,
};

export type UpdateInternationalParcelMutation = {
  updateInternationalParcel?:  {
    __typename: "InternationalParcel",
    id: string,
    slipNumber?: string | null,
    shippingFee?: number | null,
    weightKg?: number | null,
    cost?: number | null,
    delivered: boolean,
    deliveredEnum?: Delivered | null,
    shippingInfo?:  {
      __typename: "ShippingInfo",
      name?: string | null,
      i18nAreaCodeMobile?: string | null,
      mobilePhoneNumber?: string | null,
      i18nAreaCodeFixed?: string | null,
      fixedPhoneNumber?: string | null,
      detailedAddress?: string | null,
      city?: string | null,
      provinceOrState?: string | null,
      countryOrRegion?: string | null,
      postalCode?: string | null,
    } | null,
    deliveryCompany?: InternationalDeliveryCompany | null,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        name: string,
        price: number,
        category: ItemCategory,
        quantity: number,
        url: string,
        note?: string | null,
        registrationStatus: RegistrationStatus,
        weightKg?: number | null,
        user?:  {
          __typename: "User",
          id: string,
          identityId: string,
          name: string,
          companyName?: string | null,
          email: string,
          birthday: number,
          gender: Gender,
          job: string,
          i18nAreaCodeMobile?: string | null,
          mobilePhoneNumber?: string | null,
          i18nAreaCodeFixed?: string | null,
          fixedPhoneNumber?: string | null,
          detailedAddress: string,
          city: string,
          provinceOrState: string,
          countryOrRegion: string,
          postalCode: string,
          registrationStatus: RegistrationStatus,
          updatedAt: string,
          createdAt: string,
          owner?: string | null,
        } | null,
        domesticParcel?:  {
          __typename: "DomesticParcel",
          id: string,
          slipNumber?: string | null,
          accepted: boolean,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: string | null,
          updatedAt: string,
          createdAt: string,
          userDomesticParcelId?: string | null,
          owner?: string | null,
        } | null,
        internationalParcel?:  {
          __typename: "InternationalParcel",
          id: string,
          slipNumber?: string | null,
          shippingFee?: number | null,
          weightKg?: number | null,
          cost?: number | null,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: InternationalDeliveryCompany | null,
          updatedAt: string,
          createdAt: string,
          userInternationalParcelId?: string | null,
          owner?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        userItemsId?: string | null,
        domesticParcelItemsId?: string | null,
        internationalParcelItemsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      identityId: string,
      name: string,
      companyName?: string | null,
      email: string,
      birthday: number,
      gender: Gender,
      idPicture:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      job: string,
      i18nAreaCodeMobile?: string | null,
      mobilePhoneNumber?: string | null,
      i18nAreaCodeFixed?: string | null,
      fixedPhoneNumber?: string | null,
      detailedAddress: string,
      city: string,
      provinceOrState: string,
      countryOrRegion: string,
      postalCode: string,
      registrationStatus: RegistrationStatus,
      items?:  {
        __typename: "ModelItemConnection",
        items:  Array< {
          __typename: "Item",
          id: string,
          name: string,
          price: number,
          category: ItemCategory,
          quantity: number,
          url: string,
          note?: string | null,
          registrationStatus: RegistrationStatus,
          weightKg?: number | null,
          updatedAt: string,
          createdAt: string,
          userItemsId?: string | null,
          domesticParcelItemsId?: string | null,
          internationalParcelItemsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      domesticParcel?:  {
        __typename: "ModelDomesticParcelConnection",
        items:  Array< {
          __typename: "DomesticParcel",
          id: string,
          slipNumber?: string | null,
          accepted: boolean,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: string | null,
          updatedAt: string,
          createdAt: string,
          userDomesticParcelId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      internationalParcel?:  {
        __typename: "ModelInternationalParcelConnection",
        items:  Array< {
          __typename: "InternationalParcel",
          id: string,
          slipNumber?: string | null,
          shippingFee?: number | null,
          weightKg?: number | null,
          cost?: number | null,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: InternationalDeliveryCompany | null,
          updatedAt: string,
          createdAt: string,
          userInternationalParcelId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      createdAt: string,
      owner?: string | null,
    } | null,
    updatedAt: string,
    createdAt: string,
    userInternationalParcelId?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteInternationalParcelMutationVariables = {
  input: DeleteInternationalParcelInput,
  condition?: ModelInternationalParcelConditionInput | null,
};

export type DeleteInternationalParcelMutation = {
  deleteInternationalParcel?:  {
    __typename: "InternationalParcel",
    id: string,
    slipNumber?: string | null,
    shippingFee?: number | null,
    weightKg?: number | null,
    cost?: number | null,
    delivered: boolean,
    deliveredEnum?: Delivered | null,
    shippingInfo?:  {
      __typename: "ShippingInfo",
      name?: string | null,
      i18nAreaCodeMobile?: string | null,
      mobilePhoneNumber?: string | null,
      i18nAreaCodeFixed?: string | null,
      fixedPhoneNumber?: string | null,
      detailedAddress?: string | null,
      city?: string | null,
      provinceOrState?: string | null,
      countryOrRegion?: string | null,
      postalCode?: string | null,
    } | null,
    deliveryCompany?: InternationalDeliveryCompany | null,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        name: string,
        price: number,
        category: ItemCategory,
        quantity: number,
        url: string,
        note?: string | null,
        registrationStatus: RegistrationStatus,
        weightKg?: number | null,
        user?:  {
          __typename: "User",
          id: string,
          identityId: string,
          name: string,
          companyName?: string | null,
          email: string,
          birthday: number,
          gender: Gender,
          job: string,
          i18nAreaCodeMobile?: string | null,
          mobilePhoneNumber?: string | null,
          i18nAreaCodeFixed?: string | null,
          fixedPhoneNumber?: string | null,
          detailedAddress: string,
          city: string,
          provinceOrState: string,
          countryOrRegion: string,
          postalCode: string,
          registrationStatus: RegistrationStatus,
          updatedAt: string,
          createdAt: string,
          owner?: string | null,
        } | null,
        domesticParcel?:  {
          __typename: "DomesticParcel",
          id: string,
          slipNumber?: string | null,
          accepted: boolean,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: string | null,
          updatedAt: string,
          createdAt: string,
          userDomesticParcelId?: string | null,
          owner?: string | null,
        } | null,
        internationalParcel?:  {
          __typename: "InternationalParcel",
          id: string,
          slipNumber?: string | null,
          shippingFee?: number | null,
          weightKg?: number | null,
          cost?: number | null,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: InternationalDeliveryCompany | null,
          updatedAt: string,
          createdAt: string,
          userInternationalParcelId?: string | null,
          owner?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        userItemsId?: string | null,
        domesticParcelItemsId?: string | null,
        internationalParcelItemsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      identityId: string,
      name: string,
      companyName?: string | null,
      email: string,
      birthday: number,
      gender: Gender,
      idPicture:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      job: string,
      i18nAreaCodeMobile?: string | null,
      mobilePhoneNumber?: string | null,
      i18nAreaCodeFixed?: string | null,
      fixedPhoneNumber?: string | null,
      detailedAddress: string,
      city: string,
      provinceOrState: string,
      countryOrRegion: string,
      postalCode: string,
      registrationStatus: RegistrationStatus,
      items?:  {
        __typename: "ModelItemConnection",
        items:  Array< {
          __typename: "Item",
          id: string,
          name: string,
          price: number,
          category: ItemCategory,
          quantity: number,
          url: string,
          note?: string | null,
          registrationStatus: RegistrationStatus,
          weightKg?: number | null,
          updatedAt: string,
          createdAt: string,
          userItemsId?: string | null,
          domesticParcelItemsId?: string | null,
          internationalParcelItemsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      domesticParcel?:  {
        __typename: "ModelDomesticParcelConnection",
        items:  Array< {
          __typename: "DomesticParcel",
          id: string,
          slipNumber?: string | null,
          accepted: boolean,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: string | null,
          updatedAt: string,
          createdAt: string,
          userDomesticParcelId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      internationalParcel?:  {
        __typename: "ModelInternationalParcelConnection",
        items:  Array< {
          __typename: "InternationalParcel",
          id: string,
          slipNumber?: string | null,
          shippingFee?: number | null,
          weightKg?: number | null,
          cost?: number | null,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: InternationalDeliveryCompany | null,
          updatedAt: string,
          createdAt: string,
          userInternationalParcelId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      createdAt: string,
      owner?: string | null,
    } | null,
    updatedAt: string,
    createdAt: string,
    userInternationalParcelId?: string | null,
    owner?: string | null,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    identityId: string,
    name: string,
    companyName?: string | null,
    email: string,
    birthday: number,
    gender: Gender,
    idPicture:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    },
    job: string,
    i18nAreaCodeMobile?: string | null,
    mobilePhoneNumber?: string | null,
    i18nAreaCodeFixed?: string | null,
    fixedPhoneNumber?: string | null,
    detailedAddress: string,
    city: string,
    provinceOrState: string,
    countryOrRegion: string,
    postalCode: string,
    registrationStatus: RegistrationStatus,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        name: string,
        price: number,
        category: ItemCategory,
        quantity: number,
        url: string,
        note?: string | null,
        registrationStatus: RegistrationStatus,
        weightKg?: number | null,
        user?:  {
          __typename: "User",
          id: string,
          identityId: string,
          name: string,
          companyName?: string | null,
          email: string,
          birthday: number,
          gender: Gender,
          job: string,
          i18nAreaCodeMobile?: string | null,
          mobilePhoneNumber?: string | null,
          i18nAreaCodeFixed?: string | null,
          fixedPhoneNumber?: string | null,
          detailedAddress: string,
          city: string,
          provinceOrState: string,
          countryOrRegion: string,
          postalCode: string,
          registrationStatus: RegistrationStatus,
          updatedAt: string,
          createdAt: string,
          owner?: string | null,
        } | null,
        domesticParcel?:  {
          __typename: "DomesticParcel",
          id: string,
          slipNumber?: string | null,
          accepted: boolean,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: string | null,
          updatedAt: string,
          createdAt: string,
          userDomesticParcelId?: string | null,
          owner?: string | null,
        } | null,
        internationalParcel?:  {
          __typename: "InternationalParcel",
          id: string,
          slipNumber?: string | null,
          shippingFee?: number | null,
          weightKg?: number | null,
          cost?: number | null,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: InternationalDeliveryCompany | null,
          updatedAt: string,
          createdAt: string,
          userInternationalParcelId?: string | null,
          owner?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        userItemsId?: string | null,
        domesticParcelItemsId?: string | null,
        internationalParcelItemsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    domesticParcel?:  {
      __typename: "ModelDomesticParcelConnection",
      items:  Array< {
        __typename: "DomesticParcel",
        id: string,
        slipNumber?: string | null,
        accepted: boolean,
        delivered: boolean,
        deliveredEnum?: Delivered | null,
        deliveryCompany?: string | null,
        items?:  {
          __typename: "ModelItemConnection",
          nextToken?: string | null,
        } | null,
        user?:  {
          __typename: "User",
          id: string,
          identityId: string,
          name: string,
          companyName?: string | null,
          email: string,
          birthday: number,
          gender: Gender,
          job: string,
          i18nAreaCodeMobile?: string | null,
          mobilePhoneNumber?: string | null,
          i18nAreaCodeFixed?: string | null,
          fixedPhoneNumber?: string | null,
          detailedAddress: string,
          city: string,
          provinceOrState: string,
          countryOrRegion: string,
          postalCode: string,
          registrationStatus: RegistrationStatus,
          updatedAt: string,
          createdAt: string,
          owner?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        userDomesticParcelId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    internationalParcel?:  {
      __typename: "ModelInternationalParcelConnection",
      items:  Array< {
        __typename: "InternationalParcel",
        id: string,
        slipNumber?: string | null,
        shippingFee?: number | null,
        weightKg?: number | null,
        cost?: number | null,
        delivered: boolean,
        deliveredEnum?: Delivered | null,
        shippingInfo?:  {
          __typename: "ShippingInfo",
          name?: string | null,
          i18nAreaCodeMobile?: string | null,
          mobilePhoneNumber?: string | null,
          i18nAreaCodeFixed?: string | null,
          fixedPhoneNumber?: string | null,
          detailedAddress?: string | null,
          city?: string | null,
          provinceOrState?: string | null,
          countryOrRegion?: string | null,
          postalCode?: string | null,
        } | null,
        deliveryCompany?: InternationalDeliveryCompany | null,
        items?:  {
          __typename: "ModelItemConnection",
          nextToken?: string | null,
        } | null,
        user?:  {
          __typename: "User",
          id: string,
          identityId: string,
          name: string,
          companyName?: string | null,
          email: string,
          birthday: number,
          gender: Gender,
          job: string,
          i18nAreaCodeMobile?: string | null,
          mobilePhoneNumber?: string | null,
          i18nAreaCodeFixed?: string | null,
          fixedPhoneNumber?: string | null,
          detailedAddress: string,
          city: string,
          provinceOrState: string,
          countryOrRegion: string,
          postalCode: string,
          registrationStatus: RegistrationStatus,
          updatedAt: string,
          createdAt: string,
          owner?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        userInternationalParcelId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
    createdAt: string,
    owner?: string | null,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      identityId: string,
      name: string,
      companyName?: string | null,
      email: string,
      birthday: number,
      gender: Gender,
      idPicture:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      job: string,
      i18nAreaCodeMobile?: string | null,
      mobilePhoneNumber?: string | null,
      i18nAreaCodeFixed?: string | null,
      fixedPhoneNumber?: string | null,
      detailedAddress: string,
      city: string,
      provinceOrState: string,
      countryOrRegion: string,
      postalCode: string,
      registrationStatus: RegistrationStatus,
      items?:  {
        __typename: "ModelItemConnection",
        items:  Array< {
          __typename: "Item",
          id: string,
          name: string,
          price: number,
          category: ItemCategory,
          quantity: number,
          url: string,
          note?: string | null,
          registrationStatus: RegistrationStatus,
          weightKg?: number | null,
          updatedAt: string,
          createdAt: string,
          userItemsId?: string | null,
          domesticParcelItemsId?: string | null,
          internationalParcelItemsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      domesticParcel?:  {
        __typename: "ModelDomesticParcelConnection",
        items:  Array< {
          __typename: "DomesticParcel",
          id: string,
          slipNumber?: string | null,
          accepted: boolean,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: string | null,
          updatedAt: string,
          createdAt: string,
          userDomesticParcelId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      internationalParcel?:  {
        __typename: "ModelInternationalParcelConnection",
        items:  Array< {
          __typename: "InternationalParcel",
          id: string,
          slipNumber?: string | null,
          shippingFee?: number | null,
          weightKg?: number | null,
          cost?: number | null,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: InternationalDeliveryCompany | null,
          updatedAt: string,
          createdAt: string,
          userInternationalParcelId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      createdAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserByRegistrationStatusQueryVariables = {
  registrationStatus: RegistrationStatus,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserByRegistrationStatusQuery = {
  userByRegistrationStatus?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      identityId: string,
      name: string,
      companyName?: string | null,
      email: string,
      birthday: number,
      gender: Gender,
      idPicture:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      job: string,
      i18nAreaCodeMobile?: string | null,
      mobilePhoneNumber?: string | null,
      i18nAreaCodeFixed?: string | null,
      fixedPhoneNumber?: string | null,
      detailedAddress: string,
      city: string,
      provinceOrState: string,
      countryOrRegion: string,
      postalCode: string,
      registrationStatus: RegistrationStatus,
      items?:  {
        __typename: "ModelItemConnection",
        items:  Array< {
          __typename: "Item",
          id: string,
          name: string,
          price: number,
          category: ItemCategory,
          quantity: number,
          url: string,
          note?: string | null,
          registrationStatus: RegistrationStatus,
          weightKg?: number | null,
          updatedAt: string,
          createdAt: string,
          userItemsId?: string | null,
          domesticParcelItemsId?: string | null,
          internationalParcelItemsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      domesticParcel?:  {
        __typename: "ModelDomesticParcelConnection",
        items:  Array< {
          __typename: "DomesticParcel",
          id: string,
          slipNumber?: string | null,
          accepted: boolean,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: string | null,
          updatedAt: string,
          createdAt: string,
          userDomesticParcelId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      internationalParcel?:  {
        __typename: "ModelInternationalParcelConnection",
        items:  Array< {
          __typename: "InternationalParcel",
          id: string,
          slipNumber?: string | null,
          shippingFee?: number | null,
          weightKg?: number | null,
          cost?: number | null,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: InternationalDeliveryCompany | null,
          updatedAt: string,
          createdAt: string,
          userInternationalParcelId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      createdAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetItemQueryVariables = {
  id: string,
};

export type GetItemQuery = {
  getItem?:  {
    __typename: "Item",
    id: string,
    name: string,
    price: number,
    category: ItemCategory,
    quantity: number,
    url: string,
    note?: string | null,
    registrationStatus: RegistrationStatus,
    weightKg?: number | null,
    user?:  {
      __typename: "User",
      id: string,
      identityId: string,
      name: string,
      companyName?: string | null,
      email: string,
      birthday: number,
      gender: Gender,
      idPicture:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      job: string,
      i18nAreaCodeMobile?: string | null,
      mobilePhoneNumber?: string | null,
      i18nAreaCodeFixed?: string | null,
      fixedPhoneNumber?: string | null,
      detailedAddress: string,
      city: string,
      provinceOrState: string,
      countryOrRegion: string,
      postalCode: string,
      registrationStatus: RegistrationStatus,
      items?:  {
        __typename: "ModelItemConnection",
        items:  Array< {
          __typename: "Item",
          id: string,
          name: string,
          price: number,
          category: ItemCategory,
          quantity: number,
          url: string,
          note?: string | null,
          registrationStatus: RegistrationStatus,
          weightKg?: number | null,
          updatedAt: string,
          createdAt: string,
          userItemsId?: string | null,
          domesticParcelItemsId?: string | null,
          internationalParcelItemsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      domesticParcel?:  {
        __typename: "ModelDomesticParcelConnection",
        items:  Array< {
          __typename: "DomesticParcel",
          id: string,
          slipNumber?: string | null,
          accepted: boolean,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: string | null,
          updatedAt: string,
          createdAt: string,
          userDomesticParcelId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      internationalParcel?:  {
        __typename: "ModelInternationalParcelConnection",
        items:  Array< {
          __typename: "InternationalParcel",
          id: string,
          slipNumber?: string | null,
          shippingFee?: number | null,
          weightKg?: number | null,
          cost?: number | null,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: InternationalDeliveryCompany | null,
          updatedAt: string,
          createdAt: string,
          userInternationalParcelId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      createdAt: string,
      owner?: string | null,
    } | null,
    domesticParcel?:  {
      __typename: "DomesticParcel",
      id: string,
      slipNumber?: string | null,
      accepted: boolean,
      delivered: boolean,
      deliveredEnum?: Delivered | null,
      deliveryCompany?: string | null,
      items?:  {
        __typename: "ModelItemConnection",
        items:  Array< {
          __typename: "Item",
          id: string,
          name: string,
          price: number,
          category: ItemCategory,
          quantity: number,
          url: string,
          note?: string | null,
          registrationStatus: RegistrationStatus,
          weightKg?: number | null,
          updatedAt: string,
          createdAt: string,
          userItemsId?: string | null,
          domesticParcelItemsId?: string | null,
          internationalParcelItemsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        identityId: string,
        name: string,
        companyName?: string | null,
        email: string,
        birthday: number,
        gender: Gender,
        idPicture:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        job: string,
        i18nAreaCodeMobile?: string | null,
        mobilePhoneNumber?: string | null,
        i18nAreaCodeFixed?: string | null,
        fixedPhoneNumber?: string | null,
        detailedAddress: string,
        city: string,
        provinceOrState: string,
        countryOrRegion: string,
        postalCode: string,
        registrationStatus: RegistrationStatus,
        items?:  {
          __typename: "ModelItemConnection",
          nextToken?: string | null,
        } | null,
        domesticParcel?:  {
          __typename: "ModelDomesticParcelConnection",
          nextToken?: string | null,
        } | null,
        internationalParcel?:  {
          __typename: "ModelInternationalParcelConnection",
          nextToken?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        owner?: string | null,
      } | null,
      updatedAt: string,
      createdAt: string,
      userDomesticParcelId?: string | null,
      owner?: string | null,
    } | null,
    internationalParcel?:  {
      __typename: "InternationalParcel",
      id: string,
      slipNumber?: string | null,
      shippingFee?: number | null,
      weightKg?: number | null,
      cost?: number | null,
      delivered: boolean,
      deliveredEnum?: Delivered | null,
      shippingInfo?:  {
        __typename: "ShippingInfo",
        name?: string | null,
        i18nAreaCodeMobile?: string | null,
        mobilePhoneNumber?: string | null,
        i18nAreaCodeFixed?: string | null,
        fixedPhoneNumber?: string | null,
        detailedAddress?: string | null,
        city?: string | null,
        provinceOrState?: string | null,
        countryOrRegion?: string | null,
        postalCode?: string | null,
      } | null,
      deliveryCompany?: InternationalDeliveryCompany | null,
      items?:  {
        __typename: "ModelItemConnection",
        items:  Array< {
          __typename: "Item",
          id: string,
          name: string,
          price: number,
          category: ItemCategory,
          quantity: number,
          url: string,
          note?: string | null,
          registrationStatus: RegistrationStatus,
          weightKg?: number | null,
          updatedAt: string,
          createdAt: string,
          userItemsId?: string | null,
          domesticParcelItemsId?: string | null,
          internationalParcelItemsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        identityId: string,
        name: string,
        companyName?: string | null,
        email: string,
        birthday: number,
        gender: Gender,
        idPicture:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        job: string,
        i18nAreaCodeMobile?: string | null,
        mobilePhoneNumber?: string | null,
        i18nAreaCodeFixed?: string | null,
        fixedPhoneNumber?: string | null,
        detailedAddress: string,
        city: string,
        provinceOrState: string,
        countryOrRegion: string,
        postalCode: string,
        registrationStatus: RegistrationStatus,
        items?:  {
          __typename: "ModelItemConnection",
          nextToken?: string | null,
        } | null,
        domesticParcel?:  {
          __typename: "ModelDomesticParcelConnection",
          nextToken?: string | null,
        } | null,
        internationalParcel?:  {
          __typename: "ModelInternationalParcelConnection",
          nextToken?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        owner?: string | null,
      } | null,
      updatedAt: string,
      createdAt: string,
      userInternationalParcelId?: string | null,
      owner?: string | null,
    } | null,
    updatedAt: string,
    createdAt: string,
    userItemsId?: string | null,
    domesticParcelItemsId?: string | null,
    internationalParcelItemsId?: string | null,
    owner?: string | null,
  } | null,
};

export type ListItemsQueryVariables = {
  filter?: ModelItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListItemsQuery = {
  listItems?:  {
    __typename: "ModelItemConnection",
    items:  Array< {
      __typename: "Item",
      id: string,
      name: string,
      price: number,
      category: ItemCategory,
      quantity: number,
      url: string,
      note?: string | null,
      registrationStatus: RegistrationStatus,
      weightKg?: number | null,
      user?:  {
        __typename: "User",
        id: string,
        identityId: string,
        name: string,
        companyName?: string | null,
        email: string,
        birthday: number,
        gender: Gender,
        idPicture:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        job: string,
        i18nAreaCodeMobile?: string | null,
        mobilePhoneNumber?: string | null,
        i18nAreaCodeFixed?: string | null,
        fixedPhoneNumber?: string | null,
        detailedAddress: string,
        city: string,
        provinceOrState: string,
        countryOrRegion: string,
        postalCode: string,
        registrationStatus: RegistrationStatus,
        items?:  {
          __typename: "ModelItemConnection",
          nextToken?: string | null,
        } | null,
        domesticParcel?:  {
          __typename: "ModelDomesticParcelConnection",
          nextToken?: string | null,
        } | null,
        internationalParcel?:  {
          __typename: "ModelInternationalParcelConnection",
          nextToken?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        owner?: string | null,
      } | null,
      domesticParcel?:  {
        __typename: "DomesticParcel",
        id: string,
        slipNumber?: string | null,
        accepted: boolean,
        delivered: boolean,
        deliveredEnum?: Delivered | null,
        deliveryCompany?: string | null,
        items?:  {
          __typename: "ModelItemConnection",
          nextToken?: string | null,
        } | null,
        user?:  {
          __typename: "User",
          id: string,
          identityId: string,
          name: string,
          companyName?: string | null,
          email: string,
          birthday: number,
          gender: Gender,
          job: string,
          i18nAreaCodeMobile?: string | null,
          mobilePhoneNumber?: string | null,
          i18nAreaCodeFixed?: string | null,
          fixedPhoneNumber?: string | null,
          detailedAddress: string,
          city: string,
          provinceOrState: string,
          countryOrRegion: string,
          postalCode: string,
          registrationStatus: RegistrationStatus,
          updatedAt: string,
          createdAt: string,
          owner?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        userDomesticParcelId?: string | null,
        owner?: string | null,
      } | null,
      internationalParcel?:  {
        __typename: "InternationalParcel",
        id: string,
        slipNumber?: string | null,
        shippingFee?: number | null,
        weightKg?: number | null,
        cost?: number | null,
        delivered: boolean,
        deliveredEnum?: Delivered | null,
        shippingInfo?:  {
          __typename: "ShippingInfo",
          name?: string | null,
          i18nAreaCodeMobile?: string | null,
          mobilePhoneNumber?: string | null,
          i18nAreaCodeFixed?: string | null,
          fixedPhoneNumber?: string | null,
          detailedAddress?: string | null,
          city?: string | null,
          provinceOrState?: string | null,
          countryOrRegion?: string | null,
          postalCode?: string | null,
        } | null,
        deliveryCompany?: InternationalDeliveryCompany | null,
        items?:  {
          __typename: "ModelItemConnection",
          nextToken?: string | null,
        } | null,
        user?:  {
          __typename: "User",
          id: string,
          identityId: string,
          name: string,
          companyName?: string | null,
          email: string,
          birthday: number,
          gender: Gender,
          job: string,
          i18nAreaCodeMobile?: string | null,
          mobilePhoneNumber?: string | null,
          i18nAreaCodeFixed?: string | null,
          fixedPhoneNumber?: string | null,
          detailedAddress: string,
          city: string,
          provinceOrState: string,
          countryOrRegion: string,
          postalCode: string,
          registrationStatus: RegistrationStatus,
          updatedAt: string,
          createdAt: string,
          owner?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        userInternationalParcelId?: string | null,
        owner?: string | null,
      } | null,
      updatedAt: string,
      createdAt: string,
      userItemsId?: string | null,
      domesticParcelItemsId?: string | null,
      internationalParcelItemsId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ItemByRegistrationStatusQueryVariables = {
  registrationStatus: RegistrationStatus,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ItemByRegistrationStatusQuery = {
  itemByRegistrationStatus?:  {
    __typename: "ModelItemConnection",
    items:  Array< {
      __typename: "Item",
      id: string,
      name: string,
      price: number,
      category: ItemCategory,
      quantity: number,
      url: string,
      note?: string | null,
      registrationStatus: RegistrationStatus,
      weightKg?: number | null,
      user?:  {
        __typename: "User",
        id: string,
        identityId: string,
        name: string,
        companyName?: string | null,
        email: string,
        birthday: number,
        gender: Gender,
        idPicture:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        job: string,
        i18nAreaCodeMobile?: string | null,
        mobilePhoneNumber?: string | null,
        i18nAreaCodeFixed?: string | null,
        fixedPhoneNumber?: string | null,
        detailedAddress: string,
        city: string,
        provinceOrState: string,
        countryOrRegion: string,
        postalCode: string,
        registrationStatus: RegistrationStatus,
        items?:  {
          __typename: "ModelItemConnection",
          nextToken?: string | null,
        } | null,
        domesticParcel?:  {
          __typename: "ModelDomesticParcelConnection",
          nextToken?: string | null,
        } | null,
        internationalParcel?:  {
          __typename: "ModelInternationalParcelConnection",
          nextToken?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        owner?: string | null,
      } | null,
      domesticParcel?:  {
        __typename: "DomesticParcel",
        id: string,
        slipNumber?: string | null,
        accepted: boolean,
        delivered: boolean,
        deliveredEnum?: Delivered | null,
        deliveryCompany?: string | null,
        items?:  {
          __typename: "ModelItemConnection",
          nextToken?: string | null,
        } | null,
        user?:  {
          __typename: "User",
          id: string,
          identityId: string,
          name: string,
          companyName?: string | null,
          email: string,
          birthday: number,
          gender: Gender,
          job: string,
          i18nAreaCodeMobile?: string | null,
          mobilePhoneNumber?: string | null,
          i18nAreaCodeFixed?: string | null,
          fixedPhoneNumber?: string | null,
          detailedAddress: string,
          city: string,
          provinceOrState: string,
          countryOrRegion: string,
          postalCode: string,
          registrationStatus: RegistrationStatus,
          updatedAt: string,
          createdAt: string,
          owner?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        userDomesticParcelId?: string | null,
        owner?: string | null,
      } | null,
      internationalParcel?:  {
        __typename: "InternationalParcel",
        id: string,
        slipNumber?: string | null,
        shippingFee?: number | null,
        weightKg?: number | null,
        cost?: number | null,
        delivered: boolean,
        deliveredEnum?: Delivered | null,
        shippingInfo?:  {
          __typename: "ShippingInfo",
          name?: string | null,
          i18nAreaCodeMobile?: string | null,
          mobilePhoneNumber?: string | null,
          i18nAreaCodeFixed?: string | null,
          fixedPhoneNumber?: string | null,
          detailedAddress?: string | null,
          city?: string | null,
          provinceOrState?: string | null,
          countryOrRegion?: string | null,
          postalCode?: string | null,
        } | null,
        deliveryCompany?: InternationalDeliveryCompany | null,
        items?:  {
          __typename: "ModelItemConnection",
          nextToken?: string | null,
        } | null,
        user?:  {
          __typename: "User",
          id: string,
          identityId: string,
          name: string,
          companyName?: string | null,
          email: string,
          birthday: number,
          gender: Gender,
          job: string,
          i18nAreaCodeMobile?: string | null,
          mobilePhoneNumber?: string | null,
          i18nAreaCodeFixed?: string | null,
          fixedPhoneNumber?: string | null,
          detailedAddress: string,
          city: string,
          provinceOrState: string,
          countryOrRegion: string,
          postalCode: string,
          registrationStatus: RegistrationStatus,
          updatedAt: string,
          createdAt: string,
          owner?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        userInternationalParcelId?: string | null,
        owner?: string | null,
      } | null,
      updatedAt: string,
      createdAt: string,
      userItemsId?: string | null,
      domesticParcelItemsId?: string | null,
      internationalParcelItemsId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDomesticParcelQueryVariables = {
  id: string,
};

export type GetDomesticParcelQuery = {
  getDomesticParcel?:  {
    __typename: "DomesticParcel",
    id: string,
    slipNumber?: string | null,
    accepted: boolean,
    delivered: boolean,
    deliveredEnum?: Delivered | null,
    deliveryCompany?: string | null,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        name: string,
        price: number,
        category: ItemCategory,
        quantity: number,
        url: string,
        note?: string | null,
        registrationStatus: RegistrationStatus,
        weightKg?: number | null,
        user?:  {
          __typename: "User",
          id: string,
          identityId: string,
          name: string,
          companyName?: string | null,
          email: string,
          birthday: number,
          gender: Gender,
          job: string,
          i18nAreaCodeMobile?: string | null,
          mobilePhoneNumber?: string | null,
          i18nAreaCodeFixed?: string | null,
          fixedPhoneNumber?: string | null,
          detailedAddress: string,
          city: string,
          provinceOrState: string,
          countryOrRegion: string,
          postalCode: string,
          registrationStatus: RegistrationStatus,
          updatedAt: string,
          createdAt: string,
          owner?: string | null,
        } | null,
        domesticParcel?:  {
          __typename: "DomesticParcel",
          id: string,
          slipNumber?: string | null,
          accepted: boolean,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: string | null,
          updatedAt: string,
          createdAt: string,
          userDomesticParcelId?: string | null,
          owner?: string | null,
        } | null,
        internationalParcel?:  {
          __typename: "InternationalParcel",
          id: string,
          slipNumber?: string | null,
          shippingFee?: number | null,
          weightKg?: number | null,
          cost?: number | null,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: InternationalDeliveryCompany | null,
          updatedAt: string,
          createdAt: string,
          userInternationalParcelId?: string | null,
          owner?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        userItemsId?: string | null,
        domesticParcelItemsId?: string | null,
        internationalParcelItemsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      identityId: string,
      name: string,
      companyName?: string | null,
      email: string,
      birthday: number,
      gender: Gender,
      idPicture:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      job: string,
      i18nAreaCodeMobile?: string | null,
      mobilePhoneNumber?: string | null,
      i18nAreaCodeFixed?: string | null,
      fixedPhoneNumber?: string | null,
      detailedAddress: string,
      city: string,
      provinceOrState: string,
      countryOrRegion: string,
      postalCode: string,
      registrationStatus: RegistrationStatus,
      items?:  {
        __typename: "ModelItemConnection",
        items:  Array< {
          __typename: "Item",
          id: string,
          name: string,
          price: number,
          category: ItemCategory,
          quantity: number,
          url: string,
          note?: string | null,
          registrationStatus: RegistrationStatus,
          weightKg?: number | null,
          updatedAt: string,
          createdAt: string,
          userItemsId?: string | null,
          domesticParcelItemsId?: string | null,
          internationalParcelItemsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      domesticParcel?:  {
        __typename: "ModelDomesticParcelConnection",
        items:  Array< {
          __typename: "DomesticParcel",
          id: string,
          slipNumber?: string | null,
          accepted: boolean,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: string | null,
          updatedAt: string,
          createdAt: string,
          userDomesticParcelId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      internationalParcel?:  {
        __typename: "ModelInternationalParcelConnection",
        items:  Array< {
          __typename: "InternationalParcel",
          id: string,
          slipNumber?: string | null,
          shippingFee?: number | null,
          weightKg?: number | null,
          cost?: number | null,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: InternationalDeliveryCompany | null,
          updatedAt: string,
          createdAt: string,
          userInternationalParcelId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      createdAt: string,
      owner?: string | null,
    } | null,
    updatedAt: string,
    createdAt: string,
    userDomesticParcelId?: string | null,
    owner?: string | null,
  } | null,
};

export type ListDomesticParcelsQueryVariables = {
  filter?: ModelDomesticParcelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDomesticParcelsQuery = {
  listDomesticParcels?:  {
    __typename: "ModelDomesticParcelConnection",
    items:  Array< {
      __typename: "DomesticParcel",
      id: string,
      slipNumber?: string | null,
      accepted: boolean,
      delivered: boolean,
      deliveredEnum?: Delivered | null,
      deliveryCompany?: string | null,
      items?:  {
        __typename: "ModelItemConnection",
        items:  Array< {
          __typename: "Item",
          id: string,
          name: string,
          price: number,
          category: ItemCategory,
          quantity: number,
          url: string,
          note?: string | null,
          registrationStatus: RegistrationStatus,
          weightKg?: number | null,
          updatedAt: string,
          createdAt: string,
          userItemsId?: string | null,
          domesticParcelItemsId?: string | null,
          internationalParcelItemsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        identityId: string,
        name: string,
        companyName?: string | null,
        email: string,
        birthday: number,
        gender: Gender,
        idPicture:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        job: string,
        i18nAreaCodeMobile?: string | null,
        mobilePhoneNumber?: string | null,
        i18nAreaCodeFixed?: string | null,
        fixedPhoneNumber?: string | null,
        detailedAddress: string,
        city: string,
        provinceOrState: string,
        countryOrRegion: string,
        postalCode: string,
        registrationStatus: RegistrationStatus,
        items?:  {
          __typename: "ModelItemConnection",
          nextToken?: string | null,
        } | null,
        domesticParcel?:  {
          __typename: "ModelDomesticParcelConnection",
          nextToken?: string | null,
        } | null,
        internationalParcel?:  {
          __typename: "ModelInternationalParcelConnection",
          nextToken?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        owner?: string | null,
      } | null,
      updatedAt: string,
      createdAt: string,
      userDomesticParcelId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type DomesticParcelByDeliveredEnumQueryVariables = {
  deliveredEnum: Delivered,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDomesticParcelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DomesticParcelByDeliveredEnumQuery = {
  domesticParcelByDeliveredEnum?:  {
    __typename: "ModelDomesticParcelConnection",
    items:  Array< {
      __typename: "DomesticParcel",
      id: string,
      slipNumber?: string | null,
      accepted: boolean,
      delivered: boolean,
      deliveredEnum?: Delivered | null,
      deliveryCompany?: string | null,
      items?:  {
        __typename: "ModelItemConnection",
        items:  Array< {
          __typename: "Item",
          id: string,
          name: string,
          price: number,
          category: ItemCategory,
          quantity: number,
          url: string,
          note?: string | null,
          registrationStatus: RegistrationStatus,
          weightKg?: number | null,
          updatedAt: string,
          createdAt: string,
          userItemsId?: string | null,
          domesticParcelItemsId?: string | null,
          internationalParcelItemsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        identityId: string,
        name: string,
        companyName?: string | null,
        email: string,
        birthday: number,
        gender: Gender,
        idPicture:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        job: string,
        i18nAreaCodeMobile?: string | null,
        mobilePhoneNumber?: string | null,
        i18nAreaCodeFixed?: string | null,
        fixedPhoneNumber?: string | null,
        detailedAddress: string,
        city: string,
        provinceOrState: string,
        countryOrRegion: string,
        postalCode: string,
        registrationStatus: RegistrationStatus,
        items?:  {
          __typename: "ModelItemConnection",
          nextToken?: string | null,
        } | null,
        domesticParcel?:  {
          __typename: "ModelDomesticParcelConnection",
          nextToken?: string | null,
        } | null,
        internationalParcel?:  {
          __typename: "ModelInternationalParcelConnection",
          nextToken?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        owner?: string | null,
      } | null,
      updatedAt: string,
      createdAt: string,
      userDomesticParcelId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetInternationalParcelQueryVariables = {
  id: string,
};

export type GetInternationalParcelQuery = {
  getInternationalParcel?:  {
    __typename: "InternationalParcel",
    id: string,
    slipNumber?: string | null,
    shippingFee?: number | null,
    weightKg?: number | null,
    cost?: number | null,
    delivered: boolean,
    deliveredEnum?: Delivered | null,
    shippingInfo?:  {
      __typename: "ShippingInfo",
      name?: string | null,
      i18nAreaCodeMobile?: string | null,
      mobilePhoneNumber?: string | null,
      i18nAreaCodeFixed?: string | null,
      fixedPhoneNumber?: string | null,
      detailedAddress?: string | null,
      city?: string | null,
      provinceOrState?: string | null,
      countryOrRegion?: string | null,
      postalCode?: string | null,
    } | null,
    deliveryCompany?: InternationalDeliveryCompany | null,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        name: string,
        price: number,
        category: ItemCategory,
        quantity: number,
        url: string,
        note?: string | null,
        registrationStatus: RegistrationStatus,
        weightKg?: number | null,
        user?:  {
          __typename: "User",
          id: string,
          identityId: string,
          name: string,
          companyName?: string | null,
          email: string,
          birthday: number,
          gender: Gender,
          job: string,
          i18nAreaCodeMobile?: string | null,
          mobilePhoneNumber?: string | null,
          i18nAreaCodeFixed?: string | null,
          fixedPhoneNumber?: string | null,
          detailedAddress: string,
          city: string,
          provinceOrState: string,
          countryOrRegion: string,
          postalCode: string,
          registrationStatus: RegistrationStatus,
          updatedAt: string,
          createdAt: string,
          owner?: string | null,
        } | null,
        domesticParcel?:  {
          __typename: "DomesticParcel",
          id: string,
          slipNumber?: string | null,
          accepted: boolean,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: string | null,
          updatedAt: string,
          createdAt: string,
          userDomesticParcelId?: string | null,
          owner?: string | null,
        } | null,
        internationalParcel?:  {
          __typename: "InternationalParcel",
          id: string,
          slipNumber?: string | null,
          shippingFee?: number | null,
          weightKg?: number | null,
          cost?: number | null,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: InternationalDeliveryCompany | null,
          updatedAt: string,
          createdAt: string,
          userInternationalParcelId?: string | null,
          owner?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        userItemsId?: string | null,
        domesticParcelItemsId?: string | null,
        internationalParcelItemsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      identityId: string,
      name: string,
      companyName?: string | null,
      email: string,
      birthday: number,
      gender: Gender,
      idPicture:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      job: string,
      i18nAreaCodeMobile?: string | null,
      mobilePhoneNumber?: string | null,
      i18nAreaCodeFixed?: string | null,
      fixedPhoneNumber?: string | null,
      detailedAddress: string,
      city: string,
      provinceOrState: string,
      countryOrRegion: string,
      postalCode: string,
      registrationStatus: RegistrationStatus,
      items?:  {
        __typename: "ModelItemConnection",
        items:  Array< {
          __typename: "Item",
          id: string,
          name: string,
          price: number,
          category: ItemCategory,
          quantity: number,
          url: string,
          note?: string | null,
          registrationStatus: RegistrationStatus,
          weightKg?: number | null,
          updatedAt: string,
          createdAt: string,
          userItemsId?: string | null,
          domesticParcelItemsId?: string | null,
          internationalParcelItemsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      domesticParcel?:  {
        __typename: "ModelDomesticParcelConnection",
        items:  Array< {
          __typename: "DomesticParcel",
          id: string,
          slipNumber?: string | null,
          accepted: boolean,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: string | null,
          updatedAt: string,
          createdAt: string,
          userDomesticParcelId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      internationalParcel?:  {
        __typename: "ModelInternationalParcelConnection",
        items:  Array< {
          __typename: "InternationalParcel",
          id: string,
          slipNumber?: string | null,
          shippingFee?: number | null,
          weightKg?: number | null,
          cost?: number | null,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: InternationalDeliveryCompany | null,
          updatedAt: string,
          createdAt: string,
          userInternationalParcelId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      createdAt: string,
      owner?: string | null,
    } | null,
    updatedAt: string,
    createdAt: string,
    userInternationalParcelId?: string | null,
    owner?: string | null,
  } | null,
};

export type ListInternationalParcelsQueryVariables = {
  filter?: ModelInternationalParcelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInternationalParcelsQuery = {
  listInternationalParcels?:  {
    __typename: "ModelInternationalParcelConnection",
    items:  Array< {
      __typename: "InternationalParcel",
      id: string,
      slipNumber?: string | null,
      shippingFee?: number | null,
      weightKg?: number | null,
      cost?: number | null,
      delivered: boolean,
      deliveredEnum?: Delivered | null,
      shippingInfo?:  {
        __typename: "ShippingInfo",
        name?: string | null,
        i18nAreaCodeMobile?: string | null,
        mobilePhoneNumber?: string | null,
        i18nAreaCodeFixed?: string | null,
        fixedPhoneNumber?: string | null,
        detailedAddress?: string | null,
        city?: string | null,
        provinceOrState?: string | null,
        countryOrRegion?: string | null,
        postalCode?: string | null,
      } | null,
      deliveryCompany?: InternationalDeliveryCompany | null,
      items?:  {
        __typename: "ModelItemConnection",
        items:  Array< {
          __typename: "Item",
          id: string,
          name: string,
          price: number,
          category: ItemCategory,
          quantity: number,
          url: string,
          note?: string | null,
          registrationStatus: RegistrationStatus,
          weightKg?: number | null,
          updatedAt: string,
          createdAt: string,
          userItemsId?: string | null,
          domesticParcelItemsId?: string | null,
          internationalParcelItemsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        identityId: string,
        name: string,
        companyName?: string | null,
        email: string,
        birthday: number,
        gender: Gender,
        idPicture:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        job: string,
        i18nAreaCodeMobile?: string | null,
        mobilePhoneNumber?: string | null,
        i18nAreaCodeFixed?: string | null,
        fixedPhoneNumber?: string | null,
        detailedAddress: string,
        city: string,
        provinceOrState: string,
        countryOrRegion: string,
        postalCode: string,
        registrationStatus: RegistrationStatus,
        items?:  {
          __typename: "ModelItemConnection",
          nextToken?: string | null,
        } | null,
        domesticParcel?:  {
          __typename: "ModelDomesticParcelConnection",
          nextToken?: string | null,
        } | null,
        internationalParcel?:  {
          __typename: "ModelInternationalParcelConnection",
          nextToken?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        owner?: string | null,
      } | null,
      updatedAt: string,
      createdAt: string,
      userInternationalParcelId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type InternationalParcelByDeliveredEnumQueryVariables = {
  deliveredEnum: Delivered,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInternationalParcelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type InternationalParcelByDeliveredEnumQuery = {
  internationalParcelByDeliveredEnum?:  {
    __typename: "ModelInternationalParcelConnection",
    items:  Array< {
      __typename: "InternationalParcel",
      id: string,
      slipNumber?: string | null,
      shippingFee?: number | null,
      weightKg?: number | null,
      cost?: number | null,
      delivered: boolean,
      deliveredEnum?: Delivered | null,
      shippingInfo?:  {
        __typename: "ShippingInfo",
        name?: string | null,
        i18nAreaCodeMobile?: string | null,
        mobilePhoneNumber?: string | null,
        i18nAreaCodeFixed?: string | null,
        fixedPhoneNumber?: string | null,
        detailedAddress?: string | null,
        city?: string | null,
        provinceOrState?: string | null,
        countryOrRegion?: string | null,
        postalCode?: string | null,
      } | null,
      deliveryCompany?: InternationalDeliveryCompany | null,
      items?:  {
        __typename: "ModelItemConnection",
        items:  Array< {
          __typename: "Item",
          id: string,
          name: string,
          price: number,
          category: ItemCategory,
          quantity: number,
          url: string,
          note?: string | null,
          registrationStatus: RegistrationStatus,
          weightKg?: number | null,
          updatedAt: string,
          createdAt: string,
          userItemsId?: string | null,
          domesticParcelItemsId?: string | null,
          internationalParcelItemsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        identityId: string,
        name: string,
        companyName?: string | null,
        email: string,
        birthday: number,
        gender: Gender,
        idPicture:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        job: string,
        i18nAreaCodeMobile?: string | null,
        mobilePhoneNumber?: string | null,
        i18nAreaCodeFixed?: string | null,
        fixedPhoneNumber?: string | null,
        detailedAddress: string,
        city: string,
        provinceOrState: string,
        countryOrRegion: string,
        postalCode: string,
        registrationStatus: RegistrationStatus,
        items?:  {
          __typename: "ModelItemConnection",
          nextToken?: string | null,
        } | null,
        domesticParcel?:  {
          __typename: "ModelDomesticParcelConnection",
          nextToken?: string | null,
        } | null,
        internationalParcel?:  {
          __typename: "ModelInternationalParcelConnection",
          nextToken?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        owner?: string | null,
      } | null,
      updatedAt: string,
      createdAt: string,
      userInternationalParcelId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  owner?: string | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    identityId: string,
    name: string,
    companyName?: string | null,
    email: string,
    birthday: number,
    gender: Gender,
    idPicture:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    },
    job: string,
    i18nAreaCodeMobile?: string | null,
    mobilePhoneNumber?: string | null,
    i18nAreaCodeFixed?: string | null,
    fixedPhoneNumber?: string | null,
    detailedAddress: string,
    city: string,
    provinceOrState: string,
    countryOrRegion: string,
    postalCode: string,
    registrationStatus: RegistrationStatus,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        name: string,
        price: number,
        category: ItemCategory,
        quantity: number,
        url: string,
        note?: string | null,
        registrationStatus: RegistrationStatus,
        weightKg?: number | null,
        user?:  {
          __typename: "User",
          id: string,
          identityId: string,
          name: string,
          companyName?: string | null,
          email: string,
          birthday: number,
          gender: Gender,
          job: string,
          i18nAreaCodeMobile?: string | null,
          mobilePhoneNumber?: string | null,
          i18nAreaCodeFixed?: string | null,
          fixedPhoneNumber?: string | null,
          detailedAddress: string,
          city: string,
          provinceOrState: string,
          countryOrRegion: string,
          postalCode: string,
          registrationStatus: RegistrationStatus,
          updatedAt: string,
          createdAt: string,
          owner?: string | null,
        } | null,
        domesticParcel?:  {
          __typename: "DomesticParcel",
          id: string,
          slipNumber?: string | null,
          accepted: boolean,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: string | null,
          updatedAt: string,
          createdAt: string,
          userDomesticParcelId?: string | null,
          owner?: string | null,
        } | null,
        internationalParcel?:  {
          __typename: "InternationalParcel",
          id: string,
          slipNumber?: string | null,
          shippingFee?: number | null,
          weightKg?: number | null,
          cost?: number | null,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: InternationalDeliveryCompany | null,
          updatedAt: string,
          createdAt: string,
          userInternationalParcelId?: string | null,
          owner?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        userItemsId?: string | null,
        domesticParcelItemsId?: string | null,
        internationalParcelItemsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    domesticParcel?:  {
      __typename: "ModelDomesticParcelConnection",
      items:  Array< {
        __typename: "DomesticParcel",
        id: string,
        slipNumber?: string | null,
        accepted: boolean,
        delivered: boolean,
        deliveredEnum?: Delivered | null,
        deliveryCompany?: string | null,
        items?:  {
          __typename: "ModelItemConnection",
          nextToken?: string | null,
        } | null,
        user?:  {
          __typename: "User",
          id: string,
          identityId: string,
          name: string,
          companyName?: string | null,
          email: string,
          birthday: number,
          gender: Gender,
          job: string,
          i18nAreaCodeMobile?: string | null,
          mobilePhoneNumber?: string | null,
          i18nAreaCodeFixed?: string | null,
          fixedPhoneNumber?: string | null,
          detailedAddress: string,
          city: string,
          provinceOrState: string,
          countryOrRegion: string,
          postalCode: string,
          registrationStatus: RegistrationStatus,
          updatedAt: string,
          createdAt: string,
          owner?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        userDomesticParcelId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    internationalParcel?:  {
      __typename: "ModelInternationalParcelConnection",
      items:  Array< {
        __typename: "InternationalParcel",
        id: string,
        slipNumber?: string | null,
        shippingFee?: number | null,
        weightKg?: number | null,
        cost?: number | null,
        delivered: boolean,
        deliveredEnum?: Delivered | null,
        shippingInfo?:  {
          __typename: "ShippingInfo",
          name?: string | null,
          i18nAreaCodeMobile?: string | null,
          mobilePhoneNumber?: string | null,
          i18nAreaCodeFixed?: string | null,
          fixedPhoneNumber?: string | null,
          detailedAddress?: string | null,
          city?: string | null,
          provinceOrState?: string | null,
          countryOrRegion?: string | null,
          postalCode?: string | null,
        } | null,
        deliveryCompany?: InternationalDeliveryCompany | null,
        items?:  {
          __typename: "ModelItemConnection",
          nextToken?: string | null,
        } | null,
        user?:  {
          __typename: "User",
          id: string,
          identityId: string,
          name: string,
          companyName?: string | null,
          email: string,
          birthday: number,
          gender: Gender,
          job: string,
          i18nAreaCodeMobile?: string | null,
          mobilePhoneNumber?: string | null,
          i18nAreaCodeFixed?: string | null,
          fixedPhoneNumber?: string | null,
          detailedAddress: string,
          city: string,
          provinceOrState: string,
          countryOrRegion: string,
          postalCode: string,
          registrationStatus: RegistrationStatus,
          updatedAt: string,
          createdAt: string,
          owner?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        userInternationalParcelId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
    createdAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  owner?: string | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    identityId: string,
    name: string,
    companyName?: string | null,
    email: string,
    birthday: number,
    gender: Gender,
    idPicture:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    },
    job: string,
    i18nAreaCodeMobile?: string | null,
    mobilePhoneNumber?: string | null,
    i18nAreaCodeFixed?: string | null,
    fixedPhoneNumber?: string | null,
    detailedAddress: string,
    city: string,
    provinceOrState: string,
    countryOrRegion: string,
    postalCode: string,
    registrationStatus: RegistrationStatus,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        name: string,
        price: number,
        category: ItemCategory,
        quantity: number,
        url: string,
        note?: string | null,
        registrationStatus: RegistrationStatus,
        weightKg?: number | null,
        user?:  {
          __typename: "User",
          id: string,
          identityId: string,
          name: string,
          companyName?: string | null,
          email: string,
          birthday: number,
          gender: Gender,
          job: string,
          i18nAreaCodeMobile?: string | null,
          mobilePhoneNumber?: string | null,
          i18nAreaCodeFixed?: string | null,
          fixedPhoneNumber?: string | null,
          detailedAddress: string,
          city: string,
          provinceOrState: string,
          countryOrRegion: string,
          postalCode: string,
          registrationStatus: RegistrationStatus,
          updatedAt: string,
          createdAt: string,
          owner?: string | null,
        } | null,
        domesticParcel?:  {
          __typename: "DomesticParcel",
          id: string,
          slipNumber?: string | null,
          accepted: boolean,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: string | null,
          updatedAt: string,
          createdAt: string,
          userDomesticParcelId?: string | null,
          owner?: string | null,
        } | null,
        internationalParcel?:  {
          __typename: "InternationalParcel",
          id: string,
          slipNumber?: string | null,
          shippingFee?: number | null,
          weightKg?: number | null,
          cost?: number | null,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: InternationalDeliveryCompany | null,
          updatedAt: string,
          createdAt: string,
          userInternationalParcelId?: string | null,
          owner?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        userItemsId?: string | null,
        domesticParcelItemsId?: string | null,
        internationalParcelItemsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    domesticParcel?:  {
      __typename: "ModelDomesticParcelConnection",
      items:  Array< {
        __typename: "DomesticParcel",
        id: string,
        slipNumber?: string | null,
        accepted: boolean,
        delivered: boolean,
        deliveredEnum?: Delivered | null,
        deliveryCompany?: string | null,
        items?:  {
          __typename: "ModelItemConnection",
          nextToken?: string | null,
        } | null,
        user?:  {
          __typename: "User",
          id: string,
          identityId: string,
          name: string,
          companyName?: string | null,
          email: string,
          birthday: number,
          gender: Gender,
          job: string,
          i18nAreaCodeMobile?: string | null,
          mobilePhoneNumber?: string | null,
          i18nAreaCodeFixed?: string | null,
          fixedPhoneNumber?: string | null,
          detailedAddress: string,
          city: string,
          provinceOrState: string,
          countryOrRegion: string,
          postalCode: string,
          registrationStatus: RegistrationStatus,
          updatedAt: string,
          createdAt: string,
          owner?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        userDomesticParcelId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    internationalParcel?:  {
      __typename: "ModelInternationalParcelConnection",
      items:  Array< {
        __typename: "InternationalParcel",
        id: string,
        slipNumber?: string | null,
        shippingFee?: number | null,
        weightKg?: number | null,
        cost?: number | null,
        delivered: boolean,
        deliveredEnum?: Delivered | null,
        shippingInfo?:  {
          __typename: "ShippingInfo",
          name?: string | null,
          i18nAreaCodeMobile?: string | null,
          mobilePhoneNumber?: string | null,
          i18nAreaCodeFixed?: string | null,
          fixedPhoneNumber?: string | null,
          detailedAddress?: string | null,
          city?: string | null,
          provinceOrState?: string | null,
          countryOrRegion?: string | null,
          postalCode?: string | null,
        } | null,
        deliveryCompany?: InternationalDeliveryCompany | null,
        items?:  {
          __typename: "ModelItemConnection",
          nextToken?: string | null,
        } | null,
        user?:  {
          __typename: "User",
          id: string,
          identityId: string,
          name: string,
          companyName?: string | null,
          email: string,
          birthday: number,
          gender: Gender,
          job: string,
          i18nAreaCodeMobile?: string | null,
          mobilePhoneNumber?: string | null,
          i18nAreaCodeFixed?: string | null,
          fixedPhoneNumber?: string | null,
          detailedAddress: string,
          city: string,
          provinceOrState: string,
          countryOrRegion: string,
          postalCode: string,
          registrationStatus: RegistrationStatus,
          updatedAt: string,
          createdAt: string,
          owner?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        userInternationalParcelId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
    createdAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  owner?: string | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    id: string,
    identityId: string,
    name: string,
    companyName?: string | null,
    email: string,
    birthday: number,
    gender: Gender,
    idPicture:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    },
    job: string,
    i18nAreaCodeMobile?: string | null,
    mobilePhoneNumber?: string | null,
    i18nAreaCodeFixed?: string | null,
    fixedPhoneNumber?: string | null,
    detailedAddress: string,
    city: string,
    provinceOrState: string,
    countryOrRegion: string,
    postalCode: string,
    registrationStatus: RegistrationStatus,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        name: string,
        price: number,
        category: ItemCategory,
        quantity: number,
        url: string,
        note?: string | null,
        registrationStatus: RegistrationStatus,
        weightKg?: number | null,
        user?:  {
          __typename: "User",
          id: string,
          identityId: string,
          name: string,
          companyName?: string | null,
          email: string,
          birthday: number,
          gender: Gender,
          job: string,
          i18nAreaCodeMobile?: string | null,
          mobilePhoneNumber?: string | null,
          i18nAreaCodeFixed?: string | null,
          fixedPhoneNumber?: string | null,
          detailedAddress: string,
          city: string,
          provinceOrState: string,
          countryOrRegion: string,
          postalCode: string,
          registrationStatus: RegistrationStatus,
          updatedAt: string,
          createdAt: string,
          owner?: string | null,
        } | null,
        domesticParcel?:  {
          __typename: "DomesticParcel",
          id: string,
          slipNumber?: string | null,
          accepted: boolean,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: string | null,
          updatedAt: string,
          createdAt: string,
          userDomesticParcelId?: string | null,
          owner?: string | null,
        } | null,
        internationalParcel?:  {
          __typename: "InternationalParcel",
          id: string,
          slipNumber?: string | null,
          shippingFee?: number | null,
          weightKg?: number | null,
          cost?: number | null,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: InternationalDeliveryCompany | null,
          updatedAt: string,
          createdAt: string,
          userInternationalParcelId?: string | null,
          owner?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        userItemsId?: string | null,
        domesticParcelItemsId?: string | null,
        internationalParcelItemsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    domesticParcel?:  {
      __typename: "ModelDomesticParcelConnection",
      items:  Array< {
        __typename: "DomesticParcel",
        id: string,
        slipNumber?: string | null,
        accepted: boolean,
        delivered: boolean,
        deliveredEnum?: Delivered | null,
        deliveryCompany?: string | null,
        items?:  {
          __typename: "ModelItemConnection",
          nextToken?: string | null,
        } | null,
        user?:  {
          __typename: "User",
          id: string,
          identityId: string,
          name: string,
          companyName?: string | null,
          email: string,
          birthday: number,
          gender: Gender,
          job: string,
          i18nAreaCodeMobile?: string | null,
          mobilePhoneNumber?: string | null,
          i18nAreaCodeFixed?: string | null,
          fixedPhoneNumber?: string | null,
          detailedAddress: string,
          city: string,
          provinceOrState: string,
          countryOrRegion: string,
          postalCode: string,
          registrationStatus: RegistrationStatus,
          updatedAt: string,
          createdAt: string,
          owner?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        userDomesticParcelId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    internationalParcel?:  {
      __typename: "ModelInternationalParcelConnection",
      items:  Array< {
        __typename: "InternationalParcel",
        id: string,
        slipNumber?: string | null,
        shippingFee?: number | null,
        weightKg?: number | null,
        cost?: number | null,
        delivered: boolean,
        deliveredEnum?: Delivered | null,
        shippingInfo?:  {
          __typename: "ShippingInfo",
          name?: string | null,
          i18nAreaCodeMobile?: string | null,
          mobilePhoneNumber?: string | null,
          i18nAreaCodeFixed?: string | null,
          fixedPhoneNumber?: string | null,
          detailedAddress?: string | null,
          city?: string | null,
          provinceOrState?: string | null,
          countryOrRegion?: string | null,
          postalCode?: string | null,
        } | null,
        deliveryCompany?: InternationalDeliveryCompany | null,
        items?:  {
          __typename: "ModelItemConnection",
          nextToken?: string | null,
        } | null,
        user?:  {
          __typename: "User",
          id: string,
          identityId: string,
          name: string,
          companyName?: string | null,
          email: string,
          birthday: number,
          gender: Gender,
          job: string,
          i18nAreaCodeMobile?: string | null,
          mobilePhoneNumber?: string | null,
          i18nAreaCodeFixed?: string | null,
          fixedPhoneNumber?: string | null,
          detailedAddress: string,
          city: string,
          provinceOrState: string,
          countryOrRegion: string,
          postalCode: string,
          registrationStatus: RegistrationStatus,
          updatedAt: string,
          createdAt: string,
          owner?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        userInternationalParcelId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
    createdAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateItemSubscriptionVariables = {
  filter?: ModelSubscriptionItemFilterInput | null,
  owner?: string | null,
};

export type OnCreateItemSubscription = {
  onCreateItem?:  {
    __typename: "Item",
    id: string,
    name: string,
    price: number,
    category: ItemCategory,
    quantity: number,
    url: string,
    note?: string | null,
    registrationStatus: RegistrationStatus,
    weightKg?: number | null,
    user?:  {
      __typename: "User",
      id: string,
      identityId: string,
      name: string,
      companyName?: string | null,
      email: string,
      birthday: number,
      gender: Gender,
      idPicture:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      job: string,
      i18nAreaCodeMobile?: string | null,
      mobilePhoneNumber?: string | null,
      i18nAreaCodeFixed?: string | null,
      fixedPhoneNumber?: string | null,
      detailedAddress: string,
      city: string,
      provinceOrState: string,
      countryOrRegion: string,
      postalCode: string,
      registrationStatus: RegistrationStatus,
      items?:  {
        __typename: "ModelItemConnection",
        items:  Array< {
          __typename: "Item",
          id: string,
          name: string,
          price: number,
          category: ItemCategory,
          quantity: number,
          url: string,
          note?: string | null,
          registrationStatus: RegistrationStatus,
          weightKg?: number | null,
          updatedAt: string,
          createdAt: string,
          userItemsId?: string | null,
          domesticParcelItemsId?: string | null,
          internationalParcelItemsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      domesticParcel?:  {
        __typename: "ModelDomesticParcelConnection",
        items:  Array< {
          __typename: "DomesticParcel",
          id: string,
          slipNumber?: string | null,
          accepted: boolean,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: string | null,
          updatedAt: string,
          createdAt: string,
          userDomesticParcelId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      internationalParcel?:  {
        __typename: "ModelInternationalParcelConnection",
        items:  Array< {
          __typename: "InternationalParcel",
          id: string,
          slipNumber?: string | null,
          shippingFee?: number | null,
          weightKg?: number | null,
          cost?: number | null,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: InternationalDeliveryCompany | null,
          updatedAt: string,
          createdAt: string,
          userInternationalParcelId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      createdAt: string,
      owner?: string | null,
    } | null,
    domesticParcel?:  {
      __typename: "DomesticParcel",
      id: string,
      slipNumber?: string | null,
      accepted: boolean,
      delivered: boolean,
      deliveredEnum?: Delivered | null,
      deliveryCompany?: string | null,
      items?:  {
        __typename: "ModelItemConnection",
        items:  Array< {
          __typename: "Item",
          id: string,
          name: string,
          price: number,
          category: ItemCategory,
          quantity: number,
          url: string,
          note?: string | null,
          registrationStatus: RegistrationStatus,
          weightKg?: number | null,
          updatedAt: string,
          createdAt: string,
          userItemsId?: string | null,
          domesticParcelItemsId?: string | null,
          internationalParcelItemsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        identityId: string,
        name: string,
        companyName?: string | null,
        email: string,
        birthday: number,
        gender: Gender,
        idPicture:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        job: string,
        i18nAreaCodeMobile?: string | null,
        mobilePhoneNumber?: string | null,
        i18nAreaCodeFixed?: string | null,
        fixedPhoneNumber?: string | null,
        detailedAddress: string,
        city: string,
        provinceOrState: string,
        countryOrRegion: string,
        postalCode: string,
        registrationStatus: RegistrationStatus,
        items?:  {
          __typename: "ModelItemConnection",
          nextToken?: string | null,
        } | null,
        domesticParcel?:  {
          __typename: "ModelDomesticParcelConnection",
          nextToken?: string | null,
        } | null,
        internationalParcel?:  {
          __typename: "ModelInternationalParcelConnection",
          nextToken?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        owner?: string | null,
      } | null,
      updatedAt: string,
      createdAt: string,
      userDomesticParcelId?: string | null,
      owner?: string | null,
    } | null,
    internationalParcel?:  {
      __typename: "InternationalParcel",
      id: string,
      slipNumber?: string | null,
      shippingFee?: number | null,
      weightKg?: number | null,
      cost?: number | null,
      delivered: boolean,
      deliveredEnum?: Delivered | null,
      shippingInfo?:  {
        __typename: "ShippingInfo",
        name?: string | null,
        i18nAreaCodeMobile?: string | null,
        mobilePhoneNumber?: string | null,
        i18nAreaCodeFixed?: string | null,
        fixedPhoneNumber?: string | null,
        detailedAddress?: string | null,
        city?: string | null,
        provinceOrState?: string | null,
        countryOrRegion?: string | null,
        postalCode?: string | null,
      } | null,
      deliveryCompany?: InternationalDeliveryCompany | null,
      items?:  {
        __typename: "ModelItemConnection",
        items:  Array< {
          __typename: "Item",
          id: string,
          name: string,
          price: number,
          category: ItemCategory,
          quantity: number,
          url: string,
          note?: string | null,
          registrationStatus: RegistrationStatus,
          weightKg?: number | null,
          updatedAt: string,
          createdAt: string,
          userItemsId?: string | null,
          domesticParcelItemsId?: string | null,
          internationalParcelItemsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        identityId: string,
        name: string,
        companyName?: string | null,
        email: string,
        birthday: number,
        gender: Gender,
        idPicture:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        job: string,
        i18nAreaCodeMobile?: string | null,
        mobilePhoneNumber?: string | null,
        i18nAreaCodeFixed?: string | null,
        fixedPhoneNumber?: string | null,
        detailedAddress: string,
        city: string,
        provinceOrState: string,
        countryOrRegion: string,
        postalCode: string,
        registrationStatus: RegistrationStatus,
        items?:  {
          __typename: "ModelItemConnection",
          nextToken?: string | null,
        } | null,
        domesticParcel?:  {
          __typename: "ModelDomesticParcelConnection",
          nextToken?: string | null,
        } | null,
        internationalParcel?:  {
          __typename: "ModelInternationalParcelConnection",
          nextToken?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        owner?: string | null,
      } | null,
      updatedAt: string,
      createdAt: string,
      userInternationalParcelId?: string | null,
      owner?: string | null,
    } | null,
    updatedAt: string,
    createdAt: string,
    userItemsId?: string | null,
    domesticParcelItemsId?: string | null,
    internationalParcelItemsId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateItemSubscriptionVariables = {
  filter?: ModelSubscriptionItemFilterInput | null,
  owner?: string | null,
};

export type OnUpdateItemSubscription = {
  onUpdateItem?:  {
    __typename: "Item",
    id: string,
    name: string,
    price: number,
    category: ItemCategory,
    quantity: number,
    url: string,
    note?: string | null,
    registrationStatus: RegistrationStatus,
    weightKg?: number | null,
    user?:  {
      __typename: "User",
      id: string,
      identityId: string,
      name: string,
      companyName?: string | null,
      email: string,
      birthday: number,
      gender: Gender,
      idPicture:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      job: string,
      i18nAreaCodeMobile?: string | null,
      mobilePhoneNumber?: string | null,
      i18nAreaCodeFixed?: string | null,
      fixedPhoneNumber?: string | null,
      detailedAddress: string,
      city: string,
      provinceOrState: string,
      countryOrRegion: string,
      postalCode: string,
      registrationStatus: RegistrationStatus,
      items?:  {
        __typename: "ModelItemConnection",
        items:  Array< {
          __typename: "Item",
          id: string,
          name: string,
          price: number,
          category: ItemCategory,
          quantity: number,
          url: string,
          note?: string | null,
          registrationStatus: RegistrationStatus,
          weightKg?: number | null,
          updatedAt: string,
          createdAt: string,
          userItemsId?: string | null,
          domesticParcelItemsId?: string | null,
          internationalParcelItemsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      domesticParcel?:  {
        __typename: "ModelDomesticParcelConnection",
        items:  Array< {
          __typename: "DomesticParcel",
          id: string,
          slipNumber?: string | null,
          accepted: boolean,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: string | null,
          updatedAt: string,
          createdAt: string,
          userDomesticParcelId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      internationalParcel?:  {
        __typename: "ModelInternationalParcelConnection",
        items:  Array< {
          __typename: "InternationalParcel",
          id: string,
          slipNumber?: string | null,
          shippingFee?: number | null,
          weightKg?: number | null,
          cost?: number | null,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: InternationalDeliveryCompany | null,
          updatedAt: string,
          createdAt: string,
          userInternationalParcelId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      createdAt: string,
      owner?: string | null,
    } | null,
    domesticParcel?:  {
      __typename: "DomesticParcel",
      id: string,
      slipNumber?: string | null,
      accepted: boolean,
      delivered: boolean,
      deliveredEnum?: Delivered | null,
      deliveryCompany?: string | null,
      items?:  {
        __typename: "ModelItemConnection",
        items:  Array< {
          __typename: "Item",
          id: string,
          name: string,
          price: number,
          category: ItemCategory,
          quantity: number,
          url: string,
          note?: string | null,
          registrationStatus: RegistrationStatus,
          weightKg?: number | null,
          updatedAt: string,
          createdAt: string,
          userItemsId?: string | null,
          domesticParcelItemsId?: string | null,
          internationalParcelItemsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        identityId: string,
        name: string,
        companyName?: string | null,
        email: string,
        birthday: number,
        gender: Gender,
        idPicture:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        job: string,
        i18nAreaCodeMobile?: string | null,
        mobilePhoneNumber?: string | null,
        i18nAreaCodeFixed?: string | null,
        fixedPhoneNumber?: string | null,
        detailedAddress: string,
        city: string,
        provinceOrState: string,
        countryOrRegion: string,
        postalCode: string,
        registrationStatus: RegistrationStatus,
        items?:  {
          __typename: "ModelItemConnection",
          nextToken?: string | null,
        } | null,
        domesticParcel?:  {
          __typename: "ModelDomesticParcelConnection",
          nextToken?: string | null,
        } | null,
        internationalParcel?:  {
          __typename: "ModelInternationalParcelConnection",
          nextToken?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        owner?: string | null,
      } | null,
      updatedAt: string,
      createdAt: string,
      userDomesticParcelId?: string | null,
      owner?: string | null,
    } | null,
    internationalParcel?:  {
      __typename: "InternationalParcel",
      id: string,
      slipNumber?: string | null,
      shippingFee?: number | null,
      weightKg?: number | null,
      cost?: number | null,
      delivered: boolean,
      deliveredEnum?: Delivered | null,
      shippingInfo?:  {
        __typename: "ShippingInfo",
        name?: string | null,
        i18nAreaCodeMobile?: string | null,
        mobilePhoneNumber?: string | null,
        i18nAreaCodeFixed?: string | null,
        fixedPhoneNumber?: string | null,
        detailedAddress?: string | null,
        city?: string | null,
        provinceOrState?: string | null,
        countryOrRegion?: string | null,
        postalCode?: string | null,
      } | null,
      deliveryCompany?: InternationalDeliveryCompany | null,
      items?:  {
        __typename: "ModelItemConnection",
        items:  Array< {
          __typename: "Item",
          id: string,
          name: string,
          price: number,
          category: ItemCategory,
          quantity: number,
          url: string,
          note?: string | null,
          registrationStatus: RegistrationStatus,
          weightKg?: number | null,
          updatedAt: string,
          createdAt: string,
          userItemsId?: string | null,
          domesticParcelItemsId?: string | null,
          internationalParcelItemsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        identityId: string,
        name: string,
        companyName?: string | null,
        email: string,
        birthday: number,
        gender: Gender,
        idPicture:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        job: string,
        i18nAreaCodeMobile?: string | null,
        mobilePhoneNumber?: string | null,
        i18nAreaCodeFixed?: string | null,
        fixedPhoneNumber?: string | null,
        detailedAddress: string,
        city: string,
        provinceOrState: string,
        countryOrRegion: string,
        postalCode: string,
        registrationStatus: RegistrationStatus,
        items?:  {
          __typename: "ModelItemConnection",
          nextToken?: string | null,
        } | null,
        domesticParcel?:  {
          __typename: "ModelDomesticParcelConnection",
          nextToken?: string | null,
        } | null,
        internationalParcel?:  {
          __typename: "ModelInternationalParcelConnection",
          nextToken?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        owner?: string | null,
      } | null,
      updatedAt: string,
      createdAt: string,
      userInternationalParcelId?: string | null,
      owner?: string | null,
    } | null,
    updatedAt: string,
    createdAt: string,
    userItemsId?: string | null,
    domesticParcelItemsId?: string | null,
    internationalParcelItemsId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteItemSubscriptionVariables = {
  filter?: ModelSubscriptionItemFilterInput | null,
  owner?: string | null,
};

export type OnDeleteItemSubscription = {
  onDeleteItem?:  {
    __typename: "Item",
    id: string,
    name: string,
    price: number,
    category: ItemCategory,
    quantity: number,
    url: string,
    note?: string | null,
    registrationStatus: RegistrationStatus,
    weightKg?: number | null,
    user?:  {
      __typename: "User",
      id: string,
      identityId: string,
      name: string,
      companyName?: string | null,
      email: string,
      birthday: number,
      gender: Gender,
      idPicture:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      job: string,
      i18nAreaCodeMobile?: string | null,
      mobilePhoneNumber?: string | null,
      i18nAreaCodeFixed?: string | null,
      fixedPhoneNumber?: string | null,
      detailedAddress: string,
      city: string,
      provinceOrState: string,
      countryOrRegion: string,
      postalCode: string,
      registrationStatus: RegistrationStatus,
      items?:  {
        __typename: "ModelItemConnection",
        items:  Array< {
          __typename: "Item",
          id: string,
          name: string,
          price: number,
          category: ItemCategory,
          quantity: number,
          url: string,
          note?: string | null,
          registrationStatus: RegistrationStatus,
          weightKg?: number | null,
          updatedAt: string,
          createdAt: string,
          userItemsId?: string | null,
          domesticParcelItemsId?: string | null,
          internationalParcelItemsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      domesticParcel?:  {
        __typename: "ModelDomesticParcelConnection",
        items:  Array< {
          __typename: "DomesticParcel",
          id: string,
          slipNumber?: string | null,
          accepted: boolean,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: string | null,
          updatedAt: string,
          createdAt: string,
          userDomesticParcelId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      internationalParcel?:  {
        __typename: "ModelInternationalParcelConnection",
        items:  Array< {
          __typename: "InternationalParcel",
          id: string,
          slipNumber?: string | null,
          shippingFee?: number | null,
          weightKg?: number | null,
          cost?: number | null,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: InternationalDeliveryCompany | null,
          updatedAt: string,
          createdAt: string,
          userInternationalParcelId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      createdAt: string,
      owner?: string | null,
    } | null,
    domesticParcel?:  {
      __typename: "DomesticParcel",
      id: string,
      slipNumber?: string | null,
      accepted: boolean,
      delivered: boolean,
      deliveredEnum?: Delivered | null,
      deliveryCompany?: string | null,
      items?:  {
        __typename: "ModelItemConnection",
        items:  Array< {
          __typename: "Item",
          id: string,
          name: string,
          price: number,
          category: ItemCategory,
          quantity: number,
          url: string,
          note?: string | null,
          registrationStatus: RegistrationStatus,
          weightKg?: number | null,
          updatedAt: string,
          createdAt: string,
          userItemsId?: string | null,
          domesticParcelItemsId?: string | null,
          internationalParcelItemsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        identityId: string,
        name: string,
        companyName?: string | null,
        email: string,
        birthday: number,
        gender: Gender,
        idPicture:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        job: string,
        i18nAreaCodeMobile?: string | null,
        mobilePhoneNumber?: string | null,
        i18nAreaCodeFixed?: string | null,
        fixedPhoneNumber?: string | null,
        detailedAddress: string,
        city: string,
        provinceOrState: string,
        countryOrRegion: string,
        postalCode: string,
        registrationStatus: RegistrationStatus,
        items?:  {
          __typename: "ModelItemConnection",
          nextToken?: string | null,
        } | null,
        domesticParcel?:  {
          __typename: "ModelDomesticParcelConnection",
          nextToken?: string | null,
        } | null,
        internationalParcel?:  {
          __typename: "ModelInternationalParcelConnection",
          nextToken?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        owner?: string | null,
      } | null,
      updatedAt: string,
      createdAt: string,
      userDomesticParcelId?: string | null,
      owner?: string | null,
    } | null,
    internationalParcel?:  {
      __typename: "InternationalParcel",
      id: string,
      slipNumber?: string | null,
      shippingFee?: number | null,
      weightKg?: number | null,
      cost?: number | null,
      delivered: boolean,
      deliveredEnum?: Delivered | null,
      shippingInfo?:  {
        __typename: "ShippingInfo",
        name?: string | null,
        i18nAreaCodeMobile?: string | null,
        mobilePhoneNumber?: string | null,
        i18nAreaCodeFixed?: string | null,
        fixedPhoneNumber?: string | null,
        detailedAddress?: string | null,
        city?: string | null,
        provinceOrState?: string | null,
        countryOrRegion?: string | null,
        postalCode?: string | null,
      } | null,
      deliveryCompany?: InternationalDeliveryCompany | null,
      items?:  {
        __typename: "ModelItemConnection",
        items:  Array< {
          __typename: "Item",
          id: string,
          name: string,
          price: number,
          category: ItemCategory,
          quantity: number,
          url: string,
          note?: string | null,
          registrationStatus: RegistrationStatus,
          weightKg?: number | null,
          updatedAt: string,
          createdAt: string,
          userItemsId?: string | null,
          domesticParcelItemsId?: string | null,
          internationalParcelItemsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        identityId: string,
        name: string,
        companyName?: string | null,
        email: string,
        birthday: number,
        gender: Gender,
        idPicture:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        job: string,
        i18nAreaCodeMobile?: string | null,
        mobilePhoneNumber?: string | null,
        i18nAreaCodeFixed?: string | null,
        fixedPhoneNumber?: string | null,
        detailedAddress: string,
        city: string,
        provinceOrState: string,
        countryOrRegion: string,
        postalCode: string,
        registrationStatus: RegistrationStatus,
        items?:  {
          __typename: "ModelItemConnection",
          nextToken?: string | null,
        } | null,
        domesticParcel?:  {
          __typename: "ModelDomesticParcelConnection",
          nextToken?: string | null,
        } | null,
        internationalParcel?:  {
          __typename: "ModelInternationalParcelConnection",
          nextToken?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        owner?: string | null,
      } | null,
      updatedAt: string,
      createdAt: string,
      userInternationalParcelId?: string | null,
      owner?: string | null,
    } | null,
    updatedAt: string,
    createdAt: string,
    userItemsId?: string | null,
    domesticParcelItemsId?: string | null,
    internationalParcelItemsId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnCreateDomesticParcelSubscriptionVariables = {
  filter?: ModelSubscriptionDomesticParcelFilterInput | null,
  owner?: string | null,
};

export type OnCreateDomesticParcelSubscription = {
  onCreateDomesticParcel?:  {
    __typename: "DomesticParcel",
    id: string,
    slipNumber?: string | null,
    accepted: boolean,
    delivered: boolean,
    deliveredEnum?: Delivered | null,
    deliveryCompany?: string | null,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        name: string,
        price: number,
        category: ItemCategory,
        quantity: number,
        url: string,
        note?: string | null,
        registrationStatus: RegistrationStatus,
        weightKg?: number | null,
        user?:  {
          __typename: "User",
          id: string,
          identityId: string,
          name: string,
          companyName?: string | null,
          email: string,
          birthday: number,
          gender: Gender,
          job: string,
          i18nAreaCodeMobile?: string | null,
          mobilePhoneNumber?: string | null,
          i18nAreaCodeFixed?: string | null,
          fixedPhoneNumber?: string | null,
          detailedAddress: string,
          city: string,
          provinceOrState: string,
          countryOrRegion: string,
          postalCode: string,
          registrationStatus: RegistrationStatus,
          updatedAt: string,
          createdAt: string,
          owner?: string | null,
        } | null,
        domesticParcel?:  {
          __typename: "DomesticParcel",
          id: string,
          slipNumber?: string | null,
          accepted: boolean,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: string | null,
          updatedAt: string,
          createdAt: string,
          userDomesticParcelId?: string | null,
          owner?: string | null,
        } | null,
        internationalParcel?:  {
          __typename: "InternationalParcel",
          id: string,
          slipNumber?: string | null,
          shippingFee?: number | null,
          weightKg?: number | null,
          cost?: number | null,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: InternationalDeliveryCompany | null,
          updatedAt: string,
          createdAt: string,
          userInternationalParcelId?: string | null,
          owner?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        userItemsId?: string | null,
        domesticParcelItemsId?: string | null,
        internationalParcelItemsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      identityId: string,
      name: string,
      companyName?: string | null,
      email: string,
      birthday: number,
      gender: Gender,
      idPicture:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      job: string,
      i18nAreaCodeMobile?: string | null,
      mobilePhoneNumber?: string | null,
      i18nAreaCodeFixed?: string | null,
      fixedPhoneNumber?: string | null,
      detailedAddress: string,
      city: string,
      provinceOrState: string,
      countryOrRegion: string,
      postalCode: string,
      registrationStatus: RegistrationStatus,
      items?:  {
        __typename: "ModelItemConnection",
        items:  Array< {
          __typename: "Item",
          id: string,
          name: string,
          price: number,
          category: ItemCategory,
          quantity: number,
          url: string,
          note?: string | null,
          registrationStatus: RegistrationStatus,
          weightKg?: number | null,
          updatedAt: string,
          createdAt: string,
          userItemsId?: string | null,
          domesticParcelItemsId?: string | null,
          internationalParcelItemsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      domesticParcel?:  {
        __typename: "ModelDomesticParcelConnection",
        items:  Array< {
          __typename: "DomesticParcel",
          id: string,
          slipNumber?: string | null,
          accepted: boolean,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: string | null,
          updatedAt: string,
          createdAt: string,
          userDomesticParcelId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      internationalParcel?:  {
        __typename: "ModelInternationalParcelConnection",
        items:  Array< {
          __typename: "InternationalParcel",
          id: string,
          slipNumber?: string | null,
          shippingFee?: number | null,
          weightKg?: number | null,
          cost?: number | null,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: InternationalDeliveryCompany | null,
          updatedAt: string,
          createdAt: string,
          userInternationalParcelId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      createdAt: string,
      owner?: string | null,
    } | null,
    updatedAt: string,
    createdAt: string,
    userDomesticParcelId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateDomesticParcelSubscriptionVariables = {
  filter?: ModelSubscriptionDomesticParcelFilterInput | null,
  owner?: string | null,
};

export type OnUpdateDomesticParcelSubscription = {
  onUpdateDomesticParcel?:  {
    __typename: "DomesticParcel",
    id: string,
    slipNumber?: string | null,
    accepted: boolean,
    delivered: boolean,
    deliveredEnum?: Delivered | null,
    deliveryCompany?: string | null,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        name: string,
        price: number,
        category: ItemCategory,
        quantity: number,
        url: string,
        note?: string | null,
        registrationStatus: RegistrationStatus,
        weightKg?: number | null,
        user?:  {
          __typename: "User",
          id: string,
          identityId: string,
          name: string,
          companyName?: string | null,
          email: string,
          birthday: number,
          gender: Gender,
          job: string,
          i18nAreaCodeMobile?: string | null,
          mobilePhoneNumber?: string | null,
          i18nAreaCodeFixed?: string | null,
          fixedPhoneNumber?: string | null,
          detailedAddress: string,
          city: string,
          provinceOrState: string,
          countryOrRegion: string,
          postalCode: string,
          registrationStatus: RegistrationStatus,
          updatedAt: string,
          createdAt: string,
          owner?: string | null,
        } | null,
        domesticParcel?:  {
          __typename: "DomesticParcel",
          id: string,
          slipNumber?: string | null,
          accepted: boolean,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: string | null,
          updatedAt: string,
          createdAt: string,
          userDomesticParcelId?: string | null,
          owner?: string | null,
        } | null,
        internationalParcel?:  {
          __typename: "InternationalParcel",
          id: string,
          slipNumber?: string | null,
          shippingFee?: number | null,
          weightKg?: number | null,
          cost?: number | null,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: InternationalDeliveryCompany | null,
          updatedAt: string,
          createdAt: string,
          userInternationalParcelId?: string | null,
          owner?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        userItemsId?: string | null,
        domesticParcelItemsId?: string | null,
        internationalParcelItemsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      identityId: string,
      name: string,
      companyName?: string | null,
      email: string,
      birthday: number,
      gender: Gender,
      idPicture:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      job: string,
      i18nAreaCodeMobile?: string | null,
      mobilePhoneNumber?: string | null,
      i18nAreaCodeFixed?: string | null,
      fixedPhoneNumber?: string | null,
      detailedAddress: string,
      city: string,
      provinceOrState: string,
      countryOrRegion: string,
      postalCode: string,
      registrationStatus: RegistrationStatus,
      items?:  {
        __typename: "ModelItemConnection",
        items:  Array< {
          __typename: "Item",
          id: string,
          name: string,
          price: number,
          category: ItemCategory,
          quantity: number,
          url: string,
          note?: string | null,
          registrationStatus: RegistrationStatus,
          weightKg?: number | null,
          updatedAt: string,
          createdAt: string,
          userItemsId?: string | null,
          domesticParcelItemsId?: string | null,
          internationalParcelItemsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      domesticParcel?:  {
        __typename: "ModelDomesticParcelConnection",
        items:  Array< {
          __typename: "DomesticParcel",
          id: string,
          slipNumber?: string | null,
          accepted: boolean,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: string | null,
          updatedAt: string,
          createdAt: string,
          userDomesticParcelId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      internationalParcel?:  {
        __typename: "ModelInternationalParcelConnection",
        items:  Array< {
          __typename: "InternationalParcel",
          id: string,
          slipNumber?: string | null,
          shippingFee?: number | null,
          weightKg?: number | null,
          cost?: number | null,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: InternationalDeliveryCompany | null,
          updatedAt: string,
          createdAt: string,
          userInternationalParcelId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      createdAt: string,
      owner?: string | null,
    } | null,
    updatedAt: string,
    createdAt: string,
    userDomesticParcelId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteDomesticParcelSubscriptionVariables = {
  filter?: ModelSubscriptionDomesticParcelFilterInput | null,
  owner?: string | null,
};

export type OnDeleteDomesticParcelSubscription = {
  onDeleteDomesticParcel?:  {
    __typename: "DomesticParcel",
    id: string,
    slipNumber?: string | null,
    accepted: boolean,
    delivered: boolean,
    deliveredEnum?: Delivered | null,
    deliveryCompany?: string | null,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        name: string,
        price: number,
        category: ItemCategory,
        quantity: number,
        url: string,
        note?: string | null,
        registrationStatus: RegistrationStatus,
        weightKg?: number | null,
        user?:  {
          __typename: "User",
          id: string,
          identityId: string,
          name: string,
          companyName?: string | null,
          email: string,
          birthday: number,
          gender: Gender,
          job: string,
          i18nAreaCodeMobile?: string | null,
          mobilePhoneNumber?: string | null,
          i18nAreaCodeFixed?: string | null,
          fixedPhoneNumber?: string | null,
          detailedAddress: string,
          city: string,
          provinceOrState: string,
          countryOrRegion: string,
          postalCode: string,
          registrationStatus: RegistrationStatus,
          updatedAt: string,
          createdAt: string,
          owner?: string | null,
        } | null,
        domesticParcel?:  {
          __typename: "DomesticParcel",
          id: string,
          slipNumber?: string | null,
          accepted: boolean,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: string | null,
          updatedAt: string,
          createdAt: string,
          userDomesticParcelId?: string | null,
          owner?: string | null,
        } | null,
        internationalParcel?:  {
          __typename: "InternationalParcel",
          id: string,
          slipNumber?: string | null,
          shippingFee?: number | null,
          weightKg?: number | null,
          cost?: number | null,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: InternationalDeliveryCompany | null,
          updatedAt: string,
          createdAt: string,
          userInternationalParcelId?: string | null,
          owner?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        userItemsId?: string | null,
        domesticParcelItemsId?: string | null,
        internationalParcelItemsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      identityId: string,
      name: string,
      companyName?: string | null,
      email: string,
      birthday: number,
      gender: Gender,
      idPicture:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      job: string,
      i18nAreaCodeMobile?: string | null,
      mobilePhoneNumber?: string | null,
      i18nAreaCodeFixed?: string | null,
      fixedPhoneNumber?: string | null,
      detailedAddress: string,
      city: string,
      provinceOrState: string,
      countryOrRegion: string,
      postalCode: string,
      registrationStatus: RegistrationStatus,
      items?:  {
        __typename: "ModelItemConnection",
        items:  Array< {
          __typename: "Item",
          id: string,
          name: string,
          price: number,
          category: ItemCategory,
          quantity: number,
          url: string,
          note?: string | null,
          registrationStatus: RegistrationStatus,
          weightKg?: number | null,
          updatedAt: string,
          createdAt: string,
          userItemsId?: string | null,
          domesticParcelItemsId?: string | null,
          internationalParcelItemsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      domesticParcel?:  {
        __typename: "ModelDomesticParcelConnection",
        items:  Array< {
          __typename: "DomesticParcel",
          id: string,
          slipNumber?: string | null,
          accepted: boolean,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: string | null,
          updatedAt: string,
          createdAt: string,
          userDomesticParcelId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      internationalParcel?:  {
        __typename: "ModelInternationalParcelConnection",
        items:  Array< {
          __typename: "InternationalParcel",
          id: string,
          slipNumber?: string | null,
          shippingFee?: number | null,
          weightKg?: number | null,
          cost?: number | null,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: InternationalDeliveryCompany | null,
          updatedAt: string,
          createdAt: string,
          userInternationalParcelId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      createdAt: string,
      owner?: string | null,
    } | null,
    updatedAt: string,
    createdAt: string,
    userDomesticParcelId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnCreateInternationalParcelSubscriptionVariables = {
  filter?: ModelSubscriptionInternationalParcelFilterInput | null,
  owner?: string | null,
};

export type OnCreateInternationalParcelSubscription = {
  onCreateInternationalParcel?:  {
    __typename: "InternationalParcel",
    id: string,
    slipNumber?: string | null,
    shippingFee?: number | null,
    weightKg?: number | null,
    cost?: number | null,
    delivered: boolean,
    deliveredEnum?: Delivered | null,
    shippingInfo?:  {
      __typename: "ShippingInfo",
      name?: string | null,
      i18nAreaCodeMobile?: string | null,
      mobilePhoneNumber?: string | null,
      i18nAreaCodeFixed?: string | null,
      fixedPhoneNumber?: string | null,
      detailedAddress?: string | null,
      city?: string | null,
      provinceOrState?: string | null,
      countryOrRegion?: string | null,
      postalCode?: string | null,
    } | null,
    deliveryCompany?: InternationalDeliveryCompany | null,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        name: string,
        price: number,
        category: ItemCategory,
        quantity: number,
        url: string,
        note?: string | null,
        registrationStatus: RegistrationStatus,
        weightKg?: number | null,
        user?:  {
          __typename: "User",
          id: string,
          identityId: string,
          name: string,
          companyName?: string | null,
          email: string,
          birthday: number,
          gender: Gender,
          job: string,
          i18nAreaCodeMobile?: string | null,
          mobilePhoneNumber?: string | null,
          i18nAreaCodeFixed?: string | null,
          fixedPhoneNumber?: string | null,
          detailedAddress: string,
          city: string,
          provinceOrState: string,
          countryOrRegion: string,
          postalCode: string,
          registrationStatus: RegistrationStatus,
          updatedAt: string,
          createdAt: string,
          owner?: string | null,
        } | null,
        domesticParcel?:  {
          __typename: "DomesticParcel",
          id: string,
          slipNumber?: string | null,
          accepted: boolean,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: string | null,
          updatedAt: string,
          createdAt: string,
          userDomesticParcelId?: string | null,
          owner?: string | null,
        } | null,
        internationalParcel?:  {
          __typename: "InternationalParcel",
          id: string,
          slipNumber?: string | null,
          shippingFee?: number | null,
          weightKg?: number | null,
          cost?: number | null,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: InternationalDeliveryCompany | null,
          updatedAt: string,
          createdAt: string,
          userInternationalParcelId?: string | null,
          owner?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        userItemsId?: string | null,
        domesticParcelItemsId?: string | null,
        internationalParcelItemsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      identityId: string,
      name: string,
      companyName?: string | null,
      email: string,
      birthday: number,
      gender: Gender,
      idPicture:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      job: string,
      i18nAreaCodeMobile?: string | null,
      mobilePhoneNumber?: string | null,
      i18nAreaCodeFixed?: string | null,
      fixedPhoneNumber?: string | null,
      detailedAddress: string,
      city: string,
      provinceOrState: string,
      countryOrRegion: string,
      postalCode: string,
      registrationStatus: RegistrationStatus,
      items?:  {
        __typename: "ModelItemConnection",
        items:  Array< {
          __typename: "Item",
          id: string,
          name: string,
          price: number,
          category: ItemCategory,
          quantity: number,
          url: string,
          note?: string | null,
          registrationStatus: RegistrationStatus,
          weightKg?: number | null,
          updatedAt: string,
          createdAt: string,
          userItemsId?: string | null,
          domesticParcelItemsId?: string | null,
          internationalParcelItemsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      domesticParcel?:  {
        __typename: "ModelDomesticParcelConnection",
        items:  Array< {
          __typename: "DomesticParcel",
          id: string,
          slipNumber?: string | null,
          accepted: boolean,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: string | null,
          updatedAt: string,
          createdAt: string,
          userDomesticParcelId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      internationalParcel?:  {
        __typename: "ModelInternationalParcelConnection",
        items:  Array< {
          __typename: "InternationalParcel",
          id: string,
          slipNumber?: string | null,
          shippingFee?: number | null,
          weightKg?: number | null,
          cost?: number | null,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: InternationalDeliveryCompany | null,
          updatedAt: string,
          createdAt: string,
          userInternationalParcelId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      createdAt: string,
      owner?: string | null,
    } | null,
    updatedAt: string,
    createdAt: string,
    userInternationalParcelId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateInternationalParcelSubscriptionVariables = {
  filter?: ModelSubscriptionInternationalParcelFilterInput | null,
  owner?: string | null,
};

export type OnUpdateInternationalParcelSubscription = {
  onUpdateInternationalParcel?:  {
    __typename: "InternationalParcel",
    id: string,
    slipNumber?: string | null,
    shippingFee?: number | null,
    weightKg?: number | null,
    cost?: number | null,
    delivered: boolean,
    deliveredEnum?: Delivered | null,
    shippingInfo?:  {
      __typename: "ShippingInfo",
      name?: string | null,
      i18nAreaCodeMobile?: string | null,
      mobilePhoneNumber?: string | null,
      i18nAreaCodeFixed?: string | null,
      fixedPhoneNumber?: string | null,
      detailedAddress?: string | null,
      city?: string | null,
      provinceOrState?: string | null,
      countryOrRegion?: string | null,
      postalCode?: string | null,
    } | null,
    deliveryCompany?: InternationalDeliveryCompany | null,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        name: string,
        price: number,
        category: ItemCategory,
        quantity: number,
        url: string,
        note?: string | null,
        registrationStatus: RegistrationStatus,
        weightKg?: number | null,
        user?:  {
          __typename: "User",
          id: string,
          identityId: string,
          name: string,
          companyName?: string | null,
          email: string,
          birthday: number,
          gender: Gender,
          job: string,
          i18nAreaCodeMobile?: string | null,
          mobilePhoneNumber?: string | null,
          i18nAreaCodeFixed?: string | null,
          fixedPhoneNumber?: string | null,
          detailedAddress: string,
          city: string,
          provinceOrState: string,
          countryOrRegion: string,
          postalCode: string,
          registrationStatus: RegistrationStatus,
          updatedAt: string,
          createdAt: string,
          owner?: string | null,
        } | null,
        domesticParcel?:  {
          __typename: "DomesticParcel",
          id: string,
          slipNumber?: string | null,
          accepted: boolean,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: string | null,
          updatedAt: string,
          createdAt: string,
          userDomesticParcelId?: string | null,
          owner?: string | null,
        } | null,
        internationalParcel?:  {
          __typename: "InternationalParcel",
          id: string,
          slipNumber?: string | null,
          shippingFee?: number | null,
          weightKg?: number | null,
          cost?: number | null,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: InternationalDeliveryCompany | null,
          updatedAt: string,
          createdAt: string,
          userInternationalParcelId?: string | null,
          owner?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        userItemsId?: string | null,
        domesticParcelItemsId?: string | null,
        internationalParcelItemsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      identityId: string,
      name: string,
      companyName?: string | null,
      email: string,
      birthday: number,
      gender: Gender,
      idPicture:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      job: string,
      i18nAreaCodeMobile?: string | null,
      mobilePhoneNumber?: string | null,
      i18nAreaCodeFixed?: string | null,
      fixedPhoneNumber?: string | null,
      detailedAddress: string,
      city: string,
      provinceOrState: string,
      countryOrRegion: string,
      postalCode: string,
      registrationStatus: RegistrationStatus,
      items?:  {
        __typename: "ModelItemConnection",
        items:  Array< {
          __typename: "Item",
          id: string,
          name: string,
          price: number,
          category: ItemCategory,
          quantity: number,
          url: string,
          note?: string | null,
          registrationStatus: RegistrationStatus,
          weightKg?: number | null,
          updatedAt: string,
          createdAt: string,
          userItemsId?: string | null,
          domesticParcelItemsId?: string | null,
          internationalParcelItemsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      domesticParcel?:  {
        __typename: "ModelDomesticParcelConnection",
        items:  Array< {
          __typename: "DomesticParcel",
          id: string,
          slipNumber?: string | null,
          accepted: boolean,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: string | null,
          updatedAt: string,
          createdAt: string,
          userDomesticParcelId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      internationalParcel?:  {
        __typename: "ModelInternationalParcelConnection",
        items:  Array< {
          __typename: "InternationalParcel",
          id: string,
          slipNumber?: string | null,
          shippingFee?: number | null,
          weightKg?: number | null,
          cost?: number | null,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: InternationalDeliveryCompany | null,
          updatedAt: string,
          createdAt: string,
          userInternationalParcelId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      createdAt: string,
      owner?: string | null,
    } | null,
    updatedAt: string,
    createdAt: string,
    userInternationalParcelId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteInternationalParcelSubscriptionVariables = {
  filter?: ModelSubscriptionInternationalParcelFilterInput | null,
  owner?: string | null,
};

export type OnDeleteInternationalParcelSubscription = {
  onDeleteInternationalParcel?:  {
    __typename: "InternationalParcel",
    id: string,
    slipNumber?: string | null,
    shippingFee?: number | null,
    weightKg?: number | null,
    cost?: number | null,
    delivered: boolean,
    deliveredEnum?: Delivered | null,
    shippingInfo?:  {
      __typename: "ShippingInfo",
      name?: string | null,
      i18nAreaCodeMobile?: string | null,
      mobilePhoneNumber?: string | null,
      i18nAreaCodeFixed?: string | null,
      fixedPhoneNumber?: string | null,
      detailedAddress?: string | null,
      city?: string | null,
      provinceOrState?: string | null,
      countryOrRegion?: string | null,
      postalCode?: string | null,
    } | null,
    deliveryCompany?: InternationalDeliveryCompany | null,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        name: string,
        price: number,
        category: ItemCategory,
        quantity: number,
        url: string,
        note?: string | null,
        registrationStatus: RegistrationStatus,
        weightKg?: number | null,
        user?:  {
          __typename: "User",
          id: string,
          identityId: string,
          name: string,
          companyName?: string | null,
          email: string,
          birthday: number,
          gender: Gender,
          job: string,
          i18nAreaCodeMobile?: string | null,
          mobilePhoneNumber?: string | null,
          i18nAreaCodeFixed?: string | null,
          fixedPhoneNumber?: string | null,
          detailedAddress: string,
          city: string,
          provinceOrState: string,
          countryOrRegion: string,
          postalCode: string,
          registrationStatus: RegistrationStatus,
          updatedAt: string,
          createdAt: string,
          owner?: string | null,
        } | null,
        domesticParcel?:  {
          __typename: "DomesticParcel",
          id: string,
          slipNumber?: string | null,
          accepted: boolean,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: string | null,
          updatedAt: string,
          createdAt: string,
          userDomesticParcelId?: string | null,
          owner?: string | null,
        } | null,
        internationalParcel?:  {
          __typename: "InternationalParcel",
          id: string,
          slipNumber?: string | null,
          shippingFee?: number | null,
          weightKg?: number | null,
          cost?: number | null,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: InternationalDeliveryCompany | null,
          updatedAt: string,
          createdAt: string,
          userInternationalParcelId?: string | null,
          owner?: string | null,
        } | null,
        updatedAt: string,
        createdAt: string,
        userItemsId?: string | null,
        domesticParcelItemsId?: string | null,
        internationalParcelItemsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      identityId: string,
      name: string,
      companyName?: string | null,
      email: string,
      birthday: number,
      gender: Gender,
      idPicture:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      job: string,
      i18nAreaCodeMobile?: string | null,
      mobilePhoneNumber?: string | null,
      i18nAreaCodeFixed?: string | null,
      fixedPhoneNumber?: string | null,
      detailedAddress: string,
      city: string,
      provinceOrState: string,
      countryOrRegion: string,
      postalCode: string,
      registrationStatus: RegistrationStatus,
      items?:  {
        __typename: "ModelItemConnection",
        items:  Array< {
          __typename: "Item",
          id: string,
          name: string,
          price: number,
          category: ItemCategory,
          quantity: number,
          url: string,
          note?: string | null,
          registrationStatus: RegistrationStatus,
          weightKg?: number | null,
          updatedAt: string,
          createdAt: string,
          userItemsId?: string | null,
          domesticParcelItemsId?: string | null,
          internationalParcelItemsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      domesticParcel?:  {
        __typename: "ModelDomesticParcelConnection",
        items:  Array< {
          __typename: "DomesticParcel",
          id: string,
          slipNumber?: string | null,
          accepted: boolean,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: string | null,
          updatedAt: string,
          createdAt: string,
          userDomesticParcelId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      internationalParcel?:  {
        __typename: "ModelInternationalParcelConnection",
        items:  Array< {
          __typename: "InternationalParcel",
          id: string,
          slipNumber?: string | null,
          shippingFee?: number | null,
          weightKg?: number | null,
          cost?: number | null,
          delivered: boolean,
          deliveredEnum?: Delivered | null,
          deliveryCompany?: InternationalDeliveryCompany | null,
          updatedAt: string,
          createdAt: string,
          userInternationalParcelId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      createdAt: string,
      owner?: string | null,
    } | null,
    updatedAt: string,
    createdAt: string,
    userInternationalParcelId?: string | null,
    owner?: string | null,
  } | null,
};
