import {Badge, Box, Grid, Stack, StackProps, Typography} from '@mui/material';
import {ReactNode, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router';
import {useIsMobile} from '../hooks/useIsMobile';
import {AddParcelIcon} from '../images/AddParcelIcon';
import {AirportOutlinedIcon} from '../images/AirportOutlinedIcon';
import {CheckListIcon} from '../images/CheckListIcon';
import {HelpIcon} from '../images/HelpIcon';
import {ListIcon} from '../images/ListIcon';
import {LocalShippingOutlinedIcon} from '../images/LocalShippingOutlinedIcon';
import {ParcelOutlinedIcon} from '../images/ParcelOutlinedIcon';
import {ReceiptIcon} from '../images/ReceiptIcon';
import {WarehouseOutlinedIcon} from '../images/WarehouseOutlinedIcon';
import {useDomesticParcels, useInternationalParcels} from '../queries/parcel';
import {notEmpty} from '../utils/type';

type ParcelProgress = 'NotAccepted' | 'Accepted' | 'InTransit' | 'Delivered';
const categorizeDomesticParcels = <
  T extends {slipNumber?: string | null; accepted: boolean; delivered: boolean},
>(
  domesticParcels: T[],
): Array<T & {progress: ParcelProgress}> =>
  domesticParcels.map(p => {
    const progress = ((): ParcelProgress => {
      const {accepted, slipNumber, delivered} = p;
      if (delivered) {
        return 'Delivered';
      }
      if (typeof slipNumber === 'string') {
        return 'InTransit';
      }
      if (accepted) {
        return 'Accepted';
      }
      return 'NotAccepted';
    })();
    return {...p, progress};
  });
const categorizeInternationalParcels = <
  T extends {
    slipNumber?: string | null;
    delivered: boolean;
  },
>(
  internationalParcels: T[],
): Array<T & {progress: Omit<ParcelProgress, 'NotAccepted'>}> =>
  internationalParcels.map(p => {
    const progress = ((): Omit<ParcelProgress, 'NotAccepted'> => {
      const {slipNumber, delivered} = p;
      if (delivered) {
        return 'Delivered';
      }
      if (typeof slipNumber === 'string') {
        return 'InTransit';
      }
      return 'Accepted';
    })();
    return {...p, progress};
  });

const getActionCardStyle = (isMobile: boolean): StackProps => {
  return isMobile
    ? {
        width: '100%',
        padding: '0px',
      }
    : {
        width: ' 100px',
        padding: '8px',
      };
};

const ActionCard = ({
  children,
  ...props
}: StackProps & {
  children: ReactNode;
}): JSX.Element => {
  return (
    <Stack
      bgcolor="#E7F2FE"
      height="100px"
      borderRadius="8px"
      justifyContent="center"
      alignItems="center"
      sx={{cursor: 'pointer'}}
      spacing={1}
      {...props}>
      {children}
    </Stack>
  );
};

export const Home = (): JSX.Element => {
  const {t} = useTranslation();
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const {data: domesticParcelsData} = useDomesticParcels();
  const {data: internationalParcelsData} = useInternationalParcels();
  const domesticParcels = useMemo(
    () =>
      domesticParcelsData?.items
        ? categorizeDomesticParcels(
            domesticParcelsData.items.filter(notEmpty).filter(
              p =>
                !p.items?.items.every(
                  i => typeof i?.internationalParcelItemsId === 'string', // NOTE: 全商品が国際発送依頼済みの小包は除く
                ),
            ),
          )
        : [],
    [domesticParcelsData?.items],
  );
  const internationalParcels = useMemo(
    () =>
      internationalParcelsData?.items
        ? categorizeInternationalParcels(
            internationalParcelsData.items.filter(notEmpty),
          )
        : [],
    [internationalParcelsData?.items],
  );

  return (
    <Stack spacing={isMobile ? 4 : 8}>
      <Stack justifyContent="center" spacing={2}>
        <Typography sx={{fontWeight: 'bold'}}>{t('usage')}</Typography>
        <Grid container>
          <Grid item xs={isMobile ? 12 : undefined}>
            <ActionCard
              {...getActionCardStyle(isMobile)}
              onClick={() => {
                window.location.href =
                  'https://cozmic.notion.site/MOL-CART-HELP-a4182aeab8714b3cb5d2ca82709d7e16';
              }}>
              <HelpIcon />
              <Typography sx={{fontSize: '14px'}} textAlign="center">
                {t('help')}
              </Typography>
            </ActionCard>
          </Grid>
        </Grid>
      </Stack>
      <Stack justifyContent="center" spacing={2}>
        <Typography sx={{fontWeight: 'bold'}}>{t('register')}</Typography>
        <Grid container>
          <Grid item xs={isMobile ? 12 : undefined}>
            <ActionCard
              {...getActionCardStyle(isMobile)}
              onClick={() => {
                navigate('/RegisterItem');
              }}>
              <AddParcelIcon />
              <Typography sx={{fontSize: '14px'}} textAlign="center">
                {t('registerParcel')}
              </Typography>
            </ActionCard>
          </Grid>
        </Grid>
      </Stack>
      <Stack justifyContent="center" spacing={2}>
        <Typography sx={{fontWeight: 'bold'}}>{t('orderStatus')}</Typography>
        <Box sx={{flexGrow: 1, margin: 'auto'}}>
          <Grid container spacing={3}>
            <Grid item xs={isMobile ? 6 : undefined}>
              <ActionCard
                {...getActionCardStyle(isMobile)}
                onClick={() => {
                  navigate('/progressTracker#notAcceptedDomesticParcels');
                }}>
                <Badge
                  badgeContent={
                    domesticParcels.filter(p => p.progress === 'NotAccepted')
                      .length
                  }>
                  <ListIcon />
                </Badge>
                <Typography sx={{fontSize: '14px'}} textAlign="center">
                  {t('notAcceptedDomesticParcels')}
                </Typography>
              </ActionCard>
            </Grid>
            <Grid item xs={isMobile ? 6 : undefined}>
              <ActionCard
                {...getActionCardStyle(isMobile)}
                onClick={() => {
                  navigate('/progressTracker#acceptedDomesticParcels');
                }}>
                <Badge
                  badgeContent={
                    domesticParcels.filter(p => p.progress === 'Accepted')
                      .length
                  }>
                  <CheckListIcon />
                </Badge>
                <Typography sx={{fontSize: '14px'}} textAlign="center">
                  {t('acceptedDomesticParcels')}
                </Typography>
              </ActionCard>
            </Grid>
            <Grid item xs={isMobile ? 6 : undefined}>
              <ActionCard
                {...getActionCardStyle(isMobile)}
                onClick={() => {
                  navigate('/progressTracker#domesticParcelsInTransit');
                }}>
                <Badge
                  badgeContent={
                    domesticParcels.filter(p => p.progress === 'InTransit')
                      .length
                  }>
                  <LocalShippingOutlinedIcon />
                </Badge>
                <Typography sx={{fontSize: '14px'}} textAlign="center">
                  {t('domesticParcelsInTransit')}
                </Typography>
              </ActionCard>
            </Grid>
            <Grid item xs={isMobile ? 6 : undefined}>
              <ActionCard
                {...getActionCardStyle(isMobile)}
                onClick={() => {
                  navigate('/progressTracker#deliveredDomesticParcels');
                }}>
                <Badge
                  badgeContent={
                    domesticParcels.filter(p => p.progress === 'Delivered')
                      .length
                  }>
                  <WarehouseOutlinedIcon />
                </Badge>
                <Typography sx={{fontSize: '14px'}} textAlign="center">
                  {t('deliveredDomesticParcels')}
                </Typography>
              </ActionCard>
            </Grid>
            <Grid item xs={isMobile ? 6 : undefined}>
              <ActionCard
                {...getActionCardStyle(isMobile)}
                onClick={() => {
                  navigate('/progressTracker#notDeliveredInternationalParcels');
                }}>
                <Badge
                  badgeContent={
                    internationalParcels.filter(p => p.progress === 'Accepted')
                      .length
                  }>
                  <ParcelOutlinedIcon />
                </Badge>
                <Typography sx={{fontSize: '14px'}} textAlign="center">
                  {t('notDeliveredInternationalParcels')}
                </Typography>
              </ActionCard>
            </Grid>
            <Grid item xs={isMobile ? 6 : undefined}>
              <ActionCard
                {...getActionCardStyle(isMobile)}
                onClick={() => {
                  navigate('/progressTracker#internationalParcelsInTransit');
                }}>
                <Badge
                  badgeContent={
                    internationalParcels.filter(p => p.progress === 'InTransit')
                      .length
                  }>
                  <AirportOutlinedIcon />
                </Badge>
                <Typography sx={{fontSize: '14px'}} textAlign="center">
                  {t('internationalParcelsInTransit')}
                </Typography>
              </ActionCard>
            </Grid>
            <Grid item xs={isMobile ? 6 : undefined}>
              <ActionCard
                {...getActionCardStyle(isMobile)}
                onClick={() => {
                  navigate('/progressTracker#deliveredInternationalParcels');
                }}>
                <Badge
                  badgeContent={
                    internationalParcels.filter(p => p.progress === 'Delivered')
                      .length
                  }>
                  <ReceiptIcon />
                </Badge>
                <Typography sx={{fontSize: '14px'}} textAlign="center">
                  {t('deliveredInternationalParcels')}
                </Typography>
              </ActionCard>
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </Stack>
  );
};
