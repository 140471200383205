import {Omit} from '@material-ui/core';
import {Box, Button, Divider, Stack, Typography} from '@mui/material';
import {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Navigate, useLocation, useNavigate} from 'react-router-dom';
import {
  CreateInternationalParcelMutationVariables,
  Delivered,
  InternationalDeliveryCompany,
  ShippingInfo,
  UpdateItemMutationVariables,
} from '../API';
import {ItemInfo} from '../components/ItemInfo';
import {ShippingInfoTile} from '../components/ShippingInformation';
import {useIsMobile} from '../hooks/useIsMobile';
import {useOAuthUser} from '../providers/AuthProvider';
import {useUpdateItemMutation} from '../queries/item';
import {
  useCreateInternationalParcelMutation,
  useDeliveredDomesticParcels,
} from '../queries/parcel';
import {useUser} from '../queries/user';
import {isArray, notEmpty} from '../utils/type';

interface NavigateState {
  itemIds: string[];
  charge: number;
  deliveryCompany: InternationalDeliveryCompany;
  declaredPrice: number;
  totalWeightKg: number;
  shippingInfo: Omit<ShippingInfo, '__typename'>;
}

export const ConfirmInternationalShippingRequest = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const user = useOAuthUser();
  const {data: userData} = useUser(user?.username ?? '');
  const location = useLocation();
  const {
    itemIds,
    charge,
    deliveryCompany,
    declaredPrice,
    totalWeightKg,
    shippingInfo,
  } = (location.state as NavigateState) ?? {};
  const {data: parcelsData, isFetched: isParcelsFetched} =
    useDeliveredDomesticParcels();
  const {mutate: createInternationalParcelMutation} =
    useCreateInternationalParcelMutation();
  const {mutateAsync: updateItemMutationAsync} = useUpdateItemMutation();

  const items = useMemo(() => {
    if (!isParcelsFetched || !parcelsData || !isArray(itemIds)) {
      return [];
    }
    const allItems = parcelsData.items
      .map(p => p?.items?.items)
      .flat()
      .filter(notEmpty);
    return allItems.filter(i => itemIds.includes(i.id));
  }, [isParcelsFetched, itemIds, parcelsData]);

  const onClickRequest = useCallback(() => {
    try {
      if (!userData) {
        throw new Error('user not found');
      }

      const parcelVariables: CreateInternationalParcelMutationVariables = {
        input: {
          delivered: false,
          deliveredEnum: Delivered.No,
          shippingFee: charge,
          cost: declaredPrice,
          weightKg: totalWeightKg,
          shippingInfo: {
            name: shippingInfo.name,
            i18nAreaCodeMobile: shippingInfo.i18nAreaCodeMobile,
            mobilePhoneNumber: shippingInfo.mobilePhoneNumber,
            i18nAreaCodeFixed: shippingInfo.i18nAreaCodeFixed,
            fixedPhoneNumber: shippingInfo.fixedPhoneNumber,
            detailedAddress: shippingInfo.detailedAddress,
            city: shippingInfo.city,
            provinceOrState: shippingInfo.provinceOrState,
            countryOrRegion: shippingInfo.countryOrRegion,
            postalCode: shippingInfo.postalCode,
          },
          deliveryCompany,
          userInternationalParcelId: userData.id,
        },
      };
      createInternationalParcelMutation(parcelVariables, {
        onSuccess: async result => {
          const itemVariables: UpdateItemMutationVariables[] = items.map(
            item => {
              return {
                input: {
                  id: item.id,
                  internationalParcelItemsId: result?.id,
                },
              };
            },
          );

          // TODO: [SLIN-39] implement bulk mutation by custom resolver
          await Promise.all(
            itemVariables.map(async v => await updateItemMutationAsync(v)),
          ).catch(() => {
            navigate('/error');
          });
          navigate('/completeInternationalShippingRequest', {replace: true});
        },
      });
    } catch (_e) {
      navigate('/error');
    }
  }, [
    userData,
    charge,
    declaredPrice,
    totalWeightKg,
    shippingInfo.name,
    shippingInfo.i18nAreaCodeMobile,
    shippingInfo.mobilePhoneNumber,
    shippingInfo.i18nAreaCodeFixed,
    shippingInfo.fixedPhoneNumber,
    shippingInfo.detailedAddress,
    shippingInfo.city,
    shippingInfo.provinceOrState,
    shippingInfo.countryOrRegion,
    shippingInfo.postalCode,
    deliveryCompany,
    createInternationalParcelMutation,
    items,
    navigate,
    updateItemMutationAsync,
  ]);

  if (
    !itemIds?.length ||
    !charge ||
    !Object.values(InternationalDeliveryCompany).includes(deliveryCompany) ||
    !declaredPrice ||
    !shippingInfo
  ) {
    return <Navigate replace to="/error" />;
  }

  return (
    <Box sx={{width: '100%'}}>
      <Stack spacing={isMobile ? '16px' : '24px'} sx={{mb: '12px'}}>
        <Typography
          fontWeight="bold"
          sx={{
            fontSize: {xs: '18px', md: '24px'},
          }}>
          {t('internationalShippingRequest')}
        </Typography>
        <Typography
          sx={{
            fontSize: {xs: '12px', md: '14px'},
          }}>
          {t('requestShippingWithTheFollowingInformation')}
        </Typography>
        <Typography
          fontWeight="bold"
          sx={{
            fontSize: {xs: '12px', md: '14px'},
          }}>
          {t('itemsToBeShipped')}
        </Typography>
      </Stack>
      <Box
        sx={{p: '32px', mb: '24px', borderRadius: '8px', bgcolor: '#ffffff'}}>
        {items.filter(notEmpty).map((item, index) => (
          <div key={item.id}>
            {items.length && items.length > 1 ? (
              <Stack flexDirection="row">
                <Stack spacing={1} sx={{width: '100%', ml: '8px'}}>
                  <Typography
                    fontWeight="bold"
                    sx={{fontSize: isMobile ? '14px' : '16px'}}>
                    {t('point', {x: index + 1})}
                  </Typography>
                  <ItemInfo item={item} />
                </Stack>
              </Stack>
            ) : (
              <Stack>
                <ItemInfo item={item} />
              </Stack>
            )}
            {items.length > index + 1 && (
              <Divider
                sx={{
                  my: '24px',
                  border: '1px dashed rgba(0, 0, 0, 0.12)',
                }}
              />
            )}
          </div>
        ))}
      </Box>
      <Stack spacing="8px" sx={{mb: '16px'}}>
        <Typography
          fontWeight="bold"
          sx={{
            fontSize: {xs: '12px', md: '14px'},
          }}>
          {t('deliveryCompany')}
        </Typography>
        <Typography
          sx={{
            fontSize: {xs: '12px', md: '14px'},
          }}>
          {`${
            {
              [InternationalDeliveryCompany.Ems]: t('ems'),
              [InternationalDeliveryCompany.SfExpress]: t('sfExpress'),
            }[deliveryCompany]
          } (${charge.toLocaleString()}${t('yen')})`}
        </Typography>
      </Stack>
      <Stack spacing="8px" sx={{mb: '16px'}}>
        <Typography
          fontWeight="bold"
          sx={{
            fontSize: {xs: '12px', md: '14px'},
          }}>
          {t('declaredPrice')}
        </Typography>
        <Typography
          sx={{
            fontSize: {xs: '12px', md: '14px'},
          }}>
          {`${declaredPrice.toLocaleString()}${t('yen')}`}
        </Typography>
      </Stack>
      <Stack spacing="8px" sx={{mb: '16px'}}>
        <Typography
          fontWeight="bold"
          sx={{
            fontSize: {xs: '12px', md: '14px'},
          }}>
          {t('shippingAddress')}
        </Typography>
        <ShippingInfoTile {...shippingInfo} />
      </Stack>
      <Stack flexDirection="row" sx={{mt: '30px'}}>
        <Button
          variant="outlined"
          onClick={() => {
            navigate(-1);
          }}
          sx={{
            width: {xs: '128px', md: '120px'},
            height: '36px',
            mr: '16px',
            color: '#1976D2',
          }}>
          {t('back')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onClickRequest}
          sx={{width: {xs: '128px', md: '120px'}, height: '36px'}}>
          {t('requestShipping')}
        </Button>
      </Stack>
    </Box>
  );
};
