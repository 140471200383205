import {
  JAPAN_POST_CHARGES,
  SF_EXPRESS_CHARGES,
  SF_EXPRESS_FUEL_SURCHARGE_RATE,
  SF_EXPRESS_TEMPORARY_SURCHARGE_YEN,
} from './constants';

export const getJapanPostCharge = (weightKg: number) => {
  return JAPAN_POST_CHARGES.find(v => weightKg * 1000 <= v.grams)?.charge;
};

export const getSfExpressCharge = (weightKg: number) => {
  const baseCharge = SF_EXPRESS_CHARGES.find(
    v => weightKg * 1000 <= v.grams,
  )?.charge;
  return (
    baseCharge &&
    baseCharge * (1 + SF_EXPRESS_FUEL_SURCHARGE_RATE) +
      SF_EXPRESS_TEMPORARY_SURCHARGE_YEN
  );
};
