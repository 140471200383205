import {Chip, ChipProps} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {RegistrationStatus} from '../API';

export const ItemChip = ({
  registrationStatus,
  ...props
}: {registrationStatus: RegistrationStatus} & Omit<
  ChipProps,
  'label' | 'color' | 'size'
>) => {
  const {t} = useTranslation();
  const ChipProps: {
    [key: string]: {
      label: string;
      color: ChipProps['color'];
    };
  } = {
    [RegistrationStatus.Accepted]: {
      label: `${t('reviewed')}`,
      color: 'success',
    },
    [RegistrationStatus.Requesting]: {
      label: `${t('underReview')}`,
      color: 'default',
    },
    [RegistrationStatus.Denied]: {label: `${t('disapproved')}`, color: 'error'},
  } as const;
  return <Chip {...props} {...ChipProps[registrationStatus]} size="small" />;
};
