import './App.css';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {Amplify} from 'aws-amplify';
import {useMemo} from 'react';
import {QueryClient, QueryClientProvider} from 'react-query';
import {ThemeProvider} from './ThemeProvider';
import awsExports from './aws-exports';
import {AuthProvider} from './providers/AuthProvider';
import {Screens} from './screens';
import './validators/locale';

Amplify.configure(awsExports);

const App = (): JSX.Element => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <QueryClientProvider client={useMemo(() => new QueryClient(), [])}>
        <AuthProvider>
          <ThemeProvider>
            <Screens />
          </ThemeProvider>
        </AuthProvider>
      </QueryClientProvider>
    </LocalizationProvider>
  );
};

export default App;
