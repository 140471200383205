/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Typography,
  TextField,
  Grid,
  Box,
  InputAdornment,
} from '@mui/material';
import {styled} from '@mui/system';
import {t} from 'i18next';
import {
  createRef,
  FC,
  forwardRef,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {Edit, useGetOne, useGetRecordId} from 'react-admin';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import {
  Delivered,
  DomesticParcel,
  Item,
  UpdateDomesticParcelInput,
  UpdateItemInput,
} from '../API';
import {ItemInfo} from '../components/ItemInfo';
import {useIsMobile} from '../hooks/useIsMobile';
import {useUpdateItemMutation} from '../queries/item';
import {
  useDomesticParcel,
  useUpdateDomesticParcelMutation,
} from '../queries/parcel';
import {convertDateToDateTimeString} from '../utils/date';
import {getLocalizedDomesticDeliveryCompany} from '../utils/deliveryCompany';
import {onPromise} from '../utils/promise';
import {notEmpty} from '../utils/type';

const RegisterButton: FC<{id: string}> = ({id}) => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const {mutateAsync: updateItemMutationAsync} = useUpdateItemMutation();
  const {mutateAsync: updateDomesticParcelMutationAsync} =
    useUpdateDomesticParcelMutation();
  const [open, setOpen] = useState(false);
  const {data} = useDomesticParcel(id);
  const [items, setItems] = useState(() => {
    return data?.items?.items ?? [];
  });
  const disabledOpenButton = useMemo(() => {
    return !!data?.delivered || !data?.slipNumber;
  }, [data]);
  const disabledSaveButton = useMemo(() => {
    return items.filter(notEmpty).some(item => !item.weightKg);
  }, [items]);

  useEffect(() => {
    if (!data?.items?.items) return;
    setItems(data.items.items);
  }, [data]);

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const onSave = useCallback(async () => {
    await Promise.all(
      items.filter(notEmpty).map(async v => {
        const variables: UpdateItemInput = {
          id: v?.id,
          weightKg: v?.weightKg,
        };
        await updateItemMutationAsync({input: variables});
      }),
    )
      .then(async () => {
        const variables: UpdateDomesticParcelInput = {
          id,
          delivered: true,
          deliveredEnum: Delivered.Yes,
        };
        await updateDomesticParcelMutationAsync({input: variables});
      })
      .catch(() => {
        navigate('/error');
      })
      .finally(() => {
        onClose();
      });
  }, [
    id,
    items,
    navigate,
    onClose,
    updateDomesticParcelMutationAsync,
    updateItemMutationAsync,
  ]);

  const handleChange = useCallback(
    (itemId: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!itemId) {
        return;
      }
      setItems(
        items.map(item => {
          if (item?.id === itemId) {
            item.weightKg = Number(event.target.value);
            return item;
          }
          return item;
        }),
      );
    },
    [items],
  );

  return (
    <>
      <Button
        id="configurableButton"
        variant="contained"
        size={isMobile ? 'small' : 'medium'}
        onClick={() => setOpen(true)}
        disabled={disabledOpenButton}>
        重さ入力
      </Button>
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          style: {
            width: isMobile ? '100%' : '500px',
          },
        }}>
        <DialogTitle
          sx={{fontSize: isMobile ? '14px' : '16px', fontWeight: 'bold'}}>
          重さを入力してください
        </DialogTitle>
        <DialogContent sx={{display: 'grid', gap: '8px'}}>
          {items.length > 0 ? (
            items.map((item, index) =>
              item ? (
                <Box key={item.id} sx={{my: '8px'}}>
                  {items.length && items.length > 1 ? (
                    <Typography
                      fontWeight="bold"
                      sx={{fontSize: isMobile ? 14 : 16}}>
                      {t('point', {x: index + 1})} ({item.name})
                    </Typography>
                  ) : null}
                  <TextField
                    size="small"
                    placeholder="0.00"
                    onChange={handleChange(item.id)}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">Kg</InputAdornment>
                      ),
                    }}
                  />
                </Box>
              ) : null,
            )
          ) : (
            <Typography sx={{fontSize: isMobile ? '14px' : '16px'}}>
              {t('noItem')}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined">
            キャンセル
          </Button>
          <Button
            onClick={onPromise(onSave)}
            variant="contained"
            disabled={disabledSaveButton}>
            完了
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
interface DomesticParcelDetailType {
  slipNumber: string;
  item: Item;
}
const DomesticParcelDetail = forwardRef<
  HTMLDivElement,
  DomesticParcelDetailType
>(function DomesticParcelDetail({slipNumber, item}, ref) {
  const isMobile = useIsMobile();
  return (
    <PrintStyle ref={ref}>
      <Stack direction="row" spacing={1} alignItems="center" sx={{mb: '12px'}}>
        <Typography fontWeight="bold" sx={{fontSize: isMobile ? 14 : 16}}>
          国内商品ID: {slipNumber}
        </Typography>
      </Stack>
      <ItemInfo item={item} />
    </PrintStyle>
  );
});
const PrintStyle = styled('div')({
  '@media print': {
    padding: '12px',
  },
});

export const DomesticParcelEdit = () => {
  const {t} = useTranslation();
  const isMobile = useIsMobile();
  const recordId = useGetRecordId() as string;
  const {data: parcel} = useGetOne<DomesticParcel>('domesticParcels', {
    id: recordId,
  });
  const ref = useRef<Array<RefObject<HTMLDivElement>>>([]);
  parcel?.items?.items.forEach((_, i) => {
    ref.current[i] = createRef<HTMLDivElement>();
  });
  return (
    <Edit>
      {parcel ? (
        <Stack key={parcel.id} sx={{mb: isMobile ? '16px' : '24px', p: '24px'}}>
          <Grid container spacing={1} alignItems="center" mb="24px">
            <Grid item xs={isMobile ? 11 : undefined}>
              <Stack>
                <Typography
                  sx={{
                    fontSize: isMobile ? '14px' : '16px',
                    fontWeight: 'bold',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}>
                  {`${t('deliveryCompany')}:${
                    parcel.deliveryCompany
                      ? getLocalizedDomesticDeliveryCompany(
                          parcel.deliveryCompany,
                        )
                      : '未登録'
                  }`}
                </Typography>
                <Typography
                  sx={{
                    fontSize: isMobile ? '14px' : '16px',
                    fontWeight: 'bold',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}>
                  {`${t('domesticSlipNumber')}:${parcel.slipNumber ?? ''}`}
                </Typography>
                <Typography
                  sx={{fontSize: isMobile ? '10px' : '12px', opacity: '0.6'}}>
                  {`${t('registrationDate')}:${convertDateToDateTimeString(
                    new Date(parcel.createdAt),
                  )}`}
                </Typography>
              </Stack>
            </Grid>
            {isMobile ? (
              <>
                <Grid item xs={1} sx={{margin: '0 0 0 auto'}} />
                <Grid item xs>
                  <Grid item>
                    <RegisterButton id={parcel.id} />
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid item sx={{margin: '0 0 0 auto'}}>
                <Grid container spacing={3} sx={{alignItems: 'center'}}>
                  <Grid item>
                    <RegisterButton id={parcel.id} />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
          {parcel.items?.items.map((item, index) =>
            item ? (
              <div key={item.id}>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{mb: '8px'}}>
                  <Typography
                    fontWeight="bold"
                    sx={{fontSize: isMobile ? 14 : 16}}>
                    {t('point', {x: index + 1})}
                  </Typography>
                  <ReactToPrint
                    trigger={() => (
                      <Button variant="outlined" size="small">
                        {t('print')}
                      </Button>
                    )}
                    content={() => ref.current[index].current}
                    documentTitle={`${parcel.slipNumber ?? ''}-${index + 1}`}
                  />
                </Stack>
                <DomesticParcelDetail
                  slipNumber={
                    parcel.slipNumber ? `${parcel.slipNumber}-${index + 1}` : ''
                  }
                  item={item}
                  ref={ref.current[index]}
                />
                {parcel.items?.items.length &&
                  parcel.items?.items.length > index + 1 && (
                    <Divider
                      sx={{
                        my: '24px',
                        border: '1px dashed rgba(0, 0, 0, 0.12)',
                      }}
                    />
                  )}
              </div>
            ) : null,
          )}
        </Stack>
      ) : null}
    </Edit>
  );
};
