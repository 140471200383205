import {Grid, Link, Typography, TypographyProps} from '@mui/material';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {ItemCategory, RegistrationStatus} from '../API';
import {useIsMobile} from '../hooks/useIsMobile';
import {ITEM_CATEGORY_INFO} from '../utils/constants';

interface Props {
  item: {
    id: string;
    name: string;
    price: number;
    category: ItemCategory;
    quantity: number;
    url: string;
    note?: string | null;
    registrationStatus: RegistrationStatus;
    createdAt: string;
    updatedAt: string;
    weightKg?: number | null;
    domesticParcelItemsId?: string | null;
    internationalParcelItemsId?: string | null;
    owner?: string | null;
  };
}
export const ItemInfo: FC<Props> = ({item}) => {
  const {t} = useTranslation();
  const isMobile = useIsMobile();

  return (
    <>
      <Grid container spacing={1}>
        <Grid
          item
          container
          xs={isMobile ? 12 : 6}
          spacing={1}
          direction="column">
          <Grid item>
            <ItemTitleTypography isMobile={isMobile}>
              {`${t('itemName')}：`}
            </ItemTitleTypography>
            <ItemBodyTypography isMobile={isMobile}>
              {item.name}
            </ItemBodyTypography>
          </Grid>
          <Grid item>
            <ItemTitleTypography isMobile={isMobile}>
              {`${t('itemPrice')}：`}
            </ItemTitleTypography>
            <ItemBodyTypography isMobile={isMobile}>
              {item.price.toLocaleString()}
              {`${t('yen')}`}
            </ItemBodyTypography>
          </Grid>
          <Grid item>
            <ItemTitleTypography isMobile={isMobile}>
              {`${t('itemQuantity')}：`}
            </ItemTitleTypography>
            <ItemBodyTypography isMobile={isMobile}>
              {item.quantity}
            </ItemBodyTypography>
          </Grid>
          {item?.weightKg && (
            <Grid item>
              <ItemTitleTypography isMobile={isMobile}>
                {`${t('weight')}：`}
              </ItemTitleTypography>
              <ItemBodyTypography isMobile={isMobile}>
                {`${item.weightKg}Kg`}
              </ItemBodyTypography>
            </Grid>
          )}
        </Grid>
        <Grid
          item
          container
          xs={isMobile ? 12 : 6}
          spacing={1}
          direction="column">
          <Grid item>
            <ItemTitleTypography isMobile={isMobile}>
              {`${t('itemType')}：`}
            </ItemTitleTypography>
            <ItemBodyTypography isMobile={isMobile}>
              {ITEM_CATEGORY_INFO[item.category].getLabel()}
            </ItemBodyTypography>
          </Grid>
          <Grid item>
            <ItemTitleTypography isMobile={isMobile}>
              {`${t('itemURL')}：`}
            </ItemTitleTypography>
            <ItemBodyTypography isMobile={isMobile}>
              <Link component="a" href={item.url} target="_blank">
                {item.url}
              </Link>
            </ItemBodyTypography>
          </Grid>
          <Grid item>
            <ItemTitleTypography isMobile={isMobile}>
              {`${t('note')}：`}
            </ItemTitleTypography>
            <ItemBodyTypography isMobile={isMobile} whiteSpace="pre-wrap">
              {item.note}
            </ItemBodyTypography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

type CustomTypographyProps = TypographyProps & {isMobile: boolean};
const ItemTitleTypography: FC<CustomTypographyProps> = ({
  isMobile,
  ...props
}) => {
  return (
    <Typography
      sx={{
        color: '#595959',
        fontSize: isMobile ? '12px !important' : '14px !important',
      }}
      component="span"
      {...props}>
      {props.children}
    </Typography>
  );
};
const ItemBodyTypography: FC<CustomTypographyProps> = ({
  isMobile,
  ...props
}) => {
  return (
    <Typography
      sx={{
        fontWeight: 'bold',
        fontSize: isMobile ? '12px !important' : '14px !important',
        wordBreak: 'break-all',
      }}
      component="span"
      {...props}>
      {props.children}
    </Typography>
  );
};
