/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import {Typography} from '@mui/material';
import {convert, stringToCode} from 'encoding-japanese';
import jsonExport from 'jsonexport/dist';
import {
  Resource,
  Datagrid,
  List,
  TextField,
  Layout,
  LayoutProps,
  AppBar,
  ListProps,
  Admin,
  EmailField,
  DateField,
  UrlField,
  ReferenceField,
  BooleanField,
  WithRecord,
  AppBarProps,
  SelectInput,
  FilterProps,
  downloadCSV,
} from 'react-admin';
import {
  buildAuthProvider,
  buildDataProvider,
  AmplifyFilter,
} from 'react-admin-amplify';
import {
  DomesticParcel,
  InternationalParcel,
  RegistrationStatus,
  Delivered,
} from '../API';
import awsExports from '../aws-exports';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import {DomesticParcelEdit} from './AdminDomesticParcel';
import {InternationalParcelEdit} from './AdminInternationalParcel';
import {ItemEdit} from './AdminItem';
import {UserEdit} from './AdminUser';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const exporter = (data: any[]) => {
  jsonExport(data, (_err, csv) => {
    const unicodeArray = stringToCode(csv);
    const shiftJisArray = convert(unicodeArray, {
      to: 'SJIS',
      from: 'UNICODE',
    });
    const csvOfShiftJis = new Uint8Array(shiftJisArray);
    downloadCSV(csvOfShiftJis, 'download');
  });
};

type AmplifyFilterProps = {
  defaultQuery: string;
  setQuery?: React.Dispatch<string>;
} & FilterProps;

const UsersFilter = (props: Omit<AmplifyFilterProps, 'children'>) => (
  <AmplifyFilter {...props}>
    <SelectInput
      source="userByRegistrationStatus.registrationStatus"
      label="ステータス"
      alwaysOn
      resettable
      choices={[
        {id: 'Requesting', name: RegistrationStatus.Requesting},
        {id: 'Accepted', name: RegistrationStatus.Accepted},
        {id: 'Denied', name: RegistrationStatus.Denied},
      ]}
    />
  </AmplifyFilter>
);

const UserList = (props: ListProps) => (
  <List
    exporter={exporter}
    title="ユーザー一覧"
    {...props}
    filters={<UsersFilter defaultQuery="listUsers" />}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="name" label="氏名" />
      <EmailField source="email" label="メールアドレス" />
      <DateField source="birthday" label="生年月日" />
      <TextField source="gender" label="性別" />
      <TextField source="registrationStatus" label="ステータス" />
    </Datagrid>
  </List>
);

const ItemsFilter = (props: Omit<AmplifyFilterProps, 'children'>) => (
  <AmplifyFilter {...props}>
    <SelectInput
      source="itemByRegistrationStatus.registrationStatus"
      label="ステータス"
      alwaysOn
      resettable
      choices={[
        {name: 'Requesting', id: RegistrationStatus.Requesting},
        {name: 'Accepted', id: RegistrationStatus.Accepted},
        {name: 'Denied', id: RegistrationStatus.Denied},
      ]}
    />
  </AmplifyFilter>
);

const ItemList = (props: ListProps) => (
  <List
    exporter={exporter}
    title="商品一覧"
    {...props}
    filters={<ItemsFilter defaultQuery="listItems" />}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="name" label="商品名" />
      <TextField source="category" label="カテゴリー" />
      <TextField source="price" label="価格" />
      <TextField source="quantity" label="数量" />
      <UrlField source="url" label="URL" />
      <ReferenceField source="userItemsId" reference="users" label="ユーザー">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="registrationStatus" label="ステータス" />
    </Datagrid>
  </List>
);

const ParcelsFilter = ({
  source,
  ...props
}: {source: string} & Omit<AmplifyFilterProps, 'children'>) => (
  <AmplifyFilter {...props}>
    <SelectInput
      source={`${source}.deliveredEnum`}
      label="到着済み"
      alwaysOn
      resettable
      choices={[
        {name: 'Yes', id: Delivered.Yes},
        {name: 'No', id: Delivered.No},
      ]}
    />
  </AmplifyFilter>
);

const DomesticParcelList = (props: ListProps) => (
  <List
    exporter={exporter}
    title="国内小包一覧"
    {...props}
    filters={
      <ParcelsFilter
        defaultQuery="listDomesticParcels"
        source="domesticParcelByDeliveredEnum"
      />
    }>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <DateField source="createdAt" label="登録日時" showTime />
      <WithRecord
        label="商品数量"
        render={(record: DomesticParcel) => (
          <Typography>{record?.items?.items.length}点</Typography>
        )}
      />
      <ReferenceField
        source="userDomesticParcelId"
        reference="users"
        label="ユーザー">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="slipNumber" label="伝票番号" />
      <BooleanField source="delivered" label="到着済み" />
    </Datagrid>
  </List>
);

const InternationalParcelList = (props: ListProps) => (
  <List
    exporter={exporter}
    title="国際小包一覧"
    {...props}
    filters={
      <ParcelsFilter
        defaultQuery="listInternationalParcels"
        source="internationalParcelByDeliveredEnum"
      />
    }>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <DateField source="createdAt" label="登録日時" showTime />
      <WithRecord
        label="商品数量"
        render={(record: InternationalParcel) => (
          <Typography>{record?.items?.items.length}点</Typography>
        )}
      />
      <ReferenceField
        source="userInternationalParcelId"
        reference="users"
        label="ユーザー">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="cost" label="申告価格" />
      <TextField source="slipNumber" label="伝票番号" />
      <BooleanField source="delivered" label="配達済み" />
    </Datagrid>
  </List>
);

const authProvider = buildAuthProvider({
  authGroups: ['Admins'],
});

const dataProvider = buildDataProvider(
  {
    queries,
    mutations,
  },
  {
    storageBucket: awsExports.aws_user_files_s3_bucket,
    storageRegion: awsExports.aws_user_files_s3_bucket_region,
    enableAdminQueries: true,
  },
);

const CustomizedAppBar = (props: AppBarProps) => <AppBar {...props} />;
const CustomizedLayout = (props: LayoutProps) => (
  <Layout {...props} appBar={CustomizedAppBar} />
);
export const AdminScreen = () => {
  return (
    <Admin
      layout={CustomizedLayout}
      basename="/admin"
      authProvider={authProvider}
      dataProvider={dataProvider}>
      <Resource
        name="users"
        options={{label: 'ユーザー一覧'}}
        list={UserList}
        edit={UserEdit}
      />
      <Resource
        name="items"
        options={{label: '商品一覧'}}
        list={ItemList}
        edit={ItemEdit}
      />
      <Resource
        name="domesticParcels"
        options={{label: '国内小包一覧'}}
        list={DomesticParcelList}
        edit={DomesticParcelEdit}
      />
      <Resource
        name="internationalParcels"
        options={{label: '国際小包一覧'}}
        list={InternationalParcelList}
        edit={InternationalParcelEdit}
      />
    </Admin>
  );
};
