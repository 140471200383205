import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import {
  Divider,
  Typography,
  Stack,
  Button,
  Dialog,
  DialogActions,
} from '@mui/material';
import {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {Delivered, UpdateInternationalParcelMutationVariables} from '../API';
import {Accordion} from '../components/Accordion';
import {InternationalParcelsTips} from '../components/InternationalParcelsTips';
import {ItemInfo} from '../components/ItemInfo';
import {useIsMobile} from '../hooks/useIsMobile';
import {
  useInternationalParcelsInTransit,
  useUpdateInternationalParcelMutation,
} from '../queries/parcel';
import {getLocalizedInternationalDeliveryCompany} from '../utils/deliveryCompany';
import {notEmpty} from '../utils/type';

const RegisterButton = ({id}: {id: string}) => {
  const {t} = useTranslation();
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const {mutate} = useUpdateInternationalParcelMutation();

  const onReceiveInternationalParcel = useCallback(() => {
    const variables: UpdateInternationalParcelMutationVariables = {
      input: {
        id,
        delivered: true,
        deliveredEnum: Delivered.Yes,
      },
    };

    mutate(variables, {
      onSuccess: () => {
        setOpen(false);
      },
      onError: () => {
        navigate('/error');
      },
    });
  }, [id, mutate, navigate]);

  return (
    <>
      <Button
        id="configurableButton"
        variant="contained"
        size={isMobile ? 'small' : 'medium'}
        onClick={() => setOpen(true)}>
        {t('receiptConfirmation')}
      </Button>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        PaperProps={{
          style: {
            width: isMobile ? '100%' : '500px',
          },
        }}>
        <Stack flexDirection="row" alignItems="center" sx={{p: '32px'}}>
          <ErrorOutlineOutlinedIcon
            fontSize="medium"
            sx={{mr: '16px', color: '#FAAD14'}}
          />
          <Typography sx={{fontSize: isMobile ? '14px' : '16px'}}>
            {t('didYouReceive')}
          </Typography>
        </Stack>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
            }}
            variant="outlined"
            color="inherit">
            {t('no')}
          </Button>
          <Button variant="contained" onClick={onReceiveInternationalParcel}>
            {t('yes')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const InternationalParcelsInTransit = () => {
  const {t} = useTranslation();
  const {data} = useInternationalParcelsInTransit();
  const parcels = useMemo(
    () => data?.items.filter(notEmpty) ?? [],
    [data?.items],
  );
  const isMobile = useIsMobile();

  return (
    <>
      {parcels.map((parcel, index) =>
        parcel?.shippingFee && parcel?.weightKg ? (
          <Stack key={parcel.id} sx={{mb: isMobile ? '16px' : '24px'}}>
            <Accordion
              key={index}
              title={`${t('internationalSlipNumber')}:${
                parcel.slipNumber ?? ''
              }`}
              configurableButton={<RegisterButton id={parcel.id} />}>
              <InternationalParcelsTips
                shippingFee={parcel.shippingFee}
                weightKg={parcel.weightKg}
                deliveryCompany={
                  parcel.deliveryCompany
                    ? getLocalizedInternationalDeliveryCompany(
                        parcel.deliveryCompany,
                      )
                    : ''
                }
                sx={{
                  p: '8px',
                  mb: {xs: '12px', md: '24px'},
                  borderRadius: '8px',
                }}
              />
              {parcel.items?.items.map((item, index) =>
                item ? (
                  <div key={item.id}>
                    {parcel.items?.items.length &&
                    parcel.items?.items.length > 1 ? (
                      <Typography
                        fontWeight="bold"
                        sx={{fontSize: isMobile ? 14 : 16}}>
                        {t('point', {x: index + 1})}
                      </Typography>
                    ) : null}
                    <ItemInfo item={item} />
                    {parcel.items?.items.length &&
                      parcel.items?.items.length > index + 1 && (
                        <Divider
                          sx={{
                            my: '24px',
                            border: '1px dashed rgba(0, 0, 0, 0.12)',
                          }}
                        />
                      )}
                  </div>
                ) : null,
              )}
            </Accordion>
          </Stack>
        ) : null,
      )}
    </>
  );
};
