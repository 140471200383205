import {Divider, Stack, Typography} from '@mui/material';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Accordion} from '../components/Accordion';
import {ItemInfo} from '../components/ItemInfo';
import {useIsMobile} from '../hooks/useIsMobile';
import {useNotDeliveredInternationalParcels} from '../queries/parcel';
import {convertDateToDateTimeString} from '../utils/date';
import {notEmpty} from '../utils/type';

export const NotDeliveredInternationalParcels = () => {
  const {t} = useTranslation();
  const isMobile = useIsMobile();
  const {data} = useNotDeliveredInternationalParcels();
  const parcels = useMemo(
    () => data?.items.filter(notEmpty) ?? [],
    [data?.items],
  );
  return (
    <>
      {parcels.map((parcel, index) => (
        <Stack key={parcel.id} sx={{mb: isMobile ? '16px' : '24px'}}>
          <Accordion
            key={index}
            title={`${t(
              'internationalShippingRequestDate',
            )}:${convertDateToDateTimeString(new Date(parcel.createdAt))}`}>
            {parcel.items?.items.filter(notEmpty).map((item, index) => (
              <div key={item.id}>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  sx={{mb: '12px'}}>
                  <Typography
                    fontWeight="bold"
                    sx={{fontSize: isMobile ? 14 : 16}}>
                    {t('point', {x: index + 1})}
                  </Typography>
                </Stack>
                <ItemInfo item={item} />
                {parcel.items && parcel.items.items.length > index + 1 && (
                  <Divider
                    sx={{
                      my: '24px',
                      border: '1px dashed rgba(0, 0, 0, 0.12)',
                    }}
                  />
                )}
              </div>
            ))}
          </Accordion>
        </Stack>
      ))}
    </>
  );
};
