import {ItemCategory} from '../API';
import i18n from '../i18n/i18n';

export const JAPAN_POST_CHARGES: Array<{grams: number; charge: number}> = [
  {grams: 500, charge: 1450}, // 500g
  {grams: 600, charge: 1600}, // 600g
  {grams: 700, charge: 1750}, // 700g
  {grams: 800, charge: 1900}, // 800g
  {grams: 900, charge: 2050}, // 900g
  {grams: 1000, charge: 2200}, // 1.0kg
  {grams: 1250, charge: 2500}, // 1.25kg
  {grams: 1500, charge: 2800}, // 1.5kg
  {grams: 1750, charge: 3100}, // 1.75kg
  {grams: 2000, charge: 3400}, // 2.0kg
  {grams: 2500, charge: 3900}, // 2.5kg
  {grams: 3000, charge: 4400}, // 3.0kg
  {grams: 3500, charge: 4900}, // 3.5kg
  {grams: 4000, charge: 5400}, // 4.0kg
  {grams: 4500, charge: 5900}, // 4.5kg
  {grams: 5000, charge: 6400}, // 5.0kg
  {grams: 5500, charge: 6900}, // 5.5kg
  {grams: 6000, charge: 7400}, // 6.0kg
  {grams: 7000, charge: 8200}, // 7.0kg
  {grams: 8000, charge: 9000}, // 8.0kg
  {grams: 9000, charge: 9800}, // 9.0kg
  {grams: 10000, charge: 10600}, // 10.0kg
  {grams: 11000, charge: 11400}, // 11.0kg
  {grams: 12000, charge: 12200}, // 12.0kg
  {grams: 13000, charge: 13000}, // 13.0kg
  {grams: 14000, charge: 13800}, // 14.0kg
  {grams: 15000, charge: 14600}, // 15.0kg
  {grams: 16000, charge: 15400}, // 16.0kg
  {grams: 17000, charge: 16200}, // 17.0kg
  {grams: 18000, charge: 17000}, // 18.0kg
  {grams: 19000, charge: 17800}, // 19.0kg
  {grams: 20000, charge: 18600}, // 20.0kg
  {grams: 21000, charge: 19400}, // 21.0kg
  {grams: 22000, charge: 20200}, // 22.0kg
  {grams: 23000, charge: 21000}, // 23.0kg
  {grams: 24000, charge: 21800}, // 24.0kg
  {grams: 25000, charge: 22600}, // 25.0kg
  {grams: 26000, charge: 23400}, // 26.0kg
  {grams: 27000, charge: 24200}, // 27.0kg
  {grams: 28000, charge: 25000}, // 28.0kg
  {grams: 29000, charge: 25800}, // 29.0kg
  {grams: 30000, charge: 26600}, // 30.0kg
];

export const SF_EXPRESS_CHARGES: Array<{grams: number; charge: number}> = [
  {grams: 3000, charge: 3360}, // 3.0kg
  {grams: 3500, charge: 3610}, // 3.5kg
  {grams: 4000, charge: 3860}, // 4.0kg
  {grams: 4500, charge: 4110}, // 4.5kg
  {grams: 5000, charge: 4360}, // 5.0kg
  {grams: 5500, charge: 4610}, // 5.5kg
  {grams: 6000, charge: 4860}, // 6.0kg
  {grams: 6500, charge: 5110}, // 6.5kg
  {grams: 7000, charge: 5360}, // 7.0kg
  {grams: 7500, charge: 5610}, // 7.5kg
  {grams: 8000, charge: 5860}, // 8.0kg
  {grams: 8500, charge: 6110}, // 8.5kg
  {grams: 9000, charge: 6360}, // 9.0kg
  {grams: 9500, charge: 6610}, // 9.5kg
  {grams: 10000, charge: 6860}, // 10.0kg
];

export const SF_EXPRESS_FUEL_SURCHARGE_RATE = 0.3;
export const SF_EXPRESS_TEMPORARY_SURCHARGE_YEN = 100;

export const ITEM_CATEGORY_INFO: {
  [key in ItemCategory]: {
    getLabel: () => string;
    isValidationRequired: boolean;
  };
} = {
  Clothing: {
    getLabel: () => i18n.t('clothing'),
    isValidationRequired: false,
  },
  Makeup: {getLabel: () => i18n.t('makeup'), isValidationRequired: true},
  Baby: {getLabel: () => i18n.t('baby'), isValidationRequired: true},
  LeatherGoods: {
    getLabel: () => i18n.t('leatherGoods'),
    isValidationRequired: false,
  },
  Toys: {getLabel: () => i18n.t('toys'), isValidationRequired: false},
  Books: {getLabel: () => i18n.t('books'), isValidationRequired: false},
  Games: {getLabel: () => i18n.t('games'), isValidationRequired: false},
  Stationery: {
    getLabel: () => i18n.t('stationery'),
    isValidationRequired: false,
  },
  Sports: {getLabel: () => i18n.t('sports'), isValidationRequired: false},
  Other: {getLabel: () => i18n.t('other'), isValidationRequired: true},
};
