import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  DialogTitle,
  DialogProps,
  Select,
  MenuItem,
} from '@mui/material';
import {FC, useCallback} from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {UpdateDomesticParcelInput} from '../API';
import {useIsMobile} from '../hooks/useIsMobile';
import {useUpdateDomesticParcelMutation} from '../queries/parcel';
import {DomesticDeliveryCompany} from '../utils/deliveryCompany';
import {onPromise} from '../utils/promise';

interface Props {
  id: string;
  open: DialogProps['open'];
  onClose: () => void;
}
interface FormProps {
  slipNumber: UpdateDomesticParcelInput['slipNumber'];
  deliveryCompany: DomesticDeliveryCompany;
}
export const RegisterSlipNumberDialog: FC<Props> = ({id, open, onClose}) => {
  const isMobile = useIsMobile();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    resetField,
    formState: {errors},
  } = useForm<FormProps>({
    mode: 'onBlur',
  });
  const {mutate} = useUpdateDomesticParcelMutation();
  const onSave = useCallback(
    (data: FormProps) => {
      try {
        const input = {
          id,
          slipNumber: data.slipNumber,
          deliveryCompany: data.deliveryCompany,
        };
        mutate(
          {input},
          {
            onSuccess: () => {
              onClose();
            },
          },
        );
      } catch (_e) {
        navigate('/error');
      }
    },
    [id, mutate, navigate, onClose],
  );
  const handleClose = useCallback(() => {
    resetField('slipNumber');
    resetField('deliveryCompany');
    onClose();
  }, [onClose, resetField]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          width: isMobile ? '100%' : '500px',
        },
      }}>
      <DialogTitle
        sx={{fontSize: isMobile ? '14px' : '16px', fontWeight: 'bold'}}>
        {t('registerSlipNumber')}
      </DialogTitle>
      <DialogContent sx={{display: 'grid', gap: '8px'}}>
        <DialogContentText
          sx={{fontSize: isMobile ? '12px' : '14px', color: 'black'}}>
          {t('pleaseInputSlipNumber')}
        </DialogContentText>
        <TextField
          {...register('slipNumber', {required: true})}
          size="small"
          placeholder={t('domesticSlipNumber')}
          error={'slipNumber' in errors}
          fullWidth
        />
        <DialogContentText
          sx={{
            fontSize: isMobile ? '12px' : '14px',
            color: 'black',
            mt: isMobile ? '8px' : '12px',
          }}>
          {t('domesticShippingCompany')}
        </DialogContentText>
        <Select
          {...register('deliveryCompany', {required: true})}
          defaultValue=""
          error={'deliveryCompany' in errors}
          size="small">
          <MenuItem value="youpack"> {t('youPack')}</MenuItem>
          <MenuItem value="yamato"> {t('yamato')}</MenuItem>
          <MenuItem value="hikyaku"> {t('hikyaku')}</MenuItem>
          <MenuItem value="kangaroo"> {t('kangaroo')}</MenuItem>
          <MenuItem value="fukuyama"> {t('fukuyama')}</MenuItem>
          <MenuItem value="others"> {t('other')}</MenuItem>
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          {t('cancel')}
        </Button>
        <Button onClick={onPromise(handleSubmit(onSave))} variant="contained">
          {t('done')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
