import {Stack, StackProps, Typography} from '@mui/material';

export const FormLabel = ({
  label,
  isRequired = false,
  ...props
}: {
  label: string;
  isRequired?: boolean;
} & StackProps) => {
  return (
    <Stack {...props} direction="row">
      {isRequired && (
        <Typography
          fontSize="14px"
          color="red"
          display="inline"
          sx={{mr: '4px'}}>
          *
        </Typography>
      )}
      <Typography fontSize="14px" display="inline">
        {label}
      </Typography>
    </Stack>
  );
};
