import {Stack, StackProps, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';

type Props = {
  isRequired?: boolean;
} & StackProps;

export const LabelTitle = ({isRequired = false, ...props}: Props) => {
  const {t} = useTranslation();
  return (
    <Stack
      spacing={1}
      direction="row"
      alignItems="center"
      width="200px"
      {...props}>
      <Typography fontSize="14px">{props.children}</Typography>
      {isRequired && (
        <Typography
          sx={{
            color: 'warning.main',
            fontSize: '12px',
            wordBreak: 'keep-all',
          }}>
          {t('require')}
        </Typography>
      )}
    </Stack>
  );
};
