import * as yup from 'yup';
import {Gender, InternationalDeliveryCompany, ItemCategory} from '../API';
import i18n from '../i18n/i18n';
import {REGION_INFO} from '../utils/region';

export const AUTH_SCHEMA = {
  email: yup.string().email().max(128).required(),
  password: yup
    .string()
    .min(8, () => i18n.t('pleaseInputHalfAngleEnglishNumeralsAbove8Words'))
    .matches(
      /^(?=.*?[a-zA-Z])(?=.*?\d)[a-zA-Z\d!?_+*'"`#¥|$%&\-^\\@;:,./=~|[\](){}<>]{1,}$/,
      () => i18n.t('pleaseInputAlphanumericCombinations'),
    )
    .required(() => i18n.t('pleaseInputPassword')),
  authCode: yup
    .string()
    .length(6, () => i18n.t('pleaseInputAuthCode'))
    .matches(/^(?=.*?\d)[\d]{6}$/, () => i18n.t('pleaseInputAuthCode'))
    .required(() => i18n.t('pleaseInputAuthCode')),
  policy: yup.boolean().required().oneOf([true]),
};

export const USER_SCHEMA = {
  name: yup.string().max(100).required(),
  email: AUTH_SCHEMA.email,
  companyName: yup.string().max(100),
  birthday: yup.date().required(),
  gender: yup.mixed<Gender>().oneOf(Object.values(Gender)).required(),
  idPictureUrl: yup.string().min(1).required(),
  job: yup.string().max(64).required(),
  i18nAreaCodeMobile: yup
    .string()
    .oneOf(Object.values(REGION_INFO).map(c => c.countryCode)),
  mobilePhoneNumber: yup.string().max(20),
  i18nAreaCodeFixed: yup
    .string()
    .oneOf(Object.values(REGION_INFO).map(c => c.countryCode)),
  fixedPhoneNumber: yup.string().max(20),
  detailedAddress: yup
    .string()
    .matches(/^[ -~]*$/, () => i18n.t('pleaseInputInEnglish'))
    .max(100)
    .required(),
  city: yup
    .string()
    .matches(/^[ -~]*$/, () => i18n.t('pleaseInputInEnglish'))
    .max(128)
    .required(),
  provinceOrState: yup
    .string()
    .matches(/^[ -~]*$/, () => i18n.t('pleaseInputInEnglish'))
    .max(64)
    .required(),
  countryOrRegion: yup
    .string()
    .oneOf(Object.values(REGION_INFO).map(r => r.regionName))
    .required(),
  postalCode: yup.string().max(20).required(),
};

export const ITEM_SCHEMA = {
  name: yup.string().min(1).max(100).required(),
  price: yup.number().integer().min(1).required(),
  category: yup
    .mixed<ItemCategory>()
    .oneOf(Object.values(ItemCategory))
    .required(),
  quantity: yup.number().integer().min(1).required(),
  url: yup.string().url().required(),
  note: yup.string().max(256),
};

export const SELECT_DELIVERY_COMPANY_SCHEMA = {
  deliveryCompany: yup
    .mixed<InternationalDeliveryCompany>()
    .oneOf(Object.values(InternationalDeliveryCompany))
    .required(),
  declaredPrice: yup.number().integer().min(1).required(),
};
