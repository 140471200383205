import {Button as MuiButton, ButtonProps} from '@mui/material';
import {styled} from '@mui/material/styles';

export const Button = (props: ButtonProps) => {
  return <StyledButton component="span" {...props} />;
};

const StyledButton = styled(MuiButton)(() => ({
  fontSize: '16px',
  fontWeight: '700',
  borderRadius: '6px',
  lineHeight: '1.3',
  '&.MuiButton-text': {
    padding: '8px !important',
    margin: '-8px',
    '&:hover': {
      backgroundColor: '#1890FF',
    },
  },
  '&.MuiButton-sizeMedium': {
    width: '180px',
    padding: '12px',
  },
  '&.MuiButton-sizeSmall': {
    padding: '6px 12px',
    fontWeight: '500',
    fontSize: '14px',
  },
  '&.MuiButton-contained': {
    color: 'white',
    boxShadow: 'unset',
    '&:hover': {
      backgroundColor: '#1890FF',
    },
  },
  '&.MuiButton-containedWarning': {
    '&:hover': {
      backgroundColor: '#c71f1f',
    },
  },
})) as typeof MuiButton;
