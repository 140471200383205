import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {
  AccordionDetails,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import {ReactNode, useState} from 'react';
import {useIsMobile} from '../hooks/useIsMobile';

const EditButton = ({onEdit}: {onEdit: () => void}) => {
  const isMobile = useIsMobile();
  return (
    <IconButton
      sx={{
        bgcolor: '#E9EAEE',
        width: isMobile ? '24px' : '36px',
        height: isMobile ? '24px' : '36px',
      }}
      onClick={onEdit}>
      <EditOutlinedIcon
        sx={{
          width: isMobile ? '16px' : '24px',
          height: isMobile ? '16px' : '24px',
        }}
      />
    </IconButton>
  );
};

export const Accordion = ({
  title,
  subTitle,
  configurableButton,
  children,
  onEdit,
}: {
  title: string;
  subTitle?: string;
  configurableButton?: ReactNode;
  children?: ReactNode;
  onEdit?: () => void;
}) => {
  const isMobile = useIsMobile();
  const [expanded, setExpanded] = useState(true);
  return (
    <MuiAccordion
      expanded={expanded}
      onChange={(event: React.SyntheticEvent, expanded: boolean) => {
        if (
          !(
            event.target instanceof HTMLElement ||
            event.target instanceof SVGElement
          )
        )
          return;
        event.target.id === 'configurableButton'
          ? event.preventDefault()
          : setExpanded(expanded);
      }}>
      <MuiAccordionSummary
        expandIcon={
          <ArrowForwardIosSharpIcon
            sx={{fontSize: isMobile ? '14px' : '16px'}}
          />
        }>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={isMobile ? 11 : undefined}>
            <Stack>
              <Typography
                sx={{
                  fontSize: isMobile ? '14px' : '16px',
                  fontWeight: 'bold',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}>
                {title}
              </Typography>
              <Typography
                sx={{fontSize: isMobile ? '10px' : '12px', opacity: '0.6'}}>
                {subTitle}
              </Typography>
            </Stack>
          </Grid>
          {isMobile ? (
            <>
              <Grid item xs={1} sx={{margin: '0 0 0 auto'}}>
                {onEdit ? (
                  <Grid item>
                    <EditButton onEdit={onEdit} />
                  </Grid>
                ) : null}
              </Grid>
              <Grid item xs>
                {configurableButton ? (
                  <Grid item>{configurableButton}</Grid>
                ) : null}
              </Grid>
            </>
          ) : (
            <Grid item sx={{margin: '0 0 0 auto'}}>
              <Grid container spacing={3} sx={{alignItems: 'center'}}>
                {onEdit ? (
                  <Grid item>
                    <EditButton onEdit={onEdit} />
                  </Grid>
                ) : null}
                {configurableButton ? (
                  <Grid item>{configurableButton}</Grid>
                ) : null}
              </Grid>
            </Grid>
          )}
        </Grid>
      </MuiAccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </MuiAccordion>
  );
};
