import {CircularProgress, Container, Stack} from '@mui/material';
import {
  BrowserRouter,
  Navigate,
  Route,
  RouteProps,
  Routes,
  useLocation,
} from 'react-router-dom';
import {Header} from '../components/Header';
import {useOAuthUserState} from '../providers/AuthProvider';
import {useUser} from '../queries/user';
import {AdminScreen} from './Admin';
import {CompleteInternationalShippingRequest} from './CompleteInternationalShippingRequest';
import {CompleteResetPasswordScreen} from './CompleteResetPassword';
import {ConfirmInternationalShippingRequest} from './ConfirmInternationalShipping';
import {EditParcel} from './EditParcel';
import {ErrorScreen} from './Error';
import {ForgotPasswordScreen} from './ForgotPassword';
import {Home} from './Home';
import {InternationalShippingRequest} from './InternationalShippingRequest';
import {NotFoundErrorScreen} from './NotFoundError';
import {Profile} from './Profile';
import {ProfileSettings} from './ProfileSettings';
import {ProgressTracker} from './ProgressTracker';
import {RegisterItem} from './RegisterItem';
import {ResetPasswordScreen} from './ResetPassword';
import {SelectDeliveryCompany} from './SelectDeliveryCompany';
import {SignInScreen} from './SignIn';
import {SignUpScreen} from './SignUp';
import {SignUpVerificationScreen} from './SignUpVerification';

const RequireAuth = ({children, ..._rest}: RouteProps) => {
  const auth = useOAuthUserState();
  const location = useLocation();
  if (!auth.user) {
    return <Navigate to="/signIn" state={{from: location}} replace />;
  }
  return <>{children}</>;
};

export const Screens = (): JSX.Element => {
  const userState = useOAuthUserState();
  const groups =
    userState.user?.signInUserSession.accessToken.payload['cognito:groups'];
  const isAdmin = groups ? groups.includes('Admins') : false;
  const user = useUser(userState.user?.username ?? '');
  if (userState.state === 'loading' || user.isLoading) {
    return (
      <Stack
        sx={{
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <CircularProgress />
      </Stack>
    );
  }
  const DefaultScreens = (
    <>
      <Route path="/error" element={<ErrorScreen />} />
    </>
  );
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/signIn" element={<SignInScreen />} />
        <Route path="/forgotPassword" element={<ForgotPasswordScreen />} />
        <Route path="/resetPassword" element={<ResetPasswordScreen />} />
        <Route
          path="/completeResetPassword"
          element={<CompleteResetPasswordScreen />}
        />
        <Route path="/signUp" element={<SignUpScreen />} />
        <Route
          path="/signUpVerification"
          element={<SignUpVerificationScreen />}
        />
        {DefaultScreens}
        <Route
          path="*"
          element={
            <RequireAuth>
              {user.data ? (
                isAdmin ? (
                  <Routes>
                    <Route index element={<AdminScreen />} />
                    <Route path="admin/*" element={<AdminScreen />} />
                    {DefaultScreens}
                    <Route path="*" element={<NotFoundErrorScreen />} />
                  </Routes>
                ) : (
                  <>
                    <Header />
                    <Container>
                      <Routes>
                        <Route index element={<Home />} />
                        <Route path="/profile" element={<Profile />} />
                        <Route
                          path="/RegisterItem"
                          element={<RegisterItem />}
                        />
                        <Route
                          path="/progressTracker"
                          element={<ProgressTracker />}
                        />
                        <Route path="/editParcel" element={<EditParcel />} />
                        <Route
                          path="/internationalShippingRequest"
                          element={<InternationalShippingRequest />}
                        />
                        <Route
                          path="/selectDeliveryCompany"
                          element={<SelectDeliveryCompany />}
                        />
                        <Route
                          path="/confirmInternationalShippingRequest"
                          element={<ConfirmInternationalShippingRequest />}
                        />
                        <Route
                          path="/completeInternationalShippingRequest"
                          element={<CompleteInternationalShippingRequest />}
                        />
                        {DefaultScreens}
                        <Route path="*" element={<NotFoundErrorScreen />} />
                      </Routes>
                    </Container>
                  </>
                )
              ) : (
                <>
                  <Header />
                  <Container>
                    <Routes>
                      <Route index element={<ProfileSettings />} />
                      {DefaultScreens}
                      <Route path="*" element={<NotFoundErrorScreen />} />
                    </Routes>
                  </Container>
                </>
              )}
            </RequireAuth>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};
