import {GraphQLResult} from '@aws-amplify/api-graphql';
import {API, graphqlOperation} from 'aws-amplify';
import {useMutation, useQuery} from 'react-query';
import {
  CreateUserMutation,
  CreateUserMutationVariables,
  GetUserQuery,
  GetUserQueryVariables,
  ListUsersQuery,
} from '../API';
import {createUser} from '../graphql/mutations';
import {getUser, listUsers} from '../graphql/queries';

export const useUsers = () => {
  return useQuery(['users'], async () => {
    const result = (await API.graphql(
      graphqlOperation(listUsers, {}),
    )) as GraphQLResult<ListUsersQuery>;
    return result.data?.listUsers;
  });
};

export const useUser = (userId: string) => {
  return useQuery(
    ['user', userId],
    async () => {
      const variables: GetUserQueryVariables = {id: userId};
      const result = (await API.graphql(
        graphqlOperation(getUser, variables),
      )) as GraphQLResult<GetUserQuery>;
      return result.data?.getUser;
    },
    {
      enabled: !!userId,
    },
  );
};

export const useCreateUserMutation = () => {
  return useMutation(async (variables: CreateUserMutationVariables) => {
    const result = (await API.graphql(
      graphqlOperation(createUser, variables),
    )) as GraphQLResult<CreateUserMutation>;
    return result.data?.createUser;
  });
};
