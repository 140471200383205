import {Gender} from '../API';
import i18n from '../i18n/i18n';

export const getLocalizedGender = (gender: Gender) => {
  return {
    [Gender.Female]: () => i18n.t('female'),
    [Gender.Male]: () => i18n.t('male'),
    [Gender.Other]: () => i18n.t('other'),
  }[gender]();
};
