import {Omit} from '@material-ui/core';
import {Typography} from '@mui/material';
import {Stack, StackProps} from '@mui/system';
import {ShippingInfo} from '../API';
import {REGION_INFO} from '../utils/region';

export const ShippingInfoTile = <T,>({
  name,
  i18nAreaCodeMobile,
  mobilePhoneNumber,
  i18nAreaCodeFixed,
  fixedPhoneNumber,
  detailedAddress,
  city,
  provinceOrState,
  countryOrRegion,
  postalCode,
  ...stackProps
}: T & Omit<ShippingInfo, '__typename'> & StackProps) => {
  return (
    <Stack {...stackProps}>
      <Typography fontSize="14px">{name}</Typography>
      <Typography fontSize="14px">{`〒${postalCode ?? ''}`}</Typography>
      <Typography fontSize="14px">
        {`${
          REGION_INFO[countryOrRegion ?? '']?.getLocalizedRegionName() ?? ''
        } ${provinceOrState ?? ''} ${city ?? ''} ${detailedAddress ?? ''}`}
      </Typography>
      <Typography fontSize="14px">{`${i18nAreaCodeFixed ?? ''} ${
        fixedPhoneNumber ?? ''
      }`}</Typography>
      <Typography fontSize="14px">{`${i18nAreaCodeMobile ?? ''} ${
        mobilePhoneNumber ?? ''
      }`}</Typography>
    </Stack>
  );
};
