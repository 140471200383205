import {Typography, Grid, GridProps} from '@mui/material';
import {useTranslation} from 'react-i18next';

export const InternationalParcelsTips = ({
  shippingFee,
  weightKg,
  deliveryCompany,
  ...gridProps
}: {
  shippingFee: number;
  weightKg: number;
  deliveryCompany: string;
} & GridProps) => {
  const {t} = useTranslation();
  return (
    <Grid {...gridProps} container bgcolor="#E7F2FE">
      <Grid item>
        <Typography fontSize="14px">{`【${t(
          'finalConfirmation',
        )}】`}</Typography>
      </Grid>
      <Grid item>
        <Typography fontSize="14px">
          {`${t('shippingFee')} : ${shippingFee.toLocaleString()}${t(
            'yen',
          )} / ${t('weight')} : ${weightKg}kg / ${t(
            'deliveryCompany',
          )} : ${deliveryCompany}`}
        </Typography>
      </Grid>
    </Grid>
  );
};
