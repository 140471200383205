import {GraphQLResult} from '@aws-amplify/api-graphql';
import {API, graphqlOperation} from 'aws-amplify';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {
  CreateItemMutation,
  CreateItemMutationVariables,
  DeleteItemMutation,
  DeleteItemMutationVariables,
  ListItemsQuery,
  UpdateItemMutation,
  UpdateItemMutationVariables,
} from '../API';
import {createItem, deleteItem, updateItem} from '../graphql/mutations';
import {listItems} from '../graphql/queries';

export const useItems = () => {
  return useQuery(['items'], async () => {
    const result = (await API.graphql(
      graphqlOperation(listItems),
    )) as GraphQLResult<ListItemsQuery>;
    return result.data?.listItems;
  });
};

export const useCreateItemMutation = () => {
  return useMutation(async (variables: CreateItemMutationVariables) => {
    const result = (await API.graphql(
      graphqlOperation(createItem, variables),
    )) as GraphQLResult<CreateItemMutation>;
    return result.data?.createItem;
  });
};

export const useUpdateItemMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (variables: UpdateItemMutationVariables) => {
      const result = (await API.graphql(
        graphqlOperation(updateItem, variables),
      )) as GraphQLResult<UpdateItemMutation>;
      return result.data?.updateItem;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries();
      },
    },
  );
};

export const useDeleteItemMutation = () => {
  return useMutation(async (variables: DeleteItemMutationVariables) => {
    const result = (await API.graphql(
      graphqlOperation(deleteItem, variables),
    )) as GraphQLResult<DeleteItemMutation>;
    return result.data?.deleteItem;
  });
};
