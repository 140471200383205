import i18n from '../i18n/i18n';

export const REGION_INFO: {
  [region: string]: {
    regionName: string;
    countryCode: string;
    getLocalizedRegionName: () => string;
    getLocalizedCountryCode: () => string;
  };
} = {
  China: {
    regionName: 'China',
    countryCode: '86',
    getLocalizedRegionName: () => i18n.t('china'),
    getLocalizedCountryCode: () =>
      `(+${
        REGION_INFO.China.countryCode
      })${REGION_INFO.China.getLocalizedRegionName()}`,
  },
  Japan: {
    regionName: 'Japan',
    countryCode: '81',
    getLocalizedRegionName: () => i18n.t('japan'),
    getLocalizedCountryCode: () =>
      `(+${
        REGION_INFO.Japan.countryCode
      })${REGION_INFO.Japan.getLocalizedRegionName()}`,
  },
  HongKong: {
    regionName: 'HongKong',
    countryCode: '852',
    getLocalizedRegionName: () => i18n.t('hongKong'),
    getLocalizedCountryCode: () =>
      `(+${
        REGION_INFO.HongKong.countryCode
      })${REGION_INFO.HongKong.getLocalizedRegionName()}`,
  },
} as const;
