import {Divider, Typography, Stack} from '@mui/material';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Accordion} from '../components/Accordion';
import {InternationalParcelsTips} from '../components/InternationalParcelsTips';
import {ItemInfo} from '../components/ItemInfo';
import {useIsMobile} from '../hooks/useIsMobile';
import {useDeliveredInternationalParcels} from '../queries/parcel';
import {getLocalizedInternationalDeliveryCompany} from '../utils/deliveryCompany';
import {notEmpty} from '../utils/type';

export const DeliveredInternationalParcels = () => {
  const {t} = useTranslation();
  const {data} = useDeliveredInternationalParcels();
  const parcels = useMemo(
    () => data?.items.filter(notEmpty) ?? [],
    [data?.items],
  );
  const isMobile = useIsMobile();

  return (
    <>
      {parcels.map((parcel, index) =>
        parcel?.shippingFee && parcel?.weightKg ? (
          <Stack key={parcel.id} sx={{mb: isMobile ? '16px' : '24px'}}>
            <Accordion
              key={index}
              title={`${t('internationalSlipNumber')}:${
                parcel.slipNumber ?? ''
              }`}>
              <InternationalParcelsTips
                shippingFee={parcel.shippingFee}
                weightKg={parcel.weightKg}
                deliveryCompany={
                  parcel.deliveryCompany
                    ? getLocalizedInternationalDeliveryCompany(
                        parcel.deliveryCompany,
                      )
                    : ''
                }
                sx={{
                  p: '8px',
                  mb: {xs: '12px', md: '24px'},
                  borderRadius: '8px',
                }}
              />
              {parcel.items?.items.map((item, index) =>
                item ? (
                  <div key={item.id}>
                    {parcel.items?.items.length &&
                    parcel.items?.items.length > 1 ? (
                      <Typography
                        fontWeight="bold"
                        sx={{fontSize: isMobile ? 14 : 16}}>
                        {t('point', {x: index + 1})}
                      </Typography>
                    ) : null}
                    <ItemInfo item={item} />
                    {parcel.items?.items.length &&
                      parcel.items?.items.length > index + 1 && (
                        <Divider
                          sx={{
                            my: '24px',
                            border: '1px dashed rgba(0, 0, 0, 0.12)',
                          }}
                        />
                      )}
                  </div>
                ) : null,
              )}
            </Accordion>
          </Stack>
        ) : null,
      )}
    </>
  );
};
